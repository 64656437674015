import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { useDispatch, useSelector } from 'react-redux'
import { TableView } from '../../components/TableView'
import { ApiHelper } from '../../lib'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { setDealerPorts } from '../../features/dealerSlice'
import { saveDealerPort } from '../../features/dealerSlice'

export function DealerPorts(props) {
  const headCells = [
    { id: 'name', label: 'city', sort: 'name' },
    {
      id: 'state',
      label: 'state',
      sort: 'state',
    },
  ]

  const dataURL = 'ports'
  const [open, setOpen] = useState(false)

  const [startIndex, setstartIndex] = useState(1)
  const [displayDataCount, setDisplayDataCount] = useState(0)
  const initalLimitPerPage = 10
  const [portId, setPortId] = useState('')

  const initialFilterDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [errorMsg, setErrorMsg] = React.useState('')

  const [paramState, setParamState] = React.useState(tableParameters)
  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const dealerPorts = useSelector(state => state.dealer.dealerPorts)
  const api = new ApiHelper()

  function fetchDisplayData() {
    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let portsUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      portsUrl = portsUrl + '&q=' + paramState.searchKey
    }
    setIsLoading(true)
    api
      .get(portsUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerPorts(result.ports))
          setstartIndex(startIndexVal)
          setDisplayDataCount(result.pagination.count)
          setErrorMsg('')
        } else {
          setErrorMsg('Error occured while retrieving Dealer Ports')
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [paramState, dataURL])

  const handleAddNewPort = () => {
    setPortId('')
    setOpen(true)
  }

  const handleClose = () => {
    dispatch(saveDealerPort({ name: '', state: '' }))
    setOpen(false)
  }

  const handleEdit = portId => {
    let index = dealerPorts.findIndex(obj => obj.id == portId)
    let selectedPort = {}
    selectedPort = { ...dealerPorts[index] }
    dispatch(saveDealerPort(selectedPort))
    setPortId(portId)
    setOpen(true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setstartIndex(1)
    }
    setParamState(paramHash)
  }

  function backToPort() {
    fetchDisplayData()
    handleClose()
  }

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  return (
    <div>
      <TableView
        headCells={headCells}
        displayData={dealerPorts}
        startIndex={startIndex}
        sortType={headCells[0].id}
        paramState={paramState}
        loadData={loadData}
        onHandleaddNew={handleAddNewPort}
        displayDataCount={displayDataCount}
        initalLimitPerPage={initalLimitPerPage}
        handleEdit={handleEdit}
        isAddNewAllowed={true}
        isLoading={isLoading}
        sortOptions={false}
        isSortEnabled={isSortEnabled}
        open={open}
        handleClose={handleClose}
        disableEdit={true}
        backToPort={backToPort}
        portId={portId}
        formToShow={'port'}
      />
      {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
    </div>
  )
}

export const DealerPortsContainer = withStyles(combineStyles(stylesBase))(
  DealerPorts
)
