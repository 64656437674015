import React from 'react'
import '../assets/App.css'
import PrivateRoute from '../components/PrivateRoute'
import { ErrorContainer } from '../components'
import { LandingDealerContainer } from './LandingDealer'
import { LandingProcessorContainer } from './LandingProcessor'
import { LandingRootContainer } from './LandingRoot'
import { LandingVesselContainer } from './LandingVessel'
import { SignInContainer } from './SignIn'
import SignUp from './SignUp'
import Password from './Password'
import VesselRegistration from './VesselRegistration'
import Confirmation from './Confirmation'
import Trace from './Trace'
import VerificationResult from './VerificationResult'
import HailApp from './HailApp'
import EditHail from './EditHail'
import MyAccount from './MyAccount'
import { Provider } from 'react-redux'
import PersistStore from '../configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DealerHomeContainer } from './dealer/DealerHome'
import { DealerOrderContainer } from './dealer/orders/DealerOrder'
import { DealerOffloadContainer } from './dealer/DealerOffload'
import { DealerInventoryContainer } from './dealer/inventory/DealerInventory'
import { DealerVesselsContainer } from './dealer/DealerVessels'
import { DealerCustomersContainer } from './dealer/DealerCustomers'
import { DealerSpeciesContainer } from './dealer/DealerSpecies'
import { DealerFeesContainer } from './dealer/DealerFees'
import { DealerPortsContainer } from './dealer/DealerPorts'
import { DealerGearsContainer } from './dealer/DealerGears'
import { DealerHarvestAreasContainer } from './dealer/DealerHarvestAreas'
import { DealerProductLocationsContainer } from './dealer/DealerProductLocations'
import { AddNewOrderContainer } from './dealer/orders/AddNewOrder'
import { CustomerFormContainer } from './dealer/CustomerForm'
import { FeesFormContainer } from './dealer/FeesForm'
import { ActionCableProvider } from '../actioncable'
import { ApiHelper } from '../lib'

const { persistor, store } = PersistStore()

export function App() {
  let url = new ApiHelper().getWebSocketEndPoint()

  const hostName = window.location.hostname
  const traceOnlyDomains = process.env.REACT_APP_TRACE_ONLY_DOMAINS || []
  const rootPath = traceOnlyDomains.includes(hostName) ? '/trace' : '/sign_in'

  return (
    <ActionCableProvider url={url}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Switch>
            <Route exact path="/">
              <Redirect
                to={{
                  pathname: `${rootPath}`,
                  state: { preventLastLocation: true },
                }}
              />
            </Route>
            <Route
              exact
              path="/sign_in"
              component={() => <SignInContainer />}
            />
            <Route exact path="/sign_up" component={SignUp} />
            <Route exact path="/password" component={Password} />
            <Route
              exact
              path="/vessel_registration"
              component={VesselRegistration}
            />
            <Route exact path="/confirmation" component={Confirmation} />
            <Route exact path="/trace" component={Trace} />
            <Route exact path="/trace/:id" component={VerificationResult} />
            <PrivateRoute
              exact
              path="/dealer"
              component={DealerOffloadContainer}
              name="offloading"
            />
            <PrivateRoute exact path="/vessel" component={HailApp} />
            <PrivateRoute exact path="/vessel/account" component={MyAccount} />
            <PrivateRoute exact path="/vessel/new" component={EditHail} />
            <PrivateRoute exact path="/vessel/:id" component={EditHail} />
            <PrivateRoute
              exact
              path="/dealer/home"
              name="home"
              component={DealerHomeContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/orders"
              name="orders"
              component={DealerOrderContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/orders/new"
              name="orders"
              component={AddNewOrderContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/orders/edit/:id"
              name="orders"
              component={AddNewOrderContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/orders/:type"
              name="orders"
              component={DealerOrderContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/offload"
              name="offloading"
              component={DealerOffloadContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/inventory"
              name="inventory"
              component={DealerInventoryContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/vessels"
              name="dealerVessels"
              component={DealerVesselsContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/customers"
              name="dealerCustomers"
              component={DealerCustomersContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/add/customer"
              name="dealerCustomers"
              component={CustomerFormContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/add/fee"
              name="dealerCustomers"
              component={FeesFormContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/species"
              name="dealerSpecies"
              component={DealerSpeciesContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/fees"
              name="dealerFees"
              component={DealerFeesContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/ports"
              name="dealerPorts"
              component={DealerPortsContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/gears"
              name="dealerGears"
              component={DealerGearsContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/harvest_areas"
              name="dealerHarvestAreas"
              component={DealerHarvestAreasContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/product_locations"
              name="dealerProductLocations"
              component={DealerProductLocationsContainer}
            />
            <PrivateRoute
              exact
              path="/dealer/landing"
              component={LandingDealerContainer}
              name="home"
            />
            <PrivateRoute
              exact
              path="/processor"
              component={LandingProcessorContainer}
            />
            <PrivateRoute exact path="/root" component={LandingRootContainer} />
            <PrivateRoute
              exact
              path="/vessel/debug"
              component={LandingVesselContainer}
            />
            <ErrorContainer status="404" message="Not found" />
          </Switch>
        </PersistGate>
      </Provider>
    </ActionCableProvider>
  )
}

export default App
