import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withHailTheme } from '../../pages/shared/hailTheme'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import ScheduleIcon from '@material-ui/icons/Schedule'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import { isSameDay } from 'date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { saveHailEventually } from '../../features/hailSlice'

const styles = () => ({
  anotherDate: {
    paddingLeft: '0px',
    marginLeft: '-1%',
  },
  anotherDateIcon: {
    paddingRight: '1.5vw',
  },
  arrivalTimeGrid: {
    paddingTop: '3%',
  },
  loadingGrid: {
    minHeight: '70vh',
  },
})

export function ArrivalDate(props) {
  const dispatch = useDispatch()
  const [loadingState] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [open, setOpen] = React.useState(false)

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  let selectedDate = format(new Date(props.hail.arrival_date), 'MMMM dd yyyy')
  let today = format(new Date(), 'MMMM dd yyyy')
  let tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow = format(tomorrow, 'MMMM dd yyyy')

  const selectSpecies = () => {
    if (!props.hail.arrival_date) {
      setErrorMsg('Please select arrival date')
      setOpenDialog(true)
    } else {
      dispatch(saveHailEventually(props.hail))
      if (props.modify) {
        props.setShowItems('Finalize')
      } else {
        props.setShowItems('Catch')
      }
    }
  }

  const getDate = day => {
    if (day === 'Today') {
      return new Date()
    } else if (day === 'Tomorrow') {
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow
    }
  }

  const setArrivalDate = (value, checked) => {
    if (checked) {
      props.setAttribute('arrival_date', value)
    } else {
      props.setAttribute('arrival_date', null)
    }
  }

  const TextFieldComponent = props => {
    return <TextField {...props} disabled={true} />
  }

  function buttonSelector() {
    if (props.modify) {
      return <Trans i18nKey="modify" />
    } else {
      return <Trans i18nKey="next" />
    }
  }

  function showArrivalDate() {
    if (
      props.hail.arrival_date &&
      selectedDate !== today &&
      selectedDate !== tomorrow
    ) {
      return (
        <ListItemText
          className={props.classes.listPriText}
          primary="Another date"
          onClick={() => setOpen(true)}
          secondary={
            <React.Fragment>
              <Typography
                component={'span'}
                className={props.classes.listSecText}
              >
                {selectedDate}
              </Typography>
            </React.Fragment>
          }
        />
      )
    } else {
      return (
        <ListItemText primary="Another date" onClick={() => setOpen(true)} />
      )
    }
  }

  function classNameForSelectedDate(value) {
    if (isSameDay(new Date(props.hail.arrival_date), getDate(value))) {
      return props.classes.listPriText
    }
  }

  function classNameForCalendarIcon() {
    if (
      props.hail.arrival_date &&
      selectedDate !== today &&
      selectedDate !== tomorrow
    ) {
      return props.classes.listPriText
    }
  }

  function loadArrivalDate() {
    if (loadingState === 'loading') {
      return (
        <Grid
          id="circularProgress"
          container
          justify="center"
          alignItems="center"
          className={props.classes.loadingGrid}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <div>
          <Box height="60vh">
            <Grid
              container
              spacing={0}
              className={props.classes.bottomGridContainer}
            >
              <Grid item xs={11}>
                <Typography
                  color="inherit"
                  className={props.classes.headingText}
                >
                  <Trans i18nKey="select_arrival_date" />:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {['Today', 'Tomorrow'].map(value => {
                    const labelId = `checkbox-list-label-${value}`

                    return (
                      <div key={value}>
                        <ListItem key={value} role={undefined} dense button>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={isSameDay(
                                new Date(props.hail.arrival_date),
                                getDate(value)
                              )}
                              onChange={e =>
                                setArrivalDate(getDate(value), e.target.checked)
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={value}
                            className={classNameForSelectedDate(value)}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    )
                  })}
                  <ListItem className={props.classes.anotherDate}>
                    <ListItemIcon className={props.classes.anotherDateIcon}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          id="datePicker"
                          data-testid="datePicker"
                          format="MM/dd/yyyy"
                          open={open}
                          onClick={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          value={props.hail.arrival_date}
                          onChange={date =>
                            props.setAttribute('arrival_date', date)
                          }
                          InputProps={{
                            disableUnderline: true,
                          }}
                          TextFieldComponent={TextFieldComponent}
                          keyboardIcon={
                            <CalendarTodayIcon
                              fontSize="small"
                              className={classNameForCalendarIcon()}
                            />
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </ListItemIcon>
                    {showArrivalDate()}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} className={props.classes.arrivalTimeGrid}>
                <Typography
                  color="inherit"
                  className={props.classes.headingText}
                >
                  <Trans i18nKey="select_arrival_time" />:
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    id="timePicker"
                    data-testid="timePicker"
                    value={props.hail.arrival_time}
                    onChange={time => props.setAttribute('arrival_time', time)}
                    fullWidth
                    keyboardIcon={<ScheduleIcon />}
                    InputProps={{ className: `${props.classes.timeField}` }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Box>
          <Box height="10vh">
            <Grid container justify="center" alignItems="flex-end">
              <Button
                id="dateButton"
                data-testid="dateButton"
                className={props.classes.buttonNext}
                variant="outlined"
                type="button"
                onClick={() => selectSpecies()}
                margin="normal"
              >
                {buttonSelector()}
              </Button>
            </Grid>
          </Box>
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
          >
            <ErrorMessageComponent errorMessage={errorMsg} />
          </Dialog>
        </div>
      )
    }
  }

  return <div>{loadArrivalDate()}</div>
}

export const ArrivalDateContainer = withStyles(
  combineStyles(styles, stylesBase)
)(withHailTheme(ArrivalDate))
