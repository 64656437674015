import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles, Hidden, Grid, List, ListItem } from '@material-ui/core'
import combineStyles from '../../shared/CombineStyles'
import { AddNewOrderContainer } from './AddNewOrder'
import {
  setDealerOrders,
  setDealerNewModifyOrders,
  setOrderSpecies,
} from '../../../actions'
import { withDealerTheme } from '../../shared/DealerTheme'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'
import { GetApp, Print } from '@material-ui/icons'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import SortIcon from '@material-ui/icons/Sort'
import DateRangeComponent from '../../../components/DateRangeComponent'
import SearchComponent from '../../../components/SearchComponent'
import { FilterOptionDrawer } from '../../../components/FilterOptionDrawer'
import { ApiHelper } from '../../../lib'
import { DealerOrderListContainer } from './DealerOrderList'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

const styles = () => ({})

export function DealerOrder(props) {
  const dealerOrders = useSelector(state => state.dealerOrders)
  const customerList = useSelector(state => state.dealerCustomerList)
  const orderSpecies = useSelector(state => state.orderSpecies)
  const dispatch = useDispatch()
  const history = useHistory()

  const headCells = [
    { id: 'id', label: 'order_id', sort: 'order_number' },
    {
      id: 'started_at',
      label: 'date',
      fetchFromParent: 'started_at',
    },
    {
      id: 'customer_id',
      label: 'customer',
      fetchFromParent: 'customer',
    },
    {
      id: 'line_items',
      label: 'quantity',
      fetchFromParent: 'quantity',
    },
    {
      id: 'line_items',
      label: 'dealerSpecies',
      fetchFromParent: 'species',
    },
    {
      id: 'line_items',
      label: 'weight',
      fetchFromParent: 'weight',
    },
    {
      id: 'line_items',
      label: 'offload_id',
      fetchFromParent: 'offload_id',
    },
    {
      id: 'total_fees',
      label: 'fees',
    },
    {
      id: 'total_amount',
      label: 'order_total',
    },
    {
      id: 'state',
      label: 'status',
    },
  ]

  const dataURL = 'orders'
  const initalLimitPerPage = 10
  const initialFilterDate = new Date()

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  let orderHash = {
    order: {
      customer_id: '',
      fulfillment_attributes: {
        fulfillment_method_id: '',
        fulfillment_date: new Date(),
        totes_in: '',
        totes_out: '',
        is_company_address: false,
        address_id: '',
      },
      fulfillment_fees_attributes: [{ fee_id: '' }],
      line_items: [],
    },
  }

  const [data, setData] = useState({
    startIndex: 1,
    displayDataCount: 0,
    isLoading: false,
    errorMsg: '',
    paramState: tableParameters,
    filterOptionOpen: false,
    open: false,
    orderId: '',
  })

  function fetchDisplayData() {
    const api = new ApiHelper()
    let paramState = data.paramState
    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(data.displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let ordersUrl = `/${dataURL}?_sort=${data.paramState.orderBy}&_order=${data.paramState.order}&limit=${limitPerPageInt}&page=${pageInt}`
    if (paramState.searchKey !== '') {
      ordersUrl = ordersUrl + '&q=' + paramState.searchKey
    }
    setData({ ...data, isLoading: true })
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerOrders(result.orders))
          setData({
            ...data,
            startIndex: startIndexVal,
            displayDataCount: result.pagination.count,
            errorMsg: '',
            open: false,
            isLoading: false,
          })
        } else {
          setData({
            ...data,
            errorMsg: 'Error occured while retrieving order summary',
            isLoading: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
        setData({
          ...data,
          errorMsg: 'Error occured while retrieving order summary',
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [data.paramState, dataURL])

  const handleAddNewItem = () => {
    const newOrderPath = {
      pathname: `/dealer/orders/new`,
      state: { from: `/dealer/orders/new` },
    }
    history.push(newOrderPath)
    // setData({
    //   ...data,
    //   open: true,
    // })
  }

  const handleClose = () => {
    setData({
      ...data,
      open: false,
      orderId: '',
    })
    fetchDisplayData()
    dispatch(setOrderSpecies(orderSpecies))
    dispatch(setDealerNewModifyOrders(orderHash))
  }

  function handleFilterOnclick(status) {
    setData({
      ...data,
      filterOptionOpen: status,
    })
  }

  function onFetchFromParent(name, key) {
    if (name === 'customer') {
      if (key) {
        let customer = customerList.find(customer => customer.id === key)
        if (customer) {
          return customer.name
        }
        return ''
      }
    }
    if (name === 'quantity') {
      if (key) {
        return key.length + ' Item(s)'
      }
    }

    if (name === 'species' || name === 'speciesToolTip') {
      let speciesLot
      if (key) {
        speciesLot = key.map(value => value.product)
      } else {
        speciesLot = []
      }

      let speciesToDisplay = []
      speciesToDisplay[0] = (
        <List>
          <ListItem
            className={classNames(
              props.classes.listItemClass,
              props.classes.orderSpeciesWeight
            )}
          >
            {getTruncatedString(speciesLot.join(', '), 20)}
          </ListItem>
        </List>
      )
      speciesToDisplay[1] = (
        <List>
          {speciesLot.map((speciesName, index) => {
            return (
              <ListItem
                className={classNames(
                  props.classes.listItemClass,
                  props.classes.orderSpeciesWeight
                )}
                key={'species-' + String(index)}
              >
                {speciesName}
              </ListItem>
            )
          })}
        </List>
      )
      let speciesForTooltip = speciesLot.toString()
      if (name === 'speciesToolTip') {
        return speciesForTooltip
      }
      return speciesToDisplay
      //need to edit this
    }

    if (name === 'weight') {
      let weightArray = []
      if (key) {
        weightArray = key.map(value => value.quantity)
      }
      let weightToDisplay = []
      weightToDisplay[0] = (
        <List>
          <ListItem
            className={classNames(
              props.classes.listItemClass,
              props.classes.orderSpeciesWeight
            )}
          >
            {getTruncatedString(weightArray.join(', '), 10)}
          </ListItem>
        </List>
      )
      weightToDisplay[1] = (
        <List>
          {key &&
            key.map((lot, index) => {
              return (
                <ListItem
                  className={classNames(
                    props.classes.listItemClass,
                    props.classes.orderSpeciesWeight
                  )}
                  key={'weight-' + String(index)}
                >
                  {lot.quantity}
                </ListItem>
              )
            })}
        </List>
      )
      return weightToDisplay
    }

    if (name === 'offload_id') {
      let offloadIdArray = []
      if (key) {
        offloadIdArray = key.map(value => value.offload_number)
      }
      let offloadIdToDisplay = []
      offloadIdToDisplay[0] = (
        <List>
          <ListItem className={props.classes.listItemClass}>
            {getTruncatedString(offloadIdArray.join(', '), 10)}
          </ListItem>
        </List>
      )
      offloadIdToDisplay[1] = (
        <List>
          {key &&
            key.map((lot, index) => {
              return (
                <ListItem
                  className={props.classes.listItemClass}
                  key={'weight-' + String(index)}
                >
                  {lot.offload_number}
                </ListItem>
              )
            })}
        </List>
      )
      return offloadIdToDisplay
    }

    if (name === 'started_at') {
      if (key) return getArrivalDateTime(key, 'date')
      else return key
    }
  }

  function getTruncatedString(str, n) {
    return str.length > n ? str.substr(0, n) + '...' : str
  }

  function getArrivalDateTime(dateTimeValue, type) {
    let arrivalDate = new Date(dateTimeValue)

    if (type === 'date') {
      return (
        arrivalDate.getMonth() +
        1 +
        '/' +
        arrivalDate.getDate() +
        '/' +
        arrivalDate.getFullYear()
      )
    }
  }

  function handleFilterSelection(sortBy, fromDate, toDate) {
    let paramState = { ...data.paramState }
    paramState.fromDate = fromDate
    paramState.toDate = toDate
    loadData(paramState, true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setData({ ...data, startIndex: 1 })
    }
    setData({
      ...data,
      paramState: paramHash,
    })
  }

  const changeSearchKey = searchKeyTerm => {
    let paramState = { ...data.paramState }
    paramState.searchKey = searchKeyTerm
    //Everytime there is a change in search key
    //page selected should be set to first page
    paramState.pageSelected = 1
    loadData(paramState, true)
  }

  function searchWithDateRange(fromDate, toDate) {
    let paramState = { ...data.paramState }
    paramState.fromDate = fromDate
    paramState.toDate = toDate
    loadData(paramState, true)
  }

  const handleEdit = orderId => {
    const editOrderPath = {
      pathname: `/dealer/orders/edit/${orderId}`,
      state: { from: `/dealer/orders` },
    }
    history.push(editOrderPath)
  }

  return (
    <div className={props.classes.outerGrid}>
      {data.open ? (
        <AddNewOrderContainer
          open={data.open}
          handleClose={handleClose}
          orderId={data.orderId}
        />
      ) : (
        <React.Fragment>
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={12}
            spacing={1}
            alignItems="flex-end"
          >
            <Grid container item xs={12} md={3} lg={3}>
              <Grid item xs={8} sm={5} md={12} lg={12}>
                <SearchComponent onChangeSearchKey={changeSearchKey} />
              </Grid>

              <Hidden only={['md', 'lg', 'xl']}>
                <Grid item xs={2} sm={4} />
                <Grid
                  container
                  item
                  xs={2}
                  sm={3}
                  alignItems="flex-end"
                  className={props.classes.filterOptionGrid}
                >
                  <SortIcon onClick={() => handleFilterOnclick(true)} />
                  <div key={data.filterOptionOpen}>
                    <FilterOptionDrawer
                      open={data.filterOptionOpen}
                      handleOnClose={handleFilterOnclick}
                      sortOptions={props.sortOptions}
                      onFilterSelection={handleFilterSelection}
                      sortBy=""
                      fromDate={data.paramState.fromDate}
                      toDate={data.paramState.toDate}
                      isSortEnabled={props.isSortEnabled}
                    />
                  </div>
                </Grid>
              </Hidden>
            </Grid>

            <Hidden only={['xs', 'sm']}>
              <Grid item xs={4} className={props.classes.filterOptionGrid}>
                <Grid container item xs={12} justify="flex-end">
                  <DateRangeComponent
                    fromDate={data.paramState.fromDate}
                    toDate={data.paramState.toDate}
                    onDateRangeChange={searchWithDateRange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} className={props.classes.filterOptionGrid} />
              <Grid item xs={2} className={props.classes.filterOptionGrid}>
                <Grid container item xs={12} justify="space-evenly">
                  <AddShoppingCartIcon fontSize="small" />
                  <GetApp fontSize="small" />
                  <Print fontSize="small" />
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <DealerOrderListContainer
            headCells={headCells}
            displayData={dealerOrders}
            startIndex={data.startIndex}
            sortType={headCells[0].id}
            paramState={data.paramState}
            loadData={loadData}
            handleAddNewItem={handleAddNewItem}
            displayDataCount={data.displayDataCount}
            initalLimitPerPage={initalLimitPerPage}
            handleEdit={handleEdit}
            isAddNewAllowed={true}
            isLoading={data.isLoading}
            onFetchFromParent={onFetchFromParent}
            orderId={data.orderId}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export const DealerOrderContainer = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(DealerOrder))
