import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LegitFishLogo from '../assets/LegitFishLogoWhite.svg'
import { theme } from '../pages/shared/theme'

const styles = () => ({
  logoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '35vw',
    [theme.breakpoints.up('sm')]: {
      width: '25vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '12vw',
    },
  },
})

export function Logo(props) {
  return (
    <Grid
      container
      spacing={1}
      alignItems="flex-end"
      direction="row"
      className={props.classes.logoContainer}
      theme={theme}
    >
      <Grid item xs={12}>
        <img src={LegitFishLogo} alt="logo" />
      </Grid>
    </Grid>
  )
}

export const LogoContainer = withStyles(styles)(Logo)
