import React, { useState } from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  createMuiTheme,
  Divider,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import { ApiHelper } from '../../lib'
import { Trans, useTranslation } from 'react-i18next'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import withDealerTheme from '../shared/DealerStyles'
import BootstrapInput from './TextfieldForDealer'
import { useDispatch, useSelector } from 'react-redux'
import { saveDealerSpecie } from '../../features/dealerSlice'
import Autocomplete from '@material-ui/lab/Autocomplete'

const themes = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '900px',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: 'white !important',
        padding: '0px !important',
        color: 'black',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#4D4D4D',
        '&$focused': {
          color: '#4D4D4D',
        },
        '&$disabled': {
          color: '#B3B3B3',
        },
        '&$error': {
          color: '#B80000',
        },
        '&$filled': {
          color: '#4D4D4D',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0px',
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '0px 0px !important',
      },
      option: {
        fontSize: '0.75rem',
        textTransform: 'none',
      },
      inputRoot: {
        height: '2rem',
        borderRadius: '0px',
        backgroundColor: '#F6F9FA',
        fontSize: '0.75rem',
        '&$focused': {
          borderColor: '#E0E0E0',
        },
      },
    },
  },
})

export function SpeciesForm(props) {
  const classes = withDealerTheme()

  const [errorMsg, setErrorMsg] = useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const newDealerSpecie = useSelector(state => state.dealer.newDealerSpecie)
  const species = useSelector(state => state.species)
  const counts = useSelector(state => state.counts)

  const setAttribute = (key, val) => {
    const modifySpecie = Object.assign({}, newDealerSpecie)
    modifySpecie[key] = val
    dispatch(saveDealerSpecie(modifySpecie))
  }

  let apiInput = {
    product: newDealerSpecie,
  }

  const handleFinishCancel = (event, actionType) => {
    event.preventDefault()
    const api = new ApiHelper()

    if (actionType === 'cancel') {
      props.backToSpecies()
      return
    } else if (newDealerSpecie.species_id && newDealerSpecie.market_name) {
      api
        .post('/products', apiInput)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            return props.backToSpecies()
          } else {
            setErrorMsg('Error returning result')
          }
        })
        .catch(error => {
          console.error(error)
          setErrorMsg(error)
        })
    } else {
      setErrorMsg('Please fill all the details')
    }
  }

  const speciesGrades = useSelector(state => state.dealer.speciesGrades)
  const speciesDispositions = useSelector(
    state => state.dealer.speciesDispositions
  )

  return (
    <MuiThemeProvider theme={themes}>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-dialog-title"
        scroll="body"
        fullWidth={true}
        className={classes.dialogPaper}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container className={classes.titleGridContainer}>
            <Grid container item xs={11}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.titleText}>
                  <Trans i18nKey="add_a_new_species" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={1} justify="flex-end">
              <Grid
                container
                item
                xs={1}
                alignItems="flex-start"
                justify="flex-end"
              >
                <Cancel
                  className={classes.cancelIcon}
                  fontSize="small"
                  onClick={event => handleFinishCancel(event, 'cancel', '')}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1}>
            {/** Fishing Info */}
            <Grid item xs={12}>
              <Typography className={classes.lightText}>
                <Trans i18nKey="species_info" />
              </Typography>
            </Grid>
            <Grid item md={3} />
            <Grid container item md={9}>
              <Grid item xs={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="noaa_accepted" />*
                  </InputLabel>
                  <Autocomplete
                    id="noaa_accepted"
                    className={classes.selectEmpty}
                    options={species}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                    onChange={(e, newValue) =>
                      setAttribute('species_id', newValue ? newValue.id : '')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="corresponding_market_name" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newDealerSpecie.market_name
                        ? newDealerSpecie.market_name
                        : ''
                    }
                    name="market_name"
                    id="market_name"
                    onChange={e => setAttribute('market_name', e.target.value)}
                    placeholder={t('market_name', 'Market Name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="market_description" />
                  </InputLabel>
                  <Select
                    value={
                      newDealerSpecie.market_description_id
                        ? newDealerSpecie.market_description_id
                        : ''
                    }
                    name="market_description"
                    id="market_description"
                    displayEmpty
                    disableUnderline
                    className={classes.selectEmpty}
                    onChange={e =>
                      setAttribute('market_description_id', e.target.value)
                    }
                  >
                    <MenuItem value=" ">
                      <Typography className={classes.selectMenuPlaceholder}>
                        {' '}
                        <Trans i18nKey="market_description" />{' '}
                      </Typography>
                    </MenuItem>
                    {counts &&
                      counts.map(count => {
                        return (
                          <MenuItem
                            key={count.id}
                            value={count.id}
                            className={classes.selectMenuText}
                          >
                            {count.description}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="grade" />
                  </InputLabel>
                  <Select
                    value={
                      newDealerSpecie.grade_id ? newDealerSpecie.grade_id : ''
                    }
                    name="grade_id"
                    id="grade_id"
                    displayEmpty
                    disableUnderline
                    className={classes.selectEmpty}
                    onChange={e => setAttribute('grade_id', e.target.value)}
                  >
                    <MenuItem value=" ">
                      <Typography className={classes.selectMenuPlaceholder}>
                        {' '}
                        <Trans i18nKey="grade" />{' '}
                      </Typography>
                    </MenuItem>
                    {speciesGrades &&
                      speciesGrades.map(grade => {
                        return (
                          <MenuItem
                            key={grade.id}
                            value={grade.id}
                            className={classes.selectMenuText}
                          >
                            {grade.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="disposition" />
                  </InputLabel>
                  <Select
                    value={
                      newDealerSpecie.disposition_id
                        ? newDealerSpecie.disposition_id
                        : ''
                    }
                    name="disposition"
                    id="disposition"
                    displayEmpty
                    disableUnderline
                    className={classes.selectEmpty}
                    onChange={e =>
                      setAttribute('disposition_id', e.target.value)
                    }
                  >
                    <MenuItem value=" ">
                      <Typography className={classes.selectMenuPlaceholder}>
                        {' '}
                        <Trans i18nKey="disposition" />{' '}
                      </Typography>
                    </MenuItem>
                    {speciesDispositions &&
                      speciesDispositions.map(disposition => {
                        return (
                          <MenuItem
                            key={disposition.id}
                            value={disposition.id}
                            className={classes.selectMenuText}
                          >
                            {disposition.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="associated_fee" />
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newDealerSpecie.fee_per_lb
                        ? newDealerSpecie.fee_per_lb
                        : ''
                    }
                    name="fee_per_lb"
                    id="fee_per_lb"
                    onChange={e => setAttribute('fee_per_lb', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={5} />
            </Grid>
          </Grid>
          {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            className={classes.gridFirstRow}
            alignItems="flex-start"
          >
            <Grid container item xs={5} md={8} />
            <Grid container item xs={7} md={3} justify="space-around">
              <Button
                size="small"
                onClick={event => handleFinishCancel(event, 'cancel', '')}
                classes={{ label: classes.cancelButtonText }}
              >
                <Trans i18nKey="cancel" />
              </Button>
              <Button
                variant="contained"
                onClick={event => handleFinishCancel(event, 'submit', '')}
                size="small"
                className={classes.finishButton}
                classes={{ label: classes.buttonText }}
              >
                <Trans i18nKey="save" />
              </Button>
            </Grid>
            <Grid container item xs={1} md={1} />
          </Grid>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

export const SpeciesFormContainer = SpeciesForm
