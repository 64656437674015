import React, { useState } from 'react'
import { ApiHelper } from '../../lib'
import withDealerTheme from '../shared/DealerStyles'
import { useDispatch, useSelector } from 'react-redux'
import { saveProductLocation } from '../../features/dealerSlice'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles, Grid, TableRow, TableCell } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import BootstrapInput from './TextfieldForDealer'

const styles = () => ({
  tableRow: {
    backgroundColor: 'white',
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgb(0, 62, 113, .2)',
      color: 'black',
    },
  },
  tabledata: {
    borderRight: '1px ridge',
    /**whiteSpace: 'nowrap',*/

    paddingRight: '2px',
    paddingLeft: '5px',
  },
  textDataLight: {
    fontFamily: 'Rubik',
    fontWeight: 'light',
    fontSize: '10pt',
  },
  otherColumns: {
    zIndex: '-1',
  },
  saveNew: {
    fontSize: '22px',
    color: 'darkblue',
  },
  multiIconButton: {
    padding: '0px',
    paddingRight: '0px',
  },
  deleteIconGrid: {
    textAlign: 'right',
  },
})

export function ProductLocations(props) {
  const classes = withDealerTheme()

  const [errorMsg, setErrorMsg] = useState('')
  const dispatch = useDispatch()
  const newProductLocation = useSelector(
    state => state.dealer.newProductLocation
  )

  const setAttribute = (key, val) => {
    const modifyPort = Object.assign({}, newProductLocation)
    modifyPort[key] = val
    dispatch(saveProductLocation(modifyPort))
  }
  const api = new ApiHelper()
  const handleFinishCancel = event => {
    event.preventDefault()

    if (!newProductLocation.name) {
      return setErrorMsg('Please fill all the details')
    }
    if (props.id === '') {
      api
        .post('/product_locations', newProductLocation)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            return props.backToProductLocation()
          } else {
            setErrorMsg('Error returning result')
          }
        })
        .catch(error => {
          console.error(error)
          setErrorMsg(error)
        })
    } else {
      api
        .put(`/product_locations/${props.id}`, newProductLocation)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            return props.backToProductLocation()
          } else {
            setErrorMsg('Error returning result')
          }
        })
        .catch(error => {
          console.error(error)
          setErrorMsg(error)
        })
    }
  }

  const handleDelete = event => {
    event.preventDefault()
    if (props.id) {
      api.delete(`/product_locations/${props.id}`).then(() => {
        return props.backToProductLocation()
      })
    } else {
      props.handleClose()
    }
  }

  return (
    <TableRow
      hover
      classes={{ selected: classes.selected }}
      className={props.classes.tableRow}
    >
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns
        )}
      >
        {props.i ? props.i + 1 : 1}
      </TableCell>
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns,
          props.classes.multiIconButton
        )}
      >
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <BootstrapInput
              id="outlined-size-small"
              helperText={errorMsg}
              value={newProductLocation.name ? newProductLocation.name : ''}
              onChange={e => setAttribute('name', e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={4} lg={5} />
          <Grid item xs={1} className={props.classes.deleteIconGrid}>
            <IconButton onClick={event => handleDelete(event)}>
              <DeleteIcon className={props.classes.saveNew} />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns,
          props.classes.multiIconButton
        )}
      >
        <IconButton
          className={props.classes.multiIconButton}
          onClick={event => handleFinishCancel(event)}
        >
          <CheckIcon className={props.classes.saveNew} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export const ProductLocationsFormContainer = withStyles(styles)(
  ProductLocations
)
