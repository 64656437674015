import { SET_DEALERS } from '../actions'

const initialState = []

export default function dealers(state = initialState, action) {
  switch (action.type) {
    case SET_DEALERS:
      return action.dealers

    default:
      return state
  }
}
