import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { PublicContainer } from '../components'
import Grid from '@material-ui/core/Grid'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'

const styles = () => ({})

export function Confirmation(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <PublicContainer
        top={
          <form>
            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <label className={props.classes.textRegular}>
                  <Trans i18nKey="confirmation_code_entry" />
                </label>
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <label className={props.classes.textRegular}>
                  <Trans i18nKey="confirmation_code_sent" />
                </label>
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <TextField
                  id="formConfirmationCode"
                  label=<Trans i18nKey="confirmation_code" />
                  type="password"
                  //value={username}
                  // onChange={e => setUsername(e.currentTarget.value)}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        bottom={
          <Container maxWidth="sm">
            <Button
              id="formButton"
              className={props.classes.button}
              variant="outlined"
              type="button"
              //    onClick={() => confirmPassword()}
              margin="normal"
            >
              <Trans i18nKey="finish" />
            </Button>

            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <Typography
                  variant="caption"
                  className={props.classes.textRegular}
                >
                  <Trans i18nKey="resend_code" />
                </Typography>
              </Grid>
            </Grid>
          </Container>
        }
      />
    </MuiThemeProvider>
  )
}

const mapStateToProps = () => {
  return {
    //TODO: related code to be added when functionality is built-ins
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(Confirmation))
