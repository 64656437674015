import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ArrowRightAltSharpIcon from '@material-ui/icons/ArrowRightAltSharp'

import classNames from 'classnames'
import { Trans } from 'react-i18next'

const useStyles = makeStyles(() => ({
  InfoText: {
    fontFamily: 'Rubik',
    fontWeight: 'light',
    fontSize: '8pt',
  },
  componentSpacing: {
    marginLeft: '5px',
  },
  datePicker: {
    backgroundColor: 'white',
    borderRadius: '50px',
    marginLeft: '3px',
    paddingLeft: '3px',
    maxWidth: '70px',
    '&:before': {
      borderColor: '#FFFFFF',
    },
    '&:after': {
      borderColor: '#FFFFFF',
    },
  },
  rangeIcon: {
    marginLeft: '3px',
  },
}))

export default function DateRangeComponent(props) {
  const classes = useStyles()
  const [selectedFromDate, setSelectedFromDate] = React.useState(props.fromDate)
  const [selectedToDate, setSelectedToDate] = React.useState(props.toDate)

  const handleFromDateChange = fromDate => {
    if (fromDate > selectedToDate || selectedToDate === null) {
      setSelectedFromDate(fromDate)
      setSelectedToDate(null)
    } else {
      setSelectedFromDate(fromDate)
      props.onDateRangeChange(fromDate, selectedToDate)
    }
  }

  const handleToDateChange = toDate => {
    if (toDate < selectedFromDate || selectedFromDate === null) {
      setSelectedToDate(toDate)
      setSelectedFromDate(null)
    } else {
      setSelectedToDate(toDate)
      props.onDateRangeChange(selectedFromDate, toDate)
    }
  }

  let fromComponent
  let toComponent

  if (props.label && props.label === 'icon') {
    toComponent = (
      <ArrowRightAltSharpIcon
        fontSize="small"
        color="action"
        className={classes.rangeIcon}
      />
    )
  } else {
    fromComponent = (
      <Typography
        display="inline"
        className={classNames(classes.componentSpacing, classes.InfoText)}
      >
        <Trans i18nKey="from" />:
      </Typography>
    )

    toComponent = (
      <Typography
        display="inline"
        className={classNames(classes.componentSpacing, classes.InfoText)}
      >
        <Trans i18nKey="to" />:
      </Typography>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container direction="row" alignItems="center" justify="center">
        {fromComponent}
        <DatePicker
          autoOk
          variant="inline"
          format="E, MMM d"
          disableToolbar
          value={selectedFromDate}
          onChange={handleFromDateChange}
          InputProps={{ disableUnderline: true, className: classes.InfoText }}
          className={classNames(classes.componentSpacing, classes.datePicker)}
        />
        {toComponent}
        <DatePicker
          autoOk
          variant="inline"
          format="E, MMM d"
          disableToolbar
          value={selectedToDate}
          onChange={handleToDateChange}
          InputProps={{ disableUnderline: true, className: classes.InfoText }}
          className={classNames(classes.componentSpacing, classes.datePicker)}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
