import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { theme } from '../../../pages/shared/theme'
import bbseascallop from '../../../assets/trace/butcherbox/bbseascallop.svg'
import bbshrimp from '../../../assets/trace/butcherbox/bbshrimp.svg'
import bbsalmon from '../../../assets/trace/butcherbox/bbsalmon.svg'
import bbcod from '../../../assets/trace/butcherbox/bbcod.svg'
import beshrimp from '../../../assets/trace/bigeasy/beshrimp.svg'
import classNames from 'classnames'
import VerticalLinearStepper from '../../Stepper'
import { TraceAgreementContainer } from '../../TraceAgreement'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const styles = () => ({
  headingTextTrace: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    fontSize: '0.9rem',
    paddingRight: '12%',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem',
    },
  },
  bigEasyColor: {
    color: '#3675AD',
  },
  butcherBoxColor: {
    color: '#2D4D59',
  },
  text: {
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
    height: '9vh',
    marginLeft: '1vh',
    marginTop: '1vh',
    textTransform: 'capitalize',
    [theme.breakpoints.only('md')]: {
      marginLeft: '11%',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '2%',
      fontSize: '1.2rem',
    },
  },

  fishingAreaText: {
    height: 'auto',
    paddingBottom: '1vh',
  },

  lastText: {
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
    height: '2vh',
    marginLeft: '1vh',
    marginTop: '1vh',
    textTransform: 'capitalize',
    [theme.breakpoints.only('md')]: {
      marginLeft: '11%',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '2%',
      fontSize: '1.2rem',
    },
  },

  dotLine: {
    paddingTop: '1vh',
    height: '48vh',
    [theme.breakpoints.up('md')]: {
      paddingTop: '4vh',
    },
  },

  contentGrid: {
    marginTop: '4%',
    height: '50vh',
    [theme.breakpoints.up('md')]: {
      height: '52vh',
    },
  },

  secondGrid: {
    paddingTop: '1vh',
    paddingBottom: '3vh',
  },

  content: {
    padding: '0 3vh 10vh 3vh',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '18vh',
    },
  },
  scallopImageGrid: {
    marginLeft: '-12%',
    marginTop: '5vh',
    width: '35vw',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '3vw',
    },
  },
  scallopImage: {
    width: '32vw',
  },
  salmonImage: {
    height: '7vh',
  },
  traceAgreementGrid: {
    paddingTop: '5vh',
    color: '#2D4D59',
  },
  iframe: {
    width: '100%',
    height: '100vh',
    borderWidth: '0',
  },
  showMoreIcon: {
    textAlign: 'right',
  },
})

export function Trace(props) {
  const trace = props.trace
  const species = trace.species.toLowerCase()
  const component = trace.component
  const hostName = window.location.hostname
  const [showMore, setShowMore] = useState(false)
  function selectSpeciesImage() {
    if (component !== 'ButcherBoxContainer') {
      return beshrimp
    }
    if (species.includes('shrimp')) {
      return bbshrimp
    } else if (species.includes('salmon')) {
      return bbsalmon
    } else if (species.includes('cod')) {
      return bbcod
    } else if (species.includes('scallop')) {
      return bbseascallop
    }
  }

  function showMoreHorizIcon() {
    if (!trace.fishing_area) {
      return
    } else if (!showMore && trace.fishing_area.length > 100) {
      return (
        <div className={props.classes.showMoreIcon}>
          <MoreHorizIcon onClick={() => setShowMore(!showMore)} />
        </div>
      )
    }
  }

  function displayComponent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <div
              id="fishingArea"
              className={classNames(
                props.classes.text,
                showMore ? props.classes.fishingAreaText : '',
                component === 'ButcherBoxContainer'
                  ? props.classes.butcherBoxColor
                  : props.classes.bigEasyColor
              )}
            >
              {showMore
                ? trace.fishing_area
                : trace.fishing_area.slice(0, 100) || 'N/A'}
            </div>
            {showMoreHorizIcon()}
          </div>
        )
      case 1:
        return (
          <div
            id="landedDate"
            className={classNames(
              props.classes.text,
              component === 'ButcherBoxContainer'
                ? props.classes.butcherBoxColor
                : props.classes.bigEasyColor
            )}
          >
            {trace.landed_date || 'N/A'}
          </div>
        )
      case 2:
        return (
          <div
            id="productForm"
            className={classNames(
              props.classes.text,
              component === 'ButcherBoxContainer'
                ? props.classes.butcherBoxColor
                : props.classes.bigEasyColor
            )}
          >
            {(trace.product_form || 'N/A').toLowerCase()}
          </div>
        )
      case 3:
        return (
          <div
            id="landingPort"
            className={classNames(
              props.classes.lastText,
              component === 'ButcherBoxContainer'
                ? props.classes.butcherBoxColor
                : props.classes.bigEasyColor
            )}
          >
            {trace.landing_port || 'N/A'}
          </div>
        )
    }
  }

  const steps = ['fishing_area', 'landed_date', 'product_form', 'landing_port']

  function showTraceAgreement() {
    if (!hostName.startsWith('bb04')) {
      return (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="center"
          className={props.classes.traceAgreementGrid}
          onClick={() => props.setSelectedRecipe('https://legitfish.com/')}
        >
          <TraceAgreementContainer />
        </Grid>
      )
    }
  }

  function showTraceResult() {
    if (!props.selectedRecipe) {
      return (
        <div className={props.classes.content}>
          <Grid container>
            <Grid item xs={9} lg={7}>
              <div>
                <p>
                  <Typography
                    component={'span'}
                    className={classNames(
                      props.classes.headingTextTrace,
                      component === 'ButcherBoxContainer'
                        ? props.classes.butcherBoxColor
                        : props.classes.bigEasyColor
                    )}
                  >
                    {trace.market_name || 'N/A'}
                  </Typography>
                </p>
              </div>
              <div
                id="species"
                className={classNames(
                  props.classes.headingTextTrace,
                  component === 'ButcherBoxContainer'
                    ? props.classes.butcherBoxColor
                    : props.classes.bigEasyColor
                )}
              >
                <Typography> {trace.species || 'N/A'} </Typography>
              </div>
            </Grid>
            <Grid
              container
              item
              xs={3}
              lg={2}
              alignItems="center"
              justify="center"
              className={props.classes.scallopImageGrid}
            >
              <img
                src={selectSpeciesImage()}
                alt="Species"
                className={
                  species.includes('salmon') || species.includes('cod')
                    ? props.classes.salmonImage
                    : props.classes.scallopImage
                }
              />
            </Grid>
          </Grid>
          <Grid container className={props.classes.secondGrid}>
            <Grid item xs={12}>
              <VerticalLinearStepper
                steps={steps}
                displayComponent={displayComponent}
                component={component}
              />
            </Grid>
          </Grid>
          {showTraceAgreement()}
        </div>
      )
    }
    return (
      <iframe
        title="Legitfish site"
        src={props.iframe}
        width="100%"
        height="100%"
        className={props.classes.iframe}
      ></iframe>
    )
  }

  return showTraceResult()
}

export const TraceContainer = withStyles(styles)(Trace)
