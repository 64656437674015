import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { Grid, Link } from '@material-ui/core'
import { theme } from '../../../pages/shared/theme'
import '../../../assets/Trace.css'
import { Dialog } from '@material-ui/core'
import { ArrowBack, Close, Lock } from '@material-ui/icons'
import LotNumber from '../../../assets/trace/LotNumber.svg'
import MarketName from '../../../assets/trace/MarketName.svg'
import Species from '../../../assets/trace/Species.svg'
import FishingArea from '../../../assets/trace/FishingArea.svg'
import LandedDate from '../../../assets/trace/LandedDate.svg'
import LandingPort from '../../../assets/trace/LandingPort.svg'
import Sustainability from '../../../assets/trace/Sustainability.svg'
import Groundfish from '../../../assets/trace/channelfish/Groundfish.png'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const styles = () => ({
  verificationResultLink: {
    color: 'blue',
    textDecoration: 'underline',
  },

  iframe: {
    borderWidth: '1px 0',
    borderColor: 'black',
  },

  text: {
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
    marginTop: '-6%',
    marginBottom: '5%',
    marginLeft: '16%',
    [theme.breakpoints.only('md')]: {
      marginLeft: '11%',
      marginTop: '-4%',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-4vh',
      marginBottom: '2%',
      fontSize: '1.2rem',
    },
  },

  headingTextTrace: {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '0.9rem',
    color: '#4F596F',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem',
    },
  },

  headingIcon: {
    width: '15%',
    [theme.breakpoints.only('md')]: {
      width: '10%',
    },
  },

  fishImageGrid: {
    marginLeft: '-15%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '3vw',
    },
  },

  channelfishLink: {
    marginBottom: '3px',
  },
})

export function ChannelFish(props) {
  const [iframeUrl, setIframeUrl] = useState('')
  const [iframeHeader, setIframeHeader] = useState('')

  const handleIframeOpen = (name, url) => {
    setIframeHeader(name)
    setIframeUrl(url)
  }

  const handleIframeClose = () => {
    setIframeHeader('')
    setIframeUrl('')
  }

  function showChannelFishLinks() {
    if (
      props.trace.component &&
      props.trace.component === 'ChannelFishContainer'
    ) {
      return (
        <div>
          <Link
            to="#"
            className={props.classes.verificationResultLink}
            onClick={() =>
              handleIframeOpen(
                'Perfect Pollock PortionsTM',
                'https://legitfishinc-public.s3.amazonaws.com/channelfish/channel_fish_ppp_explained.pdf'
              )
            }
          >
            <div className={props.classes.channelfishLink}>
              Perfect Pollock Portions<sup>TM</sup>
            </div>
          </Link>
          <Link
            to="#"
            className={props.classes.verificationResultLink}
            onClick={() =>
              handleIframeOpen(
                'PPP Sell Sheet',
                'https://legitfishinc-public.s3.amazonaws.com/channelfish/channel_fish_ppp_sell_sheet.pdf'
              )
            }
          >
            <div className={props.classes.channelfishLink}>PPP Sell Sheet</div>
          </Link>
        </div>
      )
    }
  }

  return (
    <div data-testid="ChannelFishContainer">
      <Grid container spacing={0}>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={LotNumber}
                alt="Lot Number"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="lot_number" />
            </p>
          </div>
          <div id="lotNumber" className={props.classes.text}>
            {props.trace.id}
          </div>
        </Grid>
        <Grid
          container
          item
          xs={3}
          lg={2}
          alignItems="center"
          justify="center"
          className={props.classes.fishImageGrid}
        >
          <img src={Groundfish} alt="Species" width="170%" />
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={MarketName}
                alt="Market Name"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="market_name" />
            </p>
          </div>
          <div id="marketName" className={props.classes.text}>
            {props.trace.market_name || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Species}
                alt="Species"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="species" />
            </p>
          </div>
          <div id="species" className={props.classes.text}>
            {props.trace.species || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={LandedDate}
                alt="Landed Date"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="landed_date" />
            </p>
          </div>
          <div id="landedDate" className={props.classes.text}>
            {props.trace.landed_date}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={LandingPort}
                alt="Landing Port"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="landing_port" />
            </p>
          </div>
          <div id="landingPort" className={props.classes.text}>
            {props.trace.landing_port || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={FishingArea}
                alt="Fishng Area"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="fishing_area" />
            </p>
          </div>
          <div id="fishingArea" className={props.classes.text}>
            {props.trace.fishing_area || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7} data-testid="ChannelFishSustainabilityLink">
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Sustainability}
                alt="Sustainability"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="sustainability" />
            </p>
          </div>
          <div id="sustainability" className={props.classes.text}>
            <Link
              to="#"
              className={props.classes.verificationResultLink}
              onClick={() =>
                handleIframeOpen(
                  'fisheries.msc.org',
                  'https://fisheries.msc.org/en/fisheries/us-acadian-redfish-pollock-and-haddock-otter-trawl-fishery/@@view'
                )
              }
            >
              <div> Learn more </div>
            </Link>
            {showChannelFishLinks()}
          </div>
        </Grid>
      </Grid>
      <Dialog fullScreen open={iframeUrl ? true : false}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ paddingTop: '1vh', paddingBottom: '1vh' }}
        >
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={handleIframeClose}
          >
            <ArrowBack />
          </Grid>
          <Grid xs={8} item container justify="center" alignItems="center">
            <Lock fontSize="small" />
            &nbsp;{iframeHeader}
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={handleIframeClose}
          >
            <Close />
          </Grid>
        </Grid>
        <iframe
          className={props.classes.iframe}
          src={iframeUrl}
          width="100%"
          height="100%"
        ></iframe>
      </Dialog>
    </div>
  )
}

export const ChannelFishContainer = withStyles(styles)(ChannelFish)
