import React from 'react'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  agreement: {
    position: 'centre',
    bottom: '6vh',
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '1rem',
    textAlign: 'center',
  },
  legitFish: {
    textDecoration: 'underline',
    fontWeight: '400',
  },
})

export function TraceAgreement(props) {
  return (
    <Box maxWidth="sm" className={props.classes.agreement}>
      <label>Trace data powered by</label>
      <br />
      <label className={props.classes.legitFish}>Legit Fish</label>
    </Box>
  )
}

export const TraceAgreementContainer = withStyles(styles)(TraceAgreement)
