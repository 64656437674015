import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  createMuiTheme,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import { ApiHelper } from '../../lib'
import { Trans, useTranslation } from 'react-i18next'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import withDealerTheme from '../shared/DealerStyles'
import BootstrapInput from './TextfieldForDealer'
import { useDispatch, useSelector } from 'react-redux'
import { saveFee, setFeeUnits } from '../../features/dealerSlice'
import { useHistory } from 'react-router-dom'

const themes = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '900px',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: 'white !important',
        padding: '0px !important',
        color: 'black',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#4D4D4D',
        '&$focused': {
          color: '#4D4D4D',
        },
        '&$disabled': {
          color: '#B3B3B3',
        },
        '&$error': {
          color: '#B80000',
        },
        '&$filled': {
          color: '#4D4D4D',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0px',
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '0px 0px !important',
      },
      option: {
        fontSize: '0.75rem',
        textTransform: 'none',
      },
      inputRoot: {
        height: '2rem',
        borderRadius: '0px',
        backgroundColor: '#F6F9FA',
        fontSize: '0.75rem',
        '&$focused': {
          borderColor: '#E0E0E0',
        },
      },
    },
  },
})

export function FeesForm(props) {
  const classes = withDealerTheme()

  const [errorMsg, setErrorMsg] = useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const newFee = useSelector(state => state.dealer.newFee)
  const feeUnits = useSelector(state => state.dealer.feeUnits)
  const history = useHistory()

  const [data, setData] = useState({ open: false })

  useEffect(() => {
    if (props.open || window.location.pathname === '/dealer/add/fee') {
      fetchFeeUnit()
      setData({ ...data, open: true })
    }
  }, [])

  function fetchFeeUnit() {
    const api = new ApiHelper()
    setData({ ...data, isLoading: true })
    api
      .get(`/fee_units`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setFeeUnits(result))
        } else {
          dispatch(setFeeUnits([]))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const setAttribute = (key, val) => {
    const modifyFee = Object.assign({}, newFee)
    modifyFee[key] = val
    dispatch(saveFee(modifyFee))
  }

  const handleFinishCancel = (event, actionType) => {
    event.preventDefault()

    if (actionType === 'cancel') {
      props.backToFee()
      return
    } else if (newFee.title && newFee.amount) {
      const api = new ApiHelper()
      if (!props.feeId) {
        api
          .post('/fees', newFee)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              if (window.location.pathname === '/dealer/add/fee') {
                setErrorMsg('Fee added successfully')
              } else {
                return props.backToFee()
              }
            } else {
              setErrorMsg('Error returning result')
            }
          })
          .catch(error => {
            console.error(error)
            setErrorMsg(error)
          })
      } else {
        api
          .put(`/fees/${props.feeId}`, newFee)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              return props.backToFee()
            } else {
              setErrorMsg('Error returning result')
            }
          })
          .catch(error => {
            console.error(error)
            setErrorMsg(error)
          })
      }
    } else {
      setErrorMsg('Please fill all the details')
    }
  }

  const handleBack = () => {
    const backPath = {
      pathname: props.history.location.state.from,
      state: { from: `/dealer/add/fee` },
    }
    dispatch(saveFee({ title: '', amount: '', fee_unit_id: '' }))
    history.push(backPath)
  }

  function HandleCancelandBack() {
    if (window.location.pathname === '/dealer/add/fee') {
      return (
        <Button
          size="small"
          onClick={handleBack}
          classes={{ label: classes.cancelButtonText }}
        >
          <Trans i18nKey="back" />
        </Button>
      )
    } else {
      return (
        <Button
          size="small"
          onClick={event => handleFinishCancel(event, 'cancel', '')}
          classes={{ label: classes.cancelButtonText }}
        >
          <Trans i18nKey="cancel" />
        </Button>
      )
    }
  }

  return (
    <MuiThemeProvider theme={themes}>
      <Dialog
        open={data.open}
        onClose={props.handleClose}
        aria-labelledby="simple-dialog-title"
        scroll="body"
        fullWidth={true}
        className={classes.dialogPaper}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container className={classes.titleGridContainer}>
            <Grid container item xs={11}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.titleText}>
                  <Trans i18nKey="add_a_new_fee" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={1} justify="flex-end">
              <Grid
                container
                item
                xs={1}
                alignItems="flex-start"
                justify="flex-end"
              >
                <Cancel
                  className={classes.cancelIcon}
                  fontSize="small"
                  onClick={event => handleFinishCancel(event, 'cancel', '')}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1}>
            {/** Fishing Info */}
            <Grid item xs={12}>
              <Typography className={classes.lightText}>
                <Trans i18nKey="fee_info" />
              </Typography>
            </Grid>
            <Grid item md={3} />
            <Grid container item md={9} xs={12}>
              <Grid item xs={12} md={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="fee_title" />
                  </InputLabel>
                  <BootstrapInput
                    value={newFee.title ? newFee.title : ''}
                    name="title"
                    id="title"
                    onChange={e => setAttribute('title', e.currentTarget.value)}
                    placeholder={t('name_of_fee', 'Name of Fee')}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} />
              <Grid container xs={12} md={9} className={classes.gridFirstRow}>
                <Grid xs={12} md={5} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="associated_fee" />*
                    </InputLabel>
                    <BootstrapInput
                      value={newFee.amount ? newFee.amount : ''}
                      name="amount"
                      id="amount"
                      onChange={e =>
                        setAttribute('amount', e.currentTarget.value)
                      }
                      placeholder={t('$00.00', '$00.00')}
                    />
                  </FormControl>
                </Grid>
                <Grid md={2} />
                <Grid item xs={12} md={5} className={classes.gridFirstRow}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel
                      shrink
                      id="customer_name"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="fee_units" />
                    </InputLabel>
                    <Select
                      value={newFee.fee_unit_id ? newFee.fee_unit_id : ''}
                      name="fee_unit_id"
                      displayEmpty
                      disableUnderline
                      className={classes.selectEmpty}
                      placeholder={String(<Trans i18nKey="customer_info" />)}
                      onChange={e =>
                        setAttribute('fee_unit_id', e.target.value)
                      }
                    >
                      <MenuItem value=" ">
                        <Typography className={classes.selectMenuPlaceholder}>
                          {' '}
                          <Trans i18nKey="fee_units" />{' '}
                        </Typography>
                      </MenuItem>
                      {feeUnits &&
                        feeUnits.map(fee => {
                          return (
                            <MenuItem
                              key={fee.id}
                              value={fee.id}
                              className={classes.selectMenuText}
                            >
                              {fee.name}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={1} md={3} />
            </Grid>
          </Grid>
          {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            className={classes.gridFirstRow}
            alignItems="flex-start"
          >
            <Grid container item xs={5} md={8} />
            <Grid container item xs={7} md={3} justify="space-around">
              {<HandleCancelandBack />}
              <Button
                variant="contained"
                onClick={event => handleFinishCancel(event, 'submit', '')}
                size="small"
                className={classes.finishButton}
                classes={{ label: classes.buttonText }}
              >
                <Trans i18nKey="save" />
              </Button>
            </Grid>
            <Grid container item xs={1} md={1} />
          </Grid>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

export const FeesFormContainer = FeesForm
