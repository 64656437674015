import React from 'react'
import PropTypes from 'prop-types'
import { Container, Box, Grid } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { AgreementContainer, LogoContainer } from '../components'
import '../assets/PublicContainer.css'
import { useHistory, useLocation } from 'react-router-dom'

function Agreement(props) {
  if (!props.hideAgreement) {
    return <AgreementContainer />
  }
}

function displayLogo(location, history) {
  if (location.state && location.state.from) {
    return (
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        style={{ marginTop: '8vh', marginBottom: '5vh', marginLeft: '-16px' }}
      >
        <Grid item xs={1}>
          <ArrowBackIosIcon onClick={history.goBack} />
        </Grid>
        <Grid item xs={11}>
          <LogoContainer />
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      style={{ marginTop: '8vh', marginBottom: '5vh' }}
    >
      <Grid item xs={12}>
        <LogoContainer />
      </Grid>
    </Grid>
  )
}

const PublicPageComponent = props => {
  const history = useHistory()
  const location = useLocation()

  return (
    <Container width="100%" className="PublicContainer">
      <Box height="54vh">
        <Container maxWidth="sm">
          <Grid container direction="column" justify="flex-end">
            {displayLogo(location, history)}
            {props.top}
          </Grid>
        </Container>
      </Box>
      <Box height="46vh">
        <Container maxWidth="sm">
          {props.bottom}
          {Agreement(props)}
        </Container>
      </Box>
    </Container>
  )
}

PublicPageComponent.propTypes = {
  top: PropTypes.element.isRequired,
  bottom: PropTypes.element.isRequired,
  hideAgreement: PropTypes.bool,
}

export const PublicContainer = PublicPageComponent
