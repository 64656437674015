import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import {
  Container,
  Grid,
  Box,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { theme } from '../../pages/shared/theme'
import { TraceAgreementContainer } from '../TraceAgreement'
import '../../assets/Trace.css'
import { ArrowBack, Close, Lock } from '@material-ui/icons'
import ReportProblem from '../../assets/trace/ReportProblem.svg'
import { ApiHelper } from '../../lib'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import VerifiedTrace from '../../assets/trace/VerifiedTrace.svg'
import PendingTrace from '../../assets/trace/PendingTrace.svg'
import { useHistory } from 'react-router-dom'
import { isWidthUp } from '@material-ui/core/withWidth'
import { LotNotFoundContainer } from './LotNotFound'
import { ServerErrorContainer } from './ServerError'
import { DefaultContainer } from './Default'
import { DeviLimitedContainer } from './DeviLimited'
import VerificationResultBackgroundBottom from '../../assets/trace/VerificationResultBackgroundBottom.svg'
import VerificationResultTop from '../../assets/trace/VerificationResultTop.svg'
import Alert from '@material-ui/lab/Alert'
import { ChannelFishContainer } from './channelfish/ChannelFish'
import InfoIcon from '@material-ui/icons/Info'

const styles = () => ({
  verificationResultTop: {
    color: 'white',
    backgroundImage: `url(${VerificationResultTop})`,
    backgroundColor: 'transparent',
    maxWidth: '100%',
    backgroundSize: 'cover',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '25%',
      marginRight: '25%',
      maxWidth: '50%',
    },
  },

  traceImage: {
    zIndex: '100',
    position: 'relative',
    marginTop: '-4.7vh',
    [theme.breakpoints.up('md')]: {
      marginTop: '-5.5vh',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-9vh',
    },
  },

  carouselImage: {
    height: '40vh',
    [theme.breakpoints.up('lg')]: {
      marginTop: '-5vh',
      height: '50vh',
      marginBottom: '-5vh',
    },
  },

  iconButton: {
    padding: '0px',
  },

  topContainer: {
    maxWidth: '100%',
    minHeight: '40vh',
    paddingLeft: '0px',
    paddingRight: '0px',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '25%',
      maxWidth: '50%',
      position: 'fixed',
    },
  },

  bottomContainer: {
    fontFamily: 'Rubik',
    color: 'black',
    fontWeight: 'regular',
    backgroundImage: `url(${VerificationResultBackgroundBottom})`,
    backgroundSize: '100% 100%',
    overflow: 'scroll',
    maxWidth: '100%',
    paddingLeft: '10vw',
    paddingTop: '11%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
      margin: 'auto',
      paddingTop: '14vh',
      paddingLeft: '5vw',
    },
  },

  traceAgreementGrid: {
    padding: '15px',
    color: '#4F596F',
  },

  reportProblemHeading: {
    fontWeight: 500,
    fontSize: '1.05rem',
    color: '#4F596F',
  },

  reportProblemText: {
    color: '#808080',
    fontSize: '0.8rem',
    marginBlockStart: '0.7em',
    marginBlockEnd: 0,
  },

  flashMessageText: {
    fontWeight: 500,
    fontSize: '0.7rem',
    textAlign: 'center',
    marginTop: '-1vh',
    marginBottom: '1vh',
    paddingLeft: '8vw',
    paddingRight: '5vw',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0vw',
      paddingRight: '0vw',
      marginTop: '-2vh',
      marginBottom: '2vh',
    },
  },

  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  submitButton: {
    minWidth: '120px',
    backgroundColor: '#003e6d',
    fontSize: '1rem',
    color: '#fbf5f5',
    fontWeight: 500,
    lineHeight: 2.5,
    borderRadius: '3.2rem',
    '&:hover': {
      backgroundColor: '#003e6d',
    },
  },

  iframe: {
    borderWidth: '1px 0',
    borderColor: 'black',
  },

  mapDescription: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    color: 'white',
    transform: 'translateX(-50%)',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    boxShadow: 'inset 0 0 0 3000px rgb(3 35 54 / 60%)',
    marginBottom: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '50px',
    },
  },

  mapDescriptionIcon: {
    marginLeft: '5px',
  },
})

export function VerificationResultParent(props) {
  const [describeProblem, setDescribeProblem] = useState('')
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')

  const [ticketId, setTicketId] = useState('')

  const [errorMsg, setErrorMsg] = useState('')

  const closeTicketDialog = () => {
    setTicketId('')
    setErrorMsg('')
    setDescribeProblem('')
    setUserEmail('')
    setUserPhone('')
    setUserName('')
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setErrorMsg('')
  }

  const handleSubmit = () => {
    if (!describeProblem || !userName || !userEmail || !userPhone) {
      setErrorMsg('Please fill all the details')
    } else {
      const comment =
        'Phone: ' + userPhone + '\n\n' + 'Comment: ' + describeProblem
      reportAProblem(userName, userEmail, comment)
    }
  }

  const [openIframe, setOpenIframe] = useState(false)
  const [openMapDesc, setOpenMapDesc] = useState(false)

  /*const formatOffloadDate = offload_date => {
    if (offload_date) {
      return format(new Date(offload_date), 'MMMM dd yyyy')
    }
    return 'N/A'
  }*/

  function reportAProblem(name, email, problem) {
    props.setLoadingState('loading')
    setOpen(false)
    const api = new ApiHelper()
    api
      .post('/tickets', {
        user_name: name,
        user_email: email,
        comment: problem,
      })
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        setTicketId(result.id)
        props.setLoadingState('success')
      })
      .catch(error => {
        setOpen(true)
        if (error.error) {
          setErrorMsg(error.error)
          console.error('Error:' + error.error)
        } else {
          setErrorMsg('Unable to Connect to Server!')
          console.error('Error:' + error)
        }
        props.setLoadingState('success')
      })
  }

  const history = useHistory()
  const back = () => {
    const tracePath = {
      pathname: '/trace',
      state: { from: 'verificationResult' },
    }
    history.push(tracePath)
  }

  function showFlashMessage() {
    if (!props.flashMessage) {
      return null
    }
    return (
      <Typography className={props.classes.flashMessageText}>
        <Trans i18nKey="issue_with_label" />
      </Typography>
    )
  }

  function showVerificationBar() {
    if (
      props.trace.component === 'NorthernWindLobsterContainer' ||
      props.trace.component === 'EastCoastSeafoodLobsterContainer'
    ) {
      return
    }
    return (
      <div className={props.classes.traceImage}>
        <img
          alt="Verification Bar"
          src={props.trace.status === 'verified' ? VerifiedTrace : PendingTrace}
        />
      </div>
    )
  }

  function showMapDesc() {
    if (
      props.trace.component === 'NorthernWindLobsterContainer' ||
      props.trace.component === 'EastCoastSeafoodLobsterContainer'
    ) {
      return (
        <p className={props.classes.mapDescription}>
          <Typography
            component="span"
            color="inherit"
            onClick={() => setOpenMapDesc(true)}
          >
            <Trans i18nKey="map_description" />
            <InfoIcon className={props.classes.mapDescriptionIcon} />
          </Typography>
        </p>
      )
    }
  }

  function carouselImages() {
    const screenWidth = isWidthUp('lg', props.width)
    if (props.trace.map_images && props.trace.map_images.length !== 0) {
      return (
        <Carousel
          showThumbs={false}
          showArrows={screenWidth}
          showStatus={false}
          showIndicators={props.trace.map_images.length > 1 ? true : false}
        >
          {props.trace.map_images.map((value, index) => {
            return (
              <div key={index}>
                <img
                  alt="Map Image"
                  className={props.classes.carouselImage}
                  src={value}
                ></img>
                {showMapDesc()}
              </div>
            )
          })}
        </Carousel>
      )
    }
    return (
      <Carousel
        showIndicators={false}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
      >
        <img
          alt="Map Image"
          className={props.classes.carouselImage}
          src=""
        ></img>
      </Carousel>
    )
  }

  function appBarBackSelector(props) {
    if (!props.locationState) {
      return
    }
    if (isWidthUp('lg', props.width)) {
      return (
        <IconButton
          color="inherit"
          className={props.classes.iconButton}
          onClick={back}
        >
          <ArrowBack />
          <Typography color="inherit">
            &nbsp;
            <Trans i18nKey="back_to_search" />
          </Typography>
        </IconButton>
      )
    }
    return (
      <IconButton
        color="inherit"
        className={props.classes.iconButton}
        onClick={back}
      >
        <ArrowBack />
      </IconButton>
    )
  }

  function appBar() {
    return (
      <AppBar className={props.classes.verificationResultTop}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item container xs={2} lg={4} justify="flex-start">
              {appBarBackSelector(props)}
            </Grid>
            <Grid item container xs={8} lg={4} justify="center">
              <Typography color="inherit">
                <Trans i18nKey="verification_result" />
              </Typography>
            </Grid>
            <Grid item xs={1} lg={3}></Grid>
            <Grid item container xs={1} justify="flex-end">
              <img
                src={ReportProblem}
                alt="ReportProblem"
                width="25vw"
                onClick={handleClickOpen}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }

  function showResult() {
    if (props.loadingState === 'loading') {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          {appBar()}
          <CircularProgress />
        </Grid>
      )
    } else if (props.componentToShow === 'Lot not found') {
      return (
        <div>
          {appBar()}
          <LotNotFoundContainer
            backButton={back}
            showBackButton={props.locationState}
          />
        </div>
      )
    } else if (props.componentToShow === 'Error retrieving trace') {
      return (
        <div>
          {appBar()}
          <ServerErrorContainer
            backButton={back}
            showBackButton={props.locationState}
          />
        </div>
      )
    } else {
      const components = {
        DeviLimitedContainer: DeviLimitedContainer,
        DefaultContainer: DefaultContainer,
        NorthernWindLobsterContainer: DefaultContainer,
        EastCoastSeafoodLobsterContainer: DefaultContainer,
        ChannelFishContainer: ChannelFishContainer,
      }
      const ClientSpecificComponent = components[props.trace.component]
      return (
        <div>
          <Box height="40vh">
            {appBar()}
            <Container className={props.classes.topContainer}>
              {carouselImages()}
              {showVerificationBar()}
            </Container>
          </Box>
          <Box height="60vh">
            <Container width="100%" className={props.classes.bottomContainer}>
              {showFlashMessage()}
              <ClientSpecificComponent trace={props.trace} />
            </Container>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justify="center"
              className={props.classes.traceAgreementGrid}
              onClick={() => setOpenIframe(true)}
            >
              <TraceAgreementContainer />
            </Grid>
          </Box>
        </div>
      )
    }
  }

  return (
    <div>
      {showResult()}
      <Dialog open={ticketId ? true : false} onClose={closeTicketDialog}>
        <Alert severity="success">
          <Typography color="inherit">
            <Trans i18nKey="ticket_number_is" />: {ticketId}
          </Typography>
        </Alert>
      </Dialog>
      <Dialog open={openMapDesc} onClose={() => setOpenMapDesc(false)}>
        <Alert severity="info">
          <Typography color="inherit">
            {props.trace.map_description}
            <br />
            NARW Migration & Lobster Fishing Areas
          </Typography>
        </Alert>
      </Dialog>
      <Dialog open={open} className={props.classes.dialog}>
        <DialogContent>
          <DialogContentText className={props.classes.reportProblemHeading}>
            <Trans i18nKey="report_a_problem" />
          </DialogContentText>
          <DialogContentText className={props.classes.reportProblemText}>
            <Trans i18nKey="issue_with_site" />?
          </DialogContentText>
          <DialogContentText className={props.classes.reportProblemText}>
            <Trans i18nKey="fullName" />
          </DialogContentText>
          <TextField
            placeholder="Your name"
            onChange={e => setUserName(e.currentTarget.value)}
            value={userName}
            variant="outlined"
            fullWidth
          />
          <DialogContentText className={props.classes.reportProblemText}>
            <Trans i18nKey="email_address" />
          </DialogContentText>
          <TextField
            placeholder="Your email address"
            onChange={e => setUserEmail(e.currentTarget.value)}
            value={userEmail}
            variant="outlined"
            fullWidth
          />
          <DialogContentText className={props.classes.reportProblemText}>
            <Trans i18nKey="mobile_number" />
          </DialogContentText>
          <TextField
            placeholder="Your phone number"
            onChange={e => setUserPhone(e.currentTarget.value)}
            value={userPhone}
            variant="outlined"
            fullWidth
          />
          <DialogContentText className={props.classes.reportProblemText}>
            <Trans i18nKey="describe_the_problem" />.
          </DialogContentText>
          <FormControl fullWidth error>
            <TextField
              placeholder="Describe the problem you experienced here..."
              multiline
              rows={5}
              onChange={e => setDescribeProblem(e.currentTarget.value)}
              value={describeProblem}
              variant="outlined"
              fullWidth
            />
            <FormHelperText>{errorMsg}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans i18nKey="cancel" />
          </Button>
          <Button
            id="reportProblemButton"
            variant="outlined"
            type="button"
            onClick={handleSubmit}
            margin="normal"
            className={props.classes.submitButton}
          >
            <Trans i18nKey="submit" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen open={openIframe}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ paddingTop: '1vh', paddingBottom: '1vh' }}
        >
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={() => setOpenIframe(false)}
          >
            <ArrowBack />
          </Grid>
          <Grid xs={8} item container justify="center" alignItems="center">
            <Lock fontSize="small" />
            &nbsp;legitfish.com
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={() => setOpenIframe(false)}
          >
            <Close />
          </Grid>
        </Grid>
        <iframe
          title="Legitfish site"
          className={props.classes.iframe}
          src={'https://legitfish.com/'}
          width="100%"
          height="100%"
        ></iframe>
      </Dialog>
    </div>
  )
}

export const VerificationResultParentContainer = withStyles(styles)(
  VerificationResultParent
)
