import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Grid, Container, Typography } from '@material-ui/core'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { theme } from './shared/theme'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import HomeIcon from '@material-ui/icons/Home'
import { AccountDetailsContainer } from '../components/hail/AccountDetails'
import { NotificationsContainer } from '../components/hail/Notifications'
import { useHistory } from 'react-router-dom'
import { setNewHail } from '../actions'

const styles = () => ({
  gridContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
})

export function MyAccount(props) {
  const [showNotifications, setShowNotifications] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (props.location.state.from === 'notification') {
      setShowNotifications(true)
    }
  }, [props.location.state.from])

  const home = () => {
    const welcomeLocation = {
      pathname: '/vessel',
      state: { from: 'vessel/account' },
    }
    history.push(welcomeLocation)
  }

  function heading() {
    if (showNotifications) {
      return <Trans i18nKey="notifications" />
    } else {
      return <Trans i18nKey="my_account" />
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container className={props.classes.backgroundImage}>
        <Container width="100%" className={props.classes.topImage}>
          <Grid container spacing={0} className={props.classes.gridContainer}>
            <Grid item xs={1}>
              <ArrowBackIosIcon onClick={home} />
            </Grid>
            <Grid container item xs={10} justify="center">
              <Typography color="inherit">{heading()}</Typography>
            </Grid>
            <Grid item xs={1}>
              <HomeIcon onClick={home} />
            </Grid>
          </Grid>
        </Container>
        <Container width="100%" className={props.classes.bottomImage}>
          {showNotifications ? (
            <NotificationsContainer />
          ) : (
            <AccountDetailsContainer />
          )}
        </Container>
      </Container>
    </MuiThemeProvider>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return { hail: state.newHail }
}

const mapDispatchToProps = { setNewHail }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(MyAccount))
