import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  createMuiTheme,
  Divider,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import { ApiHelper } from '../../lib'
import { Trans } from 'react-i18next'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import withDealerTheme from '../shared/DealerStyles'
import BootstrapInput from './TextfieldForDealer'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import { saveCustomer } from '../../features/dealerSlice'

const themes = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '900px',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: 'white !important',
        padding: '0px !important',
        color: 'black',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#4D4D4D',
        '&$focused': {
          color: '#4D4D4D',
        },
        '&$disabled': {
          color: '#B3B3B3',
        },
        '&$error': {
          color: '#B80000',
        },
        '&$filled': {
          color: '#4D4D4D',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0px',
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '0px 0px !important',
      },
      option: {
        fontSize: '0.75rem',
        textTransform: 'none',
      },
      inputRoot: {
        height: '2rem',
        borderRadius: '0px',
        backgroundColor: '#F6F9FA',
        fontSize: '0.75rem',
        '&$focused': {
          borderColor: '#E0E0E0',
        },
      },
    },
  },
})

export function CustomerForm(props) {
  const classes = withDealerTheme()

  const [errorMsg, setErrorMsg] = useState('')
  const dispatch = useDispatch()
  const newCustomer = useSelector(state => state.dealer.newCustomer)
  const addressAttribute = useSelector(
    state => state.dealer.newCustomer.addresses[0]
  )
  const contactsAttribute = useSelector(state =>
    state.dealer.newCustomer.contacts
      ? state.dealer.newCustomer.contacts[0]
      : {}
  )

  const [data, setData] = useState({ open: false })

  useEffect(() => {
    if (props.open || window.location.pathname === '/dealer/add/customer') {
      setData({ ...data, open: true })
    }
  }, [])

  let apiInput = {
    name: newCustomer.name,
    federal_permit_number: newCustomer.federal_permit_number,
    state_permit_number: newCustomer.state_permit_number,
    addresses_attributes: newCustomer.addresses,
    contacts_attributes: newCustomer.contacts,
  }

  const modifyCustomer = Object.assign({}, newCustomer)
  const setAttribute = (key, val) => {
    modifyCustomer[key] = val
    dispatch(saveCustomer(modifyCustomer))
  }

  const setAddressAttribute = (key, val) => {
    const modifyCustomerAddress = Object.assign({}, addressAttribute)
    modifyCustomerAddress[key] = val
    modifyCustomer['addresses'] = [modifyCustomerAddress]
    dispatch(saveCustomer(modifyCustomer))
  }

  const setContactsAttribute = (key, val) => {
    const modifyCustomerContact = Object.assign({}, contactsAttribute)
    modifyCustomerContact[key] = val
    modifyCustomer['contacts'] = [modifyCustomerContact]
    dispatch(saveCustomer(modifyCustomer))
  }

  const handleFinishCancel = (event, actionType) => {
    event.preventDefault()

    if (actionType === 'cancel') {
      props.backToCustomer('')
      return
    } else if (newCustomer.name && newCustomer.federal_permit_number) {
      const api = new ApiHelper()
      if (!props.customerId) {
        api
          .post('/processors', apiInput)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              return props.backToCustomer(result.id)
            } else {
              setErrorMsg('Error returning result')
            }
          })
          .catch(error => {
            console.error(error)
            setErrorMsg(error)
          })
      } else {
        api
          .put(`/processors/${props.customerId}`, apiInput)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              return props.backToCustomer(result.id)
            } else {
              setErrorMsg('Error returning result')
            }
          })
          .catch(error => {
            console.error(error)
            setErrorMsg(error)
          })
      }
    } else {
      setErrorMsg('Please fill all the details')
    }
  }

  const handleBack = () => {
    props.backToCustomer('')
  }

  function HandleCancelandBack() {
    if (window.location.pathname === '/dealer/add/fee') {
      return (
        <Button
          size="small"
          onClick={handleBack}
          classes={{ label: classes.cancelButtonText }}
        >
          <Trans i18nKey="back" />
        </Button>
      )
    } else {
      return (
        <Button
          size="small"
          onClick={event => handleFinishCancel(event, 'cancel', '')}
          classes={{ label: classes.cancelButtonText }}
        >
          <Trans i18nKey="cancel" />
        </Button>
      )
    }
  }

  return (
    <MuiThemeProvider theme={themes}>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-dialog-title"
        scroll="body"
        fullWidth={true}
        className={classes.dialogPaper}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container className={classes.titleGridContainer}>
            <Grid container item xs={11}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.titleText}>
                  <Trans i18nKey="add_a_new_customer" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={1} justify="flex-end">
              <Grid
                container
                item
                xs={1}
                alignItems="flex-start"
                justify="flex-end"
              >
                <Cancel
                  className={classes.cancelIcon}
                  fontSize="small"
                  onClick={event => handleFinishCancel(event, 'cancel', '')}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.lightText}>
                <Trans i18nKey="company_info" />
              </Typography>
            </Grid>
            <Grid item md={3} />
            <Grid container item md={9}>
              <Grid item xs={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="company_name" />*
                  </InputLabel>
                  <BootstrapInput
                    value={newCustomer.name ? newCustomer.name : ''}
                    name="name"
                    id="name"
                    onChange={e => setAttribute('name', e.currentTarget.value)}
                    placeholder="Seafood Company Inc."
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="federal_permit_number" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.federal_permit_number
                        ? newCustomer.federal_permit_number
                        : ''
                    }
                    name="federal_permit_number"
                    id="federal_permit_number"
                    onChange={e =>
                      setAttribute(
                        'federal_permit_number',
                        e.currentTarget.value
                      )
                    }
                    placeholder="1243-456"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="state_permit_number" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.state_permit_number
                        ? newCustomer.state_permit_number
                        : ''
                    }
                    name="state_permit_number"
                    id="state_permit_number"
                    onChange={e =>
                      setAttribute('state_permit_number', e.currentTarget.value)
                    }
                    placeholder="1243-456"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="company_address" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.addresses[0]
                        ? newCustomer.addresses[0].address1
                        : ''
                    }
                    name="address1"
                    id="address1"
                    onChange={e =>
                      setAddressAttribute('address1', e.currentTarget.value)
                    }
                    placeholder="1234 First St"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="address_line_2" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.addresses[0]
                        ? newCustomer.addresses[0].address2
                        : ''
                    }
                    name="address2"
                    id="address2"
                    onChange={e =>
                      setAddressAttribute('address2', e.currentTarget.value)
                    }
                    placeholder="Suite 1234"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="state" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.addresses[0]
                        ? newCustomer.addresses[0].state
                        : ''
                    }
                    name="state"
                    id="state"
                    onChange={e =>
                      setAddressAttribute('state', e.currentTarget.value)
                    }
                    placeholder="MA"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="zipcode" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.addresses[0]
                        ? newCustomer.addresses[0].zipcode
                        : ''
                    }
                    name="zipcode"
                    id="zipcode"
                    onChange={e =>
                      setAddressAttribute('zipcode', e.currentTarget.value)
                    }
                    placeholder="12345"
                  />
                </FormControl>
              </Grid>
              <Grid container item alignItems="center">
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="link_to_a_vessel" />
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="vessel_name" />*
                    </InputLabel>
                    <BootstrapInput
                      disabled
                      value={
                        newCustomer.vessel_name ? newCustomer.vessel_name : ''
                      }
                      name="vessel_name"
                      id="vessel_name"
                      onChange={e =>
                        setAttribute('vessel_name', e.currentTarget.value)
                      }
                      placeholder="Rosa Lee"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.lightText}>
                <Trans i18nKey="contact_info" />
              </Typography>
            </Grid>
            <Grid item md={3} />
            <Grid container item md={9}>
              <Grid item xs={9} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="company_representative_full_name" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.contacts
                        ? newCustomer.contacts[0].full_name
                        : ''
                    }
                    name="full_name"
                    id="full_name"
                    onChange={e =>
                      setContactsAttribute('full_name', e.currentTarget.value)
                    }
                    placeholder="Joe Smith"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="rep_cell_phone_number" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.contacts
                        ? newCustomer.contacts[0].mobile_number
                        : ''
                    }
                    name="mobile_number"
                    id="mobile_number"
                    onChange={e =>
                      setContactsAttribute(
                        'mobile_number',
                        e.currentTarget.value
                      )
                    }
                    placeholder="012-345-6789"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4} className={classes.gridFirstRow}>
                <FormControl className={classes.margin} fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className={classes.labelText}
                  >
                    <Trans i18nKey="rep_email_address" />*
                  </InputLabel>
                  <BootstrapInput
                    value={
                      newCustomer.contacts ? newCustomer.contacts[0].email : ''
                    }
                    name="email"
                    id="email"
                    onChange={e =>
                      setContactsAttribute('email', e.currentTarget.value)
                    }
                    placeholder="Joe@emailprovider.com"
                  />
                </FormControl>
              </Grid>
              <Grid container item alignItems="center">
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="rep_fax_number" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        newCustomer.contacts ? newCustomer.contacts[0].fax : ''
                      }
                      name="fax"
                      id="fax"
                      onChange={e =>
                        setContactsAttribute('fax', e.currentTarget.value)
                      }
                      placeholder="012-345-6789"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Trans i18nKey="notification_preference" />:
                  </Typography>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="sms_text_message" />
                  </Typography>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="email" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            className={classes.gridFirstRow}
            alignItems="flex-start"
          >
            <Grid container item xs={5} md={8} />
            <Grid container item xs={7} md={3} justify="space-around">
              <HandleCancelandBack />
              <Button
                variant="contained"
                onClick={event => handleFinishCancel(event, 'submit', '')}
                size="small"
                className={classes.finishButton}
                classes={{ label: classes.buttonText }}
              >
                <Trans i18nKey="save" />
              </Button>
            </Grid>
            <Grid container item xs={1} md={1} />
          </Grid>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

export const CustomerFormContainer = CustomerForm
