import React from 'react'
import { connect } from 'react-redux'
import {
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MuiThemeProvider,
  createMuiTheme,
  Grid,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { Trans } from 'react-i18next'
import withDealerTheme from '../shared/DealerStyles'

const breakpoints = createBreakpoints({})
const themes = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        [breakpoints.up('md')]: {
          minWidth: '60vh',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: 'white !important',
        padding: '0px !important',
        color: 'black',
      },
    },
  },
})

export default function ConfirmationStatus(props) {
  const classes = withDealerTheme()

  function TitleComponent() {
    if (props.status === 'submit') {
      return (
        <div>
          {props.message === 'markedasfinalize' ? (
            <Trans i18nKey="final_success" />
          ) : (
            <Trans i18nKey="success" />
          )}
        </div>
      )
    } else if (props.status === 'cancel') {
      return (
        <div>
          <Trans i18nKey="want_to_exit" />
        </div>
      )
    }
  }

  function ContentComponent() {
    if (props.status === 'submit') {
      return (
        <div>
          <div>
            <Trans i18nKey={`${props.itemName}_number`} />
          </div>
          <div className={classes.offloadNumber}>
            <React.Fragment>{props.itemNumber}</React.Fragment>
          </div>
          <div>
            <Trans i18nKey={props.message} />
          </div>
        </div>
      )
    } else if (props.status === 'cancel') {
      return (
        <div>
          <div>
            <Trans i18nKey={`discard_${props.itemName}`} />
          </div>
          <br />
          <div>
            <Trans i18nKey={`${props.itemName}_lost`} />
          </div>
        </div>
      )
    }
  }

  let cancelButton = (
    <Grid
      container
      item
      xs={12}
      className={classes.gridFirstRow}
      alignItems="flex-end"
    >
      <Grid container item xs={4} md={4} />
      <Grid container item xs={8} md={8} justify="space-around">
        <Button
          size="small"
          onClick={props.handleCancel}
          classes={{ label: classes.cancelButtonText }}
        >
          <Trans i18nKey="cancel" />
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={props.handleBack}
          className={classes.finishButton}
          classes={{ label: classes.buttonText }}
        >
          <Trans i18nKey="confirm" />
        </Button>
      </Grid>
    </Grid>
  )

  function ButtonComponent() {
    if (props.status === 'submit') {
      return (
        <React.Fragment>
          {props.message === 'markedasfinalize' ? (
            cancelButton
          ) : (
            <Grid
              container
              item
              xs={12}
              className={classes.gridFirstRow}
              alignItems="flex-end"
            >
              <Grid container item md={1} />
              <Grid container item xs={12} md={10} justify="space-around">
                <Button
                  variant="outlined"
                  onClick={props.backToOffloading}
                  className={classes.finishButton}
                  classes={{ label: classes.buttonText }}
                >
                  <Trans i18nKey={`back_to_${props.itemName}`} />
                </Button>
              </Grid>
              <Grid container item md={1} />
            </Grid>
          )}
        </React.Fragment>
      )
    } else if (props.status === 'cancel') {
      return cancelButton
    }
  }
  const button = <ButtonComponent />
  const title = <TitleComponent />
  const content = <ContentComponent />
  return (
    <div>
      <MuiThemeProvider theme={themes}>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container className={classes.titleGridContainer}>
              <Grid container item xs={11} className={classes.statusTitle}>
                {title}
              </Grid>
              <Grid container item xs={1} justify="flex-end">
                <Grid
                  container
                  item
                  xs={1}
                  alignItems="flex-start"
                  justify="flex-end"
                >
                  <Cancel
                    className={classes.statusCancelIcon}
                    onClick={props.handleBack}
                    fontSize="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.contentText}>
            {content}
          </DialogContent>
          <DialogActions> {button} </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  )
}

const mapStateToProps = state => {
  return { state }
}

const mapDispatchToProps = {}

export const ConfirmationStatusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ConfirmationStatus))
