import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { useDispatch, useSelector } from 'react-redux'
import { TableView } from '../../components/TableView'
import { FeesFormContainer } from './FeesForm'
import { ApiHelper } from '../../lib'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { saveFee, setDealerFees, setFeeUnits } from '../../features/dealerSlice'

export function DealerFees(props) {
  const headCells = [
    { id: 'title', label: 'fee_title', sort: 'fee_title' },
    {
      id: 'amount',
      label: 'associated_fee',
    },
    {
      id: 'fee_unit_id',
      label: 'fee_units',
      fetchFromParent: 'fee_unit_id',
    },
  ]

  const dataURL = 'fees'
  const [open, setOpen] = useState(false)

  const [startIndex, setstartIndex] = useState(1)
  const [displayDataCount, setDisplayDataCount] = useState(0)
  const initalLimitPerPage = 10
  const [feeId, setFeeId] = useState('')

  const initialFilterDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [errorMsg, setErrorMsg] = React.useState('')

  const [paramState, setParamState] = React.useState(tableParameters)
  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const dealerFees = useSelector(state => state.dealer.dealerFees)

  function fetchDisplayData() {
    const api = new ApiHelper()

    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let feesUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      feesUrl = feesUrl + '&q=' + paramState.searchKey
    }
    setIsLoading(true)
    api
      .get(feesUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerFees(result.fees))
          setstartIndex(startIndexVal)
          setDisplayDataCount(result.pagination.count)
          setErrorMsg('')
        } else {
          setErrorMsg('Error occured while retrieving Dealer Fees')
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error)
        setIsLoading(false)
      })
  }

  const feeUnits = useSelector(state => state.dealer.feeUnits)

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get(`/fee_units`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setFeeUnits(result))
        } else {
          dispatch(setFeeUnits([]))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    fetchDisplayData()
  }, [paramState, dataURL])

  const handleAddNewFee = () => {
    setFeeId('')
    setOpen(true)
  }

  const handleClose = () => {
    dispatch(saveFee({ title: '', amount: '', fee_unit_id: '' }))
    setOpen(false)
  }

  const handleEdit = feeId => {
    let index = dealerFees.findIndex(obj => obj.id == feeId)
    let selectedFee = {}
    selectedFee = { ...dealerFees[index] }
    dispatch(saveFee(selectedFee))
    setFeeId(feeId)
    setOpen(true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setstartIndex(1)
    }
    setParamState(paramHash)
  }

  function backToFee() {
    fetchDisplayData()
    handleClose()
  }

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  function onFetchFromParent(name, key) {
    let dealerFee = dealerFees.find(obj => obj.id == key)
    let feeUnit = feeUnits.find(obj => obj.id == dealerFee.fee_unit_id)
    if (name === 'fee_unit_id' && feeUnit) {
      return feeUnit.name
    }
  }

  return (
    <div>
      <div>
        <TableView
          headCells={headCells}
          displayData={dealerFees}
          startIndex={startIndex}
          sortType={headCells[0].id}
          paramState={paramState}
          loadData={loadData}
          onHandleaddNew={handleAddNewFee}
          displayDataCount={displayDataCount}
          initalLimitPerPage={initalLimitPerPage}
          handleEdit={handleEdit}
          isAddNewAllowed={true}
          isLoading={isLoading}
          sortOptions={false}
          isSortEnabled={isSortEnabled}
          onFetchFromParent={onFetchFromParent}
        />
        {open ? (
          <FeesFormContainer
            open={open}
            handleClose={handleClose}
            feeId={feeId}
            backToFee={backToFee}
          />
        ) : null}
        {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
      </div>
    </div>
  )
}

export const DealerFeesContainer = withStyles(combineStyles(stylesBase))(
  DealerFees
)
