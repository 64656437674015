import React from 'react'
import {
  withStyles,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  IconButton,
  Hidden,
  List,
  ListItem,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import EditIcon from '@material-ui/icons/Edit'
import { Trans } from 'react-i18next'
import classNames from 'classnames'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { withDealerTheme } from '../../shared/DealerTheme'
import combineStyles from '../../shared/CombineStyles'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'

const styles = () => ({})

export function DealerInventoryList(props) {
  let paramState = { ...props.paramState }

  const defaultCollapse = {}
  props.displayData.forEach(row => (defaultCollapse[row.id] = false))

  const [data, setData] = React.useState({
    selected: [],
    collapse: defaultCollapse,
  })

  let expandData = props.expandData
  const isSelected = name => data.selected.indexOf(name) !== -1
  let order = paramState.order,
    orderBy = paramState.orderBy
  let limitPerPageSelected = paramState.limitPerPageSelected
  let displayDataCount = props.displayDataCount
  let pageSelected = paramState.pageSelected
  let startIndex = props.startIndex
  const totalPages = Math.ceil(displayDataCount / limitPerPageSelected)
  //calculate end index
  let endIndex = startIndex + limitPerPageSelected
  if (endIndex > displayDataCount) {
    endIndex = displayDataCount
  }
  //Taking page limit to be always multiple of 10
  const startPerRow = props.initialLimitPerPage
  const step = props.initialLimitPerPage

  let pageLimit = []

  if (displayDataCount < startPerRow) {
    pageLimit.push(displayDataCount)
  } else {
    pageLimit = Array.from(
      Array.from(
        Array(Math.ceil((displayDataCount - startPerRow) / step)).keys()
      ),
      x => startPerRow + x * step
    )
    if (
      pageLimit.length === 0 ||
      pageLimit[pageLimit.length - 1] < displayDataCount
    ) {
      pageLimit.push(displayDataCount)
    }
  }

  const handleClick = (event, name) => {
    let selected = data.selected
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setData({
      ...data,
      selected: newSelected,
    })
  }

  function changePageNumber(event, page) {
    paramState.pageSelected = parseInt(page)
    props.loadData(paramState, false)
  }

  function changeRowPerPage(event) {
    paramState.limitPerPageSelected = parseInt(event.target.value)
    props.loadData(paramState, false)
  }

  const handleRequestSort = (event, property) => {
    let order = paramState.order,
      orderBy = paramState.orderBy
    const isAsc = orderBy === property && order === 'asc'
    paramState.order = isAsc ? 'desc' : 'asc'
    paramState.orderBy = property
    props.loadData(paramState, false)
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  function handleChange(fieldName, status) {
    setData({
      ...data,
      [fieldName]: status,
    })
  }

  function listView(isItemSelected, row, rowId) {
    return (
      <TableRow
        hover
        selected={isItemSelected}
        classes={{ selected: props.classes.selected }}
        className={props.classes.tableRow}
        key={rowId}
      >
        <TableCell
          align="center"
          className={`${
            isItemSelected ? props.classes.tabledata : props.classes.tabledata
          }
                      ${
                        isItemSelected
                          ? props.classes.firstColumnData
                          : props.classes.firstColumnData
                      }
                      ${
                        isItemSelected
                          ? props.classes.firstColSeldData
                          : props.classes.firstColNonSelData
                      }
                      ${
                        row.status === 'submitted'
                          ? props.classes.tableCellGradient
                          : ''
                      }
                    `}
        >
          <Checkbox
            checked={isItemSelected}
            onClick={event => {
              handleClick(event, rowId)
            }}
            color="primary"
            className={props.classes.tablerowselector}
            classes={{
              root: props.classes.checkboxRoot,
              checked: props.classes.checked,
            }}
          />
        </TableCell>

        {/** start of table row iteration */}
        {props.headCells.map(function(headCell) {
          let valueToDisplay = row[headCell.id]
          if (headCell.fetchFromParent) {
            valueToDisplay = props.onFetchFromParent(
              headCell.fetchFromParent,
              valueToDisplay
            )
          }
          return (
            <TableCell
              key={valueToDisplay + '_' + headCell.id}
              className={classNames(
                props.classes.tabledata,
                props.classes.textDataLight,
                props.classes.otherColumns
              )}
              onClick={() => {
                {
                  let tempCollapse = { ...data.collapse }
                  tempCollapse[rowId] = !tempCollapse[rowId]
                  handleChange('collapse', tempCollapse)
                }
              }}
            >
              {valueToDisplay}
            </TableCell>
          )
        })}
        {/** end of table row iteration */}
        <TableCell
          className={classNames(
            props.classes.tabledata,
            props.classes.textDataLight,
            props.classes.otherColumns
          )}
        >
          {!data.collapse[row.id] ? (
            <IconButton className={props.classes.multiIconButton}>
              <EditIcon className={props.classes.tableSelectedIcon} />
            </IconButton>
          ) : (
            <>
              <List className={props.classes.listClass}>
                <ListItem className={props.classes.listItemClass}>
                  {
                    <IconButton className={props.classes.multiIconButton}>
                      <EditIcon className={props.classes.tableSelectedIcon} />
                    </IconButton>
                  }
                </ListItem>
                <ListItem className={props.classes.listItemClass}>
                  {
                    <IconButton className={props.classes.multiIconButton}>
                      <HighlightOffOutlinedIcon
                        className={props.classes.tableSelectedIcon}
                        color="primary"
                        fontSize="small"
                        onClick={() => {
                          let tempCollapse = {
                            ...data.collapse,
                          }
                          tempCollapse[row.id] = !tempCollapse[row.id]
                          handleChange('collapse', tempCollapse)
                        }}
                      />
                    </IconButton>
                  }
                </ListItem>
              </List>
            </>
          )}
        </TableCell>
      </TableRow>
    )
  }

  function expandedRow(isItemSelected, index, rowId, row, innerRow) {
    return (
      <TableRow className={props.classes.tableRow} key={index}>
        {index === 0 ? (
          <TableCell
            rowSpan={expandData[rowId][1].length}
            align="center"
            className={`${
              isItemSelected ? props.classes.tabledata : props.classes.tabledata
            }
                        ${
                          isItemSelected
                            ? props.classes.firstColumnData
                            : props.classes.firstColumnData
                        }
                        ${
                          isItemSelected
                            ? props.classes.firstColSeldData
                            : props.classes.firstColNonSelData
                        }
                        ${
                          row.status === 'submitted'
                            ? props.classes.tableCellGradient
                            : ''
                        }
                      `}
          >
            <Checkbox
              checked={isItemSelected}
              onClick={event => {
                handleClick(event, rowId)
              }}
              color="primary"
              className={props.classes.tablerowselector}
              classes={{
                root: props.classes.checkboxRoot,
                checked: props.classes.checked,
              }}
            />
          </TableCell>
        ) : null}
        {index === 0 ? (
          <TableCell
            rowSpan={expandData[rowId][1].length}
            className={classNames(
              props.classes.tabledata,
              props.classes.textDataLight,
              props.classes.otherColumns,
              props.classes.speciesName
            )}
          >
            {expandData[rowId][0]}
          </TableCell>
        ) : null}
        {props.headCells
          .filter(item => item.id != 'species')
          .map(function(headCell) {
            let valueToDisplay = innerRow[headCell.id]
            if (headCell.fetchFromParent) {
              valueToDisplay = props.onFetchFromParent(
                headCell.fetchFromParent,
                valueToDisplay
              )
            }
            if (valueToDisplay != null) {
              return (
                <TableCell
                  key={valueToDisplay + '_' + headCell.id}
                  className={classNames(
                    props.classes.tabledata,
                    props.classes.textDataLight,
                    props.classes.otherColumns,
                    `${
                      index === expandData[rowId][1].length - 1
                        ? null
                        : props.classes.noBorderRow
                    }`
                  )}
                  onClick={() => {
                    {
                      let tempCollapse = {
                        ...data.collapse,
                      }
                      tempCollapse[rowId] = !tempCollapse[rowId]
                      handleChange('collapse', tempCollapse)
                    }
                  }}
                >
                  {valueToDisplay}
                </TableCell>
              )
            }
          })}
        {index === 0 ? (
          <TableCell
            rowSpan={expandData[rowId][1].length}
            className={classNames(
              props.classes.tabledata,
              props.classes.textDataLight,
              props.classes.otherColumns
            )}
          >
            <IconButton className={props.classes.multiIconButton}>
              <EditIcon className={props.classes.tableSelectedIcon} />
            </IconButton>
          </TableCell>
        ) : null}
      </TableRow>
    )
  }

  return (
    <Grid container className={props.classes.tableGrid}>
      <div className={props.classes.inner}>
        <Table size="small" aria-label="simple table">
          {/** Table Header Start **/}
          <TableHead className={props.classes.tableHead}>
            <TableRow>
              <TableCell
                className={classNames(
                  props.classes.tableheading,
                  props.classes.textDataRegular,
                  props.classes.firstColumnHeader
                )}
              >
                Sort by
              </TableCell>

              {props.headCells.map(headCell => (
                <TableCell
                  className={classNames(
                    props.classes.tableheading,
                    props.classes.textDataRegular,
                    props.classes.otherColumns
                  )}
                  key={headCell.label}
                  sortDirection={orderBy === headCell.sort ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.sort}
                      direction={orderBy === headCell.sort ? order : 'asc'}
                      onClick={createSortHandler(headCell.sort)}
                      IconComponent={ArrowDropUpIcon}
                    >
                      <Trans i18nKey={headCell.label} />
                      {orderBy === headCell.sort ? (
                        <span className={props.classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <Trans i18nKey={headCell.label} />
                  )}
                </TableCell>
              ))}
              <TableCell
                className={classNames(
                  props.classes.tableheading,
                  props.classes.textDataRegular
                )}
              ></TableCell>
            </TableRow>
          </TableHead>

          {/** Table Header End **/}

          {/** Table row start **/}
          <TableBody>
            {displayDataCount > 0 &&
              props.displayData.map(row => {
                const isItemSelected = isSelected(row.id)
                let rowId = row.id
                let rowComponent
                if (data.collapse[row.id]) {
                  rowComponent = (
                    <React.Fragment key={row.id}>
                      {expandData[rowId][1] &&
                        expandData[rowId][1].map((innerRow, index) => {
                          return expandedRow(
                            isItemSelected,
                            index,
                            rowId,
                            row,
                            innerRow
                          )
                        })}
                    </React.Fragment>
                  )
                } else {
                  rowComponent = listView(isItemSelected, row, rowId)
                }
                return rowComponent
              })}
          </TableBody>
          {/** Table row end **/}
        </Table>
      </div>
      <Grid container>
        {/** Pagination component Start */}
        <Hidden only={['xs', 'sm']}>
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            item
            xs={12}
            md={12}
            spacing={2}
          >
            <Grid container item xs={12} md={2} justify="center">
              {displayDataCount > 0 ? (
                <Typography
                  display="inline"
                  className={classNames(
                    props.classes.pageNumber,
                    props.classes.textDataLight
                  )}
                >
                  {' '}
                  <Trans i18nKey="showing" /> {startIndex + 1}{' '}
                  <Trans i18nKey="of" /> {endIndex} <Trans i18nKey="of" />{' '}
                  {displayDataCount}{' '}
                </Typography>
              ) : (
                <Typography
                  className={classNames(
                    props.classes.pageNumber,
                    props.classes.textDataLight
                  )}
                >
                  No result returned
                </Typography>
              )}
            </Grid>

            <Grid container item xs={10} md={5} justify="center">
              {' '}
              <Pagination
                count={totalPages}
                page={pageSelected}
                onChange={(event, page) => changePageNumber(event, page)}
                color="primary"
                size="small"
              />
            </Grid>

            <Grid container item xs={4} md={5} justify="flex-end">
              <Typography
                display="inline"
                className={classNames(
                  props.classes.pageNumber,
                  props.classes.textDataLight
                )}
              >
                {' '}
                <Trans i18nKey="show" />{' '}
              </Typography>
              <select value={limitPerPageSelected} onChange={changeRowPerPage}>
                {pageLimit.map(perPageVal => (
                  <option key={perPageVal} value={perPageVal}>
                    {perPageVal}
                  </option>
                ))}
              </select>
              <Typography
                display="inline"
                className={classNames(
                  props.classes.pageNumber,
                  props.classes.textDataLight
                )}
              >
                {' '}
                <Trans i18nKey="entries" />{' '}
              </Typography>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden only={['md', 'lg', 'xl']}>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={1} sm={1} />

            <Grid container item xs={11} sm={11} justify="center">
              {' '}
              <Pagination
                count={totalPages}
                siblingCount={0}
                page={pageSelected}
                onChange={(event, page) => changePageNumber(event, page)}
                color="primary"
                size="small"
              />
            </Grid>
          </Grid>
        </Hidden>

        {/** Pagination component end */}
      </Grid>
    </Grid>
  )
}

export const InventoryListContainer = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(DealerInventoryList))
