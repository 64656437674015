import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import {
  Grid,
  Container,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { theme } from './shared/theme'
import { Trans } from 'react-i18next'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import HomeIcon from '@material-ui/icons/Home'
import AddNewHailEvtrProgressBar from '../assets/hail/AddNewHailEvtrProgressBar.png'
import AddNewHailEtaProgressBar from '../assets/hail/AddNewHailEtaProgressBar.png'
import AddNewHailPortProgressBar from '../assets/hail/AddNewHailPortProgressBar.png'
import AddNewHailCatchProgressBar from '../assets/hail/AddNewHailCatchProgressBar.png'
import { EvtrContainer } from '../components/hail/Evtr'
import { ArrivalDateContainer } from '../components/hail/ArrivalDate'
import { ArrivalPortContainer } from '../components/hail/ArrivalPort'
import { CatchContainer } from '../components/hail/Catch'
import { FinalizeContainer } from '../components/hail/Finalize'
// import { ApiHelper } from '../lib'
import { useHistory } from 'react-router-dom'
import { findHailById, findHailByUUID, saveHail } from '../features/hailSlice'

const styles = () => ({
  gridContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  progressBarGrid: {
    marginTop: '4vh',
  },
  image: {
    width: '72%',
  },

  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90vw',
    margin: 'auto',
  },

  dialogHeading: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '1.05rem',
    color: '#4F596F',
  },
})

export function EditHail(props) {
  const hail = useSelector(state => {
    return (
      findHailById(state.hails, props.match.params.id) ||
      findHailByUUID(state.hails, props.match.params.id)
    )
  })
  const dispatch = useDispatch()
  const [showItems, setShowItems] = useState('Default')
  const [modify, setModify] = useState(false)
  const [loadingState] = useState('')

  useEffect(() => {
    if (!props.location.state.to) {
      setShowItems('Finalize')
    }
  }, [])

  const setAttribute = (key, val) => {
    const hailUpdated = Object.assign({}, hail)
    hailUpdated[key] = val
    dispatch(saveHail(hailUpdated))
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const history = useHistory()

  const home = () => {
    const welcomeLocation = {
      pathname: '/vessel',
      state: { from: 'vessel/add_new_hail' },
    }
    history.push(welcomeLocation)
  }

  const back = () => {
    if (modify && showItems === 'Finalize') {
      setOpen(true)
    } else if (modify) {
      setShowItems('Finalize')
    } else if (showItems === 'Port') {
      setShowItems('Default')
    } else if (showItems === 'Date') {
      setShowItems('Port')
    } else if (showItems === 'Catch') {
      //setSpeciesNameSelected([])
      setShowItems('Date')
    } else if (showItems === 'Finalize') {
      setShowItems('Catch')
    } else {
      setOpen(true)
    }
  }

  const handleNext = nextValue => {
    setShowItems(nextValue)
  }

  function editButton() {
    setModify(true)
  }

  function renderHail() {
    if (showItems === 'Port') {
      return (
        <ArrivalPortContainer
          hail={hail}
          id="arrivalPortContainer"
          setAttribute={setAttribute}
          setShowItems={setShowItems}
          modify={modify}
        />
      )
    } else if (showItems === 'Date') {
      return (
        <ArrivalDateContainer
          hail={hail}
          id="arrivalDateContainer"
          setAttribute={setAttribute}
          setShowItems={setShowItems}
          modify={modify}
        />
      )
    } else if (showItems === 'Catch') {
      return (
        <CatchContainer
          hail={hail}
          id="catchContainer"
          setAttribute={setAttribute}
          setShowItems={setShowItems}
          modify={modify}
        />
      )
    } else if (showItems === 'Finalize') {
      return (
        <FinalizeContainer
          hail={hail}
          id="finalizeContainer"
          setAttribute={setAttribute}
          onSelectNext={handleNext}
          editButton={editButton}
        />
      )
    }
    return (
      <EvtrContainer
        hail={hail}
        id="evtrContainer"
        setShowItems={setShowItems}
        modify={modify}
        setAttribute={setAttribute}
      />
    )
  }

  function progressBarSelector() {
    if (showItems === 'Port') {
      return AddNewHailPortProgressBar
    } else if (showItems === 'Date') {
      return AddNewHailEtaProgressBar
    } else if (showItems === 'Catch' || showItems === 'Finalize') {
      return AddNewHailCatchProgressBar
    }
    return AddNewHailEvtrProgressBar
  }

  function backButtonSelector() {
    if (!props.location.state.to) {
      return null
    } else {
      return <ArrowBackIosIcon onClick={back} />
    }
  }

  function heading() {
    if (!props.location.state.to) {
      return (
        <Typography color="inherit">
          <Trans i18nKey="in_process_trips" />
        </Typography>
      )
    } else {
      return (
        <Typography color="inherit">
          <Trans i18nKey="add_new_hail" />
        </Typography>
      )
    }
  }

  function reportNumber() {
    if (!props.location.state.to) {
      return (
        <Grid
          container
          justify="flex-start"
          className={props.classes.progressBarGrid}
        >
          <Grid item xs={12}>
            <Typography color="inherit">
              <Trans i18nKey="report_number" />:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="inherit">
              {hail.order_number ? hail.order_number : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          justify="center"
          className={props.classes.progressBarGrid}
        >
          <img src={progressBarSelector()} className={props.classes.image} />
        </Grid>
      )
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container className={props.classes.backgroundImage}>
        <Container width="100%" className={props.classes.topImage}>
          <Grid container spacing={0} className={props.classes.gridContainer}>
            <Grid item xs={1}>
              {backButtonSelector()}
            </Grid>
            <Grid container item xs={10} justify="center">
              {heading()}
            </Grid>
            <Grid item xs={1}>
              <HomeIcon onClick={handleOpen} />
            </Grid>
            {reportNumber()}
          </Grid>
        </Container>
        <Container width="100%" className={props.classes.bottomImage}>
          {loadingState === 'loading' ? (
            <div className={props.classes.circularProgress}>
              <CircularProgress />
            </div>
          ) : (
            renderHail()
          )}
        </Container>
      </Container>
      <Dialog open={open} className={props.classes.dialog}>
        <DialogContent>
          <DialogContentText className={props.classes.dialogHeading}>
            Exit
          </DialogContentText>
          <DialogContentText>
            Are you sure you want to exit this Hail report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
          <Button onClick={home} color="primary">
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

export default withStyles(combineStyles(styles, stylesBase))(EditHail)
