import React, { useState, useEffect } from 'react'
import {
  Grid,
  Icon,
  IconButton,
  Collapse,
  Button,
  MenuItem,
  makeStyles,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Hidden,
} from '@material-ui/core'
import clsx from 'clsx'
import { GetApp, Print } from '@material-ui/icons'
import { Link, Route } from 'react-router-dom'
import MenuBar from '../../assets/dealer/sidebar.png'
// import Orders from '../../assets/dealer/orders.svg'
import lfLogo from '../../assets/dealer/lf_logo_standard.svg'
import lfLogoCollapsed from '../../assets/dealer/lf_logo_collapsed.svg'
import MenuCollapsed from '../../assets/dealer/collapse_menu.svg'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SettingsIcon from '@material-ui/icons/Settings'
import { ApiHelper } from '../../lib'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import {
  clearCurrentUser,
  clearReferenceData,
  clearOrganization,
  setPorts,
  setFishingAreas,
  setProducts,
  setStockArea,
  setCounts,
  setDealerLocations,
  setSpecies,
  setDealerCustomerList,
  //setOrderSpecies,
} from '../../actions'
import safisUpload from '../../assets/safisupload.svg'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

const drawerWidth = '15vw'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    width: `calc(100% - ${theme.spacing(15) + 1}px)`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(3) + 1}px)`,
    },
    backgroundColor: '#FFFFFF',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(3) + 1}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(10) + 1}px)`,
    },
    backgroundImage: 'url(' + MenuBar + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    backgroundImage: 'url(' + MenuBar + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(15) + 1,
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: '#EDEFF1',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: '0px',
    minHeight: '100vh',
  },
  logo: {
    height: '80%',
  },
  navIconHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoutButton: {
    color: '#000000',
    fontSize: '12px',
    textTransform: 'none',
    marginTop: '-.6vh',
    padding: '0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  layoutCenter: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  layoutIcon: {
    height: '100%',
  },
  appBarIcon: {
    height: '75%',
  },
  username: {
    color: '#000000',
  },
  toolbarSetting: {
    textAlign: 'center',
    paddingTop: '2vh',
  },
  smLogout: {
    color: '#FFFFFF',
    textTransform: 'none',
    marginLeft: '4vw',
    fontFamily: 'Rubik',
    fontWeight: '300',
  },
  smMenuIcons: {
    color: '#FFFFFF',
  },
  collapseMenuIcon: {
    justifyContent: 'center',
  },
  alignMenuIcon: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '10vw',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '4vw',
    },
  },
  menuTextStyle: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '18px',
    color: '#FFFFFF',
    whiteSpace: 'normal',
  },
  subMenuTextStyle: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '12px',
    color: '#FFFFFF',
  },
  subMenuGridStyle: {
    height: '5vh',
  },
  menuIcon: {
    height: '100%',
  },
  mobileMenu: {
    marginTop: '5vw',
  },
  smUserName: {
    marginTop: '1vw',
  },
  topBar: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '45px',
      maxHeight: '45px',
    },
  },
  topBarUsername: {
    color: '#000000',
    fontFamily: 'Rubik',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0',
  },
  userIcon: {
    color: '#003E71',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tobarUploadImage: {
    height: '20px',
  },
}))

export function DealerLayout(props) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(true)
  const [smOpen, setSmOpen] = useState(false)
  const [openCollapse, setCollapseOpen] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState(props.name)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [isLoading, setIsLoading] = useState(true)

  const api = new ApiHelper()

  useEffect(() => {
    setIsLoading(true)
    const api = new ApiHelper()

    let portsApi = api.get(`ports`).then(response => response.json())
    let fishingAreasApi = api
      .get(`fishing_areas`)
      .then(response => response.json())
    let productsApi = api.get(`products`).then(response => response.json())
    let locationApi = api
      .get(`dealer_locations`)
      .then(response => response.json())
    let stockAreaApi = api.get(`stock_area`).then(response => response.json())
    let countsApi = api.get(`counts`).then(response => response.json())
    let speciesApi = api.get(`species`).then(response => response.json())
    let customerApi = api.get(`/processors`).then(response => response.json())
    //let searchLotApi = api.get(`/lots/search`).then(response => response.json())

    Promise.all([
      portsApi,
      fishingAreasApi,
      productsApi,
      locationApi,
      stockAreaApi,
      countsApi,
      speciesApi,
      customerApi,
      //searchLotApi,
    ])
      .then(
        ([
          portsResult,
          fishingAreasResult,
          productsResult,
          locationResult,
          stockAreaResult,
          countsResult,
          speciesResult,
          customerResult,
          //searchLotResult,
        ]) => {
          dispatch(setDealerCustomerList(customerResult.processors))
          dispatch(setPorts(portsResult))
          dispatch(setFishingAreas(fishingAreasResult))
          dispatch(setProducts(productsResult))
          dispatch(setDealerLocations(locationResult))
          dispatch(setStockArea(stockAreaResult))
          dispatch(setCounts(countsResult))
          dispatch(setSpecies(speciesResult))
          //let stockItems = searchLotResult.stock_items.map(stock => {
          //stock['order_weight'] = ''
          //stock['price'] = ''
          //return stock
          //})
          //dispatch(setOrderSpecies(stockItems))
          setIsLoading(false)
        }
      )
      .catch(error => {
        console.error('error fetching master', error)
        setIsLoading(false)
      })
  }, [])

  const menu = [
    {
      name: 'offloading',
      haschild: false,
      targetLink: 'Offload',
      appbarName: 'offloading',
    },
    {
      name: 'inventory',
      haschild: false,
      targetLink: 'inventory',
      appbarName: 'inventory',
    },
    {
      name: 'orders',
      haschild: false,
      targetLink: 'orders',
      appbarName: 'orders',
    },
    {
      name: 'SAFIS',
      haschild: false,
      targetLink: 'landing',
      appbarName: 'SAFIS',
    },
    {
      name: 'archive',
      haschild: false,
      targetLink: 'landing',
      appbarName: 'archive',
    },
    {
      name: 'tables',
      haschild: true,
      targetLink: '',
      appbarName: 'tables',
      children: [
        {
          name: 'vessels',
          haschild: false,
          targetLink: 'vessels',
          appbarName: 'vessels',
        },
        {
          name: 'customers',
          haschild: false,
          targetLink: 'customers',
          appbarName: 'customers',
        },
        {
          name: 'species',
          haschild: false,
          targetLink: 'species',
          appbarName: 'species',
        },
        {
          name: 'fees',
          haschild: false,
          targetLink: 'fees',
          appbarName: 'fees',
        },
        {
          name: 'ports',
          haschild: false,
          targetLink: 'ports',
          appbarName: 'ports',
        },
        {
          name: 'gears',
          haschild: false,
          targetLink: 'gears',
          appbarName: 'gears',
        },
        {
          name: 'harvest_areas',
          haschild: false,
          targetLink: 'harvest_areas',
          appbarName: 'harvest_areas',
        },
        {
          name: 'product_locations',
          haschild: false,
          targetLink: 'product_locations',
          appbarName: 'product_locations',
        },
      ],
    },
  ]

  const handleDrawerOpen = () => {
    setOpen(true)
    setSmOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setSmOpen(false)
  }

  const handleCollapseOpen = () => {
    setCollapseOpen(!openCollapse)
  }

  const handleSelectedMenu = (menu, index) => {
    setSelectedMenu(menu)
    setSmOpen(false)
    setSelectedIndex(index)
  }

  //Fix to set the open state based on the device
  // Need to see if this is the best way to achieve this
  if (useMediaQuery(theme.breakpoints.down('sm')) && open) {
    setOpen(false)
  }

  // TODO - Refactor Clickable and Collapse into one function
  function getClickableMenu(
    menu,
    isImage,
    subMenu,
    targetLink,
    index,
    appbarName
  ) {
    let lowerCaseName = menu.toLowerCase()
    let name = `${menu}`
    name = <Trans i18nKey={name} />
    return (
      <MenuItem
        button
        key={lowerCaseName}
        value={menu}
        component={Link}
        to={`/dealer/${targetLink}`}
        selected={index === selectedIndex}
        onClick={() => handleSelectedMenu(appbarName, index)}
        className={subMenu ? classes.subMenuGridStyle : ''}
      >
        <Grid container>
          <Grid item sm={3} xs={3} />
          <Grid item lg={11} md={11} xl={11} sm={6} xs={6}>
            <Grid container>
              <Grid item lg={4} md={4} sm={5} xs={5}>
                {isImage ? (
                  <ListItemIcon>
                    <Icon
                      classes={{ root: 'MuiSvgIcon-root' }}
                      className={classes.layoutIcon}
                    >
                      <img
                        src={
                          require(`../../assets/dealer/${lowerCaseName}.svg`)
                            .default
                        }
                        alt={lowerCaseName}
                      />
                    </Icon>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon />
                )}
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                sm={7}
                xs={7}
                container
                alignItems="center"
              >
                <ListItemText
                  disableTypography
                  primary={name}
                  className={
                    subMenu ? classes.subMenuTextStyle : classes.menuTextStyle
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} xl={1} sm={3} xs={3} />
        </Grid>
      </MenuItem>
    )
  }

  function getCollapseMenu(
    menu,
    isImage,
    subMenu,
    targetLink,
    index,
    appbarName
  ) {
    let lowerCaseName = menu.toLowerCase()
    let name = `${menu}`
    name = <Trans i18nKey={name} />
    return (
      <MenuItem
        button
        key={lowerCaseName}
        component={Link}
        to={`/dealer/${targetLink}`}
        selected={index === selectedIndex}
        onClick={() => handleSelectedMenu(appbarName, index)}
        className={classes.layoutCenter}
      >
        <div>
          {isImage ? (
            <ListItemIcon className={classes.collapseMenuIcon}>
              <Icon
                classes={{ root: 'MuiSvgIcon-root' }}
                className={classes.layoutIcon}
              >
                <img
                  src={
                    require(`../../assets/dealer/${lowerCaseName}.svg`).default
                  }
                  alt={lowerCaseName}
                />
              </Icon>
            </ListItemIcon>
          ) : (
            <ListItemIcon />
          )}
          <ListItemText
            disableTypography
            primary={name}
            className={
              subMenu ? classes.subMenuTextStyle : classes.menuTextStyle
            }
          />
        </div>
      </MenuItem>
    )
  }

  const menuOpen = (
    <List>
      {menu.map((menu, index) => {
        if (menu.haschild) {
          return (
            <div key={menu.name}>
              <MenuItem button key="orders" onClick={handleCollapseOpen}>
                <Grid container>
                  <Grid item sm={3} xs={3} />
                  <Grid item lg={11} md={11} sm={6} xs={6}>
                    <Grid container>
                      <Grid item lg={4} md={4} sm={5} xs={5}>
                        <ListItemIcon>
                          <Icon
                            classes={{ root: 'MuiSvgIcon-root' }}
                            className={classes.layoutIcon}
                          >
                            <img
                              src={
                                require(`../../assets/dealer/${menu.name}.svg`)
                                  .default
                              }
                              alt={menu.name}
                            />
                          </Icon>
                        </ListItemIcon>
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        md={8}
                        sm={7}
                        xs={7}
                        container
                        alignItems="center"
                      >
                        <ListItemText
                          disableTypography
                          primary={<Trans i18nKey={menu.name} />}
                          className={classes.menuTextStyle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={1} md={1} sm={3} xs={3} />
                </Grid>
              </MenuItem>
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menu.children.map(submenu => {
                    return getClickableMenu(
                      submenu.name,
                      false,
                      true,
                      submenu.targetLink,
                      index,
                      submenu.appbarName
                    )
                  })}
                </List>
              </Collapse>
            </div>
          )
        }
        return getClickableMenu(
          menu.name,
          true,
          false,
          menu.targetLink,
          index,
          menu.appbarName
        )
      })}
    </List>
  )

  const menuClosed = (
    <List>
      {menu.map((menu, index) => {
        return getCollapseMenu(
          menu.name,
          true,
          false,
          menu.targetLink,
          index,
          menu.appbarName
        )
      })}
    </List>
  )

  function signOut() {
    api
      .delete('/users/sign_out')
      .then(() => {
        dispatch(clearCurrentUser())
        dispatch(clearReferenceData())
        dispatch(clearOrganization())
      })
      .catch(error => {
        alert(error)
      })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.topBar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <Icon classes={{ root: 'MuiSvgIcon-root' }}>
                <img src={MenuCollapsed} alt="menu_col" />
              </Icon>
            </IconButton>
          ) : (
            <IconButton
              onClick={handleDrawerOpen}
              className={classes.alignMenuIcon}
            >
              <Icon classes={{ root: 'MuiSvgIcon-root' }}>
                <MenuIcon />
              </Icon>
            </IconButton>
          )}
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="subtitle1" className={classes.username}>
                <Trans i18nKey={selectedMenu} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} md={5} lg={5} />

            {/** Tobar right side components for desktop and mobile views - start*/}

            {/** Tobar right icons for desktop view  -start */}
            <Hidden only={['xs', 'sm']}>
              <Grid
                container
                alignItems="center"
                justfiy="flex-start"
                item
                xs={1}
                md={3}
                lg={3}
              >
                <Grid item xs={6}>
                  <IconButton
                    aria-label="show 4 new mails"
                    color="inherit"
                    className={classes.navIconHide}
                    size="small"
                  >
                    <SettingsIcon fontSize="small" color="action" />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                    size="small"
                  >
                    <NotificationsIcon fontSize="small" color="action" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid
                container
                item
                md={4}
                lg={4}
                className={classes.navIconHide}
              >
                <Grid container item xs={3} justify="flex-end">
                  <AccountCircleIcon
                    fontSize="large"
                    className={classes.userIcon}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={9}
                  justify="flex-start"
                  spacing={0}
                  direction="column"
                >
                  <Grid container alignItems="flex-end">
                    <Typography
                      variant="caption"
                      className={classes.topBarUsername}
                    >
                      Username
                    </Typography>
                  </Grid>
                  <Grid container alignItems="flex-start" spacing={0}>
                    <Typography
                      className={classes.logoutButton}
                      onClick={() => signOut()}
                    >
                      <Trans i18nKey="logOut" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            {/** Tobar right side components for desktop - end*/}

            {/** Tobar right side components for mobile - start*/}
            <Hidden only={['md', 'lg', 'xl']}>
              {props.name === 'offloading' || props.name === 'orders' ? (
                <Grid
                  container
                  item
                  xs={10}
                  alignItems="center"
                  justify="space-evenly"
                >
                  <GetApp color="action" />
                  <Print color="action" />
                  <img
                    src={safisUpload}
                    className={classes.tobarUploadImage}
                    alt="safisupload"
                  />
                </Grid>
              ) : (
                <Grid container item xs={10} justify="center">
                  <NotificationsIcon color="action" />
                </Grid>
              )}
            </Hidden>

            {/** Tobar right side components for mobile - end*/}

            {/** Tobar right side components for desktop and mobile views - end*/}
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden only={['md', 'lg', 'xl']}>
        <Drawer
          variant="temporary"
          anchor="left"
          open={smOpen}
          onClose={handleDrawerClose}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: smOpen,
            [classes.drawerClose]: !smOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: smOpen,
              [classes.drawerClose]: !smOpen,
            }),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={(classes.toolbar, classes.toolbarSetting)}>
            <Grid container className={classes.mobileMenu}>
              <Grid item xs={2} md={2}>
                <SettingsIcon
                  fontSize="small"
                  className={classes.smMenuIcons}
                />
              </Grid>
              <Grid item xs={8} md={8} />
              <Grid item xs={2} md={2}>
                <CloseIcon
                  fontSize="small"
                  onClick={handleDrawerClose}
                  className={classes.smMenuIcons}
                />
              </Grid>
              <Grid container>
                <Grid item sm={2} xs={2} />
                <Grid item sm={7} xs={7}>
                  <Grid container>
                    <Grid item container sm={2} xs={2} />
                    <Grid
                      item
                      container
                      sm={4}
                      xs={4}
                      justify="flex-start"
                      spacing={0}
                    >
                      <AccountCircleIcon
                        fontSize="large"
                        className={classes.smMenuIcons}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      sm={4}
                      xs={4}
                      className={classes.smMenuIcons}
                      justify="flex-start"
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.smUserName}
                      >
                        Username
                      </Typography>
                    </Grid>
                    <Grid item sm={2} xs={2} />
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={3} />
              </Grid>
            </Grid>
          </div>
          <br />
          {menuOpen}
          <br />
          <Grid container>
            <Grid item xs={4} md={4} sm={4} />
            <Grid container item xs={5} md={4} sm={4} justify="center">
              <Button className={classes.smLogout} onClick={() => signOut()}>
                <Trans i18nKey="logOut" />
              </Button>
            </Grid>
            <Grid item xs={3} md={4} sm={4} />
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden only={['xs', 'sm']} implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          anchor="left"
        >
          <div className={(classes.toolbar, classes.toolbarSetting)}>
            <Grid container>
              <Grid item lg={1} md={1} />
              <Grid item lg={10} md={10}>
                {open ? (
                  <img src={lfLogo} className={classes.logo} alt="lflogo" />
                ) : (
                  <img
                    src={lfLogoCollapsed}
                    className={classes.logo}
                    alt="lflogo"
                  />
                )}
              </Grid>
              <Grid item lg={1} md={1} />
            </Grid>
          </div>
          <Divider />
          {open ? menuOpen : menuClosed}
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div key={props.location.pathname}>
            {' '}
            <Route {...props} />{' '}
          </div>
        )}
      </main>
    </div>
  )
}

export default DealerLayout
