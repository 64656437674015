import { createMuiTheme } from '@material-ui/core/styles'
import React from 'react'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'

export const hailTheme = responsiveFontSizes(
  createMuiTheme({
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: '#003E6D',
          },
        },
      },

      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: '#003E6D',
          },
        },
      },

      MuiInputBase: {
        input: {
          '&$disabled': {
            display: 'none',
          },
        },
      },

      MuiFilledInput: {
        root: {
          borderRadius: '25px !important',
        },
        underline: {
          '&&&&:before': {
            borderBottom: 'none',
          },
          '&&&&:after': {
            borderBottom: 'none',
          },
        },
        input: {
          padding: '7px 12px 7px',
        },
      },

      MuiAutocomplete: {
        root: {
          padding: '0px 0px 0px 0px !important',
        },
        input: {
          padding: '7px 4px !important',
        },
        inputRoot: {
          padding: '0px 0px 0px 7px !important',
        },
      },

      MuiInput: {
        underline: {
          '&&&&:after': {
            borderBottom: 'none',
          },
        },
      },

      MuiList: {
        padding: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },

      MuiListItem: {
        dense: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },

      MuiListItemIcon: {
        root: {
          minWidth: '35px',
        },
      },

      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#003e6d',
        },
      },

      MuiDialogTitle: {
        root: {
          backgroundColor: '#003e6d',
          paddingTop: '20%',
          color: 'white',
        },
      },

      MuiTypography: {
        h6: {
          fontWeight: 400,
        },
      },
      /*css for Dialogue in Finalize*/
      MuiDialogActions: {
        root: {
          justifyContent: 'center',
        },
      },

      /*CSS for Finalize*/

      Divider: {
        marginTop: '-1vh',
        marginBottom: '-1vh',
        marginLeft: '3vw',
        marginRight: '3vw',
      },

      /*.MuiInput-underline:before {
        border-bottom: none !important;
      }*/

      MuiSelect: {
        select: {
          borderRadius: '25px !important',
        },
      },
    },
  })
)

export const withHailTheme = WrappedComponent => {
  class Wrapper extends React.PureComponent {
    render() {
      return (
        <MuiThemeProvider theme={hailTheme}>
          <WrappedComponent {...this.props} />
        </MuiThemeProvider>
      )
    }
  }
  return Wrapper
}
