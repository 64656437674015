import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { Container, Link } from '@material-ui/core'
import ServerErrorImage from '../../assets/trace/ServerErrorImage.svg'

const styles = () => ({
  serverError: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '30vh',
    textAlign: 'center',
  },

  image: {
    width: '40%',
  },

  serverErrorHeading: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#4F596F',
  },

  search: {
    fontSize: '1.05rem',
    color: '#4F596F',
    textTransform: 'uppercase',
  },

  serverErrorText: {
    fontWeight: 300,
    fontSize: '0.8rem',
  },

  email: {
    fontSize: '1rem',
    color: '#4F596F',
  },
})

export function ServerError(props) {
  function showBacktoSearch(showBackButton) {
    if (!showBackButton) {
      return null
    }
    return (
      <Typography className={props.classes.search}>
        <Link href="#">
          <Trans i18nKey="back_to_search" />
        </Link>
      </Typography>
    )
  }

  return (
    <Container className={props.classes.serverError} id="serverError">
      <img
        alt="Server Error"
        className={props.classes.image}
        src={ServerErrorImage}
      />
      <h3>
        <Typography className={props.classes.serverErrorHeading}>
          <Trans i18nKey="internal_server_error" />
        </Typography>
      </h3>
      <Typography className={props.classes.serverErrorText}>
        <Trans i18nKey="unable_to_connect" />.
      </Typography>
      <Typography className={props.classes.serverErrorText}>
        <Trans i18nKey="to_our_server" />.
      </Typography>
      <br />
      <Typography className={props.classes.serverErrorText}>
        <Trans i18nKey="contact_legitfish_at" />
      </Typography>
      <Typography>
        <Link
          className={props.classes.email}
          href="mailto:help@legitfishinc.com"
        >
          help@legitfishinc.com
        </Link>
      </Typography>
      <div onClick={props.backButton}>
        <h3>{showBacktoSearch(props.showBackButton)}</h3>
      </div>
    </Container>
  )
}

export const ServerErrorContainer = withStyles(styles)(ServerError)
