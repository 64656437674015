import { SET_DEALER_STATUS_SUMMARY } from '../actions'
import { SET_DEALER_INVENTORY_SUMMARY } from '../actions'
import { SET_DEALER_VESSEL_LIST } from '../actions'
import { SET_STOCK_AREA } from '../actions'
import { SET_COUNTS } from '../actions'
import { SET_UNITS } from '../actions'
import { SET_DEALER_LOCATIONS } from '../actions'
import { SET_DEALER_OFFLOADS } from '../actions'
import { SET_DEALER_ORDERS } from '../actions'
import { SET_DEALER_NEW_MODIFY_OFFLOADS } from '../actions'
import { SET_DEALER_NEW_MODIFY_ORDERS } from '../actions'
import { SET_DEALER_CUSTOMER_LIST } from '../actions'
import { SET_DEALER_FREIGHT_TYPE } from '../actions'
import { SET_INVENTORY_LIST } from '../actions'
import { SET_FEES } from '../actions'
import { SET_FULFILLMENT_METHODS } from '../actions'
import { SET_ORDER_SPECIES } from '../actions'
import { SET_LINE_ITEM } from '../actions'
import { SAVE_FEE } from '../actions'

const initialStatusSummary = {}

export function dealerStatusSummary(state = initialStatusSummary, action) {
  switch (action.type) {
    case SET_DEALER_STATUS_SUMMARY:
      return action.dealerStatusSummary

    default:
      return state
  }
}

const initialInventorySummary = []
export function dealerInventorySummary(
  state = initialInventorySummary,
  action
) {
  switch (action.type) {
    case SET_DEALER_INVENTORY_SUMMARY:
      return action.dealerInventorySummary

    default:
      return state
  }
}

const initialDealerVesselList = []
export function dealerVesselList(state = initialDealerVesselList, action) {
  switch (action.type) {
    case SET_DEALER_VESSEL_LIST:
      return action.dealerVesselList

    default:
      return state
  }
}

const initialStockArea = []
export function stockArea(state = initialStockArea, action) {
  switch (action.type) {
    case SET_STOCK_AREA:
      return action.stockArea

    default:
      return state
  }
}

const initalCounts = []
export function counts(state = initalCounts, action) {
  switch (action.type) {
    case SET_COUNTS:
      return action.counts

    default:
      return state
  }
}

const initialUnits = []
export function units(state = initialUnits, action) {
  switch (action.type) {
    case SET_UNITS:
      return action.units

    default:
      return state
  }
}

const initialDealerLocations = []
export function dealerLocations(state = initialDealerLocations, action) {
  switch (action.type) {
    case SET_DEALER_LOCATIONS:
      return action.dealerLocations

    default:
      return state
  }
}

const initialDealerOffloads = []
export function dealerOffloads(state = initialDealerOffloads, action) {
  switch (action.type) {
    case SET_DEALER_OFFLOADS:
      return action.dealerOffloads

    default:
      return state
  }
}

const initialDealerOrders = []
export function dealerOrders(state = initialDealerOrders, action) {
  switch (action.type) {
    case SET_DEALER_ORDERS:
      return action.dealerOrders

    default:
      return state
  }
}

const initialDealerNewModifyOffload = {
  order: {},
}
export function dealerNewModifyOffload(
  state = initialDealerNewModifyOffload,
  action
) {
  switch (action.type) {
    case SET_DEALER_NEW_MODIFY_OFFLOADS:
      return action.dealerNewModifyOffload
    default:
      return state
  }
}

let initialDealerNewModifyOrder = {
  order: {
    customer_id: '',
    fulfillment_attributes: {
      fulfillment_method_id: '',
      fulfillment_date: new Date(),
      totes_in: '',
      totes_out: '',
      is_company_address: false,
      address_id: '',
    },
    fulfillment_fees_attributes: [{ fee_id: '' }],
    line_items: [],
  },
}
export function dealerNewModifyOrder(
  state = initialDealerNewModifyOrder,
  action
) {
  switch (action.type) {
    case SET_DEALER_NEW_MODIFY_ORDERS:
      return { ...action.dealerNewModifyOrder }
    default:
      return state
  }
}

const initialDealerCustomerList = []
export function dealerCustomerList(state = initialDealerCustomerList, action) {
  switch (action.type) {
    case SET_DEALER_CUSTOMER_LIST:
      return action.dealerCustomerList

    default:
      return state
  }
}

const initialDealerFreightType = []
export function dealerFreightType(state = initialDealerFreightType, action) {
  switch (action.type) {
    case SET_DEALER_FREIGHT_TYPE:
      return action.dealerFreightType

    default:
      return state
  }
}

const initialInventoryList = []
export function inventoryList(state = initialInventoryList, action) {
  switch (action.type) {
    case SET_INVENTORY_LIST:
      return action.inventoryList

    default:
      return state
  }
}

const initialFees = []
export function fees(state = initialFees, action) {
  switch (action.type) {
    case SET_FEES:
      return action.fees

    default:
      return state
  }
}

const initialFulfillmentMethod = []
export function fulfillmentMethods(state = initialFulfillmentMethod, action) {
  switch (action.type) {
    case SET_FULFILLMENT_METHODS:
      return action.fulfillmentMethods

    default:
      return state
  }
}

const initialOrderSpecies = []
export function orderSpecies(state = initialOrderSpecies, action) {
  switch (action.type) {
    case SET_ORDER_SPECIES:
      return action.orderSpecies

    default:
      return state
  }
}

const initialLineItem = []
export function lineItem(state = initialLineItem, action) {
  switch (action.type) {
    case SET_LINE_ITEM:
      return action.lineItem

    default:
      return state
  }
}

const initialFee = []
export function saveFee(state = initialFee, action) {
  switch (action.type) {
    case SAVE_FEE:
      return action.saveFee

    default:
      return state
  }
}
