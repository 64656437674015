import React, { useState } from 'react'
import { ApiHelper } from '../../lib'
import withDealerTheme from '../shared/DealerStyles'
import { useDispatch, useSelector } from 'react-redux'
import { saveDealerPort } from '../../features/dealerSlice'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles, Grid, TableRow, TableCell } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import BootstrapInput from './TextfieldForDealer'

const styles = () => ({
  tableRow: {
    backgroundColor: 'white',
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgb(0, 62, 113, .2)',
      color: 'black',
    },
  },
  tabledata: {
    borderRight: '1px ridge',
    /**whiteSpace: 'nowrap',*/

    paddingRight: '2px',
    paddingLeft: '5px',
  },
  textDataLight: {
    fontFamily: 'Rubik',
    fontWeight: 'light',
    fontSize: '10pt',
  },
  otherColumns: {
    zIndex: '-1',
  },
  saveNew: {
    fontSize: '22px',
    color: 'darkblue',
  },
  multiIconButton: {
    padding: '0px',
  },
})

export function PortsForm(props) {
  const classes = withDealerTheme()

  const [errorMsg, setErrorMsg] = useState('')
  const dispatch = useDispatch()
  const newPort = useSelector(state => state.dealer.newPort)

  const setAttribute = (key, val) => {
    const modifyPort = Object.assign({}, newPort)
    modifyPort[key] = val
    dispatch(saveDealerPort(modifyPort))
  }

  const handleFinishCancel = event => {
    event.preventDefault()

    if (newPort.name && newPort.state) {
      const api = new ApiHelper()
      api
        .post('/ports', newPort)
        .then(resp => {
          return resp.json()
        })
        .then(() => {
          return props.backToPort()
        })
        .catch(error => {
          console.error(error)
          setErrorMsg(error)
        })
    } else {
      setErrorMsg('Please fill all the details')
    }
  }

  return (
    <TableRow
      hover
      classes={{ selected: classes.selected }}
      className={props.classes.tableRow}
    >
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns
        )}
      >
        1
      </TableCell>
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns,
          props.classes.multiIconButton
        )}
      >
        <BootstrapInput
          id="outlined-size-small"
          helperText={errorMsg}
          value={newPort.name ? newPort.name : ''}
          onChange={e => setAttribute('name', e.currentTarget.value)}
        />
      </TableCell>
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns,
          props.classes.multiIconButton
        )}
      >
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <BootstrapInput
              id="outlined-size-small-2"
              value={newPort.state ? newPort.state : ''}
              onChange={e => setAttribute('state', e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={3} lg={5} />
          <Grid item xs={1}>
            <IconButton onClick={props.handleClose}>
              <DeleteIcon className={props.classes.saveNew} />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      {/** end of table row iteration */}
      <TableCell
        className={classNames(
          props.classes.tabledata,
          props.classes.textDataLight,
          props.classes.otherColumns,
          props.classes.multiIconButton
        )}
      >
        <IconButton
          className={props.classes.multiIconButton}
          onClick={event => handleFinishCancel(event)}
        >
          <CheckIcon className={props.classes.saveNew} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export const PortsFormContainer = withStyles(styles)(PortsForm)
