import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Farmers from '../../../assets/trace/butcherbox/Farmers.jpeg'
import Animals from '../../../assets/trace/butcherbox/Animals.jpeg'
import BB1 from '../../../assets/trace/butcherbox/BB1.jpeg'
import BB2 from '../../../assets/trace/butcherbox/BB2.jpg'
import community from '../../../assets/trace/butcherbox/community.png'
import SplitLine from '../../../assets/trace/butcherbox/SplitLine.svg'

const styles = () => ({
  content: {
    padding: '3vh 3vh 15vh 3vh',
    color: '#2D4D59',
  },
  bigEasyContent: {
    padding: '3vh 3vh 15vh 3vh',
    color: '#3675AD',
  },
  image: {
    width: '100%',
  },
  learnMoreDiv: {
    paddingTop: '2vh',
    paddingBottom: '4vh',
    textAlign: 'center',
  },
  buttonDiv: {
    paddingTop: '2vh',
    textAlign: 'center',
  },
  button: {
    background: '#2D4D59',
    color: 'white',
    borderRadius: '15px',
  },
})

export function About(props) {
  function showAbout() {
    if (props.trace.component === 'ButcherBoxContainer') {
      return (
        <div className={props.classes.content}>
          <Typography variant="body2">
            Our company began with a simple mission to make high-quality meat
            more accessible to all. We soon discovered that meant doing
            something not so simple at all: rethinking our food system.
            <br />
            We’re in pursuit of a better way, one that’s focused on animal
            welfare, supporting farmers, treating our planet with respect, and
            upholding diversity, equity, and inclusion across our business.
          </Typography>
          <Typography variant="h5">What we value</Typography>
          <img
            src={Animals}
            alt="Animals Image"
            className={props.classes.image}
          />
          <img src={SplitLine} alt="Split Line" />
          <Typography component="span" variant="body2">
            We care about the lives of animals. We’re proud to partner with
            folks who raise animals and harvest seafood with safety,
            sustainability, natural tendencies, and comfort in mind.
          </Typography>
          <img
            src={BB2}
            alt="Farmers and Fishermen Image"
            className={props.classes.image}
          />
          <img src={SplitLine} alt="Split Line" />
          <Typography variant="h5"> Farmers and Fishermen </Typography>
          <Typography variant="body2">
            These are the people who harness the power of nature - we believe
            they should earn fair and predictable living wages. We support
            farmers and fishermen of all walks of life, and we’re dedicated to
            increasing racial equity in the industry.
          </Typography>
          <img
            src={Farmers}
            alt="Farmers Image"
            className={props.classes.image}
          />
          <img src={SplitLine} alt="Split Line" />
          <Typography variant="h5"> Planet </Typography>
          <Typography component="span" variant="body2">
            Our natural resources are limited, so we want to use them
            responsibly. We’re working to support sustainable fishing and
            agricultural practices, improve soil health, minimize our carbon
            footprint, and inspire our partners to do the same.
          </Typography>
          <img src={BB1} alt="Workers Image" className={props.classes.image} />
          <img src={SplitLine} alt="Split Line" />
          <Typography variant="h5"> Workers </Typography>
          <Typography component="span" variant="body2">
            Our partners and our employees are one team - and we’re ever
            thankful for that team. We believe in creating an environment where
            diversity is celebrated, and all workers no matter their identities,
            are treated equitably.
          </Typography>
          <img
            src={community}
            alt="Community Image"
            className={props.classes.image}
          />
          <img src={SplitLine} alt="Split Line" />
          <Typography variant="h5"> Community </Typography>
          <Typography component="span" variant="body2">
            We want to make high-quality meat more accessible to all, raising
            the bar for the meat our members eat and serve the ones they love.
          </Typography>
          <div className={props.classes.learnMoreDiv}>
            <Typography variant="h5">
              Want to learn more about our story?
            </Typography>
          </div>
          <div className={props.classes.buttonDiv}>
            <Button
              variant="contained"
              className={props.classes.button}
              onClick={() => window.open('https://www.butcherbox.com')}
            >
              <Typography variant="h5"> VISIT BUTCHERBOX.COM </Typography>
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className={props.classes.bigEasyContent}>
          <Typography variant="body2">
            Legit Fish is the first fully traceable, third-party, seafood
            verification system that is matched to offical government harvest
            records. This ensures that the information provided from a specific
            <br />
            We’re in pursuit of a better way, one that’s focused on animal
            welfare, supporting farmers, treating our planet with respect, and
            upholding diversity, equity, and inclusion across our business.
          </Typography>
        </div>
      )
    }
  }
  return <div>{showAbout()}</div>
}

export const AboutContainer = withStyles(styles)(About)
