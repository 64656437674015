import { combineReducers } from 'redux'
import user from './user'
import organization from './organization'
import referenceData from './referenceData'
import { hailSliceReducer } from '../features/hailSlice'
import { dealerSliceReducer } from '../features/dealerSlice'
import ports from './port'
import species from './species'
import newHail from './newHail'
import userNotifications from './userNotifications'
import {
  dealerStatusSummary,
  dealerInventorySummary,
  dealerVesselList,
  counts,
  stockArea,
  units,
  dealerLocations,
  dealerOffloads,
  dealerOrders,
  dealerNewModifyOffload,
  dealerNewModifyOrder,
  dealerCustomerList,
  dealerFreightType,
  inventoryList,
  fees,
  fulfillmentMethods,
  orderSpecies,
  lineItem,
  saveFee,
} from './dealerData'
import fishingAreas from './fishingAreas'
import dealers from './dealer'
import products from './products'
//import { persistConfig } from '../configureStore'
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
  hails: hailSliceReducer,
  dealer: dealerSliceReducer,
  user,
  organization,
  referenceData,
  ports,
  species,
  newHail,
  userNotifications,
  dealerStatusSummary,
  dealerInventorySummary,
  dealerVesselList,
  counts,
  stockArea,
  units,
  dealerLocations,
  dealerOffloads,
  dealerOrders,
  dealerNewModifyOffload,
  fishingAreas,
  dealers,
  products,
  dealerNewModifyOrder,
  dealerCustomerList,
  dealerFreightType,
  inventoryList,
  fees,
  fulfillmentMethods,
  orderSpecies,
  lineItem,
  saveFee,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    storage.removeItem('root')
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
