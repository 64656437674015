import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import Hidden from '@material-ui/core/Hidden'
import SortIcon from '@material-ui/icons/Sort'
import { StatusCard } from '../../components/StatusCard'
import { ApiHelper } from '../../lib'
import DateRangeComponent from '../../components/DateRangeComponent'
import { FilterOptionDrawer } from '../../components/FilterOptionDrawer'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { useDispatch, useSelector } from 'react-redux'

import {
  setDealerStatusSummary,
  setDealerInventorySummary,
} from '../../actions'

import { Trans } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  InfoText: {
    fontWeight: 300,
    fontSize: '16pt',
  },
  subText: {
    fontWeight: 300,
    fontSize: '10pt',
  },
  headerText: {
    fontWeight: 400,
    fontSize: '14pt',
  },
  inventoryGrid: {
    paddingTop: '50px',
  },
  inventoryLael: {
    fontWeight: 300,
    fontSize: '8pt',
  },
  inventoryValue: {
    fontWeight: 400,
    fontSize: '9pt',
    fontFamily: 'Rubik',
  },
  smallMargin: {
    margin: theme.spacing(1),
  },
  sizeSmall: {
    height: '4px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100,
  },
  divider: {
    marginTop: '12px',
  },
  dateSelectionActive: {
    background: '#003d65 !important',
    marginLeft: '6px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '10pt',
    color: 'white',
    '&:hover': {
      backgroundColor: '#003d65 !important',
    },
  },
  dateSelectionInActive: {
    background: 'white',
    marginLeft: '6px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '10pt',
  },
  outerDiv: {
    paddingTop: '24px',
  },
}))

export function DealerHome() {
  const classes = useStyles()
  const [statusFetch, setStatusFetch] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [inventorySelOption, setInventorySelOption] = useState('24hrs')
  const [filterOptionOpen, setFilterOptionOpen] = useState(false)
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const dealerStatusSummary = useSelector(state => state.dealerStatusSummary)
  const dealerInventorySummary = useSelector(
    state => state.dealerInventorySummary
  )

  const sortOptions = [
    { id: 'species', label: 'By Species' },
    { id: 'soldTo', label: '  By Customer' },
  ]

  useEffect(() => {
    const api = new ApiHelper()

    setIsLoading(true)
    api
      .get(`dealer_status`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerStatusSummary(result))
          setStatusFetch(true)
        } else {
          dispatch(setDealerStatusSummary(''))
          setErrorMsg('Error occured while retrieving Status information')
          setStatusFetch(true)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error:' + error)
        setErrorMsg(errorMsg)
        setStatusFetch(true)
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchInventorySummary('24hrs')
  }, [])

  function fetchInventorySummary(type) {
    const api = new ApiHelper()
    api
      .get(`dealer_inventory_summary`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerInventorySummary(result))
          setInventorySelOption(type)
        } else {
          setErrorMsg('Error occured while retrieving Inventory Summary')
        }
      })
      .catch(error => {
        console.error('Error fetching inventory summary' + error)
        setErrorMsg(error)
      })
  }

  function isInventoryDateSelected(value) {
    return inventorySelOption === value ? true : false
  }

  function handleFilterOnclick(status) {
    setFilterOptionOpen(status)
  }

  function handleFilterSelection(sortBy, fromDate, toDate) {
    setFromDate(fromDate)
    setToDate(toDate)
  }

  function searchWithDateRange(fromDate, toDate) {
    //TODO: Call API with From and To date
    setFromDate(fromDate)
    setToDate(toDate)
  }

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={classes.outerDiv}>
          <Grid container spacing={1}>
            <Grid container item xs={12} lg={1} spacing={1}>
              <Grid item xs={12}>
                <Typography>
                  <Trans i18nKey="status" />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              key={statusFetch}
              container
              item
              lg={11}
              spacing={2}
              alignItems="stretch"
            >
              <Grid container item xs={6} md={3} lg={3} alignItems="stretch">
                <StatusCard
                  mainText={dealerStatusSummary.offloaded}
                  subText=<Trans i18nKey="lbs" />
                  footer=<Trans i18nKey="offloadedLast24hrs" />
                  imageName="dealer/offloaded.png"
                />
              </Grid>
              <Grid container item xs={6} md={3} lg={3} alignItems="stretch">
                <StatusCard
                  mainText={dealerStatusSummary.sales}
                  subText=<Trans i18nKey="lbs" />
                  footer=<Trans i18nKey="saleslast24hrs" />
                  imageName="dealer/sales.png"
                />
              </Grid>
              <Grid container item xs={6} md={3} lg={3} alignItems="stretch">
                <StatusCard
                  mainText={dealerStatusSummary.inventory}
                  subText=<Trans i18nKey="lbs" />
                  footer=<Trans i18nKey="inventoryRemaining" />
                  imageName="dealer/inventory.png"
                />
              </Grid>
              <Grid container item xs={6} md={3} lg={3} alignItems="stretch">
                <StatusCard
                  mainText={dealerStatusSummary.incoming}
                  subText=<Trans i18nKey="lbs" />
                  footer=<Trans i18nKey="incomingHails" />
                  imageName="dealer/incoming.png"
                />
              </Grid>
            </Grid>
          </Grid>

          {/** Inventory Summary */}
          <Grid container className={classes.inventoryGrid}>
            <Grid container item xs={12} sm={12} md={5} lg={6}>
              <Grid container item xs={10}>
                <Typography display="inline" className={classes.headerText}>
                  {' '}
                  <Trans i18nKey="inventorySummary" />{' '}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={2}
                alignItems="flex-end"
                justify="flex-start"
              >
                <Hidden only={['md', 'lg', 'xl']}>
                  <SortIcon onClick={() => handleFilterOnclick(true)} />
                  <div key={filterOptionOpen}>
                    <FilterOptionDrawer
                      open={filterOptionOpen}
                      handleOnClose={handleFilterOnclick}
                      sortOptions={sortOptions}
                      onFilterSelection={handleFilterSelection}
                      sortBy=""
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  </div>
                </Hidden>
              </Grid>
            </Grid>

            <Hidden only={['xs', 'sm']}>
              <Grid item xs={12} md={4} lg={3}>
                <Typography display="inline" className={classes.subText}>
                  {' '}
                  <Trans i18nKey="sortBy" />:{' '}
                </Typography>
                <Chip
                  variant="outlined"
                  size="small"
                  clickable={true}
                  label=<Trans i18nKey="hours24" />
                  onClick={() => fetchInventorySummary('24hrs')}
                  className={
                    isInventoryDateSelected('24hrs')
                      ? classes.dateSelectionActive
                      : classes.dateSelectionInActive
                  }
                />
                <Chip
                  variant="outlined"
                  size="small"
                  clickable={true}
                  label=<Trans i18nKey="hours48" />
                  onClick={() => fetchInventorySummary('48hrs')}
                  className={
                    isInventoryDateSelected('48hrs')
                      ? classes.dateSelectionActive
                      : classes.dateSelectionInActive
                  }
                />
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                <DateRangeComponent
                  fromDate={fromDate}
                  toDate={toDate}
                  onDateRangeChange={searchWithDateRange}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                {' '}
                <Divider className={classes.divider} />{' '}
              </Grid>
            </Hidden>
          </Grid>

          {/* Summary Details*/}
          <Grid container spacing={8} className={classes.inventoryGrid}>
            {dealerInventorySummary.length > 0 ? (
              dealerInventorySummary.map(inventory => (
                <Grid key={inventory.id} item xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={5}>
                          <Typography
                            display="inline"
                            className={classes.InfoText}
                          >
                            {inventory.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                          <Typography className={classes.inventoryLael}>
                            {' '}
                            <Trans i18nKey="offloadDate" />:{' '}
                          </Typography>
                          <Typography className={classes.inventoryValue}>
                            {' '}
                            {inventory.offloadedDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <img
                            src={require('../../assets/dealer/samplefishlogo.png')}
                            alt="logo"
                            style={{ height: 50 }}
                          />
                        </Grid>
                        <Grid item xs={6} md={12}>
                          <Typography className={classes.inventoryLael}>
                            {' '}
                            <Trans i18nKey="remaining" />:{' '}
                          </Typography>
                          <Typography className={classes.inventoryValue}>
                            {' '}
                            {inventory.remaining}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5} md={12}>
                          <Typography className={classes.inventoryLael}>
                            {' '}
                            <Trans i18nKey="id" />:{' '}
                          </Typography>
                          <Typography className={classes.inventoryValue}>
                            {' '}
                            {inventory.identifier}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography className={classes.inventoryLael}>
                  {' '}
                  No Inventory{' '}
                </Typography>
              </Grid>
            )}
          </Grid>

          {/** error message */}
          {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
        </div>
      )}
    </div>
  )
}

export const DealerHomeContainer = DealerHome
