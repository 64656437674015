import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import { withHailTheme } from '../../pages/shared/hailTheme'
import {
  Grid,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core'
import Scallop from '../../assets/trace/Scallop.png'
import { useHistory } from 'react-router-dom'
import {
  findFishingAreaNameById,
  findPortById,
  findProductById,
} from '../../selectors'
import { format } from 'date-fns'
import {
  saveHailEventually,
  selectHailSyncStatus,
} from '../../features/hailSlice'

import { EditIcon } from './EditIcon'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'

const styles = () => ({
  confirmTrip: {
    color: 'red',
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  okButton: {
    marginTop: '2vh',
    minWidth: '152px',
    backgroundColor: '#003e6d',
    '&:hover': {
      backgroundColor: '#003e6d',
    },
    fontSize: '1rem',
    color: '#fbf5f5',
    fontWeight: 500,
    lineHeight: 2.5,
    borderRadius: '3.2rem',
  },
  finalizeListGrid: {
    height: '53vh',
    overflow: 'auto',
  },
  checkCircle: {
    color: '#666666',
    position: 'fixed',
    marginTop: '-57px',
  },
  divider: {
    marginTop: '-1vh',
    marginBottom: '-1vh',
    marginLeft: '2vw',
    marginRight: '2vw',
  },
  finalizeItemHeading: {
    color: '#4D4D4D',
  },
  finalizeItem: {
    color: '#808080',
  },
  finalizeItemSpecies: {
    color: '#808080',
    textTransform: 'capitalize',
  },
  loadingGrid: {
    minHeight: '70vh',
  },
  avatar: {
    width: '40px',
    height: 'auto',
  },
})

export function Finalize(props) {
  const fishingAreas = useSelector(state => state.fishingAreas)
  const ports = useSelector(state => state.ports)
  const species = useSelector(state => state.species)
  const user = useSelector(state => state.user)
  const organization = useSelector(state => state.organization)

  const dispatch = useDispatch()

  const history = useHistory()
  let port = findPortById(ports, props.hail.port_id)
  const hailStatus = useSelector(state => {
    return selectHailSyncStatus(state.hails, props.hail.uuid)
  })

  const confirm = editOption => {
    if (editOption === 'Edit Evtr') {
      props.onSelectNext('Default')
      props.editButton()
    }
    if (editOption === 'Edit Port') {
      props.onSelectNext('Port')
      props.editButton()
    }
    if (editOption === 'Edit Arrival Date') {
      props.onSelectNext('Date')
      props.editButton()
    }
    if (editOption === 'Edit Species') {
      props.onSelectNext('Catch')
      props.editButton()
    }
  }

  const [open, setOpen] = useState(false)
  const [openOfflineDialog, setOpenOfflineDialog] = useState(false)

  const [loadingState] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  const finalizeHail = () => {
    if (
      !props.hail.permit_number ||
      !props.hail.number_of_crew ||
      !props.hail.port_id ||
      props.hail.lots.length === 0
    ) {
      setErrorMsg('Please fill all the details')
      setOpenDialog(true)
    } else {
      props.setAttribute('finalized', true)
      dispatch(saveHailEventually(props.hail, true, true))
      if (hailStatus === 'waiting_for_sync') {
        setOpenOfflineDialog(true)
      } else {
        setOpen(true)
      }
    }
  }

  const handleDone = () => {
    const welcomeLocation = {
      pathname: '/vessel',
      state: { from: 'vessel/process_report/:id' },
    }
    history.push(welcomeLocation)
  }

  function headingSelector() {
    if (props.hail.status === 'draft' && !props.hail.finalized) {
      return (
        <div>
          <Grid item xs={12}>
            <Typography
              color="inherit"
              className={props.classes.finalizeItemHeading}
            >
              <Trans i18nKey="confirm_trip_details" />:
            </Typography>
          </Grid>
          <Grid item xs={12} className={props.classes.confirmTrip}>
            <Typography>
              <Trans i18nKey="confirm_trip_details_before_finalize" />
            </Typography>
          </Grid>
        </div>
      )
    } else {
      return (
        <Grid item xs={12}>
          <Typography className={props.classes.finalizeItemHeading}>
            <Trans i18nKey="report_details" />:
          </Typography>
        </Grid>
      )
    }
  }

  function buttonSelector() {
    if (props.hail.status === 'draft' && !props.hail.finalized) {
      return (
        <Button
          id="finalizeButton"
          className={props.classes.buttonNext}
          variant="outlined"
          type="button"
          margin="normal"
          onClick={finalizeHail}
        >
          <Trans i18nKey="finalize" />
        </Button>
      )
    } else {
      return (
        <Button
          id="finalizeButton"
          data-testid="finalizeButton"
          className={props.classes.buttonNext}
          variant="outlined"
          type="button"
          margin="normal"
          onClick={handleDone}
        >
          <Trans i18nKey="done" />
        </Button>
      )
    }
  }

  function loadFinalize() {
    if (loadingState === 'loading') {
      return (
        <Grid
          id="circularProgress"
          container
          justify="center"
          alignItems="center"
          className={props.classes.loadingGrid}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <div>
          <Box height="60vh">
            <Grid
              container
              spacing={0}
              className={props.classes.bottomGridContainer}
            >
              {headingSelector()}
              <Grid item xs={12}>
                <List className={props.classes.finalizeListGrid}>
                  <ListItem>
                    <ListItemText
                      className={props.classes.finalizeItemHeading}
                      primary={
                        <Grid container alignItems="flex-end" spacing={0}>
                          <Grid item xs={11}>
                            <Trans i18nKey="vessel_information" />:
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider className={props.classes.divider} />
                  <ListItem>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            <Trans i18nKey="vessel" />: {organization.name}
                          </Typography>
                          <br />
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            <Trans i18nKey="captain" />:{' '}
                            {user.first_name + ' ' + user.last_name}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem id="editEvtr" data-testid="editEvtr">
                    <ListItemText
                      primary={
                        <Grid
                          container
                          alignItems="flex-end"
                          spacing={0}
                          className={props.classes.finalizeItemHeading}
                        >
                          <Grid item xs={11}>
                            <Trans i18nKey="evtr_information" />:
                          </Grid>
                          <EditIcon
                            editEvtr={true}
                            status={props.hail.status}
                            finalized={props.hail.finalized}
                            confirm={confirm}
                          />
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider className={props.classes.divider} />
                  <ListItem>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            <Trans i18nKey="start_time" />:{' '}
                            {format(new Date(props.hail.start_time), 'h:mm a')}
                          </Typography>
                          <br />
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            <Trans i18nKey="permit" /> #:{' '}
                            {props.hail.permit_number}
                          </Typography>
                          <br />
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            <Trans i18nKey="crew" />:{' '}
                            {props.hail.number_of_crew}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem id="editPort" data-testid="editPort">
                    <ListItemText
                      primary={
                        <Grid
                          container
                          alignItems="flex-end"
                          spacing={0}
                          className={props.classes.finalizeItemHeading}
                        >
                          <Grid item xs={11}>
                            <Trans i18nKey="arrival_port" />:
                          </Grid>
                          <EditIcon
                            editPort={true}
                            status={props.hail.status}
                            finalized={props.hail.finalized}
                            confirm={confirm}
                          />
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider className={props.classes.divider} />
                  <ListItem>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            {port != null
                              ? port.name + ',' + port.state
                              : 'N/A'}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem id="editArrivalDate" data-testid="editArrivalDate">
                    <ListItemText
                      primary={
                        <Grid
                          container
                          alignItems="flex-end"
                          spacing={0}
                          className={props.classes.finalizeItemHeading}
                        >
                          <Grid item xs={11}>
                            <Trans i18nKey="arrival_date" />:
                          </Grid>
                          <EditIcon
                            editArrivalDate={true}
                            status={props.hail.status}
                            finalized={props.hail.finalized}
                            confirm={confirm}
                          />
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider className={props.classes.divider} />
                  <ListItem>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component={'span'}
                            className={props.classes.finalizeItem}
                          >
                            {format(
                              new Date(props.hail.arrival_date),
                              'MMMM dd yyyy'
                            )}{' '}
                            {format(
                              new Date(props.hail.arrival_time),
                              'h:mm a'
                            )}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem id="editSpecies" data-testid="editSpecies">
                    <ListItemText
                      primary={
                        <Grid
                          container
                          alignItems="flex-end"
                          spacing={0}
                          className={props.classes.finalizeItemHeading}
                        >
                          <Grid item xs={11}>
                            <Trans i18nKey="species_1" />:
                          </Grid>
                          <EditIcon
                            editSpecies={true}
                            status={props.hail.status}
                            finalized={props.hail.finalized}
                            confirm={confirm}
                          />
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider className={props.classes.divider} />
                  <ListItem>
                    <List component={'span'} dense>
                      {props.hail.lots.map(lot => {
                        return (
                          <span key={lot.species_id}>
                            <ListItem component={'span'}>
                              <ListItemAvatar>
                                <Avatar
                                  className={props.classes.avatar}
                                  alt={'Scallop'}
                                  src={Scallop}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography
                                    className={
                                      props.classes.finalizeItemSpecies
                                    }
                                  >
                                    {findProductById(
                                      species,
                                      lot.species_id
                                    ).name.toLowerCase()}
                                  </Typography>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component={'span'}
                                      className={props.classes.finalizeItem}
                                    >
                                      {lot.quantity} Lbs |{' '}
                                      {findFishingAreaNameById(
                                        fishingAreas,
                                        lot.stat_area_id
                                      )}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider />
                          </span>
                        )
                      })}
                    </List>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
          <Box height="10vh">
            <Grid container justify="center" alignItems="flex-end">
              {buttonSelector()}
              <Dialog
                open={open}
                className={props.classes.dialog}
                aria-labelledby="simple-dialog-title"
              >
                <DialogContent>
                  <Grid container justify="center">
                    <Grid item xs={4} className={props.classes.checkCircle}>
                      <CheckCircleOutlineIcon style={{ fontSize: 80 }} />
                    </Grid>
                  </Grid>
                  <br />
                  <div>
                    <Typography color="inherit" variant="button">
                      <Trans i18nKey="success" />
                    </Typography>
                  </div>
                  <div>
                    <Typography color="inherit">
                      <Trans i18nKey="hail_was_reported" />!
                    </Typography>
                  </div>
                  <div>
                    <Typography color="inherit">
                      <Trans i18nKey="confirmation_number" />:
                    </Typography>
                  </div>
                  <div>
                    <Typography color="inherit">
                      <strong>{props.hail.order_number}</strong>
                    </Typography>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    id="formButton"
                    data-testid="formButton"
                    className={props.classes.okButton}
                    variant="outlined"
                    type="button"
                    margin="normal"
                    onClick={handleDone}
                  >
                    <Trans i18nKey="ok" />
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openOfflineDialog}
                className={props.classes.dialog}
                aria-labelledby="simple-dialog-title"
              >
                <DialogContent>
                  <Grid container justify="center">
                    <Grid item xs={4} className={props.classes.checkCircle}>
                      <CheckCircleOutlineIcon style={{ fontSize: 80 }} />
                    </Grid>
                  </Grid>
                  <br />
                  <div>
                    <Typography color="inherit" variant="button">
                      <Trans i18nKey="offline" />.
                    </Typography>
                  </div>
                  <div>
                    <Typography color="inherit">
                      <Trans i18nKey="back_online" />!
                    </Typography>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    id="formButton"
                    className={props.classes.okButton}
                    variant="outlined"
                    type="button"
                    margin="normal"
                    onClick={handleDone}
                  >
                    <Trans i18nKey="ok" />
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Box>
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
          >
            <ErrorMessageComponent errorMessage={errorMsg} />
          </Dialog>
        </div>
      )
    }
  }

  return <div>{loadFinalize()}</div>
}

export const FinalizeContainer = withStyles(combineStyles(styles, stylesBase))(
  withHailTheme(Finalize)
)
