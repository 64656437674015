export function findStatAreaNameById(state, stat_area_id) {
  let statArea = state.find(s => s.id === stat_area_id)
  if (statArea) return statArea.name
  return ''
}

export function findFishingAreaById(state, fishing_area) {
  let fishingArea = state.find(s => s.id === fishing_area)
  if (fishingArea) return fishingArea
  return null
}

export function findFishingAreaNameById(state, fishing_area) {
  let fishingArea = state.find(s => s.id === fishing_area)
  if (fishingArea) return fishingArea.description
  return ''
}

export function findPortsByDealerId(state, dealer) {
  let selectedDealer = state.find(s => s.id === dealer)
  if (selectedDealer) return selectedDealer.ports
  return []
}

export function findDealerById(state, dealerId) {
  let selectedDealer = state.find(s => s.id === dealerId)
  if (selectedDealer) return selectedDealer
  return null
}

export function findPortById(state, port_id) {
  let port = state.find(s => s.id === port_id)
  if (port) return port
  return null
}

export function findProductById(state, product_id) {
  let product = state.find(s => s.id === product_id)
  return product
}

export function showNotifications(userNotifications) {
  if (!userNotifications) {
    return false
  }
  let notifications = userNotifications.notifications
  if (!notifications) {
    return false
  }
  let unreadNotifications = notifications.filter(n => n.read_at === null)
  return unreadNotifications.length > 0
}

export function findMarketDescriptionById(state, market_description) {
  let marketDescription = state.find(s => s.id === market_description)
  if (marketDescription) return marketDescription
  return null
}

export const selectNotifications = state => {
  return state.userNotifications.notifications
}
