import React from 'react'
import { Container } from '@material-ui/core'
import { PublicContainer } from './PublicContainer'
import '../assets/ErrorContainer.css'

export function ErrorContainer(props) {
  return (
    <PublicContainer>
      <Container className="ErrorContainer">
        <h1>{props.status}</h1>
        <h2>{props.message}</h2>
      </Container>
    </PublicContainer>
  )
}
