import React, { useState } from 'react'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withHailTheme } from '../../pages/shared/hailTheme'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Switch,
  CircularProgress,
} from '@material-ui/core'
import {
  clearCurrentUser,
  clearReferenceData,
  clearOrganization,
  setCurrentUser,
  setOrganization,
  saveUser,
  saveOrganization,
} from '../../actions'
import { ApiHelper } from '../../lib'
import { findPortById } from '../../selectors'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ErrorMessageComponent from '../ErrorMessageComponent'

const styles = () => ({
  heading: {
    paddingTop: '5%',
    color: '#4F596F',
  },

  accountDetails: {
    color: '#4F596F',
  },

  textFieldHeading: {
    paddingTop: '3%',
    opacity: '70%',
  },

  textField: {
    paddingTop: '2%',
  },

  accountDetailsGrid: {
    paddingLeft: '1vw',
    paddingRight: '1vw',
    height: '55vh',
    overflow: 'auto',
  },

  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80vw',
    margin: 'auto',
  },

  dialogHeading: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '1.05rem',
    color: '#4F596F',
  },

  dialogActions: {
    justifyContent: 'flex-end',
  },

  loadingGrid: {
    minHeight: '70vh',
  },
})

const accountThemes = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: '25px !important',
        color: '#003E6D',
      },
      underline: {
        '&&&&:before': {
          borderBottom: 'none',
        },
        '&&&&:after': {
          borderBottom: 'none',
        },
      },
      input: {
        padding: '7px 12px 7px',
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '7px 4px !important',
      },
      inputRoot: {
        padding: '0px 0px 0px 7px !important',
      },
    },
  },
})

const AntSwitch = withStyles(theme => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex',
    borderRadius: 16 / 2,
    marginTop: '5%',
  },
  switchBase: {
    padding: 2,
    color: 'white',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.grey[500],
      '& + $track': {
        opacity: 1,
        backgroundColor: '#003E6D',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    color: 'white',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch)

export function AccountDetails(props) {
  const [loadingState, setLoadingState] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [disableEdit, setDisableEdit] = React.useState(true)

  const [openDialog, setOpenDialog] = useState(false)

  const handleCloseError = () => {
    setOpenDialog(false)
  }

  function signOut() {
    const api = new ApiHelper()
    api
      .delete('/users/sign_out')
      .then(() => {
        props.clearCurrentUser()
        props.clearReferenceData()
        props.clearOrganization()
      })
      .catch(error => {
        alert(error)
      })
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const setUserOrgDetails = user => {
    setLoadingState('loading')
    const api = new ApiHelper()
    api
      .put('/user', {
        user: {
          mobile: user.mobile,
          preference_attributes: {
            allow_notifications: user.preference
              ? user.preference.allow_notifications
              : false,
          },
        },
        organization: {
          vessel_federal_identifier:
            props.organization.vessel_federal_identifier,
          home_port_id: props.organization.home_port_id,
        },
      })
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        props.setCurrentUser(result.user)
        props.setOrganization(result.organization)
        setLoadingState('success')
      })
      .catch(error => {
        console.error('Error:' + error)
        setErrorMsg('Unable to Connect to Server!')
        setLoadingState('success')
        setOpenDialog(true)
      })
  }

  const setUserNotificationDetails = val => {
    let user = Object.assign({}, props.user)
    user.preference = user.preference || {}
    user.preference.allow_notifications = val
    props.saveUser(user)
    setUserOrgDetails(user)
  }

  const saveUserDetails = (key, val) => {
    const user = Object.assign({}, props.user)
    user[key] = val
    props.saveUser(user)
  }

  const saveOrganizationDetails = (key, val) => {
    const organization = Object.assign({}, props.organization)
    organization[key] = val
    props.saveOrganization(organization)
  }

  function loadAccount() {
    if (loadingState === 'loading') {
      return (
        <Grid
          id="circularProgress"
          container
          justify="center"
          alignItems="center"
          className={props.classes.loadingGrid}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <div>
          <MuiThemeProvider theme={accountThemes}>
            <Box height="60vh">
              <Grid
                container
                spacing={0}
                className={props.classes.bottomGridContainer}
              >
                <Grid item xs={10} className={props.classes.accountDetails}>
                  <Typography>
                    <Trans i18nKey="account_details" />:
                  </Typography>
                </Grid>
                <Grid item xs={2} onClick={() => setDisableEdit(false)}>
                  <Typography color="primary" variant="button">
                    <Trans i18nKey="edit" />
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  spacing={0}
                  className={props.classes.accountDetailsGrid}
                >
                  <Grid item xs={12} className={props.classes.heading}>
                    <Typography color="inherit" variant="button">
                      <Trans i18nKey="signin" />:
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="fullName" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.textField}>
                    <TextField
                      disabled
                      autoComplete="off"
                      id="fullName"
                      data-testid="fullName"
                      value={props.user.first_name + ' ' + props.user.last_name}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="email" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.textField}>
                    <TextField
                      disabled
                      autoComplete="off"
                      id="email"
                      value={props.user.email}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="mobile_number" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.textField}>
                    <TextField
                      disabled={disableEdit}
                      onBlur={() => setUserOrgDetails(props.user)}
                      autoComplete="off"
                      id="mobileNumber"
                      data-testid="mobileNumber"
                      value={props.user.mobile}
                      onChange={e =>
                        saveUserDetails('mobile', e.currentTarget.value)
                      }
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={props.classes.heading}>
                    <Typography color="inherit" variant="button">
                      <Trans i18nKey="registration" />:
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="vessel_name" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.textField}>
                    <TextField
                      disabled
                      autoComplete="off"
                      id="vesselName"
                      data-testid="vesselName"
                      value={props.organization.name}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="vessel_federal_id" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.textField}>
                    <TextField
                      disabled={disableEdit}
                      onBlur={() => setUserOrgDetails(props.user)}
                      autoComplete="off"
                      id="vesselFederalId"
                      data-testid="vesselFederalId"
                      value={props.organization.vessel_federal_identifier}
                      onChange={e =>
                        saveOrganizationDetails(
                          'vessel_federal_identifier',
                          e.currentTarget.value
                        )
                      }
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="home_port" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.textField}>
                    <Autocomplete
                      id="homePort"
                      data-testid="homePort"
                      onBlur={() => setUserOrgDetails(props.user)}
                      disabled={disableEdit}
                      value={findPortById(
                        props.ports,
                        props.organization.home_port_id
                      )}
                      onChange={(e, newValue) =>
                        saveOrganizationDetails(
                          'home_port_id',
                          newValue ? newValue.id : null
                        )
                      }
                      getOptionLabel={option => {
                        return option.name
                      }}
                      options={props.ports}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label=""
                          variant="filled"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className={props.classes.heading}>
                    <Typography color="inherit" variant="button">
                      <Trans i18nKey="notifications" />:
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={10} className={props.classes.textFieldHeading}>
                    <Typography color="inherit">
                      <Trans i18nKey="allow_notifications" />:
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={props.classes.textFieldHeading}>
                    <AntSwitch
                      disabled={disableEdit}
                      id="allowNotification"
                      data-testid="allowNotification"
                      checked={
                        props.user.preference &&
                        props.user.preference.allow_notifications
                      }
                      onChange={e =>
                        setUserNotificationDetails(e.target.checked)
                      }
                      name="notification"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box height="10vh">
              <Grid container justify="center" alignItems="flex-end">
                <Button
                  id="evtrButton"
                  className={props.classes.buttonNext}
                  variant="outlined"
                  type="button"
                  onClick={handleOpen}
                  margin="normal"
                >
                  <Trans i18nKey="log_out" />
                </Button>
              </Grid>
            </Box>
            <Dialog open={open} className={props.classes.dialog}>
              <DialogContent>
                <DialogContentText className={props.classes.dialogHeading}>
                  Log Out
                </DialogContentText>
                <DialogContentText>
                  Are you sure you want to sign out of your account?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={props.classes.dialogActions}>
                <Button onClick={handleClose}>CANCEL</Button>
                <Button onClick={() => signOut()} color="primary">
                  CONFIRM
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={handleCloseError}
              aria-labelledby="simple-dialog-title"
              open={openDialog}
            >
              <ErrorMessageComponent errorMessage={errorMsg} />
            </Dialog>
          </MuiThemeProvider>
        </div>
      )
    }
  }

  return <div>{loadAccount()}</div>
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    user: state.user,
    organization: state.organization,
    ports: state.ports,
  }
}

const mapDispatchToProps = {
  clearCurrentUser,
  clearReferenceData,
  clearOrganization,
  setCurrentUser,
  setOrganization,
  saveUser,
  saveOrganization,
}

export const AccountDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(withHailTheme(AccountDetails)))
