import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../../shared/CombineStyles'
import { withDealerTheme } from '../../shared/DealerTheme'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import classNames from 'classnames'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import { ApiHelper } from '../../../lib'
//import { setDealerNewModifyOrders } from '../../../actions'

const styles = () => ({
  titleGrid: {
    marginTop: '20px',
  },
})

export function DealerOrderCart(props) {
  const customers = useSelector(state => state.dealerCustomerList)
  //const dealerNewModifyOrder = useSelector(state => state.dealerNewModifyOrder)
  //const dispatch = useDispatch()
  let customer
  if (props.cartItem) {
    customer = customers.find(customer => {
      return customer.id === props.cartItem.customer_id
    })
  }

  const deleteLineItems = (evt, lineItemId) => {
    evt.preventDefault()
    const api = new ApiHelper()
    api
      .delete(`/line_items/${lineItemId}`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          props.onDeleteLineItem(result)
          //let updatedNewModifyOrder = { ...dealerNewModifyOrder }
          //updatedNewModifyOrder['order']['line_items'] = result.line_items
          //dispatch(setDealerNewModifyOrders(updatedNewModifyOrder))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <Grid
      container
      className={classNames(
        props.classes.cartRootDiv,
        props.classes.leftOuterGrid
      )}
    >
      <Grid xs={1} lg={1} />
      <Grid xs={10} lg={10} className={classNames(props.classes.titleGrid)}>
        Order # {props.cartItem && props.cartItem.order_number}
      </Grid>
      <Grid xs={1} lg={1} />
      <Grid container item>
        <Grid
          item
          xs={12}
          lg={12}
          md={12}
          sm={12}
          className={props.classes.cartFees}
        >
          {props.cartItem.customer_id ? customer.name : ''}
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
          md={12}
          sm={12}
          className={props.classes.cartFees}
        >
          {props.cartItem && props.cartItem.start_date}
        </Grid>
      </Grid>

      {props.cartItem &&
        props.cartItem.line_items &&
        props.cartItem.line_items.length > 0 &&
        props.cartItem.line_items.map(lineItem => (
          <Grid
            container
            key={lineItem.id}
            className={props.classes.paddingBottom}
          >
            <Grid
              item
              xs={3}
              md={3}
              lg={3}
              sm={3}
              className={props.classes.cartData}
            >
              {lineItem.quantity}
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              sm={4}
              className={props.classes.cartSpecies}
            >
              {lineItem.product || lineItem.product_market_name}
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              lg={3}
              sm={3}
              className={props.classes.cartData}
            >
              ${lineItem.price}
            </Grid>
            <Grid item xs={2} md={2} lg={2} sm={2}>
              <DeleteIcon
                fontSize="small"
                className={props.classes.deleteIcon}
                onClick={event => deleteLineItems(event, lineItem.id)}
              />
            </Grid>
          </Grid>
        ))}

      {props.cartItem &&
      props.cartItem.line_items &&
      props.cartItem.line_items.length > 0 ? (
        <Grid container>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            sm={6}
            className={props.classes.cartFees}
          >
            <Trans i18nKey="fees" />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            sm={6}
            className={props.classes.cartAmount}
          >
            {props.cartItem.total_fees}
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            sm={6}
            className={props.classes.cartFees}
          >
            <Trans i18nKey="estimated_fees" />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            sm={6}
            className={props.classes.cartAmount}
          >
            {props.cartItem.total_amount}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  )
}

export const DealerOrderCartContainer = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(DealerOrderCart))
