import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LfWhiteLogo from '../../../assets/trace/bigeasy/LfWhiteLogo.svg'

const styles = () => ({
  logoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
})

export function BigEasyLogoImage(props) {
  return (
    <Grid
      data-testid="BigEasyLogo"
      container
      spacing={1}
      alignItems="flex-end"
      direction="row"
      classes={{ root: props.classes.logoContainer }}
    >
      <Grid item xs={12}>
        <img src={LfWhiteLogo} alt="Big Easy Logo" />
      </Grid>
    </Grid>
  )
}

export const BigEasyLogoContainer = withStyles(styles)(BigEasyLogoImage)
