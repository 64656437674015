import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import { Trans } from 'react-i18next'

const themes = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        color: 'black',
        ontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 400,
        fontSize: '10pt',
      },
      underline: {
        borderBottom: '0px solid grey',
        '&&&&:hover:before': {
          borderBottom: '0px solid grey',
        },
        '&&&&:after': {
          borderBottom: '0px solid grey',
        },
      },
    },

    MuiInputLabel: {
      root: {
        color: 'black',
        ontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 300,
        fontSize: '10pt',
        textTransform: 'upperCase',
        '&$focused': {
          color: 'black',
        },
      },
      shrink: {
        transform: 'translate(36px, 24px) scale(1)',
      },
    },

    MuiFormLabel: {
      root: {
        color: 'red',
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        fontWeight: 300,
        fontSize: '9pt',
        '&$focused': {
          color: 'grey',
          transform: 'translate(0, 1.5px) scale(0.75)',
          transformOrigin: 'top left',
        },
      },
      filled: {
        transform: 'translate(0, 1.5px) scale(0.75) !important',
        transformOrigin: 'top left !important',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '1px',
      },
    },
  },
})

export default function SearchComponent(props) {
  const handleSearchKeyChange = searchKeyTerm => {
    props.onChangeSearchKey(searchKeyTerm)
  }

  return (
    <MuiThemeProvider theme={themes}>
      <TextField
        id="searchBox"
        label=<Trans i18nKey="search" />
        margin="normal"
        fullWidth
        onBlur={e => handleSearchKeyChange(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </MuiThemeProvider>
  )
}
