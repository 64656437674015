import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  SvgIcon,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { ReactComponent as StepperIcon } from '../assets/stepper.svg'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  bigEasyColor: {
    color: '#3675AD !important',
  },
  butcherBoxColor: {
    color: '#2D4D59 !important',
  },
}))

const stepperThemes = createMuiTheme({
  overrides: {
    MuiStepper: {
      root: {
        padding: '0',
        color: '#2D4D59',
      },
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          paddingLeft: '2vh',
          fontSize: '0.9rem',
          fontWeight: '600',
        },
      },
    },
    MuiCollapse: {
      entered: {
        height: 'auto',
      },
    },
    MuiStepContent: {
      root: {
        marginTop: '0px',
        borderLeft: '2px dashed #2D4D59',
        paddingLeft: '7vw',
        alignItems: 'center',
      },
    },
    MuiStepConnector: {
      vertical: {
        marginLeft: '13px',
        padding: '0px',
      },
      lineVertical: {
        minHeight: '0px',
        borderLeftColor: '#2D4D59',
        borderLeft: 'dashed',
        borderLeftStyle: 'dashed',
        borderLeftWidth: '2px',
      },
    },
  },
})

const stepIconComponent = () => {
  return <SvgIcon component={StepperIcon} viewBox="0 0 50 56" />
}

export function VerticalLinearStepper(props) {
  const classes = useStyles()
  const steps = props.steps

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={stepperThemes}>
        <Stepper activeStep={-1} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label} active={true}>
              <StepLabel
                StepIconComponent={stepIconComponent}
                classes={{
                  label:
                    props.component && props.component === 'ButcherBoxContainer'
                      ? classes.butcherBoxColor
                      : classes.bigEasyColor,
                }}
              >
                <Trans i18nKey={label} />
              </StepLabel>
              <StepContent>{props.displayComponent(index)}</StepContent>
            </Step>
          ))}
        </Stepper>
      </MuiThemeProvider>
    </div>
  )
}

export default VerticalLinearStepper
