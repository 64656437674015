// src/lib/CookieHelper.js

const TOKEN_COOKIE_NAME = 'LF_JWT'
const MAX_AGE = 30 // days

export class CookieHelper {
  setTokenCookie(token) {
    const cookie = `${TOKEN_COOKIE_NAME}=${token}${this.__cookieConfigString()}`
    document.cookie = cookie
  }

  clearTokenCookie() {
    const epoch = new Date(0)
    document.cookie = `${TOKEN_COOKIE_NAME}=;Path=/vessel;expires=${epoch.toUTCString()}`
    document.cookie = `${TOKEN_COOKIE_NAME}=;Path=/;expires=${epoch.toUTCString()}`
  }

  isAuthenticated() {
    return this.getToken() ? true : false
  }

  getToken() {
    var match = document.cookie.match(
      new RegExp('(^| )' + TOKEN_COOKIE_NAME + '=([^;]+)')
    )
    return match && match[2] ? match[2] : null
  }

  getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    return match && match[2] ? match[2] : null
  }

  __cookieConfigString() {
    var expires = new Date()
    expires.setDate(expires.getDate() + MAX_AGE)
    return `;expires=${expires.toUTCString()}`
  }
}

export default CookieHelper
