import React from 'react'
import { connect } from 'react-redux'
import { Button, TextField, InputAdornment } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { PublicContainer } from '../components'
import Grid from '@material-ui/core/Grid'
import { Email, AccountCircle } from '@material-ui/icons'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { withStyles } from '@material-ui/core/styles'
import { withTheme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { useHistory } from 'react-router-dom'

const styles = () => ({})

export function SignUp(props) {
  const history = useHistory()

  const signUp = () => {
    const passwordLocation = {
      pathname: '/password',
      state: { from: 'sign_up' },
    }
    history.push(passwordLocation)
  }

  return (
    <PublicContainer
      top={
        <form>
          <Grid container spacing={1} alignItems="flex-end" direction="row">
            <Grid item xs={12} className={props.classes.formControl}>
              <TextField
                id="formBasicName"
                label=<Trans i18nKey="fullName" />
                //value={username}
                // onChange={e => setUsername(e.currentTarget.value)}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" direction="row">
            <Grid item xs={12} className={props.classes.formControl}>
              <TextField
                id="formBasicEmail"
                label=<Trans i18nKey="email" />
                type="email"
                //value={username}
                // onChange={e => setUsername(e.currentTarget.value)}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="flex-end" direction="row">
            <Grid item xs={12} className={props.classes.formControl}>
              <TextField
                id="formBasicPhone"
                label=<Trans i18nKey="mobilePhone" />
                type="tel"
                //value={username}
                // onChange={e => setUsername(e.currentTarget.value)}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      }
      bottom={
        <Button
          id="formButton"
          className={props.classes.button}
          variant="outlined"
          type="button"
          onClick={() => signUp()}
          margin="normal"
        >
          <Trans i18nKey="next" />
        </Button>
      }
    />
  )
}

const mapStateToProps = (/*state, ownProps*/) => {
  return {
    //TODO: related code to be added when functionality is built-ins
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(withTheme(SignUp)))
