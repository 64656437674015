import { SET_NEW_HAIL, RESET_NEW_HAIL } from '../actions'

const initialState = {
  start_time: new Date(),
  arrival_date: new Date(),
  arrival_time: new Date(),
  status: 'draft',
  species: [],
  port: {},
}

export default function newHail(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_HAIL:
      return action.hail
    case RESET_NEW_HAIL:
      return initialState
    default:
      return state
  }
}
