import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Typography,
  Checkbox,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Email } from '@material-ui/icons'
import LockIcon from '@material-ui/icons/Lock'
import { Trans } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import { ButtonSpinner, PublicContainer } from '../components'
import { ApiHelper } from '../lib'
import { setCurrentUser, clearCurrentUser, setOrganization } from '../actions'
import i18n from '../i18n'
import { withTheme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import ErrorMessageComponent from '../components/ErrorMessageComponent'

const styles = () => ({
  languageOption: {
    color: 'white',
    fontFamily: 'Rubik',
    fontSize: '1rem',
  },

  languageOptionSelected: {
    fontWeight: 500,
  },

  languageOptionDefault: {
    fontWeight: 400,
  },
  signUpButton: {
    textTransform: 'none',
  },
})

export function SignIn(props) {
  const signUpLocation = {
    pathname: '/sign_up',
    state: { from: 'sign_in' },
  }

  const [default_username, default_password, default_language] =
    process.env.NODE_ENV === 'development'
      ? [
          process.env.REACT_APP_DEV_USER_EMAIL,
          process.env.REACT_APP_DEV_USER_PASSWORD,
          'en',
        ]
      : ['', '', 'en']
  const [username, setUsername] = useState(default_username)
  const [password, setPassword] = useState(default_password)
  const [isFetching, setIsFetching] = useState(false)
  const [language, setLanguage] = useState(getCurrentLanguage())
  const api = new ApiHelper()
  const [errorMsg, setErrorMsg] = useState('')

  function getCurrentLanguage() {
    return localStorage.getItem('I18N_LANGUAGE') || default_language
  }

  async function signIn() {
    setIsFetching(true)
    try {
      const env = process.env.NODE_ENV
      const reqMethod = process.env.REACT_APP_REQUEST_METHOD || 'post'
      const responseStatus =
        Number(process.env.REACT_APP_CREATED_RESPONSE_CODE) || 201
      let path = 'sign_in'
      if (env === 'development' && username === 'dev+dealer@legitfishinc.com') {
        path = 'sign_in'
      }

      const response = await api[reqMethod](path, {
        user: { email: username, password },
      })
      const json = await response.json()
      setIsFetching(false)
      //const auth_header = response.headers.get('Authorization')
      if (response.status === responseStatus) {
        //let token = auth_header.split(' ')[1]
        //cookie.setTokenCookie(token)
        props.setCurrentUser(json.context.current_user)
        props.setOrganization(json.context.current_organization)
        //props.setToken(token)
      } else {
        //cookie.clearTokenCookie()
        props.clearCurrentUser()
        api.handleError(json)
      }
    } catch (error) {
      setIsFetching(false)
      setErrorMsg(error)
    }
  }

  function changeLanguage(languageCode) {
    i18n.changeLanguage(languageCode)
    localStorage.setItem('I18N_LANGUAGE', languageCode)
    setLanguage(languageCode)
  }

  function classNameForLanguage(props, lang) {
    //const language = i18n.languages[0];
    if (language === lang) {
      return props.classes.languageOptionSelected
    }
    return props.classes.languageOptionDefault
  }

  const isLoggedIn = props.state.user && props.state.user.role
  // If user is authenticated
  if (isLoggedIn) {
    return (
      <Redirect
        className="signInRedirect"
        to={'/' + props.state.user.role.toLowerCase()}
      />
    )
  }

  const handleKeypress = event => {
    //it triggers by pressing the enter key
    if (event.key === 'Enter') {
      signIn()
    }
  }

  // Default display sign_in
  return (
    <PublicContainer
      top={
        <form>
          <Grid container spacing={1} alignItems="flex-end" direction="row">
            <Grid item xs={12} className="language-option-grid">
              <Button
                id="enOption"
                className={props.classes.languageOption}
                value="en"
                onClick={() => changeLanguage('en')}
              >
                <span className={classNameForLanguage(props, 'en')}>
                  English
                </span>
              </Button>
              <Typography
                variant="caption"
                className={props.classes.textRegular}
              >
                {' '}
                |{' '}
              </Typography>
              <Button
                id="ptOption"
                className={props.classes.languageOption}
                value="en"
                onClick={() => changeLanguage('pt')}
              >
                <span className={classNameForLanguage(props, 'pt')}>
                  <Trans i18nKey="portugese" />
                </span>
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="flex-end" direction="row">
            <Grid item xs={12} className={props.classes.formControl}>
              <TextField
                onKeyPress={handleKeypress}
                id="formBasicEmail"
                label=<Trans i18nKey="email" />
                value={username}
                onChange={e => setUsername(e.currentTarget.value)}
                type="email"
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" direction="row">
            <Grid item xs={12} className={props.classes.formControl}>
              <TextField
                onKeyPress={handleKeypress}
                id="formBasicPassword"
                label=<Trans i18nKey="password" />
                value={password}
                onChange={e => setPassword(e.currentTarget.value)}
                type="password"
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center" direction="row">
            <Grid item xs={1}>
              <Checkbox style={{ color: 'white', padding: '0' }} />
            </Grid>
            <Grid item xs={11} style={{ textAlign: 'left' }}>
              <Typography
                variant="caption"
                className={props.classes.textRegular}
              >
                <Trans i18nKey="rememberMe" />
              </Typography>
            </Grid>
          </Grid>
        </form>
      }
      bottom={
        <div>
          <Button
            id="signInButton"
            className={props.classes.button}
            variant="outlined"
            type="button"
            onClick={() => signIn()}
            margin="normal"
          >
            <Trans i18nKey="signin" />
            {isFetching && <ButtonSpinner />}
          </Button>
          <div>
            <Typography variant="caption" className={props.classes.textRegular}>
              <Trans i18nKey="donothaveanaccount" />
            </Typography>
            &nbsp;
            <Link to={signUpLocation} className={props.classes.link}>
              <Trans i18nKey="signup" />
            </Link>
          </div>
          {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
        </div>
      }
      hideAgreement={true}
    />
  )
}

const mapStateToProps = state => {
  return { state }
}

const mapDispatchToProps = { setCurrentUser, clearCurrentUser, setOrganization }

export const SignInContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(withTheme(SignIn)))
