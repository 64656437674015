import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  TextField,
  InputAdornment,
  Grid,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { PublicContainer } from '../components'
import PersonIcon from '@material-ui/icons/Person'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { useHistory } from 'react-router-dom'

const styles = () => ({})

export function VesselRegistration(props) {
  const history = useHistory()

  const register = () => {
    const confirmationLocation = {
      pathname: '/confirmation',
      state: { from: 'vessel_registration' },
    }
    history.push(confirmationLocation)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <PublicContainer
        top={
          <form>
            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <TextField
                  id="formVesselId"
                  label=<Trans i18nKey="vessel_federal_id" />
                  //value={username}
                  // onChange={e => setUsername(e.currentTarget.value)}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DirectionsBoatIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <TextField
                  id="formDealerCode"
                  label=<Trans i18nKey="dealer_code" />
                  type="password"
                  //value={username}
                  // onChange={e => setUsername(e.currentTarget.value)}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        bottom={
          <Container maxWidth="sm">
            <Button
              id="formButton"
              className={props.classes.button}
              variant="outlined"
              type="button"
              onClick={() => register()}
              margin="normal"
            >
              <Trans i18nKey="next" />
            </Button>
          </Container>
        }
      />
    </MuiThemeProvider>
  )
}

const mapStateToProps = () => {
  return {
    //TODO: related code to be added when functionality is built-ins
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(VesselRegistration))
