import { SET_PORTS } from '../actions'

const initialState = []

export default function ports(state = initialState, action) {
  switch (action.type) {
    case SET_PORTS:
      return action.ports

    default:
      return state
  }
}
