import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { useDispatch, useSelector } from 'react-redux'
import { TableView } from '../../components/TableView'
import { ApiHelper } from '../../lib'
import { setDealerNewModifyOffloads } from '../../actions'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import {
  setDealerHarvestAreas,
  saveHarvestArea,
  setDealerRegions,
} from '../../features/dealerSlice'

export function DealerHarvestAreas(props) {
  const headCells = [
    { id: 'federal_stat_area', label: 'stat_area', sort: 'federal_stat_area' },
    { id: 'stock_area', label: 'stock_area', sort: 'stock_area' },
    {
      id: 'region_id',
      label: 'region',
      fetchFromParent: 'region',
    },
  ]

  const dataURL = 'fishing_areas'
  const [open, setOpen] = useState(false)

  const [startIndex, setstartIndex] = useState(1)
  const [displayDataCount, setDisplayDataCount] = useState(0)
  const initalLimitPerPage = 10
  const [fishingAreaID, setFishingAreaID] = useState('')

  const initialFilterDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [errorMsg, setErrorMsg] = React.useState('')

  const [paramState, setParamState] = React.useState(tableParameters)
  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const dealerHarvestAreas = useSelector(
    state => state.dealer.dealerHarvestAreas
  )
  const regions = useSelector(state => state.dealer.regions)

  function fetchDisplayData() {
    const api = new ApiHelper()

    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let ordersUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      ordersUrl = ordersUrl + '&q=' + paramState.searchKey
    }
    setIsLoading(true)
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerHarvestAreas(result.fishing_areas))
          setstartIndex(startIndexVal)
          setDisplayDataCount(result.pagination.count)
          setErrorMsg('')
        } else {
          setErrorMsg('Error occured while retrieving Inventory Summary')
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [paramState, dataURL])

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get('/regions', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setDealerRegions(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  const handleAddNew = () => {
    setFishingAreaID('')
    setOpen(true)
  }

  const handleClose = () => {
    dispatch(
      saveHarvestArea({
        federal_stat_area: '',
        stock_area: '',
        region_id: '',
      })
    )
    setOpen(false)
  }

  const handleEdit = fishingAreaID => {
    let index = dealerHarvestAreas.findIndex(
      obj => obj.order_number == fishingAreaID
    )
    let selectedOffload = {
      order: {},
    }
    selectedOffload.order = { ...dealerHarvestAreas[index] }
    selectedOffload.order['lots_attributes'] = selectedOffload.order['lots']
    dispatch(setDealerNewModifyOffloads(selectedOffload))
    setFishingAreaID(fishingAreaID)
    setOpen(true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setstartIndex(1)
    }
    setParamState(paramHash)
  }

  function backToHarvestArea() {
    fetchDisplayData()
    handleClose()
  }

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  function onFetchFromParent(name, key) {
    let dealerHarvestArea = dealerHarvestAreas.find(obj => obj.id == key)
    let region = regions.find(obj => obj.id == dealerHarvestArea.region_id)
    if (name === 'region' && region) {
      return region.name
    }
  }

  return (
    <div>
      <div>
        <TableView
          headCells={headCells}
          displayData={dealerHarvestAreas}
          startIndex={startIndex}
          sortType={headCells[0].id}
          paramState={paramState}
          loadData={loadData}
          onHandleaddNew={handleAddNew}
          displayDataCount={displayDataCount}
          initalLimitPerPage={initalLimitPerPage}
          handleEdit={handleEdit}
          isAddNewAllowed={true}
          isLoading={isLoading}
          sortOptions={false}
          isSortEnabled={isSortEnabled}
          open={open}
          handleClose={handleClose}
          disableEdit={true}
          backToHarvestArea={backToHarvestArea}
          id={fishingAreaID}
          formToShow={'harvest area'}
          onFetchFromParent={onFetchFromParent}
        />
        {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
      </div>
    </div>
  )
}

export const DealerHarvestAreasContainer = withStyles(
  combineStyles(stylesBase)
)(DealerHarvestAreas)
