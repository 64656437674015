import React, { useEffect, useState } from 'react'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withHailTheme } from '../../pages/shared/hailTheme'
import FilterListIcon from '@material-ui/icons/FilterList'
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { ApiHelper } from '../../lib'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import GreenDot from '../../assets/hail/GreenDot.svg'
import YellowDot from '../../assets/hail/YellowDot.svg'
import { useHistory } from 'react-router-dom'
import { setUserNotifications } from '../../actions'
import ErrorMessageComponent from '../ErrorMessageComponent'
import { useSelector, useDispatch } from 'react-redux'
import { selectNotifications } from '../../selectors'

const styles = () => ({
  notifications: {
    textTransform: 'uppercase',
    color: '#4F596F',
  },

  oldNotification: {
    fontSize: '0.8rem',
    color: '#0000008A',
  },

  newNotification: {
    fontSize: '0.8rem',
    fontWeight: 600,
    color: '#4F596F',
  },

  notificationsGrid: {
    height: '55vh',
    overflow: 'auto',
  },
})

const notificationThemes = createMuiTheme({
  overrides: {
    MuiList: {
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    MuiListItem: {
      root: {
        width: '85%',
      },
      dense: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: '25px',
      },
    },
    MuiAvatar: {
      root: {
        width: '10px',
        height: '10px',
      },
    },
  },
})

export function Notifications(props) {
  const history = useHistory()
  const notifications = useSelector(selectNotifications)
  const pagination = useSelector(state => state.userNotifications.pagination)
  const dispatch = useDispatch()

  const [loadingState, setLoadingState] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [notificationPageNo, setNotificationPageNo] = useState(pagination.page)

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    setLoadingState('loading')
    const api = new ApiHelper()

    api
      .get('/notifications?page=' + notificationPageNo, {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setUserNotifications(result))
        setLoadingState('success')
      })
      .catch(error => {
        console.error('Error:' + error)
        setErrorMsg('Unable to Connect to Server!')
        setLoadingState('success')
        setOpenDialog(true)
      })
  }, [notificationPageNo])

  const showHail = orderNumber => {
    const showHailLink = {
      pathname: '/vessel/' + orderNumber,
      state: { from: '/vessel/account' },
    }
    history.push(showHailLink)
  }

  useEffect(() => {
    const api = new ApiHelper()

    api.put('/notifications/mark_as_read', {})
  }, [])

  function dotSelector(value) {
    if (value && value.includes('land')) {
      return YellowDot
    } else {
      return GreenDot
    }
  }

  function showStatus(event, sender) {
    if (!event) {
      return ''
    }
    if (event.includes('land')) {
      return 'Received by ' + sender.first_name + ' ' + sender.last_name
    } else if (event.includes('accept')) {
      return 'Accepted by ' + sender.first_name + ' ' + sender.last_name
    } else {
      return ''
    }
  }

  function classNameSelector(readAt) {
    if (readAt === null) {
      return props.classes.newNotification
    } else {
      return props.classes.oldNotification
    }
  }

  function showSeeMoreButton() {
    if (pagination.page !== pagination.last) {
      return (
        <Button
          id="portButton"
          variant="outlined"
          type="button"
          margin="normal"
          className={props.classes.buttonNext}
          onClick={() => setNotificationPageNo(pagination.page + 1)}
        >
          <Trans i18nKey="see_more" />
        </Button>
      )
    } else {
      return
    }
  }

  function loadNotifications() {
    if (loadingState === 'loading') {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '70vh' }}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          spacing={0}
          className={props.classes.bottomGridContainer}
        >
          <Grid item xs={11} className={props.classes.notifications}>
            <Typography>
              <Trans i18nKey="notifications" />:
            </Typography>
          </Grid>
          <Grid item xs={1} className={props.classes.notifications}>
            <FilterListIcon />
          </Grid>
          <Grid
            container
            item
            spacing={0}
            className={props.classes.notificationsGrid}
          >
            <Grid item xs={12}>
              <List id="notificationsList" data-testid="notificationsList">
                {notifications.map(value => {
                  const labelId = `checkbox-list-label-${value}`
                  return (
                    <div key={value.id}>
                      <ListItem
                        dense
                        button
                        onClick={() => showHail(value.data.order_number)}
                      >
                        <ListItemAvatar>
                          <Avatar src={dotSelector(value.event)} />
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          primary={
                            <Typography
                              variant="body2"
                              className={classNameSelector(value.read_at)}
                            >
                              {value.data ? value.data.order_number : 'N/A'}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classNameSelector(value.read_at)}
                              >
                                {showStatus(value.event, value.sender)}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography
                            variant="body2"
                            className={classNameSelector(value.read_at)}
                          >
                            {format(new Date(value.updated_at), 'MM/dd/yyyy')}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <div>
      <MuiThemeProvider theme={notificationThemes}>
        <Box height="60vh">{loadNotifications()}</Box>
        <Box height="10vh">
          <Grid container justify="center" alignItems="flex-end">
            {showSeeMoreButton()}
          </Grid>
        </Box>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
        >
          <ErrorMessageComponent errorMessage={errorMsg} />
        </Dialog>
      </MuiThemeProvider>
    </div>
  )
}

export const NotificationsContainer = withStyles(
  combineStyles(styles, stylesBase)
)(withHailTheme(Notifications))
