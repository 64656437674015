import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import { setReferenceData } from '../actions'
import { AppContainer } from '../components'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
  },
})

export function LandingDealer(props) {
  //const [isFetching, setIsFetching] = useState(false)
  //const api = new ApiHelper()

  // useEffect(() => {
  //   if (!props.state.referenceData.action) {
  //     getDealerData()
  //   }
  // }, [getDealerData, props.state.referenceData.action])

  /*async function getDealerData() {
    setIsFetching(true)
    try {
      const response = await api.get('landing_dealer')
      const json = await response.json()
      setIsFetching(false)
      if (response.status === 200) {
        props.setReferenceData(json.reference_data)
      } else {
        api.handleError()
      }
    } catch (error) {
      setIsFetching(false)
      alert(error)
    }
  }*/

  return (
    <div>
      <AppContainer maxWidth="sm">
        <h1>Dealer Landing</h1>
        <h3>props.state:</h3>
        <pre>{JSON.stringify(props.state, null, 2)}</pre>
      </AppContainer>
    </div>
  )
}

const mapStateToProps = state => {
  return { state }
}

const mapDispatchToProps = { setReferenceData }

export const LandingDealerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LandingDealer))
