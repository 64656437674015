import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Link } from '@material-ui/core'
import Noaa from '../../../assets/trace/bigeasy/Noaa.svg'

const styles = () => ({
  headingTextAbout: {
    fontWeight: 600,
    fontSize: '1.2rem',
    paddingBottom: '1vh',
    height: 'auto',
  },
  bigEasyContent: {
    padding: '3vh 3vh 15vh 3vh',
    color: '#3675AD',
  },
  bBcontent: {
    padding: '3vh 3vh 15vh 3vh',
    color: '#2D4D59',
  },
  noaa: {
    width: '17%',
    paddingTop: '3vh',
    paddingBottom: '3vh',
  },
  link: {
    color: 'blue',
  },
  iframe: {
    width: '100%',
    height: '100vh',
    borderWidth: '0',
  },
})

export function Sustainability(props) {
  function selectBbSUstainability() {
    if (props.trace.sustainability_url) {
      return (
        <iframe
          title="Fisheries site"
          src={props.trace.sustainability_url}
          width="100%"
          height="100%"
          className={props.classes.iframe}
        ></iframe>
      )
    } else {
      return (
        <div className={props.classes.bBcontent}>
          <Typography variant="body2">
            No one has more at stake than our local shrimpers and shrimp
            processors, as their livelihood depends on the annual harvest. The
            annual shrimp harvest has been relatively stable since the early
            1950s, in spite of significant changes throughout the shrimp
            industry. Still, long term sustainability remains the goal, and the
            U.S. gulf shrimp industry has adopted certain standards and
            approaches to help in this effort, including the use of Fishery
            Improvement Projects or FIPs.
          </Typography>
          <br />
          <Typography variant="body2">
            There are many definitions of sustainability, especially as it
            relates to seafood. Fishery Improvement Projects (FIPs) are one way
            to verify and communicate the sustainability of a fishery while also
            addressing any potential gaps or challenges within the fishery.
            While the criteria within these programs vary to some extent, there
            are overarching criteria that are relatively consistent, including
            ensuring that the amount of fish (in this case shrimp) harvested can
            replenish itself, making sure that fishing methods (for example
            shrimp nets) limit their impacts to the surrounding environment, and
            verifying that the fishery is legally compliant. A FIP takes these
            criteria into consideration and also includes the sustainability of
            the fishermen’s livelihood.
          </Typography>
          <br />
          <Typography variant="body2">
            Many U.S. gulf shrimp industry members are active participants in
            FIPs. Currently, Audubon Nature Institute’s Gulf United For Lasting
            Fisheries (G.U.L.F.) program coordinates four shrimp projects:&nbsp;
            <Link
              className={props.classes.link}
              href="https://fisheryprogress.org/fip-profile/us-texas-shrimp-otter-trawl"
            >
              Texas
            </Link>
            ,&nbsp;
            <Link
              className={props.classes.link}
              href="https://fisheryprogress.org/fip-profile/louisiana-shrimp-otterskimmer-trawl"
            >
              Louisiana
            </Link>
            ,&nbsp;
            <Link
              className={props.classes.link}
              href="https://www.audubongulf.org/wp-content/uploads/2021/07/2021-07_MS-Shrimp-Summary-Action-Plan.pdf"
            >
              Mississippi
            </Link>
            &nbsp;and&nbsp;
            <Link
              className={props.classes.link}
              href="https://www.audubongulf.org/wp-content/uploads/2021/07/2021_07_AL-Shrimp-Summary-Action-Plan.pdf"
            >
              Alabama
            </Link>
            . While each project is a little different, a common theme is
            coordinating gear (shrimp nets) checks for turtle excluder devices
            (TEDs) and bycatch reduction devices (BRDs). In many cases, TED
            non-compliance is unintentional and minor. By working with gear
            specialists, the captains and crew have a chance to address issues
            before a fishing trip begins, fixing any non-compliance found by the
            specialists immediately.
          </Typography>
          <br />
          <Typography variant="body2">
            Fishery Improvement Projects are a way to verify the sustainability
            of a fishery while also addressing any potential gaps of challenges
            within the fishery. With the input of management agencies and
            industry members, G.U.L.F. designs action plans that map the way
            toward positive change. G.U.L.F. tailors FIPs based on the needs of
            participants, referring to the guidelines from the Conservation
            Alliance for Seafood Solutions when requested.
          </Typography>
          <br />
          <Typography variant="body2">
            There are many definitions of sustainability, especially as it
            relates to seafood. Some businesses, such as grocery stores and
            restaurants, are looking for assurances of sustainability for
            domestic and international fisheries. While the criteria within
            these programs vary to some extent, there are overarching criteria
            that are relatively consistent.
          </Typography>
          <br />
          <Typography variant="body2">
            1. Stock must be harvested at a level in which it can replenish
            itself.
          </Typography>
          <br />
          <Typography variant="body2">
            2. Fishing methods must cause minimal environmental impacts.
          </Typography>
          <br />
          <Typography variant="body2">
            3. The fishery must be compliant with national and international
            laws.
          </Typography>
          <br />
          <Typography variant="body2">
            A FIP takes these criteria into consideration, and includes the
            sustainability of fishermen’s livelihood. G.U.L.F. actively works
            with management and the seafood industry to ensure that a FIP is
            holistic and provides a complete picture of a fishery. For more
            information about FIP criteria or the FIP process, contact us.
          </Typography>
          <br />
          <Typography variant="body2">
            Fishery Improvement Projects (FIPs) are one tool to verify
            sustainability. Unlike sustainability certification, FIPs offer a
            step-wise approach to advance a fishery if certification is not
            currently an option. Work within a FIP is determined by the same
            standards as outlined in some certifications. Many of our&nbsp;
            <Link
              className={props.classes.link}
              href="https://americanshrimp.com/association/membership/"
            >
              ASPA member processors&nbsp;
            </Link>
            are active participants in FIPs.
          </Typography>
          <br />
          <Typography variant="body2">
            Currently, G.U.L.F. coordinates four shrimp projects: Texas,
            Louisiana, Mississippi, and Alabama. While each project is a little
            different, a common theme is coordinating gear checks for turtle
            excluder devices (TEDs) and bycatch reduction devices (BRDs).
            Through collaboration with Texas Sea Grant and NOAA’s Gear
            Monitoring Team, we help schedule courtesy gear checks on the dock
            that can catch issues that could result in catching a sea turtle, or
            in severe fines by enforcement.
          </Typography>
          <br />
          <Typography variant="body2">
            In many cases, TED non-compliance is unintentional and minor. By
            working with these gear specialists, the captains and crew have a
            chance to address issues before a fishing trip begins, fixing any
            non-compliance found by the specialists immediately. In the last
            nine months, over 30 otter trawl vessels involved with G.U.L.F.’s
            projects have been checked.
          </Typography>
        </div>
      )
    }
  }

  function showSustainability() {
    if (props.trace.component === 'ButcherBoxContainer') {
      return selectBbSUstainability()
    } else {
      return (
        <div className={props.classes.bigEasyContent}>
          <Typography variant="body2">
            No one has more at stake than our local shrimpers and shrimp
            processors, as their livelihood depends on the annual harvest. The
            annual shrimp harvest has been relatively stable since the early
            1950s, in spite of significant changes throughout the shrimp
            industry. Still long term sustainability remains our goal and our
            industry has adopted certain standards to help us in the effort.
          </Typography>
          <Grid item container justify="center" xs={12}>
            <img src={Noaa} alt="Noaa Logo" className={props.classes.noaa} />
          </Grid>
          <Typography className={props.classes.headingTextAbout}>
            What is Sustainability?
          </Typography>
          <Typography variant="body2">
            The National Oceanic and Atmospheric Administration(NOAA), one of
            the government bodies in charge of monitoring the populations of
            fish and shellfish, defines sustainable when: &quot;The population
            of a species of fish (or shellfish) is managed in a&quot;
          </Typography>
        </div>
      )
    }
  }
  return showSustainability()
}

export const SustainabilityContainer = withStyles(styles)(Sustainability)
