import React from 'react'
import { Grid } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'

function selectEditIcon(props) {
  if (props.status === 'draft' && !props.finalized) {
    if (props.editEvtr) {
      return <CreateIcon onClick={() => props.confirm('Edit Evtr')} />
    } else if (props.editPort) {
      return <CreateIcon onClick={() => props.confirm('Edit Port')} />
    } else if (props.editArrivalDate) {
      return <CreateIcon onClick={() => props.confirm('Edit Arrival Date')} />
    } else {
      return <CreateIcon onClick={() => props.confirm('Edit Species')} />
    }
  }
}

export function EditIcon(props) {
  return (
    <Grid item xs={1}>
      {selectEditIcon(props)}
    </Grid>
  )
}
