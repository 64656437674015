export { SET_PRODUCTS, setProducts } from './products'
export { ADD_NOTIFICATION, addNotification } from './notifications'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const SAVE_USER = 'SAVE_USER'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION'
export const SAVE_ORGANIZATION = 'SAVE_ORGANIZATION'
export const SET_REFERENCE_DATA = 'SET_REFERENCE_DATA'
export const CLEAR_REFERENCE_DATA = 'CLEAR_REFERENCE_DATA'
export const SET_NEW_HAIL = 'SET_NEW_HAIL'
export const RESET_NEW_HAIL = 'RESET_NEW_HAIL'
export const SET_FISHING_AREA = 'SET_FISHING_AREA'
export const SET_PORTS = 'SET_PORTS'
export const SET_SPECIES = 'SET_SPECIES'
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS'
export const SET_FISHING_AREAS = 'SET_FISHING_AREAS'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_DEALERS = 'SET_DEALERS'

//dealer related - start
export const SET_DEALER_STATUS_SUMMARY = 'SET_DEALER_STATUS_SUMMARY'
export const SET_DEALER_INVENTORY_SUMMARY = 'SET_DEALER_INVENTORY_SUMMARY'
export const SET_DEALER_VESSEL_LIST = 'SET_DEALER_VESSEL_LIST'
export const SET_STOCK_AREA = 'SET_STOCK_AREA'
export const SET_COUNTS = 'SET_COUNTS'
export const SET_UNITS = 'SET_UNITS'
export const SET_DEALER_LOCATIONS = 'SET_DEALER_LOCATIONS'
export const SET_DEALER_OFFLOADS = 'SET_DEALER_OFFLOADS'
export const SET_DEALER_ORDERS = 'SET_DEALER_ORDERS'
export const SET_DEALER_NEW_MODIFY_OFFLOADS = 'SET_DEALER_NEW_MODIFY_OFFLOADS'
export const SET_DEALER_NEW_MODIFY_ORDERS = 'SET_DEALER_NEW_MODIFY_ORDERS'
export const SET_DEALER_CUSTOMER_LIST = 'SET_DEALER_CUSTOMER_LIST'
export const SET_DEALER_FREIGHT_TYPE = 'SET_DEALER_FREIGHT_TYPE'
export const SET_INVENTORY_LIST = 'SET_INVENTORY_LIST'
export const SET_FEES = 'SET_FEES'
export const SET_FULFILLMENT_METHODS = 'SET_FULFILLMENT_METHODS'
export const SET_ORDER_SPECIES = 'SET_ORDER_SPECIES'
export const SET_LINE_ITEM = 'SET_LINE_ITEM'
export const SAVE_FEE = 'SAVE_FEE'

//dealer related - end

// currentUser = { id: 1, username: 'me@example.com', jti: '1234' }
export function setCurrentUser(currentUser) {
  return {
    type: SET_CURRENT_USER,
    currentUser,
  }
}

export function clearCurrentUser() {
  return {
    type: CLEAR_CURRENT_USER,
  }
}

export function saveUser(user) {
  return {
    type: SAVE_USER,
    user,
  }
}

export function clearState() {
  return {
    type: CLEAR_STATE,
  }
}

export function setOrganization(current_organization) {
  return {
    type: SET_ORGANIZATION,
    current_organization,
  }
}

export function clearOrganization() {
  return {
    type: CLEAR_ORGANIZATION,
  }
}

export function saveOrganization(organization) {
  return {
    type: SAVE_ORGANIZATION,
    organization,
  }
}

export function setHails(hails) {
  return {
    type: 'SET_HAILS',
    payload: hails,
  }
}

export function setReferenceData(reference_data) {
  return {
    type: SET_REFERENCE_DATA,
    reference_data,
  }
}

export function clearReferenceData() {
  return {
    type: CLEAR_REFERENCE_DATA,
  }
}

export function setNewHail(hail) {
  return {
    type: SET_NEW_HAIL,
    hail,
  }
}

export function resetNewHail() {
  return {
    type: RESET_NEW_HAIL,
  }
}

export function setFishingArea(id, fishing_area) {
  return {
    type: SET_FISHING_AREA,
    payload: { id: id, fishing_area: fishing_area },
  }
}

export function setPorts(ports) {
  return {
    type: SET_PORTS,
    ports,
  }
}

export function setSpecies(species) {
  return {
    type: SET_SPECIES,
    species,
  }
}

export function setUserNotifications(userNotifications) {
  return {
    type: SET_USER_NOTIFICATIONS,
    userNotifications,
  }
}

//DEALER RELATED - START

export function setDealerStatusSummary(dealerStatusSummary) {
  return {
    type: SET_DEALER_STATUS_SUMMARY,
    dealerStatusSummary,
  }
}

export function setDealerInventorySummary(dealerInventorySummary) {
  return {
    type: SET_DEALER_INVENTORY_SUMMARY,
    dealerInventorySummary,
  }
}

export function setDealerVesselList(dealerVesselList) {
  return {
    type: SET_DEALER_VESSEL_LIST,
    dealerVesselList,
  }
}

export function setStockArea(stockArea) {
  return {
    type: SET_STOCK_AREA,
    stockArea,
  }
}

export function setCounts(counts) {
  return {
    type: SET_COUNTS,
    counts,
  }
}

export function setUnits(units) {
  return {
    type: SET_UNITS,
    units,
  }
}

export function setDealerLocations(dealerLocations) {
  return {
    type: SET_DEALER_LOCATIONS,
    dealerLocations,
  }
}

export function setDealerOffloads(dealerOffloads) {
  return {
    type: SET_DEALER_OFFLOADS,
    dealerOffloads,
  }
}

export function setDealerOrders(dealerOrders) {
  return {
    type: SET_DEALER_ORDERS,
    dealerOrders,
  }
}

export function setDealerNewModifyOffloads(dealerNewModifyOffload) {
  return {
    type: SET_DEALER_NEW_MODIFY_OFFLOADS,
    dealerNewModifyOffload,
  }
}

export function setDealerNewModifyOrders(dealerNewModifyOrder) {
  return {
    type: SET_DEALER_NEW_MODIFY_ORDERS,
    dealerNewModifyOrder,
  }
}

export function setDealerCustomerList(dealerCustomerList) {
  return {
    type: SET_DEALER_CUSTOMER_LIST,
    dealerCustomerList,
  }
}

export function setDealerFreightType(dealerFreightType) {
  return {
    type: SET_DEALER_FREIGHT_TYPE,
    dealerFreightType,
  }
}

export function setInventoryList(inventoryList) {
  return {
    type: SET_INVENTORY_LIST,
    inventoryList,
  }
}

export function setFees(fees) {
  return {
    type: SET_FEES,
    fees,
  }
}

export function setFulfillmentMethods(fulfillmentMethods) {
  return {
    type: SET_FULFILLMENT_METHODS,
    fulfillmentMethods,
  }
}

export function setOrderSpecies(orderSpecies) {
  return {
    type: SET_ORDER_SPECIES,
    orderSpecies,
  }
}

export function setLineItem(lineItem) {
  return {
    type: SET_LINE_ITEM,
    lineItem,
  }
}

export function saveFee(fee) {
  return {
    type: SAVE_FEE,
    fee,
  }
}

//DEALER RELATED - END
export function setFishingAreas(fishingAreas) {
  return {
    type: SET_FISHING_AREAS,
    fishingAreas,
  }
}

export function setDealers(dealers) {
  return {
    type: SET_DEALERS,
    dealers,
  }
}
