import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { Grid, CircularProgress } from '@material-ui/core'
import { theme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import '../assets/Trace.css'
import { ApiHelper } from '../lib'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import withWidth from '@material-ui/core/withWidth'
import { VerificationResultParentContainer } from '../components/trace/VerificationResultParent'
import { ButcherBoxContainer } from '../components/trace/butcherbox/ButcherBox'

const styles = () => ({})

export function VerificationResult(props) {
  const [trace, setTrace] = useState({ properties: {} })

  const [loadingState, setLoadingState] = useState('loading')
  const [componentToShow, setComponentToShow] = useState('')

  const [flashMessage, setFlashMessage] = useState(false)

  useEffect(() => {
    setLoadingState('loading')
    const api = new ApiHelper()
    api
      .get(`/traces/${props.match.params.id}`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          setTrace(result)
          if (result.flash_message) {
            setFlashMessage(true)
          }
        } else {
          console.error('Empty trace result')
        }
        setLoadingState('success')
      })
      .catch(error => {
        if (error.error === 'Lot not found') {
          setComponentToShow(error.error)
          console.error('Error:' + error.error)
        } else {
          setComponentToShow('Error retrieving trace')
          console.error('Error:' + error)
        }
        setLoadingState('success')
      })
  }, [props.match.params.id])

  function showResult() {
    if (loadingState === 'loading') {
      return (
        <Grid
          id="loadingComponent"
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          <CircularProgress />
        </Grid>
      )
    } else if (
      trace.component === 'ButcherBoxContainer' ||
      trace.component === 'BigEasyContainer'
    ) {
      return (
        <ButcherBoxContainer
          id="butcherBoxComponent"
          trace={trace}
          componentToShow={componentToShow}
          locationState={props.location.state}
        />
      )
    } else {
      return (
        <VerificationResultParentContainer
          id="verificationResultParentComponent"
          trace={trace}
          loadingState={loadingState}
          setLoadingState={setLoadingState}
          flashMessage={flashMessage}
          componentToShow={componentToShow}
          locationState={props.location.state}
        />
      )
    }
  }

  return <MuiThemeProvider theme={theme}> {showResult()} </MuiThemeProvider>
}

const mapStateToProps = () => {
  return {
    //TODO: related code to be added when functionality is built-ins
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(combineStyles(styles, stylesBase))(withWidth()(VerificationResult))
)
