import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { Grid, Link } from '@material-ui/core'
import { theme } from '../../pages/shared/theme'
import '../../assets/Trace.css'
import { Dialog } from '@material-ui/core'
import { ArrowBack, Close, Lock } from '@material-ui/icons'
import LotNumber from '../../assets/trace/LotNumber.svg'
import MarketName from '../../assets/trace/MarketName.svg'
import Species from '../../assets/trace/Species.svg'
import FishingArea from '../../assets/trace/FishingArea.svg'
import FishingVessel from '../../assets/trace/FishingVessel.svg'
import ProductForm from '../../assets/trace/ProductForm.svg'
import LandedDate from '../../assets/trace/LandedDate.svg'
import LandingPort from '../../assets/trace/LandingPort.svg'
import Sustainability from '../../assets/trace/Sustainability.svg'
import Scallop from '../../assets/trace/Scallop.png'
import Salmon from '../../assets/trace/Salmon.png'
import Lobster from '../../assets/trace/Lobster.png'
import Info from '../../assets/trace/Info.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const styles = () => ({
  verificationResultLink: {
    color: 'blue',
    textDecoration: 'none',
  },

  learnMoreImage: {
    maxWidth: '4vw',
    marginLeft: '2%',
    verticalAlign: 'text-bottom',
    paddingBottom: '1.5%',
    [theme.breakpoints.only('md')]: {
      maxWidth: '3vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '2vw',
    },
  },

  iframe: {
    borderWidth: '1px 0',
    borderColor: 'black',
  },

  text: {
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
    marginTop: '-6%',
    marginBottom: '5%',
    marginLeft: '16%',
    textTransform: 'capitalize',
    [theme.breakpoints.only('md')]: {
      marginLeft: '11%',
      marginTop: '-4%',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-4vh',
      marginBottom: '2%',
      fontSize: '1.2rem',
    },
  },

  headingTextTrace: {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '0.9rem',
    color: '#4F596F',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem',
    },
  },

  headingIcon: {
    width: '15%',
    [theme.breakpoints.only('md')]: {
      width: '10%',
    },
  },

  scallopImageGrid: {
    marginLeft: '-15%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '3vw',
    },
  },

  lobsterLink: {
    marginTop: '3px',
  },
})

export function Default(props) {
  const [iframeHeader, setIframeHeader] = useState('')
  const [iframeUrl, setIframeUrl] = useState('')

  const handleIframeOpen = (name, url) => {
    setIframeHeader(name)
    setIframeUrl(url)
  }

  const handleIframeClose = () => {
    setIframeHeader('')
    setIframeUrl('')
  }

  function learnMore() {
    if (!props.trace.species) {
      return
    }
    if (props.trace.species.toLowerCase().includes('salmon')) {
      return (
        <a
          href="https://www.alaskaseafood.org/rfm-certification/"
          className={props.classes.verificationResultLink}
        >
          {imageElem()}
        </a>
      )
    } else if (props.trace.component === 'NorthernWindLobsterContainer') {
      return (
        <Link
          to="#"
          className={props.classes.verificationResultLink}
          onClick={() =>
            handleIframeOpen(
              'fisheries.msc.org',
              'https://fisheries.msc.org/en/fisheries/bay-of-fundy-scotian-shelf-and-southern-gulf-of-st.-lawrence-lobster-trap/@@view'
            )
          }
        >
          {imageElem()}
        </Link>
      )
    } else if (props.trace.component === 'EastCoastSeafoodLobsterContainer') {
      return (
        <Link
          to="#"
          className={props.classes.verificationResultLink}
          onClick={() =>
            handleIframeOpen(
              'fisheries.msc.org',
              'https://fisheries.msc.org/en/fisheries/gulf-of-maine-lobster-fishery/'
            )
          }
        >
          {imageElem()}
        </Link>
      )
    } else {
      return (
        <Link
          to="#"
          className={props.classes.verificationResultLink}
          onClick={() =>
            handleIframeOpen('fisheries.msc.org', 'https://fisheries.msc.org')
          }
        >
          {imageElem()}
        </Link>
      )
    }
  }

  function showLobsterLink() {
    if (
      props.trace.component === 'NorthernWindLobsterContainer' ||
      props.trace.component === 'EastCoastSeafoodLobsterContainer'
    ) {
      return (
        <Link
          to="#"
          className={props.classes.verificationResultLink}
          onClick={() =>
            window.open(
              'https://fisheryprogress.org/fip-profile/us-gulf-maine-lobster-pottrap'
            )
          }
        >
          FIP: Lobster Sourcing
          <img
            alt="Learn More"
            src={Info}
            className={props.classes.learnMoreImage}
          />
        </Link>
      )
    }
  }

  function imageElem() {
    return (
      <div>
        Learn more
        <img
          alt="Learn More"
          src={Info}
          className={props.classes.learnMoreImage}
        />
      </div>
    )
  }

  function speciesIcon() {
    if (!props.trace.species) {
      return
    }
    if (props.trace.species.toLowerCase().includes('salmon')) {
      return Salmon
    } else if (
      props.trace.component === 'NorthernWindLobsterContainer' ||
      props.trace.component === 'EastCoastSeafoodLobsterContainer'
    ) {
      return Lobster
    } else {
      return Scallop
    }
  }

  function speciesIconWidth() {
    if (!props.trace.species) {
      return
    }
    if (props.trace.species.toLowerCase().includes('salmon')) {
      return '170%'
    } else if (
      props.trace.component === 'NorthernWindLobsterContainer' ||
      props.trace.component === 'EastCoastSeafoodLobsterContainer'
    ) {
      return '70%'
    } else {
      return '100%'
    }
  }

  function showFishingVessel() {
    if (!props.trace.species) {
      return
    }
    if (
      props.trace.species.toLowerCase().includes('salmon') ||
      props.trace.component === 'NorthernWindLobsterContainer' ||
      props.trace.component === 'EastCoastSeafoodLobsterContainer'
    ) {
      return
    } else {
      return (
        <div>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={FishingVessel}
                alt="Fishing Vessel"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="fishing_vessel" />
            </p>
          </div>
          <div className={props.classes.text}>
            {props.trace.fishing_vessel || 'N/A'}
          </div>
        </div>
      )
    }
  }

  function showProductForm() {
    if (!props.trace.species) {
      return
    }
    if (props.trace.species.toLowerCase().includes('salmon')) {
      return (
        <div>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={ProductForm}
                alt="Product Form"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="product_form" />
            </p>
          </div>
          <div className={props.classes.text}>
            {props.trace.product_form || 'N/A'}
          </div>
        </div>
      )
    } else {
      return
    }
  }

  return (
    <div data-testid="DefaultContainer">
      <Grid container spacing={0}>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={LotNumber}
                alt="Lot Number"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="lot_number" />
            </p>
          </div>
          <div id="lotNumber" className={props.classes.text}>
            {props.trace.id}
          </div>
        </Grid>
        <Grid
          container
          item
          xs={3}
          lg={2}
          alignItems="center"
          justify="center"
          className={props.classes.scallopImageGrid}
        >
          <img src={speciesIcon()} alt="Species" width={speciesIconWidth()} />
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={MarketName}
                alt="Market Name"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="market_name" />
            </p>
          </div>
          <div id="marketName" className={props.classes.text}>
            {props.trace.market_name || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Species}
                alt="Species"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="species" />
            </p>
          </div>
          <div id="species" className={props.classes.text}>
            {(props.trace.species || 'N/A').toLowerCase()}
          </div>
        </Grid>
        <Grid item xs={9} lg={7} id="productForm">
          {showProductForm()}
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={LandedDate}
                alt="Landed Date"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="landed_date" />
            </p>
          </div>
          <div id="landedDate" className={props.classes.text}>
            {props.trace.landed_date}
          </div>
        </Grid>
        <Grid item xs={9} lg={7} id="fishingVessel">
          {showFishingVessel()}
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={LandingPort}
                alt="Landing Port"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="landing_port" />
            </p>
          </div>
          <div id="landingPort" className={props.classes.text}>
            {props.trace.landing_port || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={FishingArea}
                alt="Fishng Area"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="fishing_area" />
            </p>
          </div>
          <div id="fishingArea" className={props.classes.text}>
            {props.trace.fishing_area || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7} data-testid="DefaultSustainabilityLink">
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Sustainability}
                alt="Sustainability"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="sustainability" />
            </p>
          </div>
          <div id="sustainability" className={props.classes.text}>
            {learnMore()}
            <div className={props.classes.lobsterLink}>{showLobsterLink()}</div>
          </div>
        </Grid>
      </Grid>
      <Dialog fullScreen open={iframeUrl ? true : false}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ paddingTop: '1vh', paddingBottom: '1vh' }}
        >
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={handleIframeClose}
          >
            <ArrowBack />
          </Grid>
          <Grid xs={8} item container justify="center" alignItems="center">
            <Lock fontSize="small" />
            &nbsp;{iframeHeader}
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={handleIframeClose}
          >
            <Close />
          </Grid>
        </Grid>
        <iframe
          title="Fisheries site"
          className={props.classes.iframe}
          src={iframeUrl}
          width="100%"
          height="100%"
        ></iframe>
      </Dialog>
    </div>
  )
}

export const DefaultContainer = withStyles(styles)(Default)
