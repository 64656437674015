import React from 'react'
import Badge from '@material-ui/core/Badge'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../pages/shared/CombineStyles'
import { stylesBase } from '../pages/shared/StylesBase'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { showNotifications } from '../selectors'

const styles = () => ({})

export function NotificationsBadge(props) {
  return (
    <Badge
      onClick={() => props.myAccount('notification')}
      color="secondary"
      invisible={!props.showNotifications}
      variant="dot"
      data-testid="notificationIcon"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <NotificationsIcon />
    </Badge>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    showNotifications: showNotifications(state.userNotifications),
  }
}

export const NotificationsBadgeContainer = connect(mapStateToProps)(
  withStyles(combineStyles(styles, stylesBase))(NotificationsBadge)
)
