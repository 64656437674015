import { createSlice } from '@reduxjs/toolkit'
import { headers, getApiHost } from '../contexts/AuthContext'

export function findHailById(state, id) {
  return state.hails.find(hail => hail.order_number === id)
}

export function findHailByUUID(state, id) {
  return state.hails.find(hail => hail.uuid === id)
}

export const selectAllHails = state => {
  return state.hails.hails
}

export const selectHailSyncStatus = (state, uuid) => {
  return state.syncStatus[uuid]
}

export function fetchHails() {
  return {
    type: 'hails/fetchHailsLocal',
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: getApiHost() + '/v1/offloads',
          method: 'GET',
          headers: headers(null, false),
          credentials: 'include',
        },
        // action to dispatch when effect succeeds:
        commit: { type: 'hails/setState' },
        // action to dispatch if network action fails permanently:
        //rollback: { type: 'FOLLOW_USER_ROLLBACK', meta: { userId } }
      },
    },
  }
}

export function saveHailEventually(hail, update = true, finalize = false) {
  let offload = {}
  delete Object.assign(offload, hail, {
    ['lots_attributes']: hail['lots'],
  })['lots']

  let effect = {
    url: getApiHost() + '/v1/offloads/',
    method: 'POST',
    json: { offload },
    headers: headers(),
    credentials: 'include',
  }

  if (update && offload.uuid && finalize) {
    effect.url = getApiHost() + '/v1/offloads/' + offload.uuid + '/finalize'
    effect.method = 'PUT'
  } else if (update && offload.uuid) {
    effect.url = getApiHost() + '/v1/offloads/' + offload.uuid + '/sync'
    effect.method = 'PUT'
  }

  return {
    type: 'hails/saveHailOffline',
    payload: hail,
    meta: {
      offline: {
        // the network action to execute:
        effect: effect,
        // action to dispatch when effect succeeds:
        commit: { type: 'hails/commitHail', meta: { offload } },
        // action to dispatch if network action fails permanently:
        rollback: { type: 'hails/saveHailOfflineRollback', meta: { offload } },
      },
    },
  }
}

function create(state, uuid) {
  const newHail = {
    start_time: new Date(),
    arrival_date: new Date(),
    arrival_time: new Date(),
    status: 'draft',
    lots: [],
    species: [],
    port: {},
    uuid: uuid,
    finalized: false,
  }
  return state.hails.push(newHail)
}

function saveHailLocal(state, hail) {
  let current_hail = null
  if (hail['order_number'] || hail.order_number) {
    current_hail = findHailById(state, hail.order_number)
  }

  if (!current_hail) {
    current_hail = findHailByUUID(state, hail.uuid)
  }

  if (current_hail) {
    Object.assign(current_hail, hail)
  } else {
    state.hails.push(hail)
  }
  return state
}

export const hailSlice = createSlice({
  name: 'hails',
  initialState: {
    hails: [],
    status: 'idle',
    error: null,
    syncStatus: {},
  },
  reducers: {
    addHail: (state, action) => {
      create(state, action.payload)
      state.syncStatus[action.payload] = 'waiting_for_sync'
    },
    saveHail: (state, action) => {
      saveHailLocal(state, action.payload)
    },
    saveOffline: (state, action) => {
      saveHailLocal(state, action.payload)
      state.syncStatus[action.payload.uuid] = 'waiting_for_sync'
    },
    commitHail: (state, action) => {
      saveHailLocal(state, action.payload)
      state.syncStatus[action.payload.uuid] = 'synchronized'
    },
    setState: (state, action) => {
      state.hails = action.payload.offloads
      state.status = 'succeeded'
    },
    fetchHailsLocal: state => {
      return state
    },
    saveHailOfflineRollback: (state, action) => {
      state.syncStatus[action.payload.uuid] = 'error'
    },
  },
})

export const {
  addHail,
  saveHail,
  saveHailOffline,
  commitHail,
  setState,
  fetchHailsLocal,
  saveHailOfflineRollback,
} = hailSlice.actions

export const hailSliceReducer = hailSlice.reducer
