import React, { useState, useEffect } from 'react'
import {
  setDealerVesselList,
  setUnits,
  setDealerNewModifyOffloads,
} from '../../actions'
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  createMuiTheme,
  Divider,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import { ApiHelper } from '../../lib'
import { SpeciesInfoComponentContainer } from './SpeciesInfoComponent'
import ConfirmationStatus from './ConfirmationStatus'
import { Trans, useTranslation } from 'react-i18next'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { GetApp, Print } from '@material-ui/icons'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import withDealerTheme from '../shared/DealerStyles'
import BootstrapInput from './TextfieldForDealer'
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'

const themes = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '900px',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: 'white !important',
        padding: '0px !important',
        color: 'black',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#4D4D4D',
        '&$focused': {
          color: '#4D4D4D',
        },
        '&$disabled': {
          color: '#B3B3B3',
        },
        '&$error': {
          color: '#B80000',
        },
        '&$filled': {
          color: '#4D4D4D',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0px',
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '0px 0px !important',
      },
      option: {
        fontSize: '0.75rem',
        textTransform: 'none',
      },
      inputRoot: {
        height: '2rem',
        borderRadius: '0px',
        backgroundColor: '#F6F9FA',
        fontSize: '0.75rem',
        '&$focused': {
          borderColor: '#E0E0E0',
        },
      },
    },
    MuiInput: {
      underline: {
        borderBottom: '1px solid #E4E7EA',
        '&&&&:before': {
          borderBottom: '0px solid #E4E7EA',
        },
        '&&&&:after': {
          borderBottom: '0px solid #E4E7EA',
        },
      },
    },
  },
})

export function AddNewOffload(props) {
  const classes = withDealerTheme()
  const [data, setData] = useState({
    isDisplayAddForm: true,
    selectedFormAction: '',
    speciesInitialCounter: 2,
    orderNumber: '',
    confirmationMsg: '',
    errorMsg: '',
    isLoading: false,
    portSearchResult: [],
    vesselsSearchResult: [],
  })

  let speciesInfoArr = new Array()
  let speciesArray = 'lots_attributes'

  let isSubmitDisabled = false

  const { t } = useTranslation()
  const errorCheckArray = [
    'vessel_id',
    'permit_number',
    'port_id',
    'vtr_number',
  ]
  const dispatch = useDispatch()
  const ports = useSelector(state => state.ports)
  const dealerVesselList = useSelector(state => state.dealerVesselList)
  const dealerNewModifyOffload = useSelector(
    state => state.dealerNewModifyOffload
  )
  if (
    dealerNewModifyOffload.offload &&
    dealerNewModifyOffload.offload !== '' &&
    dealerNewModifyOffload.offload.status === 'landed'
  ) {
    isSubmitDisabled = true
  }
  useEffect(() => {
    const api = new ApiHelper()

    // let speciesApi = api.get(`species`).then(response => response.json())
    let unitsApi = api.get(`units`).then(response => response.json())
    let vesselApi = api.get(`landing_vessel`).then(response => response.json())

    setData({ ...data, isLoading: true })
    Promise.all([unitsApi, vesselApi])
      .then(([unitsResult, vesselResult]) => {
        dispatch(setUnits(unitsResult))
        dispatch(setDealerVesselList(vesselResult))
      })
      .then(() => {
        if (props.offloadId !== '') {
          setData({
            ...data,
            orderNumber: dealerNewModifyOffload.offload.order_number,
          })
        }
        setData({
          ...data,
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        setData({
          ...data,
          errorMsg: error,
          isLoading: false,
        })
      })

    //New Offload addition scenario
    if (props.offloadId === '') {
      if (dealerNewModifyOffload) {
        createSpeciesInfoArray()
      }
    }
  }, [])

  function createSpeciesInfoArray() {
    let dealerNewModifyOffload
    dealerNewModifyOffload = { ...dealerNewModifyOffload }
    dealerNewModifyOffload = {
      offload: {
        arrival_date: new Date(),
      },
    }

    let len = 0
    if (dealerNewModifyOffload.offload[speciesArray]) {
      speciesInfoArr = dealerNewModifyOffload.offload[speciesArray]
      len = speciesInfoArr.length
      if (len >= data.speciesInitialCounter) {
        //return
      }
    }

    while (len <= data.speciesInitialCounter) {
      let speciesHash = {
        species_id: '',
        quantity: '',
        stat_area_id: '',
        market_description_name: '',
      }
      speciesInfoArr.push(speciesHash)
      len = speciesInfoArr.length
    }
    dealerNewModifyOffload.offload[speciesArray] = speciesInfoArr

    dispatch(setDealerNewModifyOffloads(dealerNewModifyOffload))
  }

  const handleChange = event => {
    let OffloadToModify = { ...dealerNewModifyOffload }
    OffloadToModify.offload[event.target.name] = event.target.value
    dispatch(setDealerNewModifyOffloads(OffloadToModify))
  }

  const dateChange = date => {
    let OffloadToModify = { ...dealerNewModifyOffload }
    OffloadToModify.offload.arrival_date = date
    dispatch(setDealerNewModifyOffloads(OffloadToModify))
  }

  const handleShowFormDisplay = () => {
    setData({ ...data, isDisplayAddForm: true })
  }

  const errorCheck = () => {
    {
      errorCheckArray.map(function(name) {
        if (
          name in dealerNewModifyOffload.offload &&
          dealerNewModifyOffload.offload[name] === ''
        ) {
          // setError(true)
        }
      })
    }
  }

  const manipulateApiData = () => {
    let OffloadToModify = { ...dealerNewModifyOffload }
    OffloadToModify.offload.arrival_time = OffloadToModify.offload.arrival_date
    let spliceArray = OffloadToModify.offload.lots_attributes
    let modifiedArray = spliceArray.filter(
      value => value['species_id'].length !== 0
    )
    OffloadToModify.offload.lots_attributes = modifiedArray
    return OffloadToModify
  }

  const handleFinishCancel = (event, actionType, iconName) => {
    event.preventDefault()

    if (actionType === 'cancel') {
      setData({
        ...data,
        selectedFormAction: actionType,
        isDisplayAddForm: false,
      })
      return
    }

    const api = new ApiHelper()
    errorCheck()
    //New Offload addition
    let OffloadToModify = manipulateApiData()
    if (props.offloadId === '') {
      return api
        .post('/offloads', OffloadToModify)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            setData({
              ...data,
              orderNumber: result.order_number,
              selectedFormAction: actionType,
              confirmationMsg: 'added_to_offload_list',
              isDisplayAddForm: false,
            })
            dispatch(setDealerNewModifyOffloads({}))
          } else {
            setData({ ...data, confirmationMsg: 'offload_status_msg' })
          }
        })
        .catch(error => {
          console.error(error)
          setData({ ...data, errorMsg: error.errors })
        })
    }

    //Case of modifiying existing offload
    onAcceptLandOffload(OffloadToModify, iconName)
  }

  function onAcceptLandOffload(OffloadToModify, iconName) {
    let orderNumber = dealerNewModifyOffload.offload.order_number
    let currentStatus = dealerNewModifyOffload.offload.status
    let apiInput = {
      offload: {
        arrival_date: OffloadToModify.offload.arrival_date,
        arrival_time: OffloadToModify.offload.arrival_date,
        lots_attributes: OffloadToModify.offload.lots_attributes,
        port_id: OffloadToModify.offload.port_id,
        vessel_name: OffloadToModify.offload.vessel_name,
        order_number: OffloadToModify.offload.order_number,
        owner_id: OffloadToModify.offload.owner_id,
        fishing_area_id: OffloadToModify.offload.fishing_area_id,
        vtr_number: OffloadToModify.offload.vtr_number,
        permit_number: OffloadToModify.offload.permit_number,
        dealer_rpt_id: OffloadToModify.offload.dealer_rpt_id,
      },
    }

    const api = new ApiHelper()
    let actionType
    if (currentStatus === 'submitted') {
      actionType = 'accept'
    } else if (currentStatus === 'accepted') {
      actionType = 'land'
    } else if (currentStatus === 'landed') {
      actionType = 'finalize'
    }
    if (actionType === 'finalize') {
      setData({
        ...data,
        orderNumber: orderNumber,
        confirmationMsg: 'markedas' + actionType,
        selectedFormAction: 'submit',
        isDisplayAddForm: false,
      })
      dispatch(setDealerNewModifyOffloads({}))
      return
    }

    let apiUrl
    let confirmationMsg
    if (iconName === 'acceptIcon' || iconName === 'landingIcon') {
      apiUrl = '/offloads/' + orderNumber + '/' + actionType
      confirmationMsg = 'markedas' + actionType
    } else {
      apiUrl = '/offloads/' + orderNumber
      confirmationMsg = 'successfully_updated'
    }

    api
      .put(apiUrl, apiInput)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          setData({
            ...data,
            orderNumber: orderNumber,
            confirmationMsg: confirmationMsg,
            selectedFormAction: 'submit',
            isDisplayAddForm: false,
          })
          dispatch(setDealerNewModifyOffloads({}))
        }
      })
  }

  const handleAddAnotherItem = evt => {
    evt.preventDefault()
    let speciesHash = {
      species_id: '',
      quantity: '',
      stat_area_id: '',
      market_description_name: '',
    }
    let dealerNewModifyOffloads
    dealerNewModifyOffloads = { ...dealerNewModifyOffload }
    dealerNewModifyOffloads.offload[speciesArray].push(speciesHash)
    dispatch(setDealerNewModifyOffloads(dealerNewModifyOffloads))
  }

  let offloadHeading
  props.offloadId === ''
    ? (offloadHeading = 'add_a_new_offload')
    : (offloadHeading = 'edit_offload')

  let submitButtonName = 'submit'
  let statusBasedIcon
  if (props.offloadId === '') {
    submitButtonName = 'add'
  } else if (
    dealerNewModifyOffload.offload &&
    dealerNewModifyOffload.offload.status === 'submitted'
  ) {
    submitButtonName = 'save'
    statusBasedIcon = (
      <React.Fragment>
        <DoneAllIcon
          fontSize="small"
          onClick={event => handleFinishCancel(event, 'submit', 'acceptIcon')}
          className={classes.iconColor}
        />
      </React.Fragment>
    )
  } else if (
    dealerNewModifyOffload.offload &&
    dealerNewModifyOffload.offload.status === 'accepted'
  ) {
    submitButtonName = 'save'
    statusBasedIcon = (
      <React.Fragment>
        <TransitEnterexitIcon
          fontSize="small"
          onClick={event => handleFinishCancel(event, 'submit', 'landingIcon')}
          className={classes.iconColor}
        />
      </React.Fragment>
    )
  } else {
    submitButtonName = 'save'
  }

  const vesselSearch = event => {
    let searchKey = event.target.value
    let vesselName = 'vessel_name'
    if (dealerNewModifyOffload.offload) {
      let orderToModify
      orderToModify = { ...dealerNewModifyOffload }
      orderToModify.offload[vesselName] = searchKey
      dispatch(setDealerNewModifyOffloads(orderToModify))
    }
    if (searchKey && searchKey.length >= 3) {
      const filtered = dealerVesselList.vessels.filter(entry =>
        Object.values(entry).some(
          val =>
            typeof val === 'string' &&
            val.toLowerCase().startsWith(searchKey.toLowerCase())
        )
      )
      setData({ ...data, vesselsSearchResult: filtered })
    } else {
      setData({ ...data, vesselsSearchResult: [] })
    }
  }

  const handleVesselsSelection = (value, name) => {
    if (dealerNewModifyOffload.offload) {
      let orderToModify
      orderToModify = { ...dealerNewModifyOffload }
      orderToModify.offload[name] = value
      dispatch(setDealerNewModifyOffloads(orderToModify))
    }
  }

  const TextFieldComponent = props => {
    return (
      <React.Fragment>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          className={classes.labelText}
        >
          <Trans i18nKey="date" />
        </InputLabel>
        <TextField {...props} size="small" />
      </React.Fragment>
    )
  }

  const portSearch = event => {
    let searchKey = event.target.value
    if (searchKey && searchKey.length >= 3) {
      const filtered = ports.filter(entry =>
        Object.values(entry).some(
          val =>
            typeof val === 'string' &&
            val.toLowerCase().startsWith(searchKey.toLowerCase())
        )
      )
      setData({ ...data, portSearchResult: filtered })
    } else {
      setData({ ...data, portSearchResult: [] })
    }
  }

  const handlePortSelection = (value, name) => {
    let portHash = ports.find(o => o.name === value)
    let selectedPortId = ''
    if (portHash) {
      selectedPortId = portHash.id
    }
    if (dealerNewModifyOffload.offload) {
      let orderToModify = { ...dealerNewModifyOffload }
      orderToModify.offload[name] = selectedPortId
      dispatch(setDealerNewModifyOffloads(orderToModify))
    }
  }

  const portValue = id => {
    let species
    if (id) {
      species = ports.find(object => object.id === id).name
    } else {
      species = ''
    }
    return species
  }

  return (
    <div key={data.isDisplayAddForm}>
      {data.isDisplayAddForm ? (
        <div>
          <MuiThemeProvider theme={themes}>
            <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="simple-dialog-title"
              scroll="body"
              fullWidth={true}
              className={classes.dialogPaper}
            >
              <DialogTitle id="form-dialog-title">
                <Grid container className={classes.titleGridContainer}>
                  <Grid container item xs={11}>
                    {props.offloadId === '' ? (
                      <React.Fragment>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.titleText}>
                            <Trans i18nKey={offloadHeading} />
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={1} />

                        <Grid container item md={2} xs={5} alignItems="center">
                          <Typography className={classes.lightText}>
                            <Trans i18nKey="dealer_rpt_id" />
                          </Typography>
                        </Grid>

                        <Grid item md={4} xs={7}>
                          <Grid container alignItems="flex-start">
                            <TextField
                              placeholder={t(
                                'dealer_rpt_id',
                                'Dealer Report Id'
                              )}
                              fullWidth
                              value={
                                dealerNewModifyOffload.offload
                                  ? dealerNewModifyOffload.offload.dealer_rpt_id
                                  : ''
                              }
                              name="dealer_rpt_id"
                              id="dealer_rpt_id"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid container item md={4} xs={12} alignItems="center">
                          <Typography className={classes.headerText}>
                            <Trans i18nKey="offloading_id" />:{''}
                            {props.offloadId ? props.offloadId : null}
                          </Typography>
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={2} xs={5} container alignItems="center">
                          <Typography className={classes.lightText}>
                            <Trans i18nKey="dealer_rpt_id" />
                          </Typography>
                        </Grid>
                        <Grid item md={2} xs={7} container alignItems="center">
                          <TextField
                            placeholder={t('dealer_rpt_id', 'Dealer Report Id')}
                            fullWidth
                            value={
                              dealerNewModifyOffload.offload
                                ? dealerNewModifyOffload.offload.dealer_rpt_id
                                : ''
                            }
                            name="dealer_rpt_id"
                            id="dealer_rpt_id"
                            onChange={handleChange}
                          />
                        </Grid>
                        {/*<Grid item md={1} xs={12} />*/}
                        <Grid item md={3} xs={12}>
                          <Grid
                            container
                            item
                            md={12}
                            xs={12}
                            alignItems="center"
                          >
                            <Grid item md={3} xs={3} />
                            <Grid item md={3} xs={3}>
                              <GetApp
                                fontSize="small"
                                className={classes.iconColor}
                              />
                            </Grid>
                            <Grid item md={3} xs={3}>
                              <Print
                                fontSize="small"
                                className={classes.iconColor}
                              />
                            </Grid>
                            <Grid item md={3} xs={3}>
                              {statusBasedIcon}
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>

                  <Grid container item xs={1} justify="flex-end">
                    <Grid
                      container
                      item
                      xs={1}
                      alignItems="flex-start"
                      justify="flex-end"
                    >
                      <Cancel
                        className={classes.cancelIcon}
                        fontSize="small"
                        onClick={event =>
                          handleFinishCancel(event, 'cancel', '')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogTitle>

              <Divider />

              <DialogContent className={classes.dialogContent}>
                {data.isLoading ? (
                  <Grid container className={classes.loaderGrid}>
                    {' '}
                    <LoadingIndicator />{' '}
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    {/** Fishing Info */}
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography className={classes.lightText}>
                        <Trans i18nKey="fishing_info" />
                      </Typography>
                    </Grid>
                    <Grid item md={2} />
                    <Grid container item md={10}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        className={classes.gridFirstRow}
                      >
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel
                            shrink
                            id="vessel_name"
                            className={classes.labelText}
                          >
                            <Trans i18nKey="vessel_name" />
                          </InputLabel>
                          <Autocomplete
                            freeSolo
                            options={data.vesselsSearchResult.map(
                              option => option.name
                            )}
                            onChange={(event, value) =>
                              handleVesselsSelection(value, 'vessel_name')
                            }
                            value={
                              dealerNewModifyOffload.offload &&
                              dealerNewModifyOffload.offload.vessel_name
                                ? dealerNewModifyOffload.offload.vessel_name
                                : ''
                            }
                            className={classes.autoComplete}
                            renderInput={params => (
                              <TextField
                                onChange={searchKey => vesselSearch(searchKey)}
                                {...params}
                                margin="normal"
                                variant="outlined"
                                placeholder={t(
                                  'fishing_vessel',
                                  'Fishing Vessel'
                                )}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={1} />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        className={classes.gridFirstRow}
                      >
                        <FormControl className={classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={classes.labelText}
                          >
                            <Trans i18nKey="vessel_permit" />
                          </InputLabel>
                          <BootstrapInput
                            value={
                              dealerNewModifyOffload.offload
                                ? dealerNewModifyOffload.offload.permit_number
                                : ''
                            }
                            name="permit_number"
                            id="permit_number"
                            onChange={handleChange}
                            placeholder={t(
                              'enter_permit_number',
                              'Enter Permit Number'
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={5} />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {/** Landed Info */}
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography className={classes.lightText}>
                        <Trans i18nKey="landed_info" />
                      </Typography>
                    </Grid>
                    <Grid item md={2} />
                    <Grid container item md={10}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        className={classes.gridFirstRow}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            id="date"
                            format="MM/dd/yyyy"
                            value={
                              dealerNewModifyOffload.offload
                                ? dealerNewModifyOffload.offload.arrival_date
                                : null
                            }
                            autoOk={true}
                            onChange={date => dateChange(date)}
                            inputVariant="outlined"
                            TextFieldComponent={TextFieldComponent}
                            keyboardIcon={
                              <CalendarTodayIcon fontSize="small" />
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{ className: classes.dateFieldClass }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item md={1} />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        className={
                          dealerNewModifyOffload.offload &&
                          dealerNewModifyOffload.offload.status === 'accepted'
                            ? ''
                            : classes.gridFirstRow
                        }
                      >
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel
                            shrink
                            id="port"
                            className={classes.labelText}
                          >
                            <Trans i18nKey="port" />
                          </InputLabel>
                          <Autocomplete
                            freeSolo
                            options={data.portSearchResult.map(
                              option => option.name
                            )}
                            onChange={(event, value) =>
                              handlePortSelection(value, 'port_id')
                            }
                            value={portValue(
                              dealerNewModifyOffload.offload &&
                                dealerNewModifyOffload.offload.port_id &&
                                dealerNewModifyOffload.offload.port_id
                            )}
                            className={classes.autoComplete}
                            renderInput={params => (
                              <TextField
                                onChange={searchKey => portSearch(searchKey)}
                                {...params}
                                margin="normal"
                                variant="outlined"
                                placeholder={t(
                                  'select_port',
                                  'Select the Port'
                                )}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={1} />
                      <Grid item xs={12} md={3}>
                        <FormControl className={classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={classes.labelText}
                          >
                            <Trans i18nKey="vtr_number" />
                          </InputLabel>
                          <BootstrapInput
                            value={
                              dealerNewModifyOffload.offload
                                ? dealerNewModifyOffload.offload.vtr_number
                                : ''
                            }
                            name="vtr_number"
                            onChange={handleChange}
                            id="vtr"
                            placeholder={t(
                              'enter_vtr_number',
                              'Enter VTR Number'
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    {/** Species Info */}

                    <Grid item xs={12} sm={12} md={12}>
                      <Typography className={classes.lightText}>
                        <Trans i18nKey="species_info" />
                      </Typography>
                    </Grid>

                    {dealerNewModifyOffload.offload &&
                    dealerNewModifyOffload.offload[speciesArray] ? (
                      dealerNewModifyOffload.offload[speciesArray].map(function(
                        speciesInfo,
                        i
                      ) {
                        if (speciesInfo._destroy) {
                          return
                        }
                        return (
                          /** Species infor array Start*/

                          <SpeciesInfoComponentContainer key={i} index={i} />

                          /** Species info array end */
                        )
                      })
                    ) : (
                      <div />
                    )}

                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="flex-end"
                      justify="center"
                      className={classes.gridFirstRow}
                    >
                      <Button
                        className={classes.addAnotherItem}
                        size="small"
                        onClick={event => handleAddAnotherItem(event)}
                      >
                        <Trans i18nKey="add_another_item" />
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={10} />
                  </Grid>
                )}
                {data.errorMsg ? (
                  <ErrorMessageComponent errorMessage={data.errorMsg} />
                ) : null}
              </DialogContent>

              <DialogActions>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.gridFirstRow}
                  alignItems="flex-start"
                >
                  <Grid container item xs={5} md={8} />
                  <Grid container item xs={7} md={3} justify="space-around">
                    <Button
                      size="small"
                      onClick={event => handleFinishCancel(event, 'cancel', '')}
                      classes={{ label: classes.cancelButtonText }}
                      disabled={isSubmitDisabled}
                    >
                      <Trans i18nKey="cancel" />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={event =>
                        handleFinishCancel(event, 'submit', 'submitButton')
                      }
                      size="small"
                      className={classes.finishButton}
                      classes={{ label: classes.buttonText }}
                      disabled={isSubmitDisabled}
                    >
                      <Trans i18nKey={submitButtonName} />
                    </Button>
                  </Grid>
                  <Grid container item xs={1} md={1} />
                </Grid>
              </DialogActions>
            </Dialog>
          </MuiThemeProvider>
        </div>
      ) : (
        <ConfirmationStatus
          open={true}
          status={data.selectedFormAction}
          handleBack={props.handleClose}
          handleCancel={handleShowFormDisplay}
          itemNumber={data.orderNumber}
          itemName="offload"
          message={data.confirmationMsg}
          backToOffloading={props.backToOffloading}
        />
      )}
    </div>
  )
}

export const AddNewOffloadContainer = AddNewOffload
