import React, { useState, useEffect } from 'react'
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  withStyles,
  MuiThemeProvider,
  Dialog,
  List,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { theme } from './shared/theme'
import { HailItemContainer } from '../components/hail/HailItem'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import { NoReportsContainer } from '../components/hail/NoReports'
import { NotificationsBadgeContainer } from '../components/NotificationsBadge'
import { v4 as uuidv4 } from 'uuid'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import { ApiHelper } from '../lib'
import {
  setUserNotifications,
  setFishingAreas,
  setDealers,
  setSpecies,
  setPorts,
  setCounts,
} from '../actions'
import { addHail, fetchHails, selectAllHails } from '../features/hailSlice'
import { useHistory } from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const styles = () => ({
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
    width: '104%',
    marginLeft: '-2%',
  },

  gridContainer: {
    paddingLeft: '5vw',
    paddingRight: '5vw',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '15vw',
      paddingRight: '15vw',
    },
  },
  gridItem: {
    marginTop: '2vh',
  },
  container: {
    marginTop: '-5vh',
    paddingLeft: '0px',
    paddingRight: '0px',
    color: '#666666',
    height: '80vh',
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      marginTop: '-4vh',
      paddingLeft: '10vw',
      paddingRight: '10vw',
    },
  },
  submittedReport: {
    paddingLeft: '9vw',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '4vw',
    },
  },
  draftReport: {
    paddingLeft: '9vw',
    color: 'white',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '4vw',
    },
  },
  vesselName: {
    textTransform: 'uppercase',
  },
  plusIcon: {
    fontSize: '7.5vh',
    color: '#003e6d',
    position: 'fixed',
    bottom: '2vh',
    right: '5vw',
    height: '10vh',
  },
})

export function HailApp(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const hails = useSelector(selectAllHails)
  const organization = useSelector(state => state.organization)

  const [loadingState] = useState('')
  const [errorMsg] = useState('')

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  const addNewHail = () => {
    const uuid = uuidv4()
    dispatch(addHail(uuid))
    const addNewHailLocation = {
      pathname: '/vessel/' + uuid,
      state: { from: 'vessel', to: 'new_hail' },
    }
    history.push(addNewHailLocation)
  }

  const myAccount = val => {
    const myAccountLocation = {
      pathname: '/vessel/account',
      state: { from: val },
    }
    history.push(myAccountLocation)
  }

  useEffect(() => {
    const api = new ApiHelper()

    api
      .get('/notifications', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setUserNotifications(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  useEffect(() => {
    const api = new ApiHelper()

    api
      .get('/fishing_areas', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setFishingAreas(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get('/dealers', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setDealers(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get('/species', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setSpecies(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get('/market_descriptions', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setCounts(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get('/ports', {})
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        dispatch(setPorts(result))
      })
      .catch(error => {
        console.error('Error:' + error)
      })
  }, [])

  useEffect(() => {
    dispatch(fetchHails())
  }, [])

  let draftReports = hails.filter(hail => {
    return hail.status === 'draft' || !hail.order_number
  })
  let submittedReports = hails.filter(
    hail => hail.status === 'submitted' || hail.status === 'accepted'
  )
  let landedReports = hails.filter(hail => hail.status === 'landed')

  function showDraftReport() {
    if (draftReports.length !== 0) {
      return (
        <div>
          <Typography
            component="span"
            variant="body2"
            className={props.classes.draftReport}
          >
            <Trans i18nKey="draft_reports" />
          </Typography>
          <List className={props.classes.list}>
            {draftReports.map(hail => {
              return <HailItemContainer key={hail.id} hail={hail} />
            })}
          </List>
        </div>
      )
    } else {
      return null
    }
  }

  function showSubmittedReport() {
    if (submittedReports.length !== 0) {
      return (
        <div>
          <Typography
            component="span"
            variant="body2"
            className={
              draftReports.length !== 0
                ? props.classes.submittedReport
                : props.classes.draftReport
            }
          >
            <Trans i18nKey="submitted_reports" />
          </Typography>
          <List className={props.classes.list}>
            {submittedReports.map(hail => {
              return <HailItemContainer key={hail.id} hail={hail} />
            })}
          </List>
        </div>
      )
    } else {
      return null
    }
  }

  function showLandedReport() {
    if (landedReports.length !== 0) {
      return (
        <div>
          <Typography
            component="span"
            variant="body2"
            className={
              draftReports.length !== 0 || submittedReports.length !== 0
                ? props.classes.submittedReport
                : props.classes.draftReport
            }
          >
            <Trans i18nKey="landed_reports" />
          </Typography>
          <List className={props.classes.list}>
            {landedReports.map(hail => {
              return <HailItemContainer key={hail.id} hail={hail} />
            })}
          </List>
        </div>
      )
    } else {
      return null
    }
  }

  function showReports() {
    if (loadingState === 'loading') {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '70vh' }}
        >
          <CircularProgress />
        </Grid>
      )
    } else if (!hails || hails.length === 0) {
      return (
        <div>
          <NoReportsContainer />
          <AddCircleIcon
            className={props.classes.plusIcon}
            onClick={() => addNewHail()}
          />
        </div>
      )
    } else {
      return (
        <Container
          width="100%"
          className={props.classes.container}
          id="reports"
          data-testid="reports"
        >
          {showDraftReport()}
          {showSubmittedReport()}
          {showLandedReport()}
          <AddCircleIcon
            className={props.classes.plusIcon}
            onClick={() => addNewHail()}
          />
        </Container>
      )
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container className={props.classes.backgroundImage}>
        <Container width="100%" className={props.classes.topImage}>
          <Grid container spacing={0} className={props.classes.gridContainer}>
            <Grid item xs={11}>
              <PermIdentityIcon
                onClick={() => myAccount('user')}
                data-testid="userIcon"
              />
            </Grid>
            <Grid item xs={1}>
              <NotificationsBadgeContainer myAccount={myAccount} />
            </Grid>
            <Grid item xs={12} className={props.classes.gridItem}>
              <Typography>
                <Trans i18nKey="welcome_back" />,
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={props.classes.vesselName}>
                {organization.name}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        {showReports()}
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
        >
          <ErrorMessageComponent errorMessage={errorMsg} />
        </Dialog>
      </Container>
    </MuiThemeProvider>
  )
}

export default withStyles(combineStyles(styles, stylesBase))(HailApp)
