// configureStore.js
import { createStore, applyMiddleware } from 'redux'
//import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import rootReducer from './reducers'
import { createOffline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index'
import thunk from 'redux-thunk'

export const persistConfig = {
  key: 'root',
  storage,
}

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
})

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(rootReducer)
)
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    offlineEnhanceStore,
    applyMiddleware(thunk, offlineMiddleware)
  )
)

/*export const store = configureStore({
  reducer: persistedReducer,
  middleware: composeWithDevTools(
    offlineEnhanceStore,
    applyMiddleware(thunk, offlineMiddleware)
  )
})*/

export const persistor = persistStore(store)

export default () => {
  return { store, persistor }
}
