import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { Container, Link } from '@material-ui/core'
import Compass from '../../assets/trace/Compass.gif'
import { theme } from '../../pages/shared/theme'

const styles = () => ({
  notFound: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '30vh',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
      margin: 'auto',
    },
  },

  compass: {
    width: '18%',
  },

  lotNotFoundHeading: {
    fontWeight: 500,
    fontSize: '1.05rem',
    color: '#4F596F',
  },

  lotNotFoundSearch: {
    fontSize: '1.05rem',
    color: '#4F59B6',
    textTransform: 'uppercase',
  },

  lotNotFoundText: {
    fontWeight: 300,
    fontSize: '0.9rem',
    color: '#4F596F',
  },
})

export function LotNotFound(props) {
  function showBacktoSearch(showBackButton) {
    if (!showBackButton) {
      return null
    }
    return (
      <Typography className={props.classes.lotNotFoundSearch}>
        <Link href="#">
          <Trans i18nKey="back_to_search" />
        </Link>
      </Typography>
    )
  }

  return (
    <Container className={props.classes.notFound} id="lotNotFound">
      <img alt="Compass" className={props.classes.compass} src={Compass} />
      <h3>
        <Typography className={props.classes.lotNotFoundHeading}>
          <Trans i18nKey="lot_not_found" />
        </Typography>
      </h3>
      <Typography className={props.classes.lotNotFoundText}>
        <Trans i18nKey="cant_find_trace" />
      </Typography>
      <Typography className={props.classes.lotNotFoundText}>
        <Trans i18nKey="associated_with_lot" />.
      </Typography>
      <br />
      <Typography className={props.classes.lotNotFoundText}>
        <Trans i18nKey="missed_search_reported" />
      </Typography>
      <div onClick={props.backButton}>
        <h3>{showBacktoSearch(props.showBackButton)}</h3>
      </div>
    </Container>
  )
}

export const LotNotFoundContainer = withStyles(styles)(LotNotFound)
