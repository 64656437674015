import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { useDispatch, useSelector } from 'react-redux'
import { TableView } from '../../components/TableView'
import { CustomerFormContainer } from './CustomerForm'
import { ApiHelper } from '../../lib'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { saveCustomer, setDealerCustomers } from '../../features/dealerSlice'

export function DealerCustomers(props) {
  const headCells = [
    { id: 'name', label: 'company', sort: 'name' },
    {
      id: 'full_name',
      label: 'contact_name',
      fetchFromParent: 'full_name',
    },
    {
      id: 'address',
      label: 'address',
      fetchFromParent: 'address',
    },
    { id: 'phone', label: 'phone', fetchFromParent: 'phone' },
    { id: 'email', label: 'email', fetchFromParent: 'email' },
  ]

  const dataURL = 'processors'
  const [open, setOpen] = useState(false)

  const [startIndex, setstartIndex] = useState(1)
  const [displayDataCount, setDisplayDataCount] = useState(0)
  const initalLimitPerPage = 10
  const [customerId, setCustomerId] = useState('')

  const initialFilterDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [errorMsg, setErrorMsg] = React.useState('')

  const [paramState, setParamState] = React.useState(tableParameters)
  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const dealerCustomers = useSelector(state => state.dealer.dealerCustomers)

  function fetchDisplayData() {
    const api = new ApiHelper()

    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let customersUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      customersUrl = customersUrl + '&q=' + paramState.searchKey
    }
    setIsLoading(true)
    api
      .get(customersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerCustomers(result.processors))
          setstartIndex(startIndexVal)
          setDisplayDataCount(result.pagination.count)
          setErrorMsg('')
        } else {
          setErrorMsg('Error occured while retrieving Inventory Summary')
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [paramState, dataURL])

  const handleAddNewCustomer = () => {
    setCustomerId('')
    setOpen(true)
  }

  const handleClose = () => {
    dispatch(
      saveCustomer({
        name: '',
        federal_permit_number: '',
        state_permit_number: '',
        addresses: [
          {
            address1: '',
            address2: '',
            state: '',
            zipcode: '',
          },
        ],
        contacts: [
          {
            full_name: '',
            mobile_number: '',
            fax: '',
            email: '',
          },
        ],
      })
    )
    setOpen(false)
  }

  const handleEdit = customerId => {
    let index = dealerCustomers.findIndex(obj => obj.id == customerId)
    let selectedCustomer = {}
    selectedCustomer = { ...dealerCustomers[index] }
    dispatch(saveCustomer(selectedCustomer))
    setCustomerId(customerId)
    setOpen(true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setstartIndex(1)
    }
    setParamState(paramHash)
  }

  function backToCustomer(id) {
    if (id !== '') {
      fetchDisplayData()
    }
    handleClose()
  }

  const sortOptions = [{ id: 'name', label: 'Company' }]

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  function onFetchFromParent(name, key) {
    let dealerCustomer = dealerCustomers.find(obj => obj.id == key)
    let customerContact = dealerCustomer.contacts[0]
    let customerAddress = dealerCustomer.addresses[0]
    if (name === 'full_name' && customerContact) {
      return dealerCustomer.contacts[0].full_name
    }
    if (name === 'address' && customerAddress) {
      return (
        dealerCustomer.addresses[0].address1 +
        ', ' +
        dealerCustomer.addresses[0].address2 +
        ', ' +
        dealerCustomer.addresses[0].state +
        ' ' +
        dealerCustomer.addresses[0].zipcode
      )
    }
    if (name === 'phone' && customerContact) {
      return dealerCustomer.contacts[0].mobile_number
    }
    if (name === 'email' && customerContact) {
      return dealerCustomer.contacts[0].email
    }
  }

  return (
    <div>
      <div>
        <TableView
          headCells={headCells}
          displayData={dealerCustomers}
          startIndex={startIndex}
          paramState={paramState}
          loadData={loadData}
          onHandleaddNew={handleAddNewCustomer}
          displayDataCount={displayDataCount}
          initalLimitPerPage={initalLimitPerPage}
          handleEdit={handleEdit}
          isAddNewAllowed={true}
          isLoading={isLoading}
          sortOptions={sortOptions}
          isSortEnabled={isSortEnabled}
          onFetchFromParent={onFetchFromParent}
        />
        {open ? (
          <CustomerFormContainer
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            customerId={customerId}
            backToCustomer={backToCustomer}
          />
        ) : null}
        {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
      </div>
    </div>
  )
}

export const DealerCustomersContainer = withStyles(combineStyles(stylesBase))(
  DealerCustomers
)
