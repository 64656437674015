import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Card,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Checkbox,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  InputAdornment,
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'
import Cancel from '@material-ui/icons/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import BootstrapInput from '../TextfieldForDealer'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  setDealerNewModifyOrders,
  setFees,
  setFulfillmentMethods,
  setOrderSpecies,
  setDealerCustomerList,
} from '../../../actions'
import { DealerOrderCartContainer } from './DealerOrderCart'
import { ApiHelper } from '../../../lib'
import addNew from '../../../assets/addNew.svg'
import combineStyles from '../../shared/CombineStyles'
import { withDealerTheme } from '../../shared/DealerTheme'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'
import Search from '@material-ui/icons/Search'
import ErrorMessageComponent from '../../../components/ErrorMessageComponent'
import { useHistory } from 'react-router-dom'
import { setFeeUnits } from '../../../features/dealerSlice'
import { CustomerFormContainer } from '../CustomerForm'
import { saveCustomer } from '../../../features/dealerSlice'

const styles = () => ({})

export function AddNewOrder(props) {
  const dispatch = useDispatch()
  const api = new ApiHelper()
  let datePickerClass = props.classes.datePickerText
  const { t } = useTranslation()
  const history = useHistory()

  const customers = useSelector(state => state.dealerCustomerList)
  const fulfillmentMethods = useSelector(state => state.fulfillmentMethods)
  const fees = useSelector(state => state.fees)
  const feeUnits = useSelector(state => state.dealer.feeUnits)
  const dealerNewModifyOrder = useSelector(state => state.dealerNewModifyOrder)
  const orderSpecies = useSelector(state => state.orderSpecies)
  const [data, setData] = useState({
    orderId: '',
    cartItem: {},
    searchKey: '',
    errorMsg: [],
    isLoading: false,
    showAddressForm: false,
    message: '',
    orderConfirmMessage: '',
  })
  const [customerFormopen, setCustomerFormopen] = useState(false)

  const [formData, setFormData] = useState({
    address1: '',
    address2: '',
    state: '',
    zipcode: '',
  })

  let orderHash = {
    order: {
      customer_id: '',
      fulfillment_attributes: {
        fulfillment_method_id: '',
        fulfillment_date: new Date(),
        totes_in: '',
        totes_out: '',
        is_company_address: false,
        address_id: '',
      },
      fulfillment_fees_attributes: [{ fee_id: '' }],
      line_items: [],
    },
  }

  const speciesInfoHeadCells = [
    { id: 'market_name', label: 'market_name' },
    { id: 'offload_id', label: 'offload_id' },
    { id: 'offload_date', label: 'offloadDate' },
    { id: 'percent_sold', label: 'percent_sold' },
    { id: 'available_weight', label: 'avl_weight' },
    { id: 'order_weight', label: 'order_weight' },
    { id: 'unit_price', label: 'unit_price' },
    { id: 'add_to_cart', label: 'add_to_cart' },
  ]

  const speciesInfoRowCells = [
    { id: 'product_market_name', name: 'product_market_name', type: 'label' },
    { id: 'offload_id', name: 'offload_id', type: 'label' },
    { id: 'offload_date', name: 'offload_date', type: 'label' },
    { id: 'percent_sold', name: 'percent_sold', type: 'label' },
    { id: 'available_weight', name: 'quantity', type: 'label' },
    { id: 'order_weight', name: 'order_weight', type: 'text' },
    { id: 'unit_price', name: 'price', type: 'text' },
    { id: 'add_to_cart', name: 'add_to_cart', type: 'plusIcon' },
  ]

  useEffect(() => {
    let fulfillmentApi = api
      .get(`/fulfillment_methods`)
      .then(response => response.json())
    let feesApi = api.get(`/fees`).then(response => response.json())
    let feeUnitsApi = api.get(`/fee_units`).then(response => response.json())
    let searchLotApi
    if (!props.orderId) {
      searchLotApi = api.get(`/lots/search`).then(response => response.json())
    }
    let customerApi = api.get(`/processors`).then(response => response.json())
    setData({ ...data, isLoading: true })
    Promise.all([
      fulfillmentApi,
      feesApi,
      feeUnitsApi,
      searchLotApi,
      customerApi,
    ])
      .then(
        ([
          fulfillmentMethod,
          feesResult,
          feeUnitsResult,
          searchLotResult,
          customerResult,
        ]) => {
          dispatch(setFulfillmentMethods(fulfillmentMethod))
          dispatch(setFees(feesResult.fees))
          dispatch(setFeeUnits(feeUnitsResult))
          dispatch(setDealerCustomerList(customerResult.processors))
          if (searchLotResult) {
            let stockItems = searchLotResult.stock_items.map(stock => {
              stock['order_weight'] = ''
              stock['price'] = ''
              return stock
            })
            dispatch(setOrderSpecies(stockItems))
          }
          setData({ ...data, isLoading: false })
          if (props.history.location.state.from === '/dealer/add/fee') {
            dispatch(setDealerNewModifyOrders({ ...dealerNewModifyOrder }))
          } else if (!props.orderId) {
            dispatch(setDealerNewModifyOrders(orderHash))
          }
          if (props.match.params.id) {
            getEditOrder(props.match.params.id)
          }
        }
      )
      .then(() => {})
      .catch(error => {
        console.error(error)
        setData({ ...data, isLoading: false })
      })
  }, [])

  function fetchCustomersAndSet(customerIdToSet) {
    const api = new ApiHelper()

    let customersUrl = `/processors`
    api
      .get(customersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerCustomerList(result.processors))
          if (customerIdToSet !== '') {
            let updatedDealerModifyOrder = { ...dealerNewModifyOrder }
            updatedDealerModifyOrder['order']['customer_id'] = parseInt(
              customerIdToSet
            )
            dispatch(setDealerNewModifyOrders(updatedDealerModifyOrder))
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  function fetchCustomerData(id) {
    if (id !== '') {
      fetchCustomersAndSet(id)
    }
    handleCustomerClose()
  }

  const getEditOrder = orderId => {
    const api = new ApiHelper()
    let ordersUrl = `/orders/${orderId}`
    setData({ ...data, isLoading: true })
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          let selectedOrder = { ...orderHash }
          selectedOrder.order.customer_id = result.customer_id
          selectedOrder.order.order_number = result.order_number
          selectedOrder.order.total_fees = result.total_fees
          selectedOrder.order.total_amount = result.total_amount
          if (result.fulfillment) {
            selectedOrder.order.fulfillment_attributes = result.fulfillment
          }
          if (result.fulfillment_fees) {
            selectedOrder.order.fulfillment_fees_attributes =
              result.fulfillment_fees
          }
          if (result.line_items && result.line_items.length > 0) {
            selectedOrder.order.line_items = result.line_items
            dispatch(setOrderSpecies(orderSpecies))
          } else {
            selectedOrder.order.line_items = []
            dispatch(setOrderSpecies(orderSpecies))
          }
          dispatch(setDealerNewModifyOrders(selectedOrder))
          setData({
            ...data,
            orderId: result.id,
            errorMsg: '',
            open: true,
            isLoading: false,
            cartItem: result,
          })
        } else {
          setData({
            ...data,
            errorMsg: 'Error occured while retrieving order summary',
            isLoading: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
        setData({
          ...data,
          errorMsg: 'Error occured while retrieving order summary',
          isLoading: false,
        })
      })
  }

  const getNewOrder = (event, section, apiInput) => {
    //New Order addition scenario
    let updatedDealerModifyOrder = { ...dealerNewModifyOrder }
    if (section === 'fulfillment_attributes') {
      apiInput = {
        order: {
          fulfillment_attributes:
            updatedDealerModifyOrder['order']['fulfillment_attributes'],
        },
      }
    }
    event.preventDefault()
    if (!data.orderId) {
      if (dealerNewModifyOrder) {
        api
          .post('/orders', apiInput)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              setData({ ...data, orderId: result.id })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    } else {
      api
        .put(`/orders/${data.orderId}`, apiInput)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            setData({
              ...data,
              orderNumber: result.id,
            })
          }
        })
    }
  }

  // const preventDefault = event => event.preventDefault()

  const handleNewEntry = (event, redirectionLink) => {
    event.preventDefault()
    let fromPath
    if (props.match.params.id) {
      fromPath = `/dealer/orders/edit/${props.match.params.id}`
    } else {
      fromPath = `/dealer/orders/new`
    }
    const redirectionPath = {
      pathname: redirectionLink,
      state: { from: fromPath },
    }
    history.push(redirectionPath)
  }

  const handleCustomerForm = (event, actionType) => {
    event.preventDefault()
    if (actionType == 'open') {
      setCustomerFormopen(true)
    } else if (actionType == 'close') {
      setCustomerFormopen(false)
    }
  }

  const handleClose = () => {
    const newOrderPath = {
      pathname: `/dealer/orders`,
      state: { from: `/dealer/orders` },
    }
    history.push(newOrderPath)
    dispatch(setOrderSpecies(orderSpecies))
    dispatch(setDealerNewModifyOrders(orderHash))
  }

  const handleFinishCancel = (event, actionType) => {
    event.preventDefault()
    if (actionType == 'submit') {
      let apiInput = { ...dealerNewModifyOrder }
      setData({ ...data, isLoading: true })
      api
        .put(`/orders/${data.orderId}/confirm`, apiInput)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            setData({
              ...data,
              orderId: result.id,
              isLoading: false,
              orderConfirmMessage: 'Order is Confirmed!',
            })
          }
        })
        .catch(error => {
          console.error(error)
          setData({
            ...data,
            errorMsg: ['Error occured while saving order'],
            isLoading: false,
          })
        })
    } else {
      handleClose()
    }
  }

  const TextFieldComponent = props => {
    return (
      <React.Fragment>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          className={datePickerClass}
        >
          <Trans i18nKey="date" />
        </InputLabel>
        <TextField {...props} size="small" />
      </React.Fragment>
    )
  }

  const setInfo = (event, section, index = 0, offload = '') => {
    event.preventDefault()
    let updatedDealerModifyOrder = { ...dealerNewModifyOrder }
    if (section === 'customer_id') {
      updatedDealerModifyOrder['order'][event.target.name] = parseInt(
        event.target.value
      )
    } else if (section === 'fulfillment_attributes') {
      updatedDealerModifyOrder['order']['fulfillment_attributes'][
        event.target.name
      ] = event.target.value
    } else if (section === 'fulfillment_fees_attributes') {
      updatedDealerModifyOrder['order']['fulfillment_fees_attributes'][index][
        event.target.name
      ] = event.target.value
    } else if (section === 'line_items') {
      let orderItemkey = offload.id
      let orderItemHash = orderSpecies.find(element => {
        return element.id === orderItemkey
      })
      orderItemHash[event.target.name] = event.target.value
    }
    dispatch(setDealerNewModifyOrders(updatedDealerModifyOrder))
  }

  const setDate = (dateSelected, section, fieldName) => {
    let updatedDealerModifyOrder = JSON.parse(
      JSON.stringify(dealerNewModifyOrder)
    )
    if (section === 'fulfillment_attributes') {
      updatedDealerModifyOrder['order']['fulfillment_attributes'][
        fieldName
      ] = dateSelected
    }
    dispatch(setDealerNewModifyOrders(updatedDealerModifyOrder))
  }

  const setAddress = event => {
    let updatedDealerModifyOrder = { ...dealerNewModifyOrder }
    updatedDealerModifyOrder['order']['fulfillment_attributes'][
      'is_company_address'
    ] = event.target.checked
    dispatch(setDealerNewModifyOrders(updatedDealerModifyOrder))
  }

  const setSpeciesSearchKey = searchKey => {
    setData({ ...data, searchKey: searchKey })
    let baseURL = 'lots/search'
    let lotSearchURL = `/${baseURL}?q=${searchKey}`
    let speciesInfoArr = []
    if (searchKey.length > 0) {
      api
        .get(lotSearchURL)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            let stockItems = result.stock_items.map(stock => {
              stock['order_weight'] = ''
              stock['price'] = ''
              return stock
            })
            dispatch(setOrderSpecies(stockItems))
          } else {
            dispatch(setOrderSpecies(speciesInfoArr))
            //setErrorMsg('Error occured while retrieving Offloads')
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      api
        .get(`/lots/search`)
        .then(response => response.json())
        .then(result => {
          if (result) {
            let stockItems = result.stock_items.map(stock => {
              stock['order_weight'] = ''
              stock['price'] = ''
              return stock
            })
            dispatch(setOrderSpecies(stockItems))
          } else {
            dispatch(setOrderSpecies(speciesInfoArr))
            //setErrorMsg('Error occured while retrieving orders')
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const addItemLine = (offloadId, lotId) => {
    let selectedLineItem = orderSpecies.find(lineItem => {
      return lineItem.id === lotId
    })

    let existingItemInCart

    if (data.cartItem.line_items) {
      existingItemInCart = data.cartItem.line_items.find(lineItem => {
        return lineItem.lot_id === lotId
      })
    }

    let apiInput = {}
    apiInput['price'] = selectedLineItem.price
    apiInput['quantity'] = selectedLineItem.order_weight
    apiInput['lot_id'] = lotId

    let modifyOrder = { ...dealerNewModifyOrder }
    let lineItemArray = [...modifyOrder['order']['line_items']]
    lineItemArray.push(apiInput)
    dispatch(setDealerNewModifyOrders(modifyOrder))
    let orderId = props.orderId ? props.orderId : data.orderId
    setData({ ...data, isLoading: true })
    if (existingItemInCart) {
      api
        .put(`/line_items/${existingItemInCart.id}`, apiInput)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            setData({ ...data, cartItem: result, isLoading: false })
          } else if (result.errors) {
            setData({ ...data, errorMsg: result.errors, isLoading: false })
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      api
        .post(`/orders/${orderId}/line_items`, apiInput)
        .then(resp => {
          return resp.json()
        })
        .then(result => {
          if (result) {
            setData({ ...data, cartItem: result, isLoading: false })
          } else if (result.errors) {
            setData({ ...data, errorMsg: result.errors, isLoading: false })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const showAddressForm = event => {
    event.preventDefault()
    setData({ ...data, showAddressForm: !data.showAddressForm })
  }

  const handleFormSave = () => {
    let shippingData = { ...formData }
    let apiInput = {
      processor: {
        addresses_attributes: [
          {
            address1: shippingData.address1,
            address2: shippingData.address2,
            state: shippingData.state,
            zipcode: shippingData.zipcode,
          },
        ],
      },
    }
    api
      .put(
        `/processors/${dealerNewModifyOrder['order']['customer_id']}`,
        apiInput
      )
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          setData({ ...data, message: 'Address added successfully' })
        } else if (result.errors) {
          setData({ ...data, errorMsg: result.errors })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const setFeeAmount = selectedFeeId => {
    if (selectedFeeId && fees.length > 0) {
      let feeAmount = fees.find(fee => {
        return fee.id === selectedFeeId
      })
      return feeAmount.amount
    } else {
      return ''
    }
  }

  const setFeeUnit = selectedFeeUnitId => {
    if (
      selectedFeeUnitId &&
      selectedFeeUnitId !== '' &&
      selectedFeeUnitId !== ' '
    ) {
      let fee = fees.find(fee => {
        return fee.id === selectedFeeUnitId
      })
      let feeUnitResult = feeUnits.find(unit => {
        return unit.id === fee.fee_unit_id
      })
      return feeUnitResult.name
    } else {
      return ''
    }
  }

  const onDeleteLineItem = updatedItem => {
    console.log('Inside On Delete Item')
    if (!updatedItem.line_items) {
      updatedItem.line_items = []
    }
    let updatedNewModifyOrder = { ...dealerNewModifyOrder }
    updatedNewModifyOrder['order']['line_items'] = updatedItem.line_items
    dispatch(setDealerNewModifyOrders(updatedNewModifyOrder))
    setData({
      ...data,
      cartItem: updatedItem,
    })
  }

  const handleCustomerClose = () => {
    dispatch(
      saveCustomer({
        name: '',
        federal_permit_number: '',
        state_permit_number: '',
        addresses: [
          {
            address1: '',
            address2: '',
            state: '',
            zipcode: '',
          },
        ],
        contacts: [
          {
            full_name: '',
            mobile_number: '',
            fax: '',
            email: '',
          },
        ],
      })
    )
    setCustomerFormopen(false)
  }

  return (
    <div className={props.classes.rootDiv}>
      <Backdrop className={props.classes.backdrop} open={data.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className={props.classes.screenTop}>
        {/** Header */}
        <Grid
          container
          alignItems="center"
          className={props.classes.headingContent}
        >
          <Grid item xs={11} className={props.classes.leftSide}>
            <Typography className={props.classes.titleText}>
              <Trans i18nKey="add_a_new_order" />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={1}
            justify="flex-end"
            className={props.classes.rightSide}
          >
            <Cancel
              className={props.classes.cancelIcon}
              fontSize="small"
              onClick={event => handleFinishCancel(event, 'cancel')}
            />
          </Grid>
        </Grid>
        {/** Container for main content - start */}
        <Grid container className={props.classes.mainContainer}>
          {/** Inner parent Grid start */}
          <Grid container className={props.classes.innerParentGrid}>
            {/** Left Panel Start */}
            <Grid
              container
              item
              xs={12}
              md={9}
              lg={9}
              className={classNames(
                props.classes.leftOuterGrid,
                props.classes.leftSide
              )}
            >
              {/** Customer Info */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={props.classes.lightText}>
                    <Trans i18nKey="customer_info" />
                  </Typography>
                </Grid>
                <Grid item md={2} />
                <Grid container item md={10}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className={props.classes.gridFirstRow}
                  >
                    <FormControl
                      className={props.classes.formControl}
                      fullWidth
                    >
                      <InputLabel
                        shrink
                        id="customer_name"
                        className={props.classes.labelText}
                      >
                        <Trans i18nKey="customer_name" />
                      </InputLabel>
                      <Select
                        value={dealerNewModifyOrder['order']['customer_id']}
                        name="customer_id"
                        displayEmpty
                        disableUnderline
                        className={props.classes.selectEmpty}
                        placeholder={String(<Trans i18nKey="customer_info" />)}
                        onChange={e => setInfo(e, 'customer_id')}
                        onBlur={e =>
                          getNewOrder(e, '', {
                            order: {
                              customer_id:
                                dealerNewModifyOrder['order']['customer_id'],
                            },
                          })
                        }
                      >
                        <MenuItem value=" ">
                          <Typography
                            className={props.classes.selectMenuPlaceholder}
                          >
                            {' '}
                            <Trans i18nKey="customer_name" />{' '}
                          </Typography>
                        </MenuItem>
                        {customers.map(customer => {
                          return (
                            <MenuItem
                              key={customer.id}
                              value={customer.id}
                              className={props.classes.selectMenuText}
                            >
                              {customer.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <Link
                      component="button"
                      onClick={event => handleCustomerForm(event, 'open')}
                      variant="caption"
                      className={props.classes.linkText}
                    >
                      <Trans i18nKey="add_a_new_customer" />
                    </Link>
                    {open ? (
                      <CustomerFormContainer
                        open={customerFormopen}
                        setOpen={setCustomerFormopen}
                        handleClose={handleCustomerClose}
                        customerId={''}
                        backToCustomer={fetchCustomerData}
                      />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {' '}
                  <Divider />{' '}
                </Grid>
              </Grid>

              {/** FulFillment Info */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={props.classes.lightText}>
                    <Trans i18nKey="fulfillment_info" />
                  </Typography>
                </Grid>
                <Grid item md={2} />
                <Grid container item md={10}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className={props.classes.gridFirstRow}
                  >
                    <FormControl
                      className={props.classes.formControl}
                      fullWidth
                    >
                      <InputLabel
                        shrink
                        id="method"
                        className={props.classes.labelText}
                      >
                        <Trans i18nKey="fulfillment_method" />
                      </InputLabel>
                      <Select
                        name="fulfillment_method_id"
                        value={
                          dealerNewModifyOrder['order'][
                            'fulfillment_attributes'
                          ]['fulfillment_method_id']
                        }
                        displayEmpty
                        disableUnderline
                        className={props.classes.selectEmpty}
                        onChange={e => setInfo(e, 'fulfillment_attributes')}
                        placeholder={String(
                          <Trans i18nKey="fulfillment_method" />
                        )}
                        onBlur={e =>
                          getNewOrder(e, 'fulfillment_attributes', {
                            order: {
                              fulfillment_attributes: {
                                fulfillment_method_id:
                                  dealerNewModifyOrder['order'][
                                    'fulfillment_attributes'
                                  ]['fulfillment_method_id'],
                              },
                            },
                          })
                        }
                      >
                        <MenuItem value=" ">
                          <Typography
                            className={props.classes.selectMenuPlaceholder}
                          >
                            {' '}
                            <Trans i18nKey="fulfillment_method" />{' '}
                          </Typography>
                        </MenuItem>
                        {fulfillmentMethods.map(fulfillmentMethod => {
                          return (
                            <MenuItem
                              key={fulfillmentMethod.id}
                              value={fulfillmentMethod.id}
                              className={props.classes.selectMenuText}
                            >
                              {fulfillmentMethod.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={1} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className={props.classes.datePickerGrid}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="date"
                        format="MM/dd/yyyy"
                        value={
                          dealerNewModifyOrder['order'][
                            'fulfillment_attributes'
                          ]['fulfillment_date']
                        }
                        autoOk={true}
                        inputVariant="outlined"
                        TextFieldComponent={TextFieldComponent}
                        InputProps={{
                          className: props.classes.dateFieldClass,
                        }}
                        onChange={date =>
                          setDate(
                            date,
                            'fulfillment_attributes',
                            'fulfillment_date'
                          )
                        }
                        onBlur={e =>
                          getNewOrder(e, 'fulfillment_attributes', {
                            order: {
                              fulfillment_attributes: {
                                fulfillment_date:
                                  dealerNewModifyOrder['order'][
                                    'fulfillment_attributes'
                                  ]['fulfillment_date'],
                              },
                            },
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={1} />
                  <Grid container item md={4}>
                    <Grid item xs={5} sm={5} md={4}>
                      <FormControl className={props.classes.margin} fullWidth>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input"
                          className={props.classes.labelText}
                        >
                          <Trans i18nKey="totes_in" />
                        </InputLabel>
                        <BootstrapInput
                          value={
                            dealerNewModifyOrder['order'][
                              'fulfillment_attributes'
                            ]['totes_in']
                          }
                          name="totes_in"
                          id="totes_in"
                          onChange={e => setInfo(e, 'fulfillment_attributes')}
                          onBlur={e =>
                            getNewOrder(e, 'fulfillment_attributes', {
                              order: {
                                fulfillment_attributes: {
                                  totes_in:
                                    dealerNewModifyOrder['order'][
                                      'fulfillment_attributes'
                                    ]['totes_in'],
                                },
                              },
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} md={2} />
                    <Grid item xs={5} sm={5} md={4}>
                      <FormControl className={props.classes.margin} fullWidth>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input"
                          className={props.classes.labelText}
                        >
                          <Trans i18nKey="totes_out" />
                        </InputLabel>
                        <BootstrapInput
                          value={
                            dealerNewModifyOrder['order'][
                              'fulfillment_attributes'
                            ]['totes_out']
                          }
                          name="totes_out"
                          id="totes_out"
                          onChange={e => setInfo(e, 'fulfillment_attributes')}
                          onBlur={e =>
                            getNewOrder(e, 'fulfillment_attributes', {
                              order: {
                                fulfillment_attributes: {
                                  totes_out:
                                    dealerNewModifyOrder['order'][
                                      'fulfillment_attributes'
                                    ]['totes_out'],
                                },
                              },
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} md={2} />
                  </Grid>
                  <Grid container item xs={12} md={12}>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={props.classes.labelText}
                    >
                      <Trans i18nKey="shipping_address" />
                    </InputLabel>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    alignItems="center"
                    className={props.classes.labelLineSpacing}
                  >
                    <Checkbox
                      disableRipple
                      name="is_company_address"
                      color="default"
                      checked={
                        dealerNewModifyOrder['order']['fulfillment_attributes'][
                          'is_company_address'
                        ]
                      }
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                      classes={{
                        root: props.classes.checkboxRoot,
                        checked: props.classes.checked,
                      }}
                      onChange={e => setAddress(e)}
                      onBlur={e =>
                        getNewOrder(e, 'fulfillment_attributes', {
                          order: {
                            fulfillment_attributes: {
                              is_company_address:
                                dealerNewModifyOrder['order'][
                                  'fulfillment_attributes'
                                ]['is_company_address'],
                            },
                          },
                        })
                      }
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={props.classes.labelText}
                    >
                      <Trans i18nKey="same_as_company_address" />
                    </InputLabel>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    alignItems="center"
                    className={props.classes.labelLineSpacing}
                  >
                    <Link
                      component="button"
                      onClick={event => showAddressForm(event)}
                      variant="caption"
                      className={props.classes.linkText}
                    >
                      <Trans i18nKey="add_a_new_shipping_address" />
                    </Link>
                  </Grid>
                </Grid>
                {data.showAddressForm ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography className={props.classes.lightText}>
                        <Trans i18nKey="company_info" />
                      </Typography>
                    </Grid>
                    <Grid item md={3} />
                    <Grid container item md={9}>
                      <Grid item xs={9} className={props.classes.gridFirstRow}>
                        <FormControl className={props.classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={props.classes.labelText}
                          >
                            <Trans i18nKey="company_name" />*
                          </InputLabel>
                          <BootstrapInput
                            value={''}
                            name="permit_number"
                            id="permit_number"
                            onChange={handleChange}
                            placeholder="Seafood Company Inc."
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={9} className={props.classes.gridFirstRow}>
                        <FormControl className={props.classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={props.classes.labelText}
                          >
                            <Trans i18nKey="company_address" />*
                          </InputLabel>
                          <BootstrapInput
                            value={formData.address1}
                            name="address1"
                            id="address1"
                            onChange={event => handleChange(event)}
                            placeholder="1234 First St"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={9} className={props.classes.gridFirstRow}>
                        <FormControl className={props.classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={props.classes.labelText}
                          >
                            <Trans i18nKey="address_line_2" />*
                          </InputLabel>
                          <BootstrapInput
                            value={formData.address2}
                            name="address2"
                            id="address2"
                            onChange={event => handleChange(event)}
                            placeholder="Suite 1234"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} className={props.classes.gridFirstRow}>
                        <FormControl className={props.classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={props.classes.labelText}
                          >
                            <Trans i18nKey="state" />*
                          </InputLabel>
                          <BootstrapInput
                            value={formData.state}
                            name="state"
                            id="state"
                            onChange={event => handleChange(event)}
                            placeholder="MA"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={4} className={props.classes.gridFirstRow}>
                        <FormControl className={props.classes.margin} fullWidth>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input"
                            className={props.classes.labelText}
                          >
                            <Trans i18nKey="zipcode" />*
                          </InputLabel>
                          <BootstrapInput
                            value={formData.zipcode}
                            name="zipcode"
                            id="zipcode"
                            onChange={event => handleChange(event)}
                            placeholder="12345"
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={8} sm={8} md={8} lg={8} />
                      <Grid xs={4} sm={4} md={4} lg={4}>
                        <Button
                          size="small"
                          onClick={event => handleFormSave(event)}
                          className={props.classes.actionButtons}
                          classes={{
                            label: props.classes.cancelButtonText,
                          }}
                        >
                          <Trans i18nKey="save" />
                        </Button>
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={12}>
                        <Typography>{data.message}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {' '}
                  <Divider />{' '}
                </Grid>
              </Grid>
              {/** Fulfillment end */}
              {/** Fee start */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={props.classes.lightText}>
                    <Trans i18nKey="fee_info" />
                  </Typography>
                </Grid>
                {dealerNewModifyOrder['order'].fulfillment_fees_attributes &&
                  dealerNewModifyOrder['order'].fulfillment_fees_attributes.map(
                    function(fee_info_row, i) {
                      return (
                        <Grid container key={i}>
                          <Grid item md={2} />
                          <Grid container item md={10}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              className={props.classes.gridFirstRow}
                            >
                              <FormControl
                                className={props.classes.formControl}
                                fullWidth
                              >
                                <InputLabel
                                  shrink
                                  id="fee_name"
                                  className={props.classes.labelText}
                                >
                                  <Trans i18nKey="fee_name" />
                                </InputLabel>
                                <Select
                                  value={
                                    dealerNewModifyOrder['order'][
                                      'fulfillment_fees_attributes'
                                    ][i]['fee_id']
                                  }
                                  name="fee_id"
                                  displayEmpty
                                  disableUnderline
                                  className={props.classes.selectEmpty}
                                  placeholder={String(
                                    <Trans i18nKey="fee_name" />
                                  )}
                                  onChange={e =>
                                    setInfo(e, 'fulfillment_fees_attributes', i)
                                  }
                                  onBlur={e =>
                                    getNewOrder(e, '', {
                                      order: {
                                        fulfillment_fees_attributes: [
                                          {
                                            fee_id:
                                              dealerNewModifyOrder['order'][
                                                'fulfillment_fees_attributes'
                                              ][i]['fee_id'],
                                          },
                                        ],
                                      },
                                    })
                                  }
                                >
                                  <MenuItem value=" ">
                                    <Typography
                                      className={
                                        props.classes.selectMenuPlaceholder
                                      }
                                    >
                                      {' '}
                                      <Trans i18nKey="fee_name" />{' '}
                                    </Typography>
                                  </MenuItem>
                                  {fees.map(fee => {
                                    return (
                                      <MenuItem
                                        key={fee.id}
                                        value={fee.id}
                                        className={props.classes.selectMenuText}
                                      >
                                        {fee.title}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={1} />
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              className={props.classes.gridFirstRow}
                            >
                              <FormControl
                                className={props.classes.margin}
                                fullWidth
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="bootstrap-input"
                                  className={props.classes.labelText}
                                >
                                  <Trans i18nKey="fee_amount" />
                                </InputLabel>
                                <BootstrapInput
                                  disabled
                                  value={setFeeAmount(
                                    dealerNewModifyOrder['order'][
                                      'fulfillment_fees_attributes'
                                    ][i]['fee_id']
                                  )}
                                  name="amount"
                                  id="fee_amount"
                                  onChange={e =>
                                    setInfo(e, 'fulfillment_fees_attributes', i)
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={1} />
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              className={props.classes.gridFirstRow}
                            >
                              <FormControl
                                className={props.classes.margin}
                                fullWidth
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="bootstrap-input"
                                  className={props.classes.labelText}
                                >
                                  <Trans i18nKey="fee_units" />
                                </InputLabel>
                                <BootstrapInput
                                  disabled
                                  value={setFeeUnit(
                                    dealerNewModifyOrder['order'][
                                      'fulfillment_fees_attributes'
                                    ][i]['fee_id']
                                  )}
                                  name="units"
                                  id="fee_units"
                                  onChange={e =>
                                    setInfo(e, 'fulfillment_fees_attributes', i)
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }
                  )}
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <Link
                      component="button"
                      onClick={event =>
                        handleNewEntry(event, '/dealer/add/fee')
                      }
                      variant="caption"
                      className={props.classes.linkText}
                    >
                      <Trans i18nKey="add_another_fee" />
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {' '}
                  <Divider />{' '}
                </Grid>
              </Grid>
              {/** Fee end */}
              {/** Species info Start */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={props.classes.lightText}>
                    <Trans i18nKey="species_info" />
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item md={11} xs={12}>
                    <FormControl className={props.classes.margin} fullWidth>
                      <BootstrapInput
                        name="species_name"
                        id="species_name"
                        onBlur={e => setSpeciesSearchKey(e.currentTarget.value)}
                        placeholder={t('search', 'Search')}
                        startAdornment={
                          <InputAdornment position="start">
                            <Search fontSize="small" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={1} />

                  <Grid
                    item
                    xs={12}
                    md={11}
                    className={props.classes.tableOuterGrid}
                  >
                    <Table
                      size="small"
                      aria-label="simple table"
                      key="mainTableKey"
                    >
                      <TableHead className={props.classes.tableHead}>
                        <TableRow>
                          {speciesInfoHeadCells.map(headCell => (
                            <TableCell
                              className={classNames(
                                props.classes.speciessectionlabel
                              )}
                              key={headCell.label}
                            >
                              <Trans i18nKey={headCell.label} />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="speciesTableBodyKey">
                        {orderSpecies.map(function(offload, i) {
                          let key =
                            offload.offload_id + offload.offload_number + i
                          return (
                            <TableRow key={key}>
                              {speciesInfoRowCells.map(function(cell, i) {
                                let name = cell.name
                                let type = cell.type
                                if (type === 'label') {
                                  return (
                                    <TableCell
                                      key={name + i}
                                      className={classNames(
                                        props.classes.speciessectionlabel
                                      )}
                                    >
                                      {offload[name]}
                                    </TableCell>
                                  )
                                }
                                if (type === 'text') {
                                  let orderItemHash = orderSpecies.find(
                                    element => {
                                      return element['id'] === offload.id
                                    }
                                  )
                                  let valueToSet = ''
                                  if (orderItemHash && orderItemHash[name]) {
                                    valueToSet = orderItemHash[name]
                                  }

                                  return (
                                    <TableCell key={name + i}>
                                      <BootstrapInput
                                        key={name + 'textbox' + i}
                                        className={
                                          props.classes.speciesInfoCellText
                                        }
                                        value={valueToSet}
                                        name={name}
                                        onChange={e =>
                                          setInfo(e, 'line_items', 0, offload)
                                        }
                                      />
                                    </TableCell>
                                  )
                                }
                                if (type === 'plusIcon') {
                                  return (
                                    <TableCell key={'plusIcon' + i}>
                                      <Grid
                                        container
                                        item
                                        xs={2}
                                        md={2}
                                        justify="center"
                                      >
                                        <img
                                          src={addNew}
                                          className={
                                            props.classes.orderSpeciesAdd
                                          }
                                          onClick={() =>
                                            addItemLine(
                                              offload.offload_id,
                                              offload.id
                                            )
                                          }
                                          alt="notification"
                                        />
                                      </Grid>
                                    </TableCell>
                                  )
                                }
                              })}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item md={1} />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={props.classes.speciesInfoDivider}
                >
                  {' '}
                  <Divider />{' '}
                </Grid>
              </Grid>
              {/** Species info end */}
            </Grid>
            {/** Left Panel End */}
            <Grid
              container
              item
              xs={12}
              md={3}
              lg={3}
              className={props.classes.rightOuterGrid}
            >
              <DealerOrderCartContainer
                cartItem={data.cartItem}
                key={data.cartItem}
                onDeleteLineItem={onDeleteLineItem}
              />
            </Grid>
          </Grid>
          {data.errorMsg
            ? data.errorMsg.map((errorMsg, index) => (
                <ErrorMessageComponent key={index} errorMessage={errorMsg} />
              ))
            : null}

          <Grid item xs={12}>
            {' '}
            <Divider />{' '}
          </Grid>

          {/** Inner parent Grid end */}
        </Grid>
        <Grid container alignItems="center" justify="flex-end">
          {data.orderConfirmMessage ? (
            <Alert severity="success" className={props.classes.labelText}>
              {data.orderConfirmMessage}
            </Alert>
          ) : null}

          <Button
            size="small"
            onClick={event => handleFinishCancel(event, 'cancel', '')}
            className={props.classes.actionButtons}
            classes={{ label: props.classes.cancelButtonText }}
          >
            <Trans i18nKey="cancel" />
          </Button>
          <Button
            variant="contained"
            onClick={event => handleFinishCancel(event, 'submit', '')}
            size="small"
            className={classNames(
              props.classes.finishButton,
              props.classes.actionButtons
            )}
            classes={{ label: props.classes.buttonText }}
          >
            <Trans i18nKey="confirm_order" />
          </Button>
        </Grid>
        {/** Container for main content - end */}
      </Card>
    </div>
  )
}

export const AddNewOrderContainer = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(AddNewOrder))
