import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { useDispatch, useSelector } from 'react-redux'
import { TableView } from '../../components/TableView'
import { SpeciesFormContainer } from './SpeciesForm'
import { ApiHelper } from '../../lib'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import {
  saveDealerSpecie,
  setDealerSpecies,
  setSpeciesGrades,
  setSpeciesDispositions,
} from '../../features/dealerSlice'

export function DealerSpecies(props) {
  const headCells = [
    {
      id: 'specie_names',
      label: 'species',
      sort: 'specie_names',
    },
    { id: 'market_name', label: 'market_name', sort: 'market_name' },
    {
      id: 'market_description_id',
      label: 'market_description',
      fetchFromParent: 'market_description',
    },
    {
      id: 'grade_id',
      label: 'grade',
      fetchFromParent: 'grade',
    },
    {
      id: 'disposition',
      label: 'disposition',
      fetchFromParent: 'disposition',
    },
    { id: 'fee_per_lb', label: 'associated_fee' },
  ]

  const dataURL = 'products'
  const [open, setOpen] = useState(false)

  const [startIndex, setstartIndex] = useState(1)
  const [displayDataCount, setDisplayDataCount] = useState(0)
  const initalLimitPerPage = 10
  const [speciesId, setOffloadId] = useState('')

  const initialFilterDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [errorMsg, setErrorMsg] = React.useState('')

  const [paramState, setParamState] = React.useState(tableParameters)
  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const dealerSpecies = useSelector(state => state.dealer.dealerSpecies)
  const counts = useSelector(state => state.counts)
  const speciesGrades = useSelector(state => state.dealer.speciesGrades)
  const speciesDispositions = useSelector(
    state => state.dealer.speciesDispositions
  )
  const api = new ApiHelper()

  function fetchDisplayData() {
    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let ordersUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      ordersUrl = ordersUrl + '&q=' + paramState.searchKey
    }
    setIsLoading(true)
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerSpecies(result.products))
          setstartIndex(startIndexVal)
          setDisplayDataCount(result.pagination.count)
          setErrorMsg('')
        } else {
          setErrorMsg('Error occured while retrieving Inventory Summary')
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [paramState, dataURL])

  useEffect(() => {
    api
      .get(`/grades`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setSpeciesGrades(result))
        } else {
          dispatch(setSpeciesGrades([]))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    api
      .get(`/dispositions`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setSpeciesDispositions(result))
        } else {
          dispatch(setSpeciesDispositions([]))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const handleAddNewOffload = () => {
    let initialState = {}
    dispatch(saveDealerSpecie(initialState))
    setOffloadId('')
    setOpen(true)
  }

  const handleClose = () => {
    let clearFormSelections = {}
    dispatch(saveDealerSpecie(clearFormSelections))
    setOpen(false)
  }

  const handleEdit = speciesId => {
    let index = dealerSpecies.findIndex(obj => obj.order_number == speciesId)
    let selectedOffload = {
      order: {},
    }
    selectedOffload.order = { ...dealerSpecies[index] }
    selectedOffload.order['lots_attributes'] = selectedOffload.order['lots']
    dispatch(saveDealerSpecie(selectedOffload))
    setOffloadId(speciesId)
    setOpen(true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setstartIndex(1)
    }
    setParamState(paramHash)
  }

  function backToSpecies() {
    fetchDisplayData()
    handleClose()
  }

  const sortOptions = [
    { id: 'specie_names', label: 'Species' },
    { id: 'market_name', label: 'Market Names' },
  ]

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  function onFetchFromParent(name, key) {
    let dealerSpecie = dealerSpecies.find(obj => obj.id == key)
    let marketDescription = counts.find(
      obj => obj.id == dealerSpecie.market_description_id
    )
    let grade = speciesGrades.find(obj => obj.id == dealerSpecie.grade_id)
    let disposition = speciesDispositions.find(
      obj => obj.id == dealerSpecie.disposition_id
    )
    if (name === 'market_description' && marketDescription) {
      return marketDescription.description
    }
    if (name === 'grade' && grade) {
      return grade.name
    }
    if (name === 'disposition' && disposition) {
      return disposition.name
    }
  }

  return (
    <div>
      <div>
        <TableView
          headCells={headCells}
          displayData={dealerSpecies}
          startIndex={startIndex}
          sortType={headCells[0].id}
          paramState={paramState}
          loadData={loadData}
          onHandleaddNew={handleAddNewOffload}
          displayDataCount={displayDataCount}
          initalLimitPerPage={initalLimitPerPage}
          handleEdit={handleEdit}
          isAddNewAllowed={true}
          isLoading={isLoading}
          sortOptions={sortOptions}
          isSortEnabled={isSortEnabled}
          disableEdit={true}
          onFetchFromParent={onFetchFromParent}
        />
        {open ? (
          <SpeciesFormContainer
            open={open}
            handleClose={handleClose}
            id={speciesId}
            backToSpecies={backToSpecies}
          />
        ) : null}
        {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
      </div>
    </div>
  )
}

export const DealerSpeciesContainer = withStyles(combineStyles(stylesBase))(
  DealerSpecies
)
