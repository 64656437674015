import React, { useState, useEffect } from 'react'
import {
  withStyles,
  Typography,
  Grid,
  Hidden,
  List,
  ListItem,
} from '@material-ui/core'
import combineStyles from '../../shared/CombineStyles'
import { useDispatch, useSelector } from 'react-redux'
import { ApiHelper } from '../../../lib'
import { setInventoryList } from '../../../actions'
import ErrorMessageComponent from '../../../components/ErrorMessageComponent'
import { withDealerTheme } from '../../shared/DealerTheme'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'
import SortIcon from '@material-ui/icons/Sort'
import DateRangeComponent from '../../../components/DateRangeComponent'
import SearchComponent from '../../../components/SearchComponent'
import { FilterOptionDrawer } from '../../../components/FilterOptionDrawer'
import { InventoryCardView } from './InventoryCardView'
import ListView from '../../../assets/dealer/listview.svg'
import ListViewActive from '../../../assets/dealer/listviewhighlighted.svg'
import TileView from '../../../assets/dealer/tileview.svg'
import TileViewActive from '../../../assets/dealer/tileviewhighlighted.svg'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { GetApp, Print } from '@material-ui/icons'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import { InventoryListContainer } from './DealerInventoryList'

const styles = () => ({
  inventorylistItem: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: '2px',
    paddingRight: '8px',
    color: '#003e6d',
  },
})

export function DealerInventory(props) {
  const headCells = [
    {
      id: 'species',
      label: 'species',
      sort: 'product_market_name',
      fetchFromParent: 'species',
    },
    {
      id: 'offload_id',
      label: 'offload_id',
      sort: 'order_number',
      fetchFromParent: 'offload_id',
    },
    {
      id: 'offload_date',
      label: 'offloadDate',
      sort: 'arriving_at',
      fetchFromParent: 'offload_date',
    },
    {
      id: 'available_weight',
      label: 'available_weight',
      fetchFromParent: 'available_weight',
    },
    { id: 'open_orders', label: 'open_orders' },
    { id: 'fishing_vessel', label: 'fishing_vessel' },
    { id: 'port_name', label: 'port' },
  ]

  const dataURL = 'stock_items'
  const initialLimitPerPage = 20
  const initialFilterDate = new Date()
  const dispatch = useDispatch()
  const inventoryList = useSelector(state => state.inventoryList)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initialLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }
  const [data, setData] = useState({
    startIndex: 1,
    displayDataCount: 0,
    isLoading: false,
    errorMsg: '',
    paramState: tableParameters,
    filterOptionOpen: false,
    changeView: 'list',
  })

  function fetchDisplayData() {
    const api = new ApiHelper()

    let pageInt = parseInt(data.paramState.pageSelected)
    const limitPerPageInt = parseInt(data.paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(data.displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let ordersUrl = `/${dataURL}?sort_by=${data.paramState.orderBy}&direction=${data.paramState.order}`
    if (data.paramState.searchKey !== '') {
      ordersUrl = ordersUrl + '&q=' + data.paramState.searchKey
    }
    setData({ ...data, isLoading: true })
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setInventoryList(result.stock_items))
          setData({
            ...data,
            startIndex: startIndexVal,
            displayDataCount: result.pagination.count,
            errorMsg: '',
            isLoading: false,
          })
        } else {
          setData({
            ...data,
            errorMsg: 'Error occured while retrieving Inventory Summary',
            isLoading: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
        setData({ ...data, errorMsg: error.error, isLoading: false })
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [data.paramState, dataURL])

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setData({ ...data, startIndex: 1 })
    }
    setData({
      ...data,
      paramState: paramHash,
    })
  }

  function createListData(inventoryList) {
    let dataArray = []
    inventoryList.map(function(inventory, index) {
      let result = createHomeView(
        Object.keys(inventory),
        Object.values(inventory),
        index
      )
      return dataArray.push(result)
    })
    return dataArray
  }

  function createHomeView(species, inventory, index) {
    let hash = {
      id: '',
      species: '',
      offload_id: '',
      offload_date: '',
      available_weight: '',
      open_orders: '',
      fishing_vessel: '',
      port_name: '',
    }
    let offloadIdArray = [],
      availableWeight = [],
      offloadDates = [],
      vesselArray = []
    hash.species = species
    hash.id = index
    inventory[0].map(function(data) {
      let offloadIdArr = [...offloadIdArray]
      offloadIdArr.push(data.order_number)
      offloadIdArray = offloadIdArr
      hash.offload_id = offloadIdArr.join(', ')
      hash.available_weight = findSum(availableWeight, data.total)
      hash.open_orders = findSum(availableWeight, parseInt(data.open_orders))
      let dateArr = [...offloadDates]
      dateArr.push(data.offload_date)
      offloadDates = dateArr
      var orderedDates = dateArr.sort(function(a, b) {
        return Date.parse(a) - Date.parse(b)
      })
      hash.offload_date = orderedDates[0]
      let vesselArr = [...vesselArray]
      vesselArr.push(data.vessel_name)
      vesselArray = vesselArr
      hash.fishing_vessel = vesselArr.join(', ')
      hash.port_name = data.port_name
    })
    return hash
  }

  function findSum(indArray, data) {
    let sumArray = [...indArray]
    sumArray.push(data)
    indArray = sumArray
    return indArray.reduce((a, b) => a + b, 0)
  }

  function createExpandData(inventoryList) {
    let dataArray = []
    inventoryList.map(function(inventory, index) {
      let result = expandView(
        Object.keys(inventory),
        Object.values(inventory),
        index
      )
      return dataArray.push(result)
    })
    return dataArray
  }

  function expandView(species, inventory, index) {
    let individualArr = [],
      hashArr = []
    individualArr.push(species)
    inventory.map(data => {
      data.map(row => {
        let hash = {
          id: '',
          offload_id: '',
          offload_date: '',
          available_weight: '',
          open_orders: '',
          fishing_vessel: '',
          port_name: '',
        }
        hash.id = index
        let indHashArr = [...hashArr]
        hash.offload_id = row.order_number
        hash.offload_date = row.offload_date
        hash.available_weight = row.total
        hash.open_orders = parseInt(row.open_orders)
        if (row.vessel_name) {
          hash.fishing_vessel = row.vessel_name
        } else {
          hash.fishing_vessel = ' - '
        }
        if (row.port_name) {
          hash.port_name = row.port_name
        } else {
          hash.port_name = ' - '
        }
        indHashArr.push(hash)
        hashArr = indHashArr
      })
    })
    individualArr.push(hashArr)
    return individualArr
  }

  function onFetchFromParent(name, key) {
    if (
      name === 'species' ||
      name === 'offload_id' ||
      name === 'available_weight'
    ) {
      return (
        <List>
          <ListItem className={props.classes.inventorylistItem}>{key}</ListItem>
        </List>
      )
    }

    if (name === 'offload_date') {
      if (key) {
        return (
          <List>
            <ListItem className={props.classes.inventorylistItem}>
              {getArrivalDateTime(key, 'date')}
            </ListItem>
          </List>
        )
      } else return key
    }
  }

  function getArrivalDateTime(dateTimeValue, type) {
    let arrivalDate = new Date(dateTimeValue)
    if (type === 'date') {
      return (
        arrivalDate.getMonth() +
        1 +
        '/' +
        arrivalDate.getDate() +
        '/' +
        arrivalDate.getFullYear()
      )
    }
  }

  const changeSearchKey = searchKeyTerm => {
    let paramState = { ...data.paramState }
    paramState.searchKey = searchKeyTerm
    //Everytime there is a change in search key
    //page selected should be set to first page
    paramState.pageSelected = 1
    loadData(paramState, true)
  }

  function searchWithDateRange(fromDate, toDate) {
    let paramState = { ...data.paramState }
    paramState.fromDate = fromDate
    paramState.toDate = toDate
    loadData(paramState, true)
  }

  function handleChange(fieldName, status) {
    setData({
      ...data,
      [fieldName]: status,
    })
  }

  function handleFilterOnclick(status) {
    setData({
      ...data,
      filterOptionOpen: status,
    })
  }

  function handleFilterSelection(sortBy, fromDate, toDate) {
    let paramState = { ...data.paramState }
    paramState.fromDate = fromDate
    paramState.toDate = toDate
    loadData(paramState, true)
  }

  function displayComponent() {
    if (data.changeView === 'list') {
      return (
        <InventoryListContainer
          headCells={headCells}
          displayData={createListData(inventoryList)}
          startIndex={data.startIndex}
          paramState={data.paramState}
          loadData={loadData}
          displayDataCount={data.displayDataCount}
          initialLimitPerPage={initialLimitPerPage}
          isLoading={data.isLoading}
          onFetchFromParent={onFetchFromParent}
          expandData={createExpandData(inventoryList)}
        />
      )
    } else {
      return <InventoryCardView inventory={createListData(inventoryList)} />
    }
  }

  return (
    <div>
      <div>
        <div className={props.classes.outerGrid}>
          <Grid
            container
            item
            xs={12}
            md={12}
            spacing={1}
            alignItems="flex-end"
          >
            <Grid container item xs={12} md={3} lg={3}>
              <Grid item xs={8} sm={5} md={12} lg={12}>
                <SearchComponent onChangeSearchKey={changeSearchKey} />
              </Grid>

              <Hidden only={['md', 'lg', 'xl']}>
                <Grid item xs={2} sm={4} />
                <Grid
                  container
                  item
                  xs={2}
                  sm={3}
                  alignItems="flex-end"
                  className={props.classes.filterOptionGrid}
                >
                  <SortIcon onClick={() => handleFilterOnclick(true)} />
                  <div key={data.filterOptionOpen}>
                    <FilterOptionDrawer
                      open={data.filterOptionOpen}
                      handleOnClose={handleFilterOnclick}
                      sortOptions={props.sortOptions}
                      onFilterSelection={handleFilterSelection}
                      sortBy=""
                      fromDate={data.paramState.fromDate}
                      toDate={data.paramState.toDate}
                      isSortEnabled={props.isSortEnabled}
                    />
                  </div>
                </Grid>
              </Hidden>
            </Grid>

            <Hidden only={['xs', 'sm']}>
              <Grid item xs={4} className={props.classes.filterOptionGrid}>
                <Grid container item xs={12} justify="flex-end">
                  <DateRangeComponent
                    fromDate={data.paramState.fromDate}
                    toDate={data.paramState.toDate}
                    onDateRangeChange={searchWithDateRange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} className={props.classes.filterOptionGrid} />
              <Grid item xs={2} className={props.classes.filterOptionGrid}>
                <Grid container item xs={12} justify="space-evenly">
                  <AddShoppingCartIcon fontSize="small" />
                  <GetApp fontSize="small" />
                  <Print fontSize="small" />
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container>
            <Grid container item xs={6} md={1}>
              <Grid item xs={6} md={5}>
                <img
                  src={
                    data.changeView === 'tile'
                      ? `${TileViewActive}`
                      : `${TileView}`
                  }
                  onClick={() => handleChange('changeView', 'tile')}
                  className={props.classes.navIcons}
                />
              </Grid>
              <Grid item xs={6} md={5}>
                <img
                  src={
                    data.changeView === 'list'
                      ? `${ListViewActive}`
                      : `${ListView}`
                  }
                  onClick={() => handleChange('changeView', 'list')}
                  className={props.classes.navIcons}
                />
              </Grid>
              <Grid item md={2} />
            </Grid>
            <Grid container item xs={6} md={11} />
          </Grid>
          {data.isLoading ? <LoadingIndicator /> : displayComponent()}
          <Typography display="inline"> {data.errorMsg} </Typography>
        </div>
        {data.errorMsg ? (
          <ErrorMessageComponent errorMessage={data.errorMsg} />
        ) : null}
      </div>
    </div>
  )
}

export const DealerInventoryContainer = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(DealerInventory))
