import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import { withHailTheme } from '../../pages/shared/hailTheme'
import {
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import RedTile from '../../assets/hail/RedTile.png'
import YellowTile from '../../assets/hail/YellowTile.png'
import GreenTile from '../../assets/hail/GreenTile.png'
import { format } from 'date-fns'
import { findPortById } from '../../selectors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import { useSelector } from 'react-redux'
import { selectHailSyncStatus } from '../../features/hailSlice'

const styles = () => ({
  draftReport: {
    color: 'black',
    fontWeight: 'regular',
    backgroundImage: `url(${RedTile})`,
    backgroundSize: '100% 100%',
    minHeight: '25vh',
    paddingLeft: '15vw',
    paddingTop: '4vh',
    marginBottom: '-7vh',
  },

  submittedReport: {
    color: 'black',
    fontWeight: 'regular',
    backgroundImage: `url(${YellowTile})`,
    backgroundSize: '100% 100%',
    minHeight: '25vh',
    paddingLeft: '15vw',
    paddingTop: '4vh',
    marginBottom: '-7vh',
  },

  landedReport: {
    color: 'black',
    fontWeight: 'regular',
    backgroundImage: `url(${GreenTile})`,
    backgroundSize: '100% 100%',
    minHeight: '25vh',
    paddingLeft: '15vw',
    paddingTop: '4vh',
    marginBottom: '-7vh',
  },

  secondaryAction: {
    minHeight: '11vh',
    padding: '10%',
  },
})

function hailStyle(props) {
  if (props.hail.status === 'draft') {
    return props.classes.draftReport
  } else if (
    props.hail.status === 'submitted' ||
    props.hail.status === 'accepted'
  ) {
    return props.classes.submittedReport
  }
  return props.classes.landedReport
}

export function HailItem(props) {
  const hailStatus = useSelector(state => {
    return selectHailSyncStatus(state.hails, props.hail.uuid)
  })
  const port = findPortById(props.ports, props.hail.port_id)
  const pathId = props.hail.order_number
    ? props.hail.order_number
    : props.hail.uuid
  const history = useHistory()
  const editHail = () => {
    const editHailLink = {
      pathname: '/vessel/' + pathId,
      state: { from: '/vessel' },
    }
    history.push(editHailLink)
  }

  function showListItemAction() {
    if (hailStatus === 'waiting_for_sync') {
      return (
        <ListItemSecondaryAction className={props.classes.secondaryAction}>
          <IconButton aria-label="status">
            <SyncProblemIcon style={{ color: 'red' }} />
          </IconButton>
        </ListItemSecondaryAction>
      )
    } else {
      return (
        <ListItemSecondaryAction className={props.classes.secondaryAction}>
          <IconButton aria-label="status">
            <CheckCircleIcon style={{ color: 'green' }} />
          </IconButton>
        </ListItemSecondaryAction>
      )
    }
  }

  return (
    <ListItem
      alignItems="flex-start"
      className={hailStyle(props)}
      onClick={() => editHail(props.hail)}
    >
      <ListItemText
        primary={props.hail.order_number ? props.hail.order_number : 'N/A'}
        secondary={
          <React.Fragment>
            <Typography component={'span'} variant="body2" color="textPrimary">
              <Trans i18nKey="arriving" />:{' '}
              {port ? port.name + ', ' + port.state : 'N/A'}
            </Typography>
            <br />
            <Typography component={'span'} variant="body2" color="textPrimary">
              {format(new Date(props.hail.arrival_date), 'MMMM dd yyyy')}{' '}
              {format(new Date(props.hail.arrival_time), 'h:mm a')}
            </Typography>
          </React.Fragment>
        }
      />
      {showListItemAction()}
    </ListItem>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ports: state.ports,
  }
}

const mapDispatchToProps = {}

export const HailItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(withHailTheme(HailItem)))
