import { createMuiTheme } from '@material-ui/core/styles'
import React from 'react'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontWeight: 400,
    },

    overrides: {
      MuiInput: {
        root: {
          color: 'white',
        },
        underline: {
          borderBottom: '1px solid white',
          '&&&&:before': {
            borderBottom: '0px solid white',
          },
          '&&&&:after': {
            borderBottom: '0px solid white',
          },
        },
      },

      MuiInputLabel: {
        root: {
          color: 'white',
          textTransform: 'upperCase',
          '&$focused': {
            color: 'white',
          },
        },
        shrink: {
          transform: 'translate(36px, 24px) scale(1)',
        },
      },

      MuiFormLabel: {
        root: {
          color: 'white',
          '&$focused': {
            color: 'white',
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'top left',
          },
        },
        filled: {
          transform: 'translate(0, 1.5px) scale(0.75) !important',
          transformOrigin: 'top left !important',
        },
      },
      MuiButton: {
        outlined: {
          border: 'none',
        },
      },

      /*Below are styles specific for Trace App*/

      /*style for sustainability learn more link in verification result*/
      MuiDialog: {
        scrollPaper: {
          paddingTop: '5vh',
          [breakpoints.up('lg')]: {
            width: '50vw',
            margin: 'auto',
          },
        },
        paperFullScreen: {
          borderRadius: '20px',
        },
      },

      MuiAppBar: {
        root: {
          [breakpoints.up('lg')]: {
            width: '50vw',
            marginRight: '25vw',
          },
        },
      },
      /*style for tool bar in verification result*/
      MuiToolbar: {
        gutters: {
          marginTop: '-7px',
          marginBottom: '-8px',
        },
      },
      // Color change for notification dot
      MuiBadge: {
        colorSecondary: {
          backgroundColor: '#f4a721',
        },
      },
      // Style for report a problem dialog
      MuiOutlinedInput: {
        input: {
          padding: '8px 8px',
        },
      },
    },
  })
)

export const withTheme = WrappedComponent => {
  class Wrapper extends React.PureComponent {
    render() {
      return (
        <MuiThemeProvider theme={theme}>
          <WrappedComponent {...this.props} />
        </MuiThemeProvider>
      )
    }
  }
  return Wrapper
}
