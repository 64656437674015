import React from 'react'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
// import { Trans } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alertText: {
    fontSize: '10px',
  },
}))

export default function ErrorMessageComponent(props) {
  const classes = useStyles()
  let errorMessage = props.errorMessage ? props.errorMessage.toString() : ''
  if (errorMessage.indexOf(',') !== -1) {
    errorMessage = errorMessage.split(',').map(line => (
      <span key={line.length} className={classes.alertText}>
        {line}
        <br />
      </span>
    ))
  }
  return (
    <div className={classes.root}>
      <Alert severity="error">{errorMessage}</Alert>
    </div>
  )
}
