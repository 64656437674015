import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { useHistory } from 'react-router-dom'
import { LogoContainer } from '../components/Logo'
import { NorthernWindLogoContainer } from '../components/NorthernWindLogo'
import { DeviShrimpLogoContainer } from '../components/trace/DeviShrimpLogo'
import { ButcherBoxLogoContainer } from '../components/trace/butcherbox/ButcherBoxLogo'
import { BigEasyLogoContainer } from '../components/trace/bigeasy/BigEasyLogo'
import { ChannelFishLogoContainer } from '../components/trace/channelfish/ChannelFishLogo'
import { EastCoastLogoContainer } from '../components/trace/eastcoast/EastCoastLogo'
import '../assets/Trace.css'
import GpsImage from '../assets/GpsImage.png'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TraceDesktopBackground from '../assets/TraceDesktopBackground.png'
import TraceDesktopBackground1 from '../assets/TraceDesktopBackground1.svg'
import ButcherboxTraceHomeBackground from '../assets/trace/butcherbox/ButcherboxTraceHomeBackground.png'
import DeviBackground from '../assets/trace/devishrimp/DeviBackground.svg'
import BigEasyTraceHomeBackground from '../assets/trace/bigeasy/BigEasyTraceHomeBackground.png'
import ChannelFishBgImage from '../assets/trace/channelfish/ChannelFishBgImage.png'
import ChannelFishBgImage1 from '../assets/trace/channelfish/ChannelFishBgImage1.png'
import butcherboxshrimplotnumber from '../assets/trace/butcherbox/butcherboxshrimplotnumber.png'
import bbscallopslotnumber from '../assets/trace/butcherbox/bbscallopslotnumber.png'
import EastCoastBGImage from '../assets/trace/eastcoast/EastCoastBGImage.png'

const styles = () => ({
  helpMeText: {
    color: 'white',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '1rem',
  },

  traceButton: {
    marginTop: '1vh',
    minWidth: '152px',
    backgroundColor: 'white',
    fontSize: '1rem',
    color: '#06669A',
    fontWeight: '500',
    lineHeight: '2.5',
    borderRadius: '3.2rem',
    '&:hover': {
      backgroundColor: 'white',
    },
  },

  northernWindContainer: {
    color: 'white',
    fontWeight: 'regular',
    textAlign: 'center',
    backgroundImage: `url(${TraceDesktopBackground})`,
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${TraceDesktopBackground1})`,
    },
    backgroundAttachment: 'fixed',
    backgroundSize: '100% 100%',
    maxWidth: '100%',
    minHeight: '100vh',
    position: 'fixed',
  },

  channelFishContainer: {
    color: 'white',
    fontWeight: 'regular',
    textAlign: 'center',
    backgroundImage: `url(${ChannelFishBgImage})`,
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${ChannelFishBgImage1})`,
    },
    backgroundAttachment: 'fixed',
    backgroundSize: '100% 100%',
    maxWidth: '100%',
    minHeight: '100vh',
    position: 'fixed',
    boxShadow: 'inset 0 0 0 3000px rgb(3 35 54 / 85%)',
  },

  deviContainer: {
    color: 'white',
    fontWeight: 'regular',
    textAlign: 'center',
    backgroundPosition: 'right',
    backgroundSize: '140% 190%',
    backgroundImage: `url(${DeviBackground})`,
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '100% 180%',
    },
    backgroundAttachment: 'fixed',
    maxWidth: '100%',
    minHeight: '100vh',
    position: 'fixed',
  },

  butcherBoxContainer: {
    color: 'white',
    fontWeight: 'regular',
    textAlign: 'center',
    backgroundImage: `url(${ButcherboxTraceHomeBackground})`,
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '100% 200%',
    },
    backgroundAttachment: 'fixed',
    backgroundSize: '100% 100%',
    maxWidth: '100%',
    minHeight: '100vh',
    position: 'fixed',
  },

  bigEasyContainer: {
    color: 'white',
    fontWeight: 'regular',
    textAlign: 'center',
    backgroundImage: `url(${BigEasyTraceHomeBackground})`,
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '100% 200%',
    },
    backgroundAttachment: 'fixed',
    backgroundSize: '100% 100%',
    maxWidth: '100%',
    minHeight: '100vh',
    position: 'fixed',
  },

  eastCoastContainer: {
    color: 'white',
    fontWeight: 'regular',
    textAlign: 'center',
    backgroundImage: `url(${EastCoastBGImage})`,
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '100% 200%',
    },
    backgroundAttachment: 'fixed',
    backgroundSize: '100% 100%',
    maxWidth: '100%',
    minHeight: '100vh',
    position: 'fixed',
    boxShadow: 'inset 0 0 0 3000px rgb(3 35 54 / 50%)',
  },

  helpMeHeading: {
    fontFamily: 'Rubik',
    fontWeight: 600,
    fontSize: '1.05rem',
    color: '#4F596F',
  },
  shrimpLotImage: {
    width: '100%',
  },
  agreement: {
    fontSize: '1.1rem',
    paddingBottom: '1vh',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.4rem',
    },
  },
  bottomBox: {
    position: 'relative',
    zIndex: '-1',
  },
  bottomContainer: {
    minWidth: '100%',
    position: 'absolute',
    bottom: '3vh',
  },
})

export function Trace(props) {
  const history = useHistory()

  const [trackingId, setTrackingId] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const hostName = window.location.hostname

  const traceId = () => {
    const verificationResult = {
      pathname: '/trace/' + trackingId,
      state: { from: 'trace' },
    }
    if (trackingId === '') {
      setErrorMsg('Please enter a valid lot number')
    } else {
      history.push(verificationResult)
    }
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  function selectClientLogo() {
    if (hostName.startsWith('d01')) {
      return (
        <Grid item xs={12}>
          <DeviShrimpLogoContainer />
        </Grid>
      )
    } else if (hostName.startsWith('bb')) {
      return (
        <Grid item xs={12}>
          <ButcherBoxLogoContainer />
        </Grid>
      )
    } else if (hostName.startsWith('be01')) {
      return (
        <Grid item xs={12}>
          <BigEasyLogoContainer />
        </Grid>
      )
    } else if (hostName.startsWith('cf')) {
      return (
        <Grid item xs={12}>
          <ChannelFishLogoContainer />
        </Grid>
      )
    } else if (hostName.startsWith('ec')) {
      return (
        <Grid item xs={12}>
          <EastCoastLogoContainer />
        </Grid>
      )
    } else {
      return (
        <Grid item xs={12}>
          <NorthernWindLogoContainer />
        </Grid>
      )
    }
  }

  function selectClientContainer() {
    if (hostName.startsWith('d01')) {
      return props.classes.deviContainer
    } else if (hostName.startsWith('bb')) {
      return props.classes.butcherBoxContainer
    } else if (hostName.startsWith('be01')) {
      return props.classes.bigEasyContainer
    } else if (hostName.startsWith('cf')) {
      return props.classes.channelFishContainer
    } else if (hostName.startsWith('ec')) {
      return props.classes.eastCoastContainer
    } else {
      return props.classes.northernWindContainer
    }
  }

  function showDialogContent() {
    if (hostName.startsWith('bb05')) {
      return (
        <DialogContent>
          <DialogContentText className={props.classes.helpMeHeading}>
            <Trans i18nKey="shrimp_lot_number" />.
          </DialogContentText>
          <br />
          <img
            alt="Shrimp Lot"
            src={butcherboxshrimplotnumber}
            className={props.classes.shrimpLotImage}
          />
        </DialogContent>
      )
    } else if (
      hostName.startsWith('bb02') ||
      hostName.startsWith('bb04') ||
      hostName.startsWith('ec') ||
      hostName.startsWith('d01')
    ) {
      return (
        <DialogContent>
          <DialogContentText className={props.classes.helpMeHeading}>
            <Trans i18nKey="salmon_lot_number" />.
          </DialogContentText>
        </DialogContent>
      )
    } else if (hostName.startsWith('bb03')) {
      return (
        <DialogContent>
          <DialogContentText className={props.classes.helpMeHeading}>
            <Trans i18nKey="scallop_lot_number" />.
          </DialogContentText>
          <br />
          <img
            alt="Scallop Lot"
            src={bbscallopslotnumber}
            className={props.classes.shrimpLotImage}
          />
        </DialogContent>
      )
    } else if (hostName.startsWith('bb')) {
      return (
        <DialogContent>
          <DialogContentText className={props.classes.helpMeHeading}>
            <Trans i18nKey="lot_number_located" />.
          </DialogContentText>
          <br />
          <Typography gutterBottom>
            <Trans i18nKey="add_photo_of_package" />
          </Typography>
        </DialogContent>
      )
    } else if (hostName.startsWith('cf01')) {
      return (
        <DialogContent>
          <DialogContentText className={props.classes.helpMeHeading}>
            <Trans i18nKey="how_do_i_find_lot" />?
          </DialogContentText>
          <br />
          <Typography gutterBottom>
            <Trans i18nKey="generic_lot_number_is_located" />.
          </Typography>
        </DialogContent>
      )
    } else {
      return (
        <DialogContent>
          <DialogContentText className={props.classes.helpMeHeading}>
            <Trans i18nKey="how_do_i_find_lot" />?
          </DialogContentText>
          <br />
          <Typography gutterBottom>
            <Trans i18nKey="lot_number_is_located" />.
            <br />
            <br />
            <Trans i18nKey="sequence_of_numbers" />.
          </Typography>
        </DialogContent>
      )
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container width="100%" className={selectClientContainer()}>
        <Box height="54vh">
          <Container maxWidth="sm">
            <Grid container direction="column" justify="flex-end">
              <Grid
                container
                item
                direction="row"
                alignItems="center"
                style={{ marginTop: '15vh', marginBottom: '5vh' }}
              >
                {selectClientLogo()}
              </Grid>
              <form onSubmit={() => traceId()}>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  direction="row"
                >
                  <Grid item xs={12} className={props.classes.formControl}>
                    <label className={props.classes.textRegular}>
                      <Trans i18nKey="enter_the_lot_number" />
                    </label>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  direction="row"
                >
                  <Grid item xs={12} className={props.classes.formControl}>
                    <TextField
                      id="trackingIdNumber"
                      error={errorMsg.length === 0 ? false : true}
                      label="Lot Number"
                      value={trackingId}
                      onChange={e => setTrackingId(e.currentTarget.value)}
                      margin="normal"
                      fullWidth
                      helperText={errorMsg}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              alt="GPS"
                              src={GpsImage}
                              style={{ width: '70%' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  direction="row"
                >
                  <Grid item xs={12} className={props.classes.formControl}>
                    <div>
                      <label
                        className={props.classes.helpMeText}
                        onClick={handleClickOpen}
                      >
                        <Trans i18nKey="help_me_find_lot" />
                      </label>
                      <Dialog
                        aria-labelledby="customized-dialog-title"
                        open={open}
                      >
                        {showDialogContent()}
                        <DialogActions>
                          <Button
                            autoFocus
                            onClick={handleClose}
                            color="primary"
                          >
                            <Trans i18nKey="close" />
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  alignItems="flex-start"
                  direction="row"
                >
                  <Grid item xs={12} className={props.classes.formControl}>
                    <Button
                      id="formButton"
                      className={props.classes.traceButton}
                      variant="outlined"
                      type="submit"
                      margin="normal"
                    >
                      <Trans i18nKey="trace" />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Container>
        </Box>
        <Box height="46vh" className={props.classes.bottomBox}>
          <Container className={props.classes.bottomContainer}>
            <Grid container justify="center" alignItems="flex-end">
              <Grid item xs={12}>
                <Typography className={props.classes.agreement}>
                  <label>Trace data powered by</label>
                </Typography>
                <LogoContainer />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </MuiThemeProvider>
  )
}

const mapStateToProps = () => {
  return {
    //TODO: related code to be added when functionality is built-ins
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(Trace))
