import { SET_CURRENT_USER, CLEAR_CURRENT_USER, SAVE_USER } from '../actions'

const initialState = {}

export default function user(state = initialState, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case SET_CURRENT_USER:
      newState = action.currentUser
      return newState

    case CLEAR_CURRENT_USER:
      newState = initialState
      return newState

    case SAVE_USER:
      return action.user

    default:
      return state
  }
}
