import React, { useState, useEffect } from 'react'
import { setDealerNewModifyOffloads } from '../../actions'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  Divider,
  Typography,
  TextField,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import { Trans, useTranslation } from 'react-i18next'
import withDealerTheme from '../shared/DealerStyles'
import BootstrapInput from './TextfieldForDealer'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'

const themes = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '0px 0px !important',
      },
      option: {
        fontSize: '0.75rem',
        textTransform: 'none',
      },
      inputRoot: {
        height: '2rem',
        borderRadius: '0px',
        backgroundColor: '#F6F9FA',
        fontSize: '0.75rem',
        '&$focused': {
          borderColor: '#E0E0E0',
        },
      },
    },
  },
})

export function SpeciesInfoComponent(props) {
  const classes = withDealerTheme()

  const { t } = useTranslation()

  let speciesArray = 'lots_attributes'
  let arrayIndex = props.index

  const dispatch = useDispatch()
  const species = useSelector(state => state.species)
  const fishingAreas = useSelector(state => state.fishingAreas)
  const counts = useSelector(state => state.counts)
  const dealerNewModifyOffload = useSelector(
    state => state.dealerNewModifyOffload
  )

  useEffect(() => {}, [])

  const handleSpeciesInfo = (event, id, name) => {
    let index = id
    if (dealerNewModifyOffload.offload[speciesArray][index]) {
      let orderToModify
      orderToModify = { ...dealerNewModifyOffload }
      orderToModify.offload[speciesArray][index][name] = event.target.value
      dispatch(setDealerNewModifyOffloads(orderToModify))
    }
  }

  const handleDeleteSpeciesInfo = (evt, index) => {
    evt.preventDefault()
    let dealerNewModifyOffloads
    dealerNewModifyOffloads = { ...dealerNewModifyOffload }
    dealerNewModifyOffloads.offload[speciesArray][index]._destroy = 1
    dispatch(setDealerNewModifyOffloads(dealerNewModifyOffloads))
  }

  const [speciesSearchResult, setspeciesSearchResult] = useState([])
  const speciesSearch = event => {
    let searchKey = event.target.value
    if (searchKey && searchKey.length >= 3) {
      const filtered = species.filter(entry =>
        Object.values(entry).some(
          val =>
            typeof val === 'string' &&
            val.toLowerCase().startsWith(searchKey.toLowerCase())
        )
      )
      setspeciesSearchResult(filtered)
    } else {
      setspeciesSearchResult([])
    }
  }

  const handleSpeciesSelection = (value, id, name) => {
    let index = id
    let speciesHash = species.find(o => o.name === value)
    let selectedSpeciesId = ''
    if (speciesHash) {
      selectedSpeciesId = speciesHash.id
    }
    if (dealerNewModifyOffload.offload[speciesArray][index]) {
      let orderToModify
      orderToModify = { ...dealerNewModifyOffload }
      orderToModify.offload[speciesArray][index][name] = selectedSpeciesId
      dispatch(setDealerNewModifyOffloads(orderToModify))
    }
  }

  const speciesValue = id => {
    let speciesSelected
    if (id) {
      speciesSelected = species.find(object => object.id === id).name
    } else {
      speciesSelected = ''
    }
    return speciesSelected
  }

  return (
    <Grid
      container
      className={
        arrayIndex === 0 ? classes.firstRowGrid : classes.nonFirstRowGrid
      }
      spacing={2}
    >
      <Hidden smDown>
        <Grid container item md={2}>
          <Grid item md={11}>
            {' '}
          </Grid>
          <Grid item md={1}>
            <p className={classes.serialNumber}>{arrayIndex + 1}. </p>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container item xs={12} justify="flex-start">
          <Grid container item xs={6}>
            {arrayIndex + 1}.
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <DeleteIcon
              fontSize="small"
              className={classes.deleteIcon}
              onClick={event => handleDeleteSpeciesInfo(event, arrayIndex)}
            />
          </Grid>
        </Grid>
      </Hidden>

      <Grid container item md={9}>
        {/* First Row Start */}
        <Grid container item xs={12} md={4}>
          <Grid item xs={12} md={10}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel shrink id="species" className={classes.labelText}>
                <Trans i18nKey="species_1" />
              </InputLabel>
              <MuiThemeProvider theme={themes}>
                <Autocomplete
                  freeSolo
                  options={speciesSearchResult.map(option => option.name)}
                  onChange={(event, value) =>
                    handleSpeciesSelection(value, arrayIndex, 'species_id')
                  }
                  value={speciesValue(
                    dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                      'species_id'
                    ] &&
                      dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                        'species_id'
                      ]
                  )}
                  className={classes.autoComplete}
                  renderInput={params => (
                    <TextField
                      onChange={searchKey => speciesSearch(searchKey)}
                      {...params}
                      margin="normal"
                      variant="outlined"
                      placeholder={t(
                        'select_the_species',
                        'Select the Species'
                      )}
                    />
                  )}
                />
              </MuiThemeProvider>
            </FormControl>
          </Grid>
          <Grid item md={2} />
        </Grid>
        <Grid container item xs={12} md={8}>
          <Grid item xs={12} md={5} className={classes.speciesInfo}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel shrink id="stock_area" className={classes.labelText}>
                <Trans i18nKey="stock_area" />
              </InputLabel>
              <Select
                value={
                  dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                    'stat_area_id'
                  ]
                    ? dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                        'stat_area_id'
                      ]
                    : ' '
                }
                name="stat_area_id"
                onChange={e => handleSpeciesInfo(e, arrayIndex, 'stat_area_id')}
                displayEmpty
                disableUnderline
                className={classes.selectEmpty}
                placeholder={String(<Trans i18nKey="stock_area" />)}
              >
                <MenuItem value=" ">
                  <Typography className={classes.selectMenuPlaceholder}>
                    {' '}
                    <Trans i18nKey="stock_area" />{' '}
                  </Typography>
                </MenuItem>
                {fishingAreas.map(fishingArea => {
                  return (
                    <MenuItem
                      key={fishingArea.id}
                      value={fishingArea.id}
                      className={classes.selectMenuText}
                    >
                      {fishingArea.description}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1} />
          <Grid item xs={12} md={5} className={classes.speciesInfo}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel shrink id="counts" className={classes.labelText}>
                <Trans i18nKey="counts" />
              </InputLabel>
              <Select
                name="counts"
                value={
                  dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                    'market_description_id'
                  ]
                    ? dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                        'market_description_id'
                      ]
                    : ' '
                }
                displayEmpty
                disableUnderline
                className={classes.selectEmpty}
                placeholder={String(<Trans i18nKey="select_counts" />)}
                onChange={e =>
                  handleSpeciesInfo(e, arrayIndex, 'market_description_id')
                }
              >
                <MenuItem value=" ">
                  <Typography className={classes.selectMenuPlaceholder}>
                    {' '}
                    <Trans i18nKey="counts" />{' '}
                  </Typography>
                </MenuItem>
                {counts.map(count => {
                  return (
                    <MenuItem
                      key={count.id}
                      value={count.id}
                      className={classes.selectMenuText}
                    >
                      {count.description}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* First Row End */}
        {/* Second Row Start */}
        <Grid container item xs={12} md={4}>
          <Grid item xs={12} md={10} className={classes.nonFirstRow}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                className={classes.labelText}
              >
                <Trans i18nKey="meat_lbs" />
              </InputLabel>
              <BootstrapInput
                name="meat_lbs"
                placeholder={t('meat_lbs', 'Meat Lbs')}
                onChange={e => handleSpeciesInfo(e, arrayIndex, 'quantity')}
                value={
                  dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                    'quantity'
                  ]
                    ? dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                        'quantity'
                      ]
                    : ''
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={2} className={classes.nonFirstRow} />
        </Grid>
        <Grid container item xs={12} md={8} className={classes.speciesInfo}>
          <Grid item xs={12} md={5} className={classes.nonFirstRow}>
            <FormControl className={classes.margin} fullWidth>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                className={classes.labelText}
              >
                <Trans i18nKey="dealer_market_name" />
              </InputLabel>
              <BootstrapInput
                value={
                  dealerNewModifyOffload.offload &&
                  dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                    'custom_market_name_attr'
                  ]
                    ? dealerNewModifyOffload.offload[speciesArray][arrayIndex][
                        'custom_market_name_attr'
                      ]
                    : ''
                }
                name="custom_market_name_attr"
                id="custom_market_name_attr"
                onChange={e =>
                  handleSpeciesInfo(e, arrayIndex, 'custom_market_name_attr')
                }
                placeholder={t('dealer_market_name', 'Market Name')}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} />
        </Grid>
        {/* Second Row end */}
      </Grid>
      <Hidden smDown>
        <Grid container item md={1}>
          <Grid item md={2}>
            <Divider
              orientation="vertical"
              classes={{ root: classes.dividerColor }}
            />
          </Grid>
          <Grid item md={10}>
            <DeleteIcon
              fontSize="small"
              className={classes.deleteIcon}
              onClick={event => handleDeleteSpeciesInfo(event, arrayIndex)}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Divider classes={{ root: classes.dividerColor }} />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export const SpeciesInfoComponentContainer = SpeciesInfoComponent
