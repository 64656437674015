import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  InfoText: {
    fontWeight: 400,
    fontSize: '14pt',
  },
  subText: {
    fontWeight: 300,
    fontSize: '10pt',
  },
  outerGrid: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  outerCard: {
    width: '100%',
  },
}))

export function StatusCard(props) {
  const classes = useStyles()
  const imageName = props.imageName

  return (
    <Card className={classes.outerCard}>
      <CardContent>
        <Grid container spacing={1} className={classes.outerGrid}>
          <Grid item xs={12} sm={9} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography display="inline" className={classes.InfoText}>
                  {' '}
                  {props.mainText}{' '}
                </Typography>
                <Typography display="inline" className={classes.subText}>
                  {' '}
                  {props.subText}{' '}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display="inline" className={classes.subText}>
                  {props.footer}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Grid item xs={12}>
              <img
                src={require('../assets/' + imageName)}
                alt="logo"
                style={{ height: 45, width: 45 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
