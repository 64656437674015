import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import { ApiHelper } from '../lib'
import { setReferenceData } from '../actions'
import { AppContainer } from '../components'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
  },
})

export function LandingRoot(props) {
  //const [isFetching, setIsFetching] = useState(false)
  const api = new ApiHelper()

  useEffect(() => {
    if (!props.state.referenceData.action) {
      getRootData()
    }
  }, [])

  async function getRootData() {
    //setIsFetching(true)
    try {
      const response = await api.get('landing_root')
      const json = await response.json()
      //setIsFetching(false)
      if (response.status === 200) {
        props.setReferenceData(json.reference_data)
      } else {
        api.handleError()
      }
    } catch (error) {
      //setIsFetching(false)
      alert(error)
    }
  }

  return (
    <AppContainer maxWidth="sm">
      <h1>Root Landing</h1>
      <h3>props.state:</h3>
      <pre>{JSON.stringify(props.state, null, 2)}</pre>
    </AppContainer>
  )
}

const mapStateToProps = state => {
  return { state }
}

const mapDispatchToProps = { setReferenceData }

export const LandingRootContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LandingRoot))
