import { createMuiTheme } from '@material-ui/core/styles'
import React from 'react'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'

export const dealerTheme = responsiveFontSizes(
  createMuiTheme({
    overrides: {
      MuiPaginationItem: {
        textPrimary: {
          '&$selected': {
            backgroundColor: '#003E71',
            '&:hover': {
              backgroundColor: '#003E71 !important',
            },
          },
        },
      },
      MuiList: {
        padding: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
      MuiInputLabel: {
        root: {
          color: '#4D4D4D',
          '&$focused': {
            color: '#4D4D4D',
          },
          '&$disabled': {
            color: '#B3B3B3',
          },
          '&$error': {
            color: '#B80000',
          },
          '&$filled': {
            color: '#4D4D4D',
          },
        },
      },
    },
  })
)

export const withDealerTheme = WrappedComponent => {
  class Wrapper extends React.PureComponent {
    render() {
      return (
        <MuiThemeProvider theme={dealerTheme}>
          <WrappedComponent {...this.props} />
        </MuiThemeProvider>
      )
    }
  }
  return Wrapper
}
