import React from 'react'
import {
  withStyles,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core'
import { withDealerTheme } from '../../shared/DealerTheme'
import combineStyles from '../../shared/CombineStyles'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'
import Cod from '../../../assets/dealer/Cod.svg'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Trans } from 'react-i18next'

const styles = () => ({})

export function CardView(props) {
  return (
    <Grid container spacing={3}>
      {props.inventory.map((inventory, index) => {
        let dateString = inventory.offload_date.split('-')
        dateString = [dateString[1], dateString[2]]
        dateString = dateString.join('/')
        return (
          <Grid item xs={12} md={3} key={index}>
            <Card className={props.classes.cardView}>
              <CardContent>
                <Grid container item xs={12} md={12}>
                  <Grid item xs={12} md={9}>
                    <Typography className={props.classes.tileHeading}>
                      {inventory.species}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={3} direction="column">
                    <Grid item xs={5} md={6}>
                      <Typography className={props.classes.lightText}>
                        <Trans i18nKey="oldest" />
                      </Typography>
                    </Grid>
                    <Grid item xs={5} md={6}>
                      <Typography className={props.classes.tileContent}>
                        {dateString}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={12} style={{ color: 'green' }}>
                  <Grid container item xs={12} md={9}>
                    <Grid item xs={3} md={4}>
                      <Typography>$15.51</Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <ArrowDropUpIcon fontSize="small" />
                    </Grid>
                    <Grid item xs={12} md={6} />
                  </Grid>
                  <Grid item xs={12} md={3} />
                </Grid>
                <Grid container item xs={12} md={12}>
                  <img src={Cod} className={props.classes.cardImage} />
                </Grid>
                <Grid container item xs={6} md={12} direction="column">
                  <Grid item xs={6} md={6}>
                    <Typography className={props.classes.lightText}>
                      <Trans i18nKey="available" />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography className={props.classes.tileContent}>
                      {inventory.available_weight} lbs
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={6} md={12} direction="column">
                  <Grid item xs={6} md={6}>
                    <Typography className={props.classes.lightText}>
                      <Trans i18nKey="open_order" />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography className={props.classes.tileContent}>
                      {inventory.open_orders} lbs
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export const InventoryCardView = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(CardView))
