import { SET_USER_NOTIFICATIONS, ADD_NOTIFICATION } from '../actions'

const initialState = { notifications: [], pagination: {} }

function addNotification(current_notifications = [], notification) {
  let result = { ...current_notifications }
  result.notifications = [notification, ...(result.notifications || [])]
  return result
}

export default function userNotifications(state = initialState, action) {
  switch (action.type) {
    case SET_USER_NOTIFICATIONS:
      return action.userNotifications
    case ADD_NOTIFICATION:
      return addNotification(state, action.notification)
    default:
      return state
  }
}
