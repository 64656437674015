import React, { useState } from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import { withHailTheme } from '../../pages/shared/hailTheme'
import FilterListIcon from '@material-ui/icons/FilterList'
import {
  findFishingAreaNameById,
  findMarketDescriptionById,
  findProductById,
} from '../../selectors'
import SearchIcon from '@material-ui/icons/Search'
import { saveHailEventually } from '../../features/hailSlice'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
// import { ApiHelper } from '../../lib'
// import { saveHail, setNewHail } from '../../actions'
import Autocomplete from '@material-ui/lab/Autocomplete'

const styles = () => ({
  speciesListGrid: {
    height: '50vh',
    overflow: 'auto',
  },

  searcIcon: {
    color: '#003E6D',
  },

  cancelButton: {
    paddingTop: '5vh',
  },

  textField: {
    paddingTop: '2vh',
    opacity: '55%',
    textAlign: 'center',
    fontSize: '0.8rem',
  },

  speciesDialog: {
    marginBlockStart: '1em',
    marginBlockEnd: '0em',
  },

  loadingGrid: {
    minHeight: '70vh',
  },
})

function PaperComponent(props) {
  return <Paper {...props} />
}

export function Catch(props) {
  const dispatch = useDispatch()
  const fishingAreas = useSelector(state => state.fishingAreas)
  const species = useSelector(state => state.species)
  const counts = useSelector(state => state.counts)
  const [loadingState] = useState('')

  const [currentLot, setCurrentLot] = useState({})

  const [errorMsg, setErrorMsg] = useState('')

  const [open, setOpen] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  const finalize = () => {
    if (props.hail.lots.length === 0) {
      setErrorMsg('Please select species')
      setOpenDialog(true)
    } else {
      dispatch(saveHailEventually(props.hail))
      props.setShowItems('Finalize')
    }
  }

  const [sortOrder, setSortOrder] = useState([])

  const handleSortToggle = value => () => {
    const currentSortIndex = sortOrder.indexOf(value)
    const newSortOrder = []

    if (currentSortIndex === -1) {
      newSortOrder.push(value)
    } else {
      newSortOrder.splice(currentSortIndex, 1)
    }

    setSortOrder(newSortOrder)
  }

  function compareSpecies(a, b) {
    const specieA = a.name.toUpperCase()
    const specieB = b.name.toUpperCase()

    let comparison = 0
    if (specieA > specieB) {
      comparison = 1
    } else if (specieA < specieB) {
      comparison = -1
    }
    return comparison
  }

  function sortFunctionSelector() {
    if (sortOrder[0] === 'Alphabetical by Species Name') {
      return compareSpecies
    }
  }

  const [showItems, setShowItems] = useState('Default')

  function buttonSelector() {
    if (props.modify) {
      return <Trans i18nKey="modify" />
    } else {
      return <Trans i18nKey="next" />
    }
  }

  const setLots = (lot, specie, checked) => {
    if (checked) {
      editLot(lot, specie)
    } else {
      let lots = props.hail.lots
      const result = lots.filter(l => l.species_id !== specie.id)
      props.setAttribute('lots', result)
    }
  }

  const editLot = (lot, specie) => {
    setErrorMsg('')
    setOpen(true)
    // Product already
    if (lot) {
      setCurrentLot(
        Object.assign({}, currentLot, {
          species_id: specie.id,
          stat_area_id: lot.stat_area_id || props.hail.fishing_area_id,
          quantity: lot.quantity,
          market_description_id: lot.market_description_id,
        })
      )
    } else {
      setCurrentLot(
        Object.assign({}, currentLot, {
          quantity: '',
          species_id: specie.id,
          stat_area_id: props.hail.fishing_area_id,
          market_description_id: '',
        })
      )
    }
  }

  function renderLotText(specie, lot) {
    const labelId = `checkbox-list-label-${specie.id}`
    if (lot) {
      return (
        <ListItemText
          id={labelId}
          className={props.classes.listPriText}
          onClick={() => editLot(lot, specie)}
          primary={specie.name}
          secondary={
            <React.Fragment>
              <Typography
                component={'span'}
                className={props.classes.listSecText}
              >
                {lot.quantity} Lbs |{' '}
                {findFishingAreaNameById(fishingAreas, lot.stat_area_id)}
              </Typography>
            </React.Fragment>
          }
        />
      )
    } else {
      return <ListItemText id={labelId} primary={specie.name} />
    }
  }

  function renderSpecies(specie) {
    const labelId = `checkbox-list-label-${specie.id}`
    let lot =
      props.hail.lots && props.hail.lots.find(l => l.species_id === specie.id)

    return (
      <div key={specie.id}>
        <ListItem key={specie.id} role={undefined} dense button>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={lot != null}
              onClick={e => setLots(lot, specie, e.target.checked)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          {renderLotText(specie, lot)}
        </ListItem>
        <Divider />
      </div>
    )
  }

  function showSelectedSpecies() {
    let selectedLots = props.hail.lots || []
    if (selectedLots.length === 0) {
      return null
    } else {
      return (
        <div>
          <Grid item xs={12}>
            <Typography color="inherit" className={props.classes.headingText}>
              <Trans i18nKey="selected_species" />:
            </Typography>
          </Grid>
          <List>
            {selectedLots.map(lot => {
              const selectedSpecie = findProductById(species, lot.species_id)
              return renderSpecies(selectedSpecie)
            })}
          </List>
        </div>
      )
    }
  }

  function showSpeciesList() {
    if (speciesSearchResult.length === 0) {
      return (
        <div>
          <Grid item container xs={12} justify="center">
            <Grid item container xs={9} justify="center">
              <Typography color="inherit" className={props.classes.textField}>
                <Trans i18nKey="please_search_species_name" />
              </Typography>
            </Grid>
          </Grid>
          <br />
          {showSelectedSpecies()}
        </div>
      )
    } else {
      return (
        <List>
          {speciesSearchResult.sort(sortFunctionSelector()).map(s => {
            return renderSpecies(s)
          })}
        </List>
      )
    }
  }

  function renderSpeciesDialog() {
    return (
      <Dialog open={open} PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title">SELECT SPECIES:</DialogTitle>
        <DialogContent>
          <DialogContentText className={props.classes.speciesDialog}>
            <Trans i18nKey="weight" />*
          </DialogContentText>
          <DialogContentText component={'span'}>
            <TextField
              type="number"
              autoComplete="off"
              id="speciesWeight"
              onChange={e =>
                setCurrentLot(
                  Object.assign({}, currentLot, {
                    quantity: e.currentTarget.value,
                  })
                )
              }
              variant="filled"
              fullWidth
              value={currentLot.quantity || ''}
            />
          </DialogContentText>
          <DialogContentText className={props.classes.speciesDialog}>
            <Trans i18nKey="stat_area" />*
          </DialogContentText>
          <DialogContentText component={'span'}>
            <FormControl fullWidth error>
              <Select
                id="statArea"
                variant="filled"
                onChange={e =>
                  setCurrentLot(
                    Object.assign({}, currentLot, {
                      stat_area_id: e.target.value,
                    })
                  )
                }
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={currentLot.stat_area_id || props.hail.fishing_area_id}
              >
                {fishingAreas.map(fa => {
                  return (
                    <MenuItem key={fa.id} value={fa.id}>
                      {fa.description}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </DialogContentText>
          <DialogContentText className={props.classes.speciesDialog}>
            <Trans i18nKey="market_description" />*
          </DialogContentText>
          <DialogContentText component={'span'}>
            <FormControl fullWidth error>
              <Autocomplete
                id="marketDescription"
                onChange={(e, newValue) =>
                  setCurrentLot(
                    Object.assign({}, currentLot, {
                      market_description_id: newValue ? newValue.id : null,
                    })
                  )
                }
                value={findMarketDescriptionById(
                  counts,
                  currentLot.market_description_id
                )}
                getOptionLabel={option => {
                  return option.description
                }}
                options={counts}
                renderInput={params => (
                  <TextField {...params} label="" variant="filled" fullWidth />
                )}
              />
              <FormHelperText>{errorMsg}</FormHelperText>
            </FormControl>
          </DialogContentText>
          <DialogContentText>
            *<Trans i18nKey="required_field" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="cancelButton"
            autoFocus
            onClick={closeSpeciesDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button id="saveButton" onClick={saveCurrentLot} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const saveCurrentLot = () => {
    if (
      !currentLot.quantity ||
      !currentLot.market_description_id ||
      (!currentLot.stat_area_id && !props.hail.fishing_area_id)
    ) {
      setErrorMsg('Please enter weight, market description & stat area')
    } else {
      setErrorMsg('')
      setOpen(false)
      let lots = props.hail.lots || []
      let lot = lots.find(s => s.species_id === currentLot.species_id)
      // User unchecks an already selected species
      if (lot) {
        let newLots = lots.map(l => {
          if (l.species_id === lot.species_id) {
            return Object.assign({}, l, {
              quantity: currentLot.quantity,
              stat_area_id: currentLot.stat_area_id,
              market_description_id: currentLot.market_description_id,
            })
          }
          return l
        })
        props.setAttribute('lots', newLots)
      } else {
        let newLots = lots.concat([currentLot])
        props.setAttribute('lots', newLots)
      }
    }
    // use selects a new species that does not exist in selected species
  }

  const closeSpeciesDialog = () => {
    setOpen(false)
    setErrorMsg('')
  }

  function displaySortFilters() {
    return (
      <Grid
        id="sortBy"
        container
        spacing={0}
        className={props.classes.bottomGridContainer}
      >
        <Grid item xs={12}>
          <Typography color="inherit" variant="button">
            <Trans i18nKey="sortBy" />:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            {['Alphabetical by Species Name', 'Recently Used', 'Most Used'].map(
              value => {
                const labelId = `checkbox-list-label-${value}`

                return (
                  <div key={value}>
                    <ListItem
                      key={value}
                      role={undefined}
                      dense
                      button
                      onClick={handleSortToggle(value)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={sortOrder.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value} />
                    </ListItem>
                    <Divider />
                  </div>
                )
              }
            )}
          </List>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={props.classes.cancelButton}
          onClick={() => setShowItems('Default')}
        >
          <Typography color="inherit" variant="button">
            <Trans i18nKey="cancel" />
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const [speciesSearchResult, setspeciesSearchResult] = useState([])

  const speciesSearch = event => {
    let searchKey = event.target.value
    if (searchKey && searchKey.length >= 3) {
      const filtered = species.filter(entry =>
        Object.values(entry).some(
          val =>
            typeof val === 'string' &&
            val.toLowerCase().includes(searchKey.toLowerCase())
        )
      )
      setspeciesSearchResult(filtered)
    } else {
      setspeciesSearchResult([])
    }
  }

  function loadCatch() {
    if (showItems === 'Sort') {
      return displaySortFilters()
    } else if (loadingState === 'loading') {
      return (
        <Grid
          id="circularProgress"
          container
          justify="center"
          alignItems="center"
          className={props.classes.loadingGrid}
        >
          <CircularProgress />
        </Grid>
      )
    }
    return (
      <div>
        <Box height="60vh">
          <Grid
            container
            spacing={0}
            className={props.classes.bottomGridContainer}
          >
            <Grid item xs={11}>
              <Typography color="inherit" className={props.classes.headingText}>
                <Trans i18nKey="select_species" />:
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <FilterListIcon
                id="sortByButton"
                data-testid="sortByButton"
                onClick={() => setShowItems('Sort')}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="species"
                data-testid="species"
                options={speciesSearchResult.map(option => option.name)}
                renderInput={params => (
                  <TextField
                    placeholder="&nbsp;SEARCH"
                    onChange={searchKey => speciesSearch(searchKey)}
                    {...params}
                    variant="filled"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className={props.classes.searcIcon}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={props.classes.speciesListGrid}
              id="speciesList"
              data-testid="speciesList"
            >
              {showSpeciesList()}
            </Grid>
          </Grid>
        </Box>
        <Box height="10vh">
          <Grid container justify="center" alignItems="flex-end">
            <Button
              id="catchButton"
              data-testid="catchButton"
              className={props.classes.buttonNext}
              variant="outlined"
              type="button"
              onClick={() => finalize()}
              margin="normal"
            >
              {buttonSelector()}
            </Button>
          </Grid>
        </Box>
        {renderSpeciesDialog()}
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
        >
          <ErrorMessageComponent errorMessage={errorMsg} />
        </Dialog>
      </div>
    )
  }

  return loadCatch()
}

export const CatchContainer = withStyles(combineStyles(styles, stylesBase))(
  withHailTheme(Catch)
)
