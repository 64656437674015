// lib/ApiHelper.js

import CookieHelper from './CookieHelper'
import { clearState } from '../actions'
import { store } from '../configureStore'

const URL_PARTS = {
  apiHost: {
    development: process.env.REACT_APP_API_URL,
    test: process.env.REACT_APP_API_URL,
    staging: process.env.REACT_APP_API_URL,
    production: process.env.REACT_APP_API_URL,
  },
  apiBase: '/v1',
}

// shortName: route
const ENDPOINTS = {
  landing: '/',
  sign_in: '/users/sign_in',
  sign_out: '/users/sign_out',
  landing_root: '/root',
  landing_dealer: '/dealer',
  landing_processor: '/processor',
  landing_vessel: '/vessel',
  trace: '/trace',
  report: '/report',
  ports: '/ports',
  tickets: '/tickets',
  species: '/species',
  user: '/user',
  new_hail_details: '/new_hail_details',
  notifications: '/notifications',
  stock_area: '/dealer/stock_area/list',
  dealer_vessel_list: '/dealer/vessel/list',
  dealer_status: '/dealer/status',
  dealer_inventory_summary: '/dealer/inventory/summary',
  dealer_offloads: '/dealer/offloads',
  dealer_orders: '/dealer/orders',
  counts: '/market_descriptions',
  units: '/dealer/units',
  dealer_locations: '/dealer/locations',
  dealers: '/dealers',
  freight_type: '/dealer/freight_type',
  customer: '/dealer/customer',
  order_create: '/dealer/order',
  fishing_areas: '/fishing_areas',
  products: '/products',
  fees: '/fees',
}

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

const cookie = new CookieHelper()

export class ApiHelper {
  constructor(token = null) {
    this.token = token || cookie.getToken()
    this.apiHost = this.getApiHost()
    this.apiBase = URL_PARTS['apiBase']
  }

  handleErrors(response) {
    if (response && response.status === 401) {
      //Need to clear user state as well
      this.clearToken()
      cookie.clearTokenCookie()
      store.dispatch(clearState())
      return Promise.reject('You need to sign in or sign up before continuing.')
    } else if (response && !response.ok) {
      return response.json().then(data => {
        return Promise.reject(data)
      })
    } else {
      return response
    }
  }

  delete(shortName, params = {}, headers = {}) {
    return fetch(this.endpoint(shortName), {
      method: 'delete',
      headers: this.headers(headers),
      body: JSON.stringify(params),
      credentials: 'include',
    }).then(response => {
      return this.handleErrors(response)
    })
  }

  get(shortName, params = {}, headers = {}) {
    var url = new URL(this.endpoint(shortName))
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )
    return fetch(url.toString(), {
      method: 'get',
      headers: this.headers(headers, false),
      credentials: 'include',
    }).then(response => {
      return this.handleErrors(response)
    })
  }

  post(shortName, params = {}, headers = {}) {
    return fetch(this.endpoint(shortName), {
      method: 'post',
      headers: this.headers(headers),
      body: JSON.stringify(params),
      credentials: 'include',
    }).then(response => {
      return this.handleErrors(response)
    })
  }

  put(shortName, params = {}, headers = {}) {
    return fetch(this.endpoint(shortName), {
      method: 'put',
      headers: this.headers(headers),
      body: JSON.stringify(params),
      credentials: 'include',
    }).then(response => {
      return this.handleErrors(response)
    })
  }

  setToken(token) {
    this.token = token
  }

  clearToken() {
    this.token = null
  }

  // protected

  getApiHost() {
    return process.env.REACT_APP_API_URL
  }

  getWebSocketEndPoint() {
    let apiHost = this.getApiHost()
    let wsUrl = apiHost.replace('http', 'ws')
    wsUrl += '/cable'
    return wsUrl
  }

  endpoint(shortName) {
    if (ENDPOINTS[shortName]) {
      shortName = ENDPOINTS[shortName]
    }
    return this.apiHost + this.apiBase + shortName
  }

  handleError(json) {
    alert('ERROR\n' + JSON.stringify(json, null, 2))
  }

  authHeader() {
    return cookie.isAuthenticated() && this.token
      ? { Authorization: `Bearer ${this.token}` }
      : {}
  }

  csrfHeader(csrf) {
    const csrf_token = cookie.getCookie('CSRF-TOKEN')
    if (csrf && csrf_token) {
      return {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': decodeURIComponent(csrf_token),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
      //return { "X-CSRF-Token": csrf_token }
    }
    return {}
  }

  headers(request_headers, csrf = true) {
    const headers = {
      ...COMMON_HEADERS,
      ...request_headers,
      ...this.csrfHeader(csrf),
    }
    return headers
  }
}
