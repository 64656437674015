import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DeviShrimpLogo from '../../assets/trace/devishrimp/DeviShrimpLogo.svg'

const styles = () => ({
  logoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
})

export function DeviShrimpLogoImage(props) {
  return (
    <Grid
      data-testid="DeviShrimpLogo"
      container
      spacing={1}
      alignItems="flex-end"
      direction="row"
      classes={{ root: props.classes.logoContainer }}
    >
      <Grid item xs={12}>
        <img src={DeviShrimpLogo} alt="Devi Shrimp Logo" />
      </Grid>
    </Grid>
  )
}

export const DeviShrimpLogoContainer = withStyles(styles)(DeviShrimpLogoImage)
