import { SET_SPECIES } from '../actions'

const initialState = []

export default function species(state = initialState, action) {
  switch (action.type) {
    case SET_SPECIES:
      return action.species

    default:
      return state
  }
}
