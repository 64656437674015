import React from 'react'
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Checkbox,
  TableSortLabel,
  IconButton,
  Hidden,
  Chip,
  Tooltip,
  List,
  ListItem,
  withStyles,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import EditIcon from '@material-ui/icons/Edit'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import { Trans } from 'react-i18next'
import classNames from 'classnames'
import addNew from '../../../assets/addNew.svg'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import combineStyles from '../../shared/CombineStyles'
import { withDealerTheme } from '../../shared/DealerTheme'
import { dealerStylesBase } from '../../shared/dealerInventoryStyles'

const styles = () => ({})

export function DealerOrderList(props) {
  const defaultCollapse = {}
  props.displayData.forEach(row => (defaultCollapse[row.id] = false))

  const [data, setData] = React.useState({
    collapse: defaultCollapse,
    filterOptionOpen: false,
    selected: [],
    errorMsg: '',
  })

  let startIndex = props.startIndex
  let displayDataCount = props.displayDataCount
  let pageSelected = props.paramState.pageSelected

  let order = props.paramState.order
  let orderBy = props.paramState.orderBy

  let initalLimitPerPage = props.initalLimitPerPage

  let limitPerPageSelected = props.paramState.limitPerPageSelected

  const totalPages = Math.ceil(displayDataCount / limitPerPageSelected)

  let parameterValues = { ...props.paramState }

  //calculate end index
  let endIndex = startIndex + limitPerPageSelected
  if (endIndex > displayDataCount) {
    endIndex = displayDataCount
  }

  //Taking page limit to be always multiple of 10
  const startPerRow = initalLimitPerPage
  const step = initalLimitPerPage

  let pageLimit = []

  if (displayDataCount < startPerRow) {
    pageLimit.push(displayDataCount)
  } else {
    pageLimit = Array.from(
      Array.from(
        Array(Math.ceil((displayDataCount - startPerRow) / step)).keys()
      ),
      x => startPerRow + x * step
    )
    if (
      pageLimit.length === 0 ||
      pageLimit[pageLimit.length - 1] < displayDataCount
    ) {
      pageLimit.push(displayDataCount)
    }
  }

  //Mobile view filter option

  const isSelected = name => data.selected.indexOf(name) !== -1

  const handleClick = (event, name) => {
    let selected = data.selected
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setData({ ...data, selected: newSelected })
  }

  function changePageNumber(event, page) {
    parameterValues.pageSelected = parseInt(page)
    props.loadData(parameterValues, false)
  }

  function changeRowPerPage(event) {
    parameterValues.limitPerPageSelected = parseInt(event.target.value)
    props.loadData(parameterValues, false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    parameterValues.order = isAsc ? 'desc' : 'asc'
    parameterValues.orderBy = property
    props.loadData(parameterValues, false)
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  return (
    <div className={props.classes.outerGrid}>
      {props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container className={props.classes.tableGrid}>
          <div className={props.classes.inner}>
            <Table size="small" aria-label="simple table">
              {/** Table Header Start **/}
              <TableHead className={props.classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      props.classes.tableheading,
                      props.classes.textDataRegular,
                      props.classes.firstColumnHeader
                    )}
                  >
                    Sort by
                  </TableCell>

                  {props.headCells.map(headCell => (
                    <TableCell
                      className={classNames(
                        props.classes.tableheading,
                        props.classes.textDataRegular,
                        props.classes.otherColumns
                      )}
                      key={headCell.label}
                      sortDirection={orderBy === headCell.sort ? order : false}
                    >
                      {headCell.sort ? (
                        <TableSortLabel
                          active={orderBy === headCell.sort}
                          direction={orderBy === headCell.sort ? order : 'asc'}
                          onClick={createSortHandler(headCell.sort)}
                        >
                          <Trans i18nKey={headCell.label} />
                          {orderBy === headCell.sort ? (
                            <span className={props.classes.visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <Trans i18nKey={headCell.label} />
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    className={classNames(
                      props.classes.tableheading,
                      props.classes.textDataRegular
                    )}
                  ></TableCell>
                </TableRow>
              </TableHead>

              {/** Table Header End **/}

              {/** Table row start **/}
              <TableBody>
                {props.displayDataCount > 0 &&
                  props.displayData.map(row => {
                    const isItemSelected = isSelected(row.id)
                    return (
                      <TableRow
                        hover
                        selected={isItemSelected}
                        classes={{ selected: props.classes.selected }}
                        className={props.classes.tableRow}
                        key={row.id}
                      >
                        <TableCell
                          align="center"
                          className={`${
                            isItemSelected
                              ? props.classes.tabledata
                              : props.classes.tabledata
                          }
                                      ${
                                        isItemSelected
                                          ? props.classes.firstColumnData
                                          : props.classes.firstColumnData
                                      }
                                      ${
                                        isItemSelected
                                          ? props.classes.firstColSeldData
                                          : props.classes.firstColNonSelData
                                      }
                                      ${
                                        row.status === 'submitted'
                                          ? props.classes.tableCellGradient
                                          : ''
                                      }
                                    `}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={event => {
                              handleClick(event, row.id)
                            }}
                            color="primary"
                            className={props.classes.tablerowselector}
                            classes={{
                              root: props.classes.checkboxRoot,
                              checked: props.classes.checked,
                            }}
                          />
                        </TableCell>

                        {/** start of table row iteration */}
                        {props.headCells.map(function(headCell, index) {
                          let valueToDisplay = row[headCell.id]
                          if (headCell.type && headCell.type === 'Date') {
                            valueToDisplay = valueToDisplay.split(' ')[0]
                          }

                          if (headCell.fetchFromParent) {
                            if (headCell.fetchFromParent == 'weight') {
                              data.collapse[row.id]
                                ? (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[1])
                                : (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[0])
                            } else if (headCell.fetchFromParent == 'species') {
                              data.collapse[row.id]
                                ? (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[1])
                                : (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[0])
                            } else if (
                              headCell.fetchFromParent == 'offload_id'
                            ) {
                              data.collapse[row.id]
                                ? (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[1])
                                : (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[0])
                            } else {
                              valueToDisplay = props.onFetchFromParent(
                                headCell.fetchFromParent,
                                valueToDisplay
                              )
                            }
                          }

                          if (headCell.toolTipFromParent) {
                            let toolTipValue = row[headCell.id]
                            toolTipValue = props.onFetchFromParent(
                              headCell.toolTipFromParent,
                              toolTipValue
                            )
                            let toolTipBaseValue = valueToDisplay
                            valueToDisplay = (
                              <Tooltip title={toolTipValue} placement="bottom">
                                <div>{toolTipBaseValue}</div>
                              </Tooltip>
                            )
                          }

                          return (
                            <TableCell
                              key={index + '_' + headCell.label}
                              className={classNames(
                                props.classes.tabledata,
                                props.classes.textDataLight,
                                props.classes.otherColumns
                              )}
                              onClick={() => {
                                {
                                  let tempCollapse = { ...data.collapse }
                                  tempCollapse[row.id] = !tempCollapse[row.id]
                                  setData({ ...data, collapse: tempCollapse })
                                }
                              }}
                            >
                              {headCell.id === 'state' ? (
                                <Chip
                                  size="small"
                                  className={`props.classes.statusChip
                                  ${
                                    valueToDisplay === 'sold'
                                      ? props.classes.statusRed
                                      : ''
                                  }
                                  ${
                                    valueToDisplay === 'draft'
                                      ? props.classes.statusYellow
                                      : ''
                                  }
                                `}
                                  label={valueToDisplay}
                                />
                              ) : (
                                <div
                                  className={
                                    row.status === 'submitted'
                                      ? props.classes.textBold
                                      : ''
                                  }
                                >
                                  {valueToDisplay}
                                </div>
                              )}
                            </TableCell>
                          )
                        })}
                        {/** end of table row iteration */}

                        <TableCell
                          className={classNames(
                            props.classes.tabledata,
                            props.classes.textDataLight,
                            props.classes.otherColumns
                          )}
                        >
                          {!data.collapse[row.id] ? (
                            <IconButton
                              className={props.classes.multiIconButton}
                              onClick={() => props.handleEdit(row.id)}
                            >
                              {row.status === 'submitted' ? (
                                <DoneAllIcon
                                  className={props.classes.tableSelectedIcon}
                                  color={'primary'}
                                />
                              ) : (
                                <EditIcon
                                  className={props.classes.tableSelectedIcon}
                                  color={'primary'}
                                />
                              )}
                            </IconButton>
                          ) : (
                            <>
                              <List className={props.classes.listClass}>
                                <ListItem
                                  className={props.classes.listItemClass}
                                >
                                  {
                                    <IconButton
                                      className={props.classes.multiIconButton}
                                      onClick={() =>
                                        props.handleEdit(row.order_number)
                                      }
                                    >
                                      {row.status === 'submitted' ? (
                                        <DoneAllIcon
                                          className={
                                            props.classes.tableSelectedIcon
                                          }
                                          color={'primary'}
                                        />
                                      ) : (
                                        <EditIcon
                                          className={
                                            props.classes.tableSelectedIcon
                                          }
                                          color={'primary'}
                                        />
                                      )}
                                    </IconButton>
                                  }
                                </ListItem>
                                <ListItem
                                  className={props.classes.listItemClass}
                                >
                                  {
                                    <IconButton
                                      className={props.classes.multiIconButton}
                                    >
                                      <HighlightOffOutlinedIcon
                                        className={
                                          props.classes.tableSelectedIcon
                                        }
                                        color="primary"
                                        fontSize="small"
                                        onClick={() => {
                                          let tempCollapse = {
                                            ...data.collapse,
                                          }
                                          tempCollapse[row.id] = !tempCollapse[
                                            row.id
                                          ]
                                          setData({
                                            ...data,
                                            collapse: tempCollapse,
                                          })
                                        }}
                                      />
                                    </IconButton>
                                  }
                                </ListItem>
                              </List>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
              {/** Table row end **/}
            </Table>
          </div>

          <Grid container>
            {/** Pagination component Start */}
            <Hidden only={['xs', 'sm']}>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                item
                xs={12}
                md={12}
                spacing={2}
              >
                <Grid container item xs={12} md={2} justify="center">
                  {displayDataCount > 0 ? (
                    <Typography
                      display="inline"
                      className={classNames(
                        props.classes.pageNumber,
                        props.classes.textDataLight
                      )}
                    >
                      {' '}
                      <Trans i18nKey="showing" /> {startIndex + 1}{' '}
                      <Trans i18nKey="of" /> {endIndex} <Trans i18nKey="of" />{' '}
                      {displayDataCount}{' '}
                    </Typography>
                  ) : (
                    <Typography
                      className={classNames(
                        props.classes.pageNumber,
                        props.classes.textDataLight
                      )}
                    >
                      No result returned
                    </Typography>
                  )}
                </Grid>

                <Grid container item xs={10} md={5} justify="center">
                  <Pagination
                    count={totalPages}
                    page={pageSelected}
                    onChange={(event, page) => changePageNumber(event, page)}
                    color="primary"
                    size="small"
                  />
                </Grid>

                <Grid container item xs={2} md={3} justify="flex-end">
                  <Typography
                    display="inline"
                    className={classNames(
                      props.classes.pageNumber,
                      props.classes.textDataLight
                    )}
                  >
                    {' '}
                    <Trans i18nKey="show" />{' '}
                  </Typography>
                  <select
                    value={limitPerPageSelected}
                    onChange={changeRowPerPage}
                  >
                    {pageLimit.map(perPageVal => (
                      <option key={perPageVal} value={perPageVal}>
                        {perPageVal}
                      </option>
                    ))}
                  </select>
                  <Typography
                    display="inline"
                    className={classNames(
                      props.classes.pageNumber,
                      props.classes.textDataLight
                    )}
                  >
                    {' '}
                    <Trans i18nKey="entries" />{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={2} md={2} justify="center">
                  <img
                    src={addNew}
                    className={props.classes.addNewIcon}
                    alt="notification"
                    onClick={event => props.handleAddNewItem(event)}
                  />
                </Grid>
              </Grid>
            </Hidden>

            <Hidden only={['md', 'lg', 'xl']}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={1} sm={1} />

                <Grid container item xs={9} sm={8} justify="center">
                  <Pagination
                    count={totalPages}
                    siblingCount={0}
                    page={pageSelected}
                    onChange={(event, page) => changePageNumber(event, page)}
                    color="primary"
                    size="small"
                  />
                </Grid>

                <Grid item xs={2} sm={3}>
                  <img
                    src={addNew}
                    className={props.classes.addNewIcon}
                    alt="notification"
                    onClick={event => props.handleAddNewItem(event)}
                  />
                </Grid>
              </Grid>
            </Hidden>

            {/** Pagination component end */}
          </Grid>
        </Grid>
      )}
      <Typography display="inline"> {data.errorMsg} </Typography>
    </div>
  )
}

export const DealerOrderListContainer = withStyles(
  combineStyles(styles, dealerStylesBase)
)(withDealerTheme(DealerOrderList))
