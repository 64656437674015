import { makeStyles } from '@material-ui/core/styles'

export const withDealerTheme = makeStyles(theme => ({
  lightText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '.7rem',
  },
  titleText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '24px',
  },
  labelText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '18px',
    textTransform: 'none',
  },
  linkText: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    color: '#003E71',
    fontSize: '.55rem',
  },
  selectMenuText: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '14px',
  },
  selectMenuPlaceholder: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '14px',
    color: 'grey',
  },

  selectEmpty: {
    'label + &': {
      marginTop: theme.spacing(2),
      fontSize: '0.75rem',
      color: 'black',
      paddingLeft: '4px',
    },
    backgroundColor: '#F6F9FA',
    border: '1px solid #E0E0E0',
    height: '40px',
  },

  titleGridContainer: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },

  dialogContent: {
    maxHeight: 400,
    marginRight: '25px',
  },

  dialogPaper: {
    maxHeight: '90vh',
  },

  searchInput: {
    fontSize: '9px',
    fontFamily: 'Rubik',
    color: 'grey',
  },

  gridFirstRow: {
    paddingBottom: '2vw !important',
  },

  checkboxText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '14px',
  },

  finishButton: {
    backgroundColor: '#003E71',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: '#003E71',
    },
  },

  buttonText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '13px',
    color: 'white',
    padding: '2px 10px',
  },
  cancelButtonText: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '14px',
    color: '#003E71',
    textTransform: 'none',
  },
  cancelIcon: {
    color: '#003E71',
    marginTop: '1vh',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loaderGrid: {
    height: '50vh',
  },
  addAnotherItem: {
    textTransform: 'none',
    color: '#003E71',
    fontFamily: 'Rubik',
    fontWeight: '300',
  },
  iconColor: {
    color: '#003E71',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dateFieldClass: {
    backgroundColor: '#F6F9FA',
    borderColor: '#E0E0E0',
    fontFamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '300',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      height: '2rem',
      marginTop: theme.spacing(0),
    },
  },
  vtrField: {
    [theme.breakpoints.up('sm')]: {
      width: '125%',
    },
  },
  deleteIcon: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      fontSize: '22px',
      color: 'grey',
      '&:hover': {
        color: 'darkblue',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down('sm')]: {
      color: 'grey',
    },
  },
  dollarIcon: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      color: 'grey',
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      color: 'grey',
    },
  },
  serialNumber: {
    marginTop: theme.spacing(4),
    fontSize: '14px',
    color: 'grey',
  },
  firstRowGrid: {
    paddingTop: '3px',
  },
  nonFirstRowGrid: {
    paddingTop: '18px',
  },
  dollarAdornment: {
    color: 'grey',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-3vw',
    },
  },
  labelsWithDollar: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '18px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      marginLeft: '2vw',
    },
  },
  dividerColor: {
    backgroundColor: '#E0E0E0',
    marginTop: '5px',
    marginBottom: '10px',
  },
  nonFirstRow: {
    paddingTop: '4vh',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1vh',
    },
  },
  contentText: {
    fontFamily: 'Rubik',
    fontWeight: '100',
    fontSize: '12px',
    textAlign: 'center',
  },
  statusTitle: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '20px',
    justifyContent: 'center',
  },
  offloadNumber: {
    fontFamily: 'Rubik',
    fontWeight: '0',
    fontSize: '20px',
  },
  statusCancelIcon: {
    color: '#003E71',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  speciesInfo: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '3vh',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1vh',
    },
  },
}))

export default withDealerTheme
