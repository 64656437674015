export const dealerStylesBase = theme => ({
  root: {
    flexGrow: 1,
  },
  tableheading: {
    whiteSpace: 'nowrap',
    backgroundColor: '#EDEFF1',
    border: '0px',
    paddingLeft: '3px',
    paddingRight: '2px',
  },
  tabledata: {
    borderRight: '1px ridge',
    /**whiteSpace: 'nowrap',*/

    paddingRight: '2px',
    paddingLeft: '5px',
    verticalAlign: 'baseline',
  },
  tablerowselector: {
    paddingLeft: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },

  tableIcon: {
    fontSize: '26px',
    color: 'grey',
    '&:hover': {
      color: 'darkblue',
    },
  },
  tableSelectedIcon: {
    fontSize: '26px',
  },
  multiIconButton: {
    padding: '0px',
    paddingRight: '-10px',
  },
  tableRow: {
    backgroundColor: 'white',
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgb(0, 62, 113, .2)',
      color: 'black',
    },
  },
  selected: {},

  tableHead: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },

  pageNumber: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  fromDate: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  toDate: {
    paddingRight: '75px',
    paddingLeft: '75px',
  },

  sortDropdown: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  textDataRegular: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '10pt',
  },

  textDataLight: {
    fontFamily: 'Rubik',
    fontWeight: 'light',
    fontSize: '10pt',
  },

  textBold: {
    fontWeight: '500',
  },

  select: {
    backgroundColor: 'white',
    borderRadius: '50px',
    marginTop: '0px',
    marginLeft: '5px',
    minWidth: '108px',
    maxHeight: '25px',
    paddingLeft: '5px',
    '&:before': {
      borderColor: '#EDEFF1',
    },
    '&:after': {
      borderColor: '#EDEFF1',
    },
  },

  selectIcon: {
    marginTop: '-3px',
  },

  addNewIcon: {
    height: '50px',
    marginRight: '8px',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      height: '58px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  statusChip: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '10pt',
    width: '100%',
  },

  statusGreen: {
    backgroundColor: '#75EFB2',
    color: '#006837',
  },
  statusYellow: {
    backgroundColor: '#F4CF98',
    color: '#916019',
  },
  statusRed: {
    backgroundColor: '#EDA496',
    color: '#EA3713',
  },

  table: {
    overflow: 'hidden',
    flexGrow: 0,
  },

  tableGrid: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    //flexGrow: '0 !important',
    position: 'relative',
  },
  searchFilterSortcontainer: {
    paddingBottom: '1.5vw !important',
  },
  filterOptionGrid: {
    paddingBottom: '8px !important',
  },
  firstColumnHeader: {
    position: 'sticky',
    left: '0px',
    zIndex: 1,
  },
  firstColumnData: {
    position: 'sticky',
    left: '0px',
    zIndex: 1,
  },
  firstColSeldData: {
    backgroundColor: '#EDEFF1',
  },
  firstColNonSelData: {
    backgroundColor: 'white',
  },
  inner: {
    overflowX: 'scroll',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '94vw',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '94vw',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  },
  otherColumns: {
    zIndex: '-1',
  },
  outerGrid: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
    },
  },

  tableCellGradient: {
    background: 'linear-gradient(-90deg, #FFFFFF 90%, #003E71 10%)',
  },

  checkboxRoot: {
    '&$checked': {
      color: '#003E71',
    },
  },
  checked: {},
  listClass: {
    padding: 0,
    margin: 0,
    position: 'relative',
    top: '8px',
  },
  listItemClass: {
    padding: 0,
    paddingBottom: '8px',
    margin: 0,
  },
  topListItemClass: {
    padding: 0,
    margin: 0,
  },
  cardView: {
    borderRadius: 0,
    // boxShadow: '10px 5px rgba(0, 0, 0, .1)',
  },
  lightText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '.7rem',
  },
  tileHeading: {
    fontSize: '14px',
    color: '#003E71',
    fontWeight: 'bold',
  },
  tileContent: {
    fontSize: '14px',
  },
  cardImage: {
    height: '10vh',
    width: '20vw',
    [theme.breakpoints.down('sm')]: {
      height: '20vh',
      width: '80vw',
    },
  },
  navIcons: {
    height: 45,
    width: 25,
  },
  noBorderRow: {
    borderBottom: 'none',
  },
  speciesName: {
    color: '#003E71',
  },
  rootDiv: {
    [theme.breakpoints.down('md')]: {
      minWidth: '95vw',
    },
  },

  headingContent: {
    marginTop: '3vh',
  },

  leftSide: {
    paddingLeft: '2vw',
  },

  rightSide: {
    paddingRight: '1vw',
  },

  screenTop: {
    [theme.breakpoints.down('md')]: {
      marginTop: '2vh',
    },
  },

  mainContainer: {
    marginTop: '4vh',
  },

  innerParentGrid: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '70vh',
    },
  },
  leftOuterGrid: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      maxHeight: '65vh',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '2vh',
    },
  },
  datePickerGrid: {
    marginTop: '-3px',
  },
  datePickerText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '18px',
    textTransform: 'none',
  },
  labelLineSpacing: {
    marginTop: '4px',
  },
  actionButtons: {
    marginTop: '2vh',
    marginBottom: '2vh',
    marginRight: '3vw',
  },
  speciesInfoDivider: {
    marginBottom: '10vh',
  },
  speciessectionlabel: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '10px',
    textTransform: 'none',
    lineHeight: '0.6rem',
    paddingLeft: '5px',
  },
  tableOuterGrid: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '80vw',
      overflowX: 'scroll',
    },
  },
  speciesInfoCellText: {
    marginLeft: '-1vw',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-3vw',
    },
  },
  addNewOrderIcon: {
    height: '30px',
    [theme.breakpoints.up('md')]: {
      height: '30px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '24px',
  },
  gridFirstRow: {
    paddingBottom: '2vw !important',
  },
  labelText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '18px',
    textTransform: 'none',
  },
  linkText: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    color: '#003E71',
    fontSize: '.55rem',
  },
  selectEmpty: {
    'label + &': {
      marginTop: theme.spacing(2),
      fontSize: '0.75rem',
      color: 'black',
      paddingLeft: '4px',
    },
    backgroundColor: '#F6F9FA',
    border: '1px solid #E0E0E0',
    height: '40px',
  },
  selectMenuText: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '14px',
  },
  selectMenuPlaceholder: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '14px',
    color: 'grey',
  },
  dateFieldClass: {
    backgroundColor: '#F6F9FA',
    borderColor: '#E0E0E0',
    fontFamily: 'Rubik',
    fontSize: '0.75rem',
    fontWeight: '300',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      height: '2rem',
      marginTop: theme.spacing(0),
    },
  },
  finishButton: {
    backgroundColor: '#003E71',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: '#003E71',
    },
  },
  buttonText: {
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '13px',
    color: 'white',
    padding: '2px 10px',
  },
  cancelButtonText: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '14px',
    color: '#003E71',
    textTransform: 'none',
  },
  cancelIcon: {
    color: '#003E71',
    marginTop: '1vh',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cartRootDiv: {
    background: 'white',
    borderStyle: 'solid',
    borderColor: '#F7F2F2',
    borderWidth: 'thin',
    marginRight: '25px',
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      background: 'white',
    },
  },
  orderSpeciesAdd: {
    height: '35px',
  },
  cartData: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    textAlign: 'center',
  },
  cartSpecies: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
  },
  cartFees: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    textAlign: 'left',
    paddingLeft: '2vw',
  },
  cartAmount: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    textAlign: 'center',
  },
  orderSpeciesWeight: {
    color: '#003E6d',
  },
  paddingBottom: {
    paddingBottom: '2vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})
