import { createSlice } from '@reduxjs/toolkit'

export const dealerSlice = createSlice({
  name: 'dealer',
  initialState: {
    dealerFees: [],
    newFee: {
      title: '',
      amount: '',
      fee_unit_id: '',
    },
    dealerPorts: [],
    newPort: {
      state: '',
      name: '',
    },
    dealerGears: [],
    newGear: {
      name: '',
    },
    dealerProductLocations: [],
    newProductLocation: {
      name: '',
    },
    feeUnits: [],
    dealerCustomers: [],
    newCustomer: {
      name: '',
      federal_permit_number: '',
      state_permit_number: '',
      addresses: [
        {
          address1: '',
          address2: '',
          state: '',
          zipcode: '',
        },
      ],
      contacts: [
        {
          full_name: '',
          mobile_number: '',
          fax: '',
          email: '',
        },
      ],
    },
    dealerVessels: [],
    newVessel: {
      name: '',
      vessel_federal_identifier: '',
      hull_id_number: '',
      home_port_id: '',
      vessel_permits: [
        {
          permit_number: '',
          permit_type_id: '',
        },
      ],
      contacts: [
        {
          full_name: '',
          mobile_number: '',
          email: '',
          fax: '',
          role: 'captain',
        },
        {
          full_name: '',
          mobile_number: '',
          email: '',
          fax: '',
          role: 'representative',
        },
      ],
    },
    permitTypes: [],
    dealerHarvestAreas: [],
    newHarvestArea: {
      federal_stat_area: '',
      stock_area: '',
      region_id: '',
    },
    dealerSpecies: [],
    newDealerSpecie: {
      species_id: '',
      market_name: '',
      disposition_id: '',
      market_description_id: '',
      grade_id: '',
      fee_per_lb: '',
    },
    speciesGrades: [],
    speciesDispositions: [],
    regions: [],
  },
  reducers: {
    setDealerFees: (state, action) => {
      state.dealerFees = action.payload
    },
    setFeeUnits: (state, action) => {
      state.feeUnits = action.payload
    },
    saveFee: (state, action) => {
      state.newFee = action.payload
    },
    setDealerPorts: (state, action) => {
      state.dealerPorts = action.payload
    },
    saveDealerPort: (state, action) => {
      state.newPort = action.payload
    },
    setDealerGears: (state, action) => {
      state.dealerGears = action.payload
    },
    saveGear: (state, action) => {
      state.newGear = action.payload
    },
    setDealerProductLocations: (state, action) => {
      state.dealerProductLocations = action.payload
    },
    saveProductLocation: (state, action) => {
      state.newProductLocation = action.payload
    },
    setDealerCustomers: (state, action) => {
      state.dealerCustomers = action.payload
    },
    saveCustomer: (state, action) => {
      state.newCustomer = action.payload
    },
    setDealerVessels: (state, action) => {
      state.dealerVessels = action.payload
    },
    saveVessel: (state, action) => {
      state.newVessel = action.payload
    },
    fetchInventory: state => {
      return state
    },
    setPermitTypes: (state, action) => {
      state.permitTypes = action.payload
    },
    setDealerHarvestAreas: (state, action) => {
      state.dealerHarvestAreas = action.payload
    },
    saveHarvestArea: (state, action) => {
      state.newHarvestArea = action.payload
    },
    setDealerSpecies: (state, action) => {
      state.dealerSpecies = action.payload
    },
    saveDealerSpecie: (state, action) => {
      state.newDealerSpecie = action.payload
    },
    setSpeciesGrades: (state, action) => {
      state.speciesGrades = action.payload
    },
    setSpeciesDispositions: (state, action) => {
      state.speciesDispositions = action.payload
    },
    setDealerRegions: (state, action) => {
      state.regions = action.payload
    },
  },
})

export const {
  fetchInventory,
  setDealerFees,
  setFeeUnits,
  saveFee,
  setDealerCustomers,
  saveCustomer,
  setDealerPorts,
  saveDealerPort,
  setDealerGears,
  saveGear,
  setDealerProductLocations,
  saveProductLocation,
  setDealerVessels,
  saveVessel,
  setPermitTypes,
  setDealerHarvestAreas,
  saveHarvestArea,
  setDealerSpecies,
  saveDealerSpecie,
  setSpeciesGrades,
  setSpeciesDispositions,
  setDealerRegions,
} = dealerSlice.actions

export const dealerSliceReducer = dealerSlice.reducer
