import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BottomLine from '../../../assets/trace/butcherbox/BottomLine.svg'
import { Grid, AppBar, Toolbar } from '@material-ui/core'
import { theme } from '../../../pages/shared/theme'

const styles = () => ({
  appBarBottom: {
    backgroundColor: 'white',
    height: '12vh',
    top: 'auto',
    bottom: 0,
    [theme.breakpoints.up('lg')]: {
      marginLeft: '25%',
      marginRight: '25%',
      maxWidth: '50%',
      height: '15vh',
    },
  },
  grid: {
    height: '4vh',
  },
  traceImg: {
    width: '42%',
    paddingLeft: '4%',
  },
  recipesImg: {
    paddingLeft: '5%',
    width: '53%',
    marginLeft: '-15%',
    paddingBottom: '2vh',
  },
  harvestImg: {
    paddingLeft: '5%',
    width: '54%',
    paddingBottom: '2vh',
  },
  sustainabilityImg: {
    marginLeft: '-23%',
    width: '103%',
  },
  aboutImg: {
    marginLeft: '6%',
    width: '86%',
    paddingBottom: '1.2vh',
  },
  bottomLineDiv: {
    paddingTop: '2vh',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '4vh',
    },
  },
  bottomLine: {
    width: '50%',
  },
})

export function BottomAppBar(props) {
  const hostName = window.location.hostname
  const handleClick = component => {
    props.setSelectedRecipe('')
    props.setComponentToShow(component)
  }
  function imgSelector(img) {
    let componentToShow = props.componentToShow
    if (hostName.startsWith('bb04') && props.componentToShow === 'Trace') {
      componentToShow = 'Harvest'
    }
    if (img === componentToShow) {
      return props.component === 'ButcherBoxContainer' ? 'BB' + img : img
    }
    return props.component === 'ButcherBoxContainer'
      ? 'BB' + 'Not' + img
      : 'Not' + img
  }

  return (
    <AppBar position="fixed" className={props.classes.appBarBottom}>
      <Toolbar>
        <Grid container justify="center" className={props.classes.grid}>
          <Grid xs={3} item onClick={() => handleClick('Trace')}>
            <img
              className={
                hostName.startsWith('bb04')
                  ? props.classes.harvestImg
                  : props.classes.traceImg
              }
              src={
                require(`../../../assets/trace/butcherbox/${imgSelector(
                  hostName.startsWith('bb04') ? 'Harvest' : 'Trace'
                )}.svg`).default
              }
              alt="Trace"
            />
          </Grid>
          <Grid xs={3} item onClick={() => handleClick('Recipes')}>
            <img
              className={props.classes.recipesImg}
              src={
                require(`../../../assets/trace/butcherbox/${imgSelector(
                  'Recipes'
                )}.svg`).default
              }
              alt="Recipes"
            />
          </Grid>
          <Grid xs={3} item onClick={() => handleClick('Sustainability')}>
            <img
              className={props.classes.sustainabilityImg}
              src={
                require(`../../../assets/trace/butcherbox/${imgSelector(
                  'Sustainability'
                )}.svg`).default
              }
              alt="Sustainability"
            />
          </Grid>
          <Grid xs={3} item onClick={() => handleClick('About')}>
            <img
              className={props.classes.aboutImg}
              src={
                require(`../../../assets/trace/butcherbox/${imgSelector(
                  'About'
                )}.svg`).default
              }
              alt="About"
            />
          </Grid>
        </Grid>
      </Toolbar>
      <div className={props.classes.bottomLineDiv}>
        <img
          src={BottomLine}
          alt="Bottom Line"
          className={props.classes.bottomLine}
        />
      </div>
    </AppBar>
  )
}

export const BottomAppBarContainer = withStyles(styles)(BottomAppBar)
