import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import Button from '@material-ui/core/Button'

import classNames from 'classnames'

import { Trans } from 'react-i18next'
import DateRangeComponent from './DateRangeComponent'

const useStyles = makeStyles({
  labelText: {
    fontWeight: '400',
    fontSize: '11pt',
  },
  contentText: {
    fontWeight: '400',
    fontSize: '10pt',
    color: '#999999',
  },
  outerGrid: {
    width: '90vw',
  },
  headerGrid: {
    height: '08vh',
  },
  bodyGrid: {
    background: '#EDEFF1',
    height: '92vh',
  },
  list: {},
  contentsGrid: {
    height: '08vh',
  },
  select: {
    backgroundColor: '#EDEFF1',
    borderRadius: '50px',
    marginTop: '0px',
    minWidth: '100%',
    maxHeight: '25px',
    textAlign: 'right',
    '&:before': {
      borderColor: '#EDEFF1',
    },
    '&:after': {
      borderColor: '#EDEFF1',
    },
  },

  selectedText: {
    textAlign: 'right !important',
  },
})

export function FilterOptionDrawer(props) {
  const classes = useStyles()
  const [openState, setOpenState] = React.useState(props.open)
  const anchor = 'right'

  const [sortBy, setSortBy] = React.useState('')
  const [fromDate, setFromDate] = React.useState(props.fromDate)
  const [toDate, setToDate] = React.useState(props.toDate)

  function closeDrawer() {
    setOpenState(false)
    props.handleOnClose(false)
  }

  const changeSortOption = event => {
    setSortBy(event.target.value)
  }

  function searchWithDateRange(fromDate, toDate) {
    setFromDate(fromDate)
    setToDate(toDate)
    //TODO: Call API with From and To date
  }

  const handleDateRangecmpt = (event, enable) => {
    let date
    if (enable) date = new Date()
    setFromDate(date)
    setToDate(date)
  }

  function handleFilterSelectDone(event) {
    event.preventDefault()
    props.onFilterSelection(sortBy, fromDate, toDate)
    closeDrawer()
  }

  let DateRangeCmpt

  if (!(fromDate && toDate)) {
    DateRangeCmpt = (
      <Grid container item xs={8} justify="flex-end" alignItems="center">
        <Typography
          className={classNames(classes.labelText, classes.contentText)}
        >
          Any Date
        </Typography>
        <IconButton onClick={event => handleDateRangecmpt(event, true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Grid>
    )
  } else {
    DateRangeCmpt = (
      <Grid container item xs={8} justify="flex-end" alignItems="center">
        <Grid container item xs={10}>
          <DateRangeComponent
            label="icon"
            onDateRangeChange={searchWithDateRange}
            fromDate={fromDate}
            toDate={toDate}
          />
        </Grid>
        <Grid
          container
          item
          xs={2}
          justify="flex-start"
          alignItems="flex-start"
        >
          <IconButton onClick={event => handleDateRangecmpt(event, false)}>
            <CancelOutlinedIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <Drawer
      open={openState}
      anchor={anchor}
      onClose={() => closeDrawer()}
      className={classes.drawer}
    >
      <Grid container className={classes.outerGrid}>
        <Grid container className={classes.headerGrid}>
          <Grid item xs={1} />
          <Grid container item xs={5} justify="flex-start" alignItems="center">
            <Typography className={classes.labelText}>
              <Trans i18nKey="filter" />
            </Typography>
          </Grid>
          <Grid container item xs={5} justify="flex-end" alignItems="center">
            <Button
              color="primary"
              onClick={event => handleFilterSelectDone(event)}
            >
              <Trans i18nKey="conclude" />
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>

        {/* Body Grid */}

        <Grid item xs={12} className={classes.bodyGrid}>
          {props.isSortEnabled ? (
            <Grid
              container
              item
              xs={12}
              justify="flex-start"
              alignItems="center"
              className={classes.contentsGrid}
            >
              <Grid item xs={1} />
              <Grid
                container
                item
                xs={5}
                justify="flex-start"
                alignItems="center"
              >
                <Typography className={classes.labelText}>
                  <Trans i18nKey="sort" />
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={5}
                justify="flex-end"
                alignItems="center"
              >
                <Select
                  value={sortBy}
                  onChange={changeSortOption}
                  IconComponent={KeyboardArrowRightIcon}
                  className={classNames(classes.select, classes.contentText)}
                  inputProps={{
                    classes: {
                      icon: classes.selectIcon,
                    },
                  }}
                >
                  {props.sortOptions.map(sortOption => (
                    <MenuItem
                      key={sortOption.id}
                      value={sortOption.id}
                      className={classNames(
                        classes.labelText,
                        classes.contentText
                      )}
                    >
                      {sortOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          ) : (
            <div />
          )}
          <Divider />
          <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignItems="center"
            className={classes.contentsGrid}
          >
            <Grid item xs={1} />
            <Grid
              container
              item
              xs={2}
              justify="flex-start"
              alignItems="center"
            >
              <Typography className={classes.labelText}>
                <Trans i18nKey="dates" />
              </Typography>
            </Grid>
            {DateRangeCmpt}

            <Grid item xs={1} />
          </Grid>
          <Divider />
        </Grid>
      </Grid>
    </Drawer>
  )
}
