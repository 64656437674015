import CookieHelper from '../lib/CookieHelper'

const cookie = new CookieHelper()

export function authHeader() {
  console.log('isAuthenticated', cookie.isAuthenticated())
  if (cookie.isAuthenticated() && cookie.getToken()) {
    return { Authorization: `Bearer ${cookie.getToken()}` }
  }
  return {}
}

function csrfHeader(csrf) {
  const csrf_token = cookie.getCookie('CSRF-TOKEN')
  if (csrf && csrf_token) {
    return {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': decodeURIComponent(csrf_token),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
    //return { "X-CSRF-Token": csrf_token }
  }
  return {}
}

export const COMMON_HEADERS = {
  'Content-Type': 'application/json',
}

export function headers(request_headers, csrf = true) {
  //console.log('authHeader', authHeader())
  return { ...COMMON_HEADERS, ...request_headers, ...csrfHeader(csrf) }
}

export function getApiHost() {
  return process.env.REACT_APP_API_URL //URL_PARTS['apiHost'][process.env.NODE_ENV]
}
