import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Link } from '@material-ui/core'
import { ApiHelper } from '../lib'
import CookieHelper from '../lib/CookieHelper'
import {
  clearCurrentUser,
  clearReferenceData,
  clearOrganization,
} from '../actions'
import { ButtonSpinner } from '../components'
import LegitFishLogo from '../assets/LegitFishLogo.png'

export function MainNav(props) {
  const [isFetching, setIsFetching] = useState(false)
  const api = new ApiHelper()
  const cookie = new CookieHelper()

  async function signOut() {
    /*
      DANGER!  Failing to send the current auth token
      in the sign_out DELETE request will leave the prior
      token in place.  The user can then sign in
      with any password.

      This is a known issue in devise.  Fix is currently in 5rc.  See
      https://github.com/waiting-for-dev/devise-jwt/issues/71
      https://github.com/plataformatec/devise/issues/4782
    */
    setIsFetching(true)
    try {
      const reqMethod =
        process.env.NODE_ENV === 'development' ? 'get' : 'delete'
      const response = await api[reqMethod]('sign_out')
      setIsFetching(false)
      const responseStatus = process.env.NODE_ENV === 'development' ? 200 : 204
      if (response.status === responseStatus) {
        api.clearToken()
        cookie.clearTokenCookie()
        props.clearCurrentUser()
        props.clearReferenceData()
        props.clearOrganization()
      } else {
        api.handleError({})
      }
    } catch (error) {
      setIsFetching(false)
      alert(error)
    }
  }

  return (
    <div className="MainNav" style={{ paddingTop: 20 }}>
      <Link href={'/' + props.state.user.role.toLowerCase()}>
        <img src={LegitFishLogo} alt="logo" style={{ height: 28 }} />
      </Link>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        style={{ float: 'right' }}
        onClick={() => signOut()}
      >
        Sign Out
        {isFetching && <ButtonSpinner />}
      </Button>
    </div>
  )
}

const mapStateToProps = state => {
  return { state }
}

const mapDispatchToProps = {
  clearCurrentUser,
  clearReferenceData,
  clearOrganization,
}

export const MainNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNav)
