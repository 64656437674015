import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { DealerOffloadList } from './DealerOffloadList'
import { AddNewOffloadContainer } from './AddNewOffload'
import { ApiHelper } from '../../lib'
import { setDealerOffloads, setDealerNewModifyOffloads } from '../../actions'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  listItemClass: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: '2px',
    paddingRight: '8px',
    color: '#003e6d',
  },
}))

export function DealerOffload(props) {
  const classes = useStyles()
  const headCells = [
    { id: 'order_number', label: 'offloading_id', sort: 'order_number' },
    { id: 'vessel_name', label: 'fishing_vessel' },
    { id: 'port_id', label: 'port', fetchFromParent: 'ports', sort: 'port_id' },
    { id: 'lots', label: 'quantity', fetchFromParent: 'quantity' },
    {
      id: 'lots',
      label: 'dealerSpecies',
      fetchFromParent: 'species',
      toolTipFromParent: 'speciesToolTip',
    },
    { id: 'lots', label: 'weight', fetchFromParent: 'weight' },
    {
      id: 'arrival_time',
      label: 'date',
      fetchFromParent: 'arrival_date',
      sort: 'arriving_at',
    },
    { id: 'arrival_time', label: 'eta', fetchFromParent: 'eta' },
    { id: 'status', label: 'status', fetchFromParent: 'status', sort: 'state' },
  ]

  const dataURL = 'offloads'
  const initalLimitPerPage = 5
  const initialFilterDate = new Date()

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [data, setData] = useState({
    open: false,
    startIndex: 1,
    displayDataCount: 0,
    offloadId: '',
    isLoading: false,
    errorMsg: '',
    paramState: tableParameters,
  })

  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const ports = useSelector(state => state.ports)
  const speciesDb = useSelector(state => state.species)
  const fishingAreas = useSelector(state => state.fishingAreas)
  const dealerOffloads = useSelector(state => state.dealerOffloads)

  function fetchDisplayData() {
    const api = new ApiHelper()
    let paramState = data.paramState
    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(data.displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let ordersUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      ordersUrl = ordersUrl + '&q=' + paramState.searchKey
    }
    setData({ ...data, isLoading: true })
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerOffloads(result.offloads))
          setData({
            ...data,
            startIndex: startIndexVal,
            displayDataCount: result.pagination.count,
            errorMsg: '',
            open: false,
            isLoading: false,
          })
        } else {
          setData({
            ...data,
            errorMsg: 'Error occured while retrieving Inventory Summary',
            isLoading: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
        setData({
          ...data,
          errorMsg: 'Error occured while retrieving Inventory Summary',
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [data.paramState, dataURL])

  const handleAddNewOffload = () => {
    let initialOffloasState = {}
    dispatch(setDealerNewModifyOffloads(initialOffloasState))
    setData({
      ...data,
      offloadId: '',
      open: true,
    })
  }

  const handleClose = () => {
    let clearDealerOffloadFormSelections = {}
    dispatch(setDealerNewModifyOffloads(clearDealerOffloadFormSelections))
    setData({ ...data, open: false })
  }

  const handleEdit = offloadId => {
    let index = dealerOffloads.findIndex(obj => obj.order_number == offloadId)
    let selectedOffload = {
      offload: {},
    }
    selectedOffload.offload = JSON.parse(JSON.stringify(dealerOffloads[index]))
    selectedOffload.offload['lots_attributes'] = selectedOffload.offload['lots']
    dispatch(setDealerNewModifyOffloads(selectedOffload))
    setData({
      ...data,
      offloadId: offloadId,
      open: true,
    })
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setData({ ...data, startIndex: 1 })
    }
    setData({
      ...data,
      paramState: paramHash,
    })
  }

  function onFetchFromParent(name, key) {
    if (name === 'ports') {
      let portIndex = ports.findIndex(port => port.id === key)
      let portDetail = ports[portIndex]
      if (portDetail) {
        return portDetail.name + ', ' + portDetail.state
      }
      return ''
    }

    if (name === 'fishingAreas') {
      let fishingArea = fishingAreas[key]
      return fishingArea.description
    }

    if (name === 'quantity') {
      return key.length + ' Item(s)'
    }

    if (name === 'status') {
      if (typeof key !== 'string') return ''

      if (key === 'submitted') {
        return 'Incoming'
      }

      return key.charAt(0).toUpperCase() + key.slice(1)
    }

    if (name === 'species' || name === 'speciesToolTip') {
      let speciesLot = key.map(value => value.product_market_name)
      let speciesNames = []
      let speciesIds = key.map(lot => lot.species_id)
      if (speciesIds) {
        speciesNames = speciesIds.map(id => {
          const species = speciesDb.find(x => x.id === id)
          return species ? species.name : ''
        })
      }
      let speciesToDisplay = []
      speciesToDisplay[0] = (
        <List>
          <ListItem className={classes.listItemClass}>
            {getTruncatedString(speciesNames.join(', '), 20)}
          </ListItem>
        </List>
      )
      speciesToDisplay[1] = (
        <List>
          {speciesNames.map((speciesName, index) => {
            return (
              <ListItem
                className={classes.listItemClass}
                key={'species-' + String(index)}
              >
                {speciesName}
              </ListItem>
            )
          })}
        </List>
      )
      let speciesForTooltip = speciesLot.toString()
      if (name === 'speciesToolTip') {
        return speciesForTooltip
      }
      return speciesToDisplay
      //need to edit this
    }

    if (name === 'weight') {
      let weightArray = key.map(value => value.quantity)
      let weightToDisplay = []
      weightToDisplay[0] = (
        <List>
          <ListItem className={classes.listItemClass}>
            {getTruncatedString(weightArray.join(', '), 10)}
          </ListItem>
        </List>
      )
      weightToDisplay[1] = (
        <List>
          {key.map((lot, index) => {
            return (
              <ListItem
                className={classes.listItemClass}
                key={'weight-' + String(index)}
              >
                {lot.quantity}
              </ListItem>
            )
          })}
        </List>
      )
      return weightToDisplay
    }

    if (name === 'arrival_date') {
      if (key) return getArrivalDateTime(key, 'date')
      else return key
    }

    if (name === 'eta') {
      if (key) return getArrivalDateTime(key, 'time')
      else return key
    }
  }

  function getTruncatedString(str, n) {
    return str.length > n ? str.substr(0, n) + '...' : str
  }

  function getArrivalDateTime(dateTimeValue, type) {
    let arrivalDate = new Date(dateTimeValue)

    if (type === 'date') {
      //let dateVal = dateTimeValue.split('T')[0]
      //let dateObj = new Date(dateVal)
      return (
        arrivalDate.getMonth() +
        1 +
        '/' +
        arrivalDate.getDate() +
        '/' +
        arrivalDate.getFullYear()
      )
    }

    if (type === 'time') {
      /**let timeVal = dateTimeValue.split('T')[1]
      let hours = parseInt(timeVal.substring(0, 2))
      var suffix = hours >= 12 ? 'PM' : 'AM'
      hours = ((hours + 11) % 12) + 1
      let min = timeVal.substring(3, 5)
      */
      return format(arrivalDate, 'h:mm a')
      //return hours + ':' + min + ' ' + suffix
    }
  }

  function onAcceptLandOffload(orderNumber, currentStatus) {
    const api = new ApiHelper()
    let actionType
    if (currentStatus === 'submitted') {
      actionType = 'accept'
    } else if (currentStatus === 'accepted') {
      actionType = 'land'
    }

    api
      .put('/offloads/' + orderNumber + '/' + actionType)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        //TODO: Console statement to be moved to confirmation popup flow
        console.log(result)
      })
  }

  function backToOffloading() {
    fetchDisplayData()
    handleClose()
  }

  const sortOptions = [
    { id: 'order_number', label: 'Offloading ID' },
    { id: 'port_id', label: 'Ports' },
    { id: 'arriving_at', label: 'Date' },
    { id: 'state', label: 'Status' },
  ]

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  return (
    <div>
      <div>
        <DealerOffloadList
          headCells={headCells}
          displayData={dealerOffloads}
          startIndex={data.startIndex}
          sortType={headCells[0].id}
          paramState={data.paramState}
          loadData={loadData}
          onHandleaddNew={handleAddNewOffload}
          displayDataCount={data.displayDataCount}
          initalLimitPerPage={initalLimitPerPage}
          handleEdit={handleEdit}
          isAddNewAllowed={true}
          isLoading={data.isLoading}
          onFetchFromParent={onFetchFromParent}
          onAcceptLandOffload={onAcceptLandOffload}
          sortOptions={sortOptions}
          isSortEnabled={isSortEnabled}
        />
        {data.open ? (
          <AddNewOffloadContainer
            open={data.open}
            handleClose={handleClose}
            offloadId={data.offloadId}
            backToOffloading={backToOffloading}
          />
        ) : null}
        {data.errorMsg ? (
          <ErrorMessageComponent errorMessage={data.errorMsg} />
        ) : null}
      </div>
    </div>
  )
}

export const DealerOffloadContainer = withStyles(combineStyles(stylesBase))(
  DealerOffload
)
