import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { Dialog } from '@material-ui/core'
import { Grid, Link } from '@material-ui/core'
import { theme } from '../../pages/shared/theme'
import '../../assets/Trace.css'
import { ArrowBack, Close, Lock } from '@material-ui/icons'
import MarketName from '../../assets/trace/MarketName.svg'
import Species from '../../assets/trace/Species.svg'
import Region from '../../assets/trace/Region.svg'
import HarvestDate from '../../assets/trace/HarvestDate.svg'
import ProcessDate from '../../assets/trace/ProcessDate.svg'
import Sustainability from '../../assets/trace/Sustainability.svg'
import BapLogo from '../../assets/trace/devishrimp/BapLogo.svg'
import Shrimp from '../../assets/trace/Shrimp.svg'
import Info from '../../assets/trace/Info.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const styles = () => ({
  verificationResultLink: {
    color: 'blue',
    textDecoration: 'none',
  },

  learnMoreImage: {
    maxWidth: '4vw',
    marginLeft: '2%',
    verticalAlign: 'text-bottom',
    paddingBottom: '1.5%',
    [theme.breakpoints.only('md')]: {
      maxWidth: '3vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '2vw',
    },
  },

  iframe: {
    borderWidth: '1px 0',
    borderColor: 'black',
  },

  text: {
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
    marginTop: '-6%',
    marginBottom: '5%',
    marginLeft: '16%',
    textTransform: 'capitalize',
    paddingRight: '20%',
    [theme.breakpoints.only('md')]: {
      marginLeft: '11%',
      marginTop: '-4%',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-4vh',
      marginBottom: '2%',
      fontSize: '1.2rem',
    },
  },

  speciesText: {
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
    marginTop: '-6%',
    marginBottom: '5%',
    marginLeft: '16%',
    paddingRight: '20%',
    [theme.breakpoints.only('md')]: {
      marginLeft: '11%',
      marginTop: '-4%',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-4vh',
      marginBottom: '2%',
      fontSize: '1.2rem',
    },
  },

  headingTextTrace: {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '0.9rem',
    color: '#4F596F',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem',
    },
  },

  headingIcon: {
    width: '15%',
    [theme.breakpoints.only('md')]: {
      width: '10%',
    },
  },

  shrimpImageGrid: {
    marginLeft: '-15%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '3vw',
    },
  },
})

export function DeviLimited(props) {
  const [modalUrl, setModalUrl] = useState('')

  const handleLearnMoreClick = () => {
    setModalUrl(
      'https://bapcertification.org/assets/resources/informational/bap_back_house_poster_no_bleed.pdf'
    )
  }

  const handleRatingClick = () => {
    setModalUrl('https://bapcertification.org/OurLogo#stars')
  }

  const handleLearnMoreClose = () => {
    setModalUrl('')
  }

  function capitalizeFirstWord(str) {
    let strLower = str.toLowerCase()
    return strLower.charAt(0).toUpperCase() + strLower.slice(1)
  }

  return (
    <div data-testid="DeviLimitedContainer">
      <Grid container spacing={0}>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={MarketName}
                alt="Market Name"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="market_name" />
            </p>
          </div>
          <div id="marketName" className={props.classes.text}>
            {props.trace.market_name || 'N/A'}
          </div>
        </Grid>
        <Grid
          container
          item
          xs={3}
          lg={2}
          alignItems="center"
          justify="center"
          className={props.classes.shrimpImageGrid}
        >
          <img src={Shrimp} alt="Species" width="100%" />
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Species}
                alt="Species"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="species" />
            </p>
          </div>
          <div id="Species" className={props.classes.speciesText}>
            {capitalizeFirstWord(props.trace.species) || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Region}
                alt="Region"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="country_of_origin" />
            </p>
          </div>
          <div id="region" className={props.classes.text}>
            {(props.trace.region || 'N/A').toLowerCase()}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={BapLogo}
                alt="Bap Plant Number"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="bap_plant_number" />
            </p>
          </div>
          <div id="bapPlantNumber" className={props.classes.text}>
            {props.trace.bap_plant_number || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={BapLogo}
                alt="Bap Farm Number"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="bap_farm_number" />
            </p>
          </div>
          <div id="bapFarmNumber" className={props.classes.text}>
            {props.trace.bap_farm_number || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={BapLogo}
                alt="Bap Hatchery Number"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="bap_hatchery_number" />
            </p>
          </div>
          <div id="bapHatcheryNumber" className={props.classes.text}>
            {props.trace.bap_hatchery_number || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={BapLogo}
                alt="Bap Feed Mill Number"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="bap_feed_mill_number" />
            </p>
          </div>
          <div id="bapFeedMillNumber" className={props.classes.text}>
            {props.trace.bap_feed_mill_number || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={BapLogo}
                alt="Bap Rating"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="bap_rating" />
            </p>
          </div>
          <div id="bapRating" className={props.classes.text}>
            <Link
              to="#"
              className={props.classes.verificationResultLink}
              onClick={() => handleRatingClick()}
            >
              {props.trace.bap_rating || 'N/A'}
              <img
                alt="Learn More"
                src={Info}
                className={props.classes.learnMoreImage}
              />
            </Link>
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={HarvestDate}
                alt="Harvest Date"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="harvest_date" />
            </p>
          </div>
          <div id="harvestDate" className={props.classes.text}>
            {props.trace.harvest_date || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7}>
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={ProcessDate}
                alt="Process Date"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="process_date" />
            </p>
          </div>
          <div id="processDate" className={props.classes.text}>
            {props.trace.process_date || 'N/A'}
          </div>
        </Grid>
        <Grid item xs={9} lg={7} data-testid="DeviLimitedSustainabilityLink">
          <div className={props.classes.headingTextTrace}>
            <p>
              <img
                src={Sustainability}
                alt="Sustainability"
                className={props.classes.headingIcon}
                align="middle"
              />{' '}
              <Trans i18nKey="sustainability" />
            </p>
          </div>
          <div id="sustainability" className={props.classes.text}>
            <Link
              to="#"
              className={props.classes.verificationResultLink}
              onClick={() => handleLearnMoreClick()}
            >
              Learn more
              <img
                alt="Learn More"
                src={Info}
                className={props.classes.learnMoreImage}
              />
            </Link>
          </div>
        </Grid>
      </Grid>
      <Dialog fullScreen open={modalUrl !== ''}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={props.classes.headingTextTrace}
          style={{ paddingTop: '1vh', paddingBottom: '1vh' }}
        >
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={handleLearnMoreClose}
          >
            <ArrowBack />
          </Grid>
          <Grid xs={8} item container justify="center" alignItems="center">
            <Lock fontSize="small" />
            &nbsp;bapcertification.org
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={2}
            onClick={handleLearnMoreClose}
          >
            <Close />
          </Grid>
        </Grid>
        <iframe
          title="Bap site"
          className={props.classes.iframe}
          src={modalUrl}
          width="100%"
          height="100%"
        ></iframe>
      </Dialog>
    </div>
  )
}

export const DeviLimitedContainer = withStyles(styles)(DeviLimited)
