// import React, { useEffect } from 'react'
// import {
//   withStyles,
//   InputBase,
//   FormControl,
//   InputLabel,
//   FormHelperText,
// } from '@material-ui/core'
// import { Trans, useTranslation } from 'react-i18next'
import { withStyles, InputBase } from '@material-ui/core'

export const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
      fontSize: '0.75rem',
      color: '#E0E0E0',
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: '#F6F9FA',
    border: '1px solid #E4E7EA',
    fontSize: '0.75rem',
    color: 'black',

    height: '18px',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  focused: {
    boxShadow: '0 0 0 0.1rem #003E71',
  },
  disabled: {
    boxShadow: '0 0 0 0.1rem #E4E7EA',
  },
  error: {
    boxShadow: '0 0 0 0.1rem #B80000',
  },
}))(InputBase)

// export function BootstrapInputComponent(props) {
//   return (
//     <React.Fragment>
//       <FormControl fullWidth>
//         <InputLabel
//           shrink
//           htmlFor="bootstrap-input"
//           // className={classes.labelText}
//         >
//           <Trans i18nKey={props.labelName} />
//         </InputLabel>
//         <BootstrapInput
//           name={props.name}
//           id={props.id}
//           placeholder={props.placeholder}
//           onChange={props.handleOnchange}
//           value={props.value}
//           error={props.error}
//         />
//         {props.error ? (
//           <FormHelperText>{props.helperText}</FormHelperText>
//         ) : null}
//       </FormControl>
//     </React.Fragment>
//   )
// }

// export default BootstrapInputComponent
export default BootstrapInput
