import React from 'react'
import { Box } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  agreement: {
    position: 'absolute',
    bottom: '6vh',
    fontFamily: 'Rubik',
    fontWeight: '300',
    fontSize: '1rem',
  },
})

export function Agreement(props) {
  return (
    <Box maxWidth="sm" className={props.classes.agreement}>
      <label>
        <Trans i18nKey="agreement" />
      </label>
    </Box>
  )
}

export const AgreementContainer = withStyles(styles)(Agreement)
