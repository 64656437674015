import WelcomeBackGroundImage from '../../assets/WelcomeBackGroundImage.png'
import WelcomeBackGroundTopImage from '../../assets/WelcomeBackGroundTopImage.png'
import BottomImage from '../../assets/hail/AddNewHailBottomBackground.png'

export const stylesBase = () => ({
  formControl: {
    marginBottom: '1vh',
  },

  button: {
    //height: '8vh',
    marginTop: '6vh',
    //marginTop: '63px',
    minWidth: '152px',
    backgroundColor: 'white',
    fontSize: '1rem',
    color: '#06669A',
    fontWeight: '500',
    lineHeight: '2.5',
    borderRadius: '3.2rem',
    '&:hover': {
      backgroundColor: 'white',
    },
  },

  link: {
    color: 'white',
    textDecoration: 'none',
    fontFamily: 'Rubik',
    fontWeight: '500',
  },

  languageGrid: {
    //paddingTop: '50px',
  },

  faIcon: {
    verticalAlign: '0em',
  },

  textRegular: {
    color: 'white',
    fontFamily: 'Rubik',
    fontWeight: 300,
    fontSize: '1rem',
  },

  backgroundImage: {
    color: 'white',
    fontWeight: 'regular',
    backgroundImage: `url(${WelcomeBackGroundImage})`,
    backgroundSize: '100% 100%',
    minHeight: '100vh',
    maxWidth: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
  },

  bottomImage: {
    color: 'white',
    fontWeight: 'Regular',
    backgroundImage: `url(${BottomImage})`,
    backgroundSize: '100% 110%',
    minHeight: '76vh',
    maxWidth: '85%',
    marginTop: '-1vh',
    display: 'flex',
    flexDirection: 'column',
  },

  topImage: {
    color: 'white',
    fontWeight: 'regular',
    backgroundImage: `url(${WelcomeBackGroundTopImage})`,
    backgroundSize: '100% 100%',
    minHeight: '25vh',
    maxWidth: '100%',
    paddingTop: '5vh',
    paddingBottom: '2vh',
    marginLeft: '0px',
  },

  bottomGridContainer: {
    paddingTop: '2vh',
    color: 'black',
  },

  headingText: {
    fontWeight: 400,
    fontSize: '0.8rem',
  },

  timeField: {
    color: 'black',
  },

  buttonNext: {
    marginTop: '5vh',
    minWidth: '152px',
    backgroundColor: '#003e6d',
    '&:hover': {
      backgroundColor: '#003e6d',
    },
    fontSize: '1rem',
    color: '#fbf5f5',
    fontWeight: 500,
    lineHeight: 2.5,
    borderRadius: '3.2rem',
  },

  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
  },

  listPriText: {
    color: '#003E6D',
  },

  listSecText: {
    color: '#7F9EB6',
  },
})
