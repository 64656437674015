import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Pagination from '@material-ui/lab/Pagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import MenuItem from '@material-ui/core/MenuItem'
import EditIcon from '@material-ui/icons/Edit'
import SortIcon from '@material-ui/icons/Sort'
import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import classNames from 'classnames'
import addNew from '../assets/addNew.svg'
import SearchComponent from './SearchComponent'
import { FilterOptionDrawer } from './FilterOptionDrawer'
import { LoadingIndicator } from './LoadingIndicator'
import { PortsFormContainer } from '../pages/dealer/PortsForm'
import { ProductLocationsFormContainer } from '../pages/dealer/ProductLocationsForm'
import { HarvestAreaFormContainer } from '../pages/dealer/HarvestAreaForm'
import { GearFormContainer } from '../pages/dealer/GearForm'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  tableheading: {
    whiteSpace: 'nowrap',

    backgroundColor: '#EDEFF1',
    border: '0px',
    paddingLeft: '3px',
    paddingRight: '2px',
  },
  tabledata: {
    borderRight: '1px ridge',
    /**whiteSpace: 'nowrap',*/

    paddingRight: '2px',
    paddingLeft: '5px',
  },
  tablerowselector: {
    paddingLeft: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },

  tableIcon: {
    fontSize: '22px',
    color: 'grey',
    '&:hover': {
      color: 'darkblue',
    },
  },
  tableSelectedIcon: {
    fontSize: '22px',
  },
  multiIconButton: {
    padding: '0px',
  },
  tableRow: {
    backgroundColor: 'white',
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgb(0, 62, 113, .2)',
      color: 'black',
    },
  },
  selected: {},

  tableHead: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },

  pageNumber: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  fromDate: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  toDate: {
    paddingRight: '75px',
    paddingLeft: '75px',
  },

  sortDropdown: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  textDataRegular: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '10pt',
  },

  textDataLight: {
    fontFamily: 'Rubik',
    fontWeight: 'light',
    fontSize: '10pt',
  },

  select: {
    backgroundColor: 'white',
    borderRadius: '50px',
    marginTop: '0px',
    marginLeft: '5px',
    minWidth: '108px',
    maxHeight: '25px',
    paddingLeft: '5px',
    '&:before': {
      borderColor: '#EDEFF1',
    },
    '&:after': {
      borderColor: '#EDEFF1',
    },
  },

  selectIcon: {
    marginTop: '-3px',
  },

  addNewIcon: {
    height: '50px',
    marginRight: '8px',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      height: '58px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  table: {
    overflow: 'hidden',
    flexGrow: 0,
  },

  tableGrid: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    //flexGrow: '0 !important',
    position: 'relative',
    marginBottom: '7vh',
  },
  searchFilterSortcontainer: {
    paddingBottom: '1.5vw !important',
  },
  filterOptionGrid: {
    paddingBottom: '8px !important',
  },
  firstColumnHeader: {
    position: 'sticky',
    left: '0px',
    zIndex: 1,
    width: '15px',
  },
  firstColumnData: {
    position: 'sticky',
    left: '0px',
    zIndex: 1,
  },
  firstColSeldData: {
    backgroundColor: '#EDEFF1',
  },
  firstColNonSelData: {
    backgroundColor: 'white',
  },
  lastColumnHeader: {
    width: '15px',
  },
  inner: {
    overflowX: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '94vw',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  },
  otherColumns: {
    zIndex: '-1',
  },
  outerGrid: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
    },
  },
  checkboxRoot: {
    '&$checked': {
      color: '#003E71',
    },
  },
  checked: {},
  bottomGrid: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20%',
    },
    position: 'absolute',
    bottom: 5,
    right: 10,
  },
}))

const themes = createMuiTheme({
  overrides: {
    MuiPaginationItem: {
      textPrimary: {
        '&$selected': {
          backgroundColor: '#003E71',
          '&:hover': {
            backgroundColor: '#003E71 !important',
          },
        },
      },
    },
  },
})

export function TableView(props) {
  const classes = useStyles()

  let startIndex = props.startIndex

  let displayDataCount = props.displayDataCount
  let pageSelected = props.paramState.pageSelected

  let order = props.paramState.order
  let orderBy = props.paramState.orderBy

  let initalLimitPerPage = props.initalLimitPerPage

  let limitPerPageSelected = props.paramState.limitPerPageSelected

  const [errorMsg] = React.useState('')

  const totalPages = Math.ceil(displayDataCount / limitPerPageSelected)

  let parameterValues = { ...props.paramState }

  //calculate end index
  let endIndex = startIndex + limitPerPageSelected
  if (endIndex > displayDataCount) {
    endIndex = displayDataCount
  }

  //Taking page limit to be always multiple of 10
  const startPerRow = initalLimitPerPage
  const step = initalLimitPerPage

  let pageLimit = []

  if (displayDataCount < startPerRow) {
    pageLimit.push(displayDataCount)
  } else {
    pageLimit = Array.from(
      Array.from(
        Array(Math.ceil((displayDataCount - startPerRow) / step)).keys()
      ),
      x => startPerRow + x * step
    )
    if (
      pageLimit.length === 0 ||
      pageLimit[pageLimit.length - 1] < displayDataCount
    ) {
      pageLimit.push(displayDataCount)
    }
  }

  //Mobile view filter option
  const [filterOptionOpen, setFilterOptionOpen] = React.useState(false)

  function changePageNumber(event, page) {
    parameterValues.pageSelected = parseInt(page)
    props.loadData(parameterValues, false)
  }

  function changeRowPerPage(event) {
    parameterValues.limitPerPageSelected = parseInt(event.target.value)
    props.loadData(parameterValues, false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    parameterValues.order = isAsc ? 'desc' : 'asc'
    parameterValues.orderBy = property
    props.loadData(parameterValues, false)
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const changeSearchKey = searchKeyTerm => {
    parameterValues.searchKey = searchKeyTerm
    //Everytime there is a change in search key
    //page selected should be set to first page
    parameterValues.pageSelected = 1
    props.loadData(parameterValues, true)
  }

  const changeSortOption = event => {
    parameterValues.order = 'asc'
    parameterValues.orderBy = event.target.value
    props.loadData(parameterValues, false)
  }

  const handleAddNewItem = () => {
    //if check is temporarly done as add new is not implemented for all components yet
    if (props.isAddNewAllowed) {
      props.onHandleaddNew()
    }
  }

  function handleFilterOnclick(status) {
    setFilterOptionOpen(status)
  }

  function handleFilterSelection(sortBy, fromDate, toDate) {
    parameterValues.fromDate = fromDate
    parameterValues.toDate = toDate
    props.loadData(parameterValues, true)
  }

  function showAddNewRow(type, i) {
    if (!props.open && type === 'new') {
      return
    }
    if (!props.openEdit && type === 'edit') {
      return
    }
    if (props.formToShow === 'port') {
      return (
        <PortsFormContainer
          handleClose={props.handleClose}
          backToPort={props.backToPort}
        />
      )
    } else if (props.formToShow === 'product location') {
      return (
        <ProductLocationsFormContainer
          handleClose={props.handleClose}
          backToProductLocation={props.backToProductLocation}
          id={props.id}
          i={i}
        />
      )
    } else if (props.formToShow === 'harvest area') {
      return (
        <HarvestAreaFormContainer
          handleClose={props.handleClose}
          backToHarvestArea={props.backToHarvestArea}
          id={props.id}
        />
      )
    } else if (props.formToShow === 'gear') {
      return (
        <GearFormContainer
          handleClose={props.handleClose}
          backToGear={props.backToGear}
          id={props.id}
          i={i}
        />
      )
    }
  }

  return (
    <div className={classes.outerGrid}>
      <Grid container item xs={12} md={12} spacing={1} alignItems="flex-end">
        <Grid container item xs={12} md={3} lg={3}>
          <Grid item xs={8} sm={5} md={12} lg={12}>
            <SearchComponent onChangeSearchKey={changeSearchKey} />
          </Grid>

          <Hidden only={['md', 'lg', 'xl']}>
            <Grid item xs={2} sm={4} />
            <Grid
              container
              item
              xs={2}
              sm={3}
              alignItems="flex-end"
              className={classes.filterOptionGrid}
            >
              <SortIcon onClick={() => handleFilterOnclick(true)} />
              <div key={filterOptionOpen}>
                <FilterOptionDrawer
                  open={filterOptionOpen}
                  handleOnClose={handleFilterOnclick}
                  sortOptions={props.sortOptions}
                  onFilterSelection={handleFilterSelection}
                  sortBy=""
                  fromDate={parameterValues.fromDate}
                  toDate={parameterValues.toDate}
                  isSortEnabled={props.isSortEnabled}
                />
              </div>
            </Grid>
          </Hidden>
        </Grid>

        <Hidden only={['xs', 'sm']}>
          <Grid item xs={3} className={classes.filterOptionGrid}>
            {props.sortOptions ? (
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignItems="center"
              >
                <Typography
                  display="inline"
                  className={classNames(
                    classes.sortDropdown,
                    classes.textDataLight
                  )}
                >
                  {' '}
                  <Trans i18nKey="sortBy" />{' '}
                </Typography>
                <Select
                  value={orderBy}
                  onChange={changeSortOption}
                  className={classNames(
                    classes.sortOptionSelector,
                    classes.select
                  )}
                  inputProps={{
                    classes: {
                      icon: classes.selectIcon,
                    },
                  }}
                >
                  {props.sortOptions.map(sortOption => (
                    <MenuItem
                      key={sortOption.id}
                      value={sortOption.id}
                      className={classes.textDataRegular}
                    >
                      {sortOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : (
              <div> </div>
            )}
          </Grid>
        </Hidden>
      </Grid>

      {props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container className={classes.tableGrid}>
          <div className={classes.inner}>
            <Table size="small" aria-label="simple table">
              {/** Table Header Start **/}
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.tableheading,
                      classes.textDataRegular,
                      classes.firstColumnHeader
                    )}
                  >
                    Sort:
                  </TableCell>
                  {props.headCells.map(headCell => (
                    <TableCell
                      className={classNames(
                        classes.tableheading,
                        classes.textDataRegular,
                        classes.otherColumns
                      )}
                      key={headCell.label}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.sort ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          <Trans i18nKey={headCell.label} />
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <Trans i18nKey={headCell.label} />
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    className={classNames(
                      classes.tableheading,
                      classes.textDataRegular,
                      classes.lastColumnHeader
                    )}
                  ></TableCell>
                </TableRow>
              </TableHead>

              {/** Table Header End **/}

              {/** Table row start **/}
              <TableBody>
                {showAddNewRow('new')}
                {props.displayDataCount > 0 &&
                  props.displayData.map((row, i) => {
                    if (row.id === props.id && props.formToShow) {
                      return showAddNewRow('edit', i)
                    }
                    return (
                      <TableRow
                        hover
                        key={row.id}
                        classes={{ selected: classes.selected }}
                        className={classes.tableRow}
                      >
                        {/** start of table row iteration */}
                        <TableCell
                          key={row.id}
                          className={classNames(
                            classes.tabledata,
                            classes.textDataLight,
                            classes.otherColumns
                          )}
                        >
                          {props.open ? i + 2 : i + 1}
                        </TableCell>
                        {props.headCells.map(function(headCell) {
                          let valueToDisplay = row[headCell.id]
                          if (headCell.type && headCell.type === 'Date') {
                            valueToDisplay = valueToDisplay.split('T')[0]
                          }
                          if (headCell.fetchFromParent) {
                            valueToDisplay = props.onFetchFromParent(
                              headCell.fetchFromParent,
                              row.id
                            )
                          }
                          return (
                            <TableCell
                              key={valueToDisplay}
                              className={classNames(
                                classes.tabledata,
                                classes.textDataLight,
                                classes.otherColumns
                              )}
                            >
                              {valueToDisplay}
                            </TableCell>
                          )
                        })}
                        {/** end of table row iteration */}
                        <TableCell
                          className={classNames(
                            classes.tabledata,
                            classes.textDataLight,
                            classes.otherColumns
                          )}
                        >
                          <IconButton
                            className={classes.multiIconButton}
                            onClick={() => props.handleEdit(row.id)}
                            disabled={props.disableEdit}
                          >
                            <EditIcon
                              className={classes.tableIcon}
                              color="disabled"
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
              {/** Table row end **/}
            </Table>
          </div>
        </Grid>
      )}
      <Grid container className={classes.bottomGrid}>
        {/** Pagination component Start */}
        <Hidden only={['xs', 'sm']}>
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            item
            xs={12}
            md={12}
            spacing={2}
          >
            <Grid container item xs={12} md={2} justify="center">
              {displayDataCount > 0 ? (
                <Typography
                  display="inline"
                  className={classNames(
                    classes.pageNumber,
                    classes.textDataLight
                  )}
                >
                  {' '}
                  <Trans i18nKey="showing" /> {startIndex + 1}{' '}
                  <Trans i18nKey="of" /> {endIndex} <Trans i18nKey="of" />{' '}
                  {displayDataCount}{' '}
                </Typography>
              ) : (
                <Typography
                  className={classNames(
                    classes.pageNumber,
                    classes.textDataLight
                  )}
                >
                  No result returned
                </Typography>
              )}
            </Grid>

            <Grid container item xs={10} md={5} justify="center">
              <MuiThemeProvider theme={themes}>
                {' '}
                <Pagination
                  count={totalPages}
                  page={pageSelected}
                  onChange={(event, page) => changePageNumber(event, page)}
                  color="primary"
                  size="small"
                />
              </MuiThemeProvider>
            </Grid>

            <Grid container item xs={2} md={3} justify="flex-end">
              <Typography
                display="inline"
                className={classNames(
                  classes.pageNumber,
                  classes.textDataLight
                )}
              >
                {' '}
                <Trans i18nKey="show" />{' '}
              </Typography>
              <select value={limitPerPageSelected} onChange={changeRowPerPage}>
                {pageLimit.map(perPageVal => (
                  <option key={perPageVal} value={perPageVal}>
                    {perPageVal}
                  </option>
                ))}
              </select>
              <Typography
                display="inline"
                className={classNames(
                  classes.pageNumber,
                  classes.textDataLight
                )}
              >
                {' '}
                <Trans i18nKey="entries" />{' '}
              </Typography>
            </Grid>

            <Grid container item xs={2} md={2} justify="center">
              <img
                src={addNew}
                className={classes.addNewIcon}
                alt="notification"
                onClick={event => handleAddNewItem(event)}
              />
            </Grid>
          </Grid>
        </Hidden>

        <Hidden only={['md', 'lg', 'xl']}>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={1} sm={1} />

            <Grid container item xs={9} sm={8} justify="center">
              <MuiThemeProvider theme={themes}>
                {' '}
                <Pagination
                  count={totalPages}
                  siblingCount={0}
                  page={pageSelected}
                  onChange={(event, page) => changePageNumber(event, page)}
                  color="primary"
                  size="small"
                />
              </MuiThemeProvider>
            </Grid>
            <Grid item xs={2} sm={3}>
              <img
                src={addNew}
                className={classes.addNewIcon}
                alt="notification"
                onClick={event => handleAddNewItem(event)}
              />
            </Grid>
          </Grid>
        </Hidden>
        {/** Pagination component end */}
      </Grid>
      <Typography display="inline"> {errorMsg} </Typography>
    </div>
  )
}
