import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Pagination from '@material-ui/lab/Pagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'

import EditIcon from '@material-ui/icons/Edit'
import GetApp from '@material-ui/icons/GetApp'
import Print from '@material-ui/icons/Print'
import SortIcon from '@material-ui/icons/Sort'
import DoneAllIcon from '@material-ui/icons/DoneAll'

import { createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { Trans } from 'react-i18next'
import classNames from 'classnames'

import addNew from '../../assets/addNew.svg'
import safisUpload from '../../assets/safisupload.svg'

import DateRangeComponent from '../../components/DateRangeComponent'
import SearchComponent from '../../components/SearchComponent'

import { FilterOptionDrawer } from '../../components/FilterOptionDrawer'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import Tooltip from '@material-ui/core/Tooltip'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import { List, ListItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  tableheading: {
    whiteSpace: 'nowrap',

    backgroundColor: '#EDEFF1',
    border: '0px',
    paddingLeft: '3px',
    paddingRight: '2px',
  },
  tabledata: {
    borderRight: '1px ridge',
    /**whiteSpace: 'nowrap',*/

    paddingRight: '2px',
    paddingLeft: '5px',
    verticalAlign: 'baseline',
  },
  tablerowselector: {
    paddingLeft: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },

  tableIcon: {
    fontSize: '26px',
    color: 'grey',
    '&:hover': {
      color: 'darkblue',
    },
  },
  tableSelectedIcon: {
    fontSize: '26px',
  },
  multiIconButton: {
    padding: '0px',
    paddingRight: '-10px',
  },
  tableRow: {
    backgroundColor: 'white',
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgb(0, 62, 113, .2)',
      color: 'black',
    },
  },
  selected: {},

  tableHead: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },

  pageNumber: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  fromDate: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  toDate: {
    paddingRight: '75px',
    paddingLeft: '75px',
  },

  sortDropdown: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  textDataRegular: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '10pt',
  },

  textDataLight: {
    fontFamily: 'Rubik',
    fontWeight: 'light',
    fontSize: '10pt',
  },

  textBold: {
    fontWeight: '500',
  },

  select: {
    backgroundColor: 'white',
    borderRadius: '50px',
    marginTop: '0px',
    marginLeft: '5px',
    minWidth: '108px',
    maxHeight: '25px',
    paddingLeft: '5px',
    '&:before': {
      borderColor: '#EDEFF1',
    },
    '&:after': {
      borderColor: '#EDEFF1',
    },
  },

  selectIcon: {
    marginTop: '-3px',
  },

  addNewIcon: {
    height: '50px',
    marginRight: '8px',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      height: '58px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  statusChip: {
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontSize: '10pt',
    width: '100%',
  },

  statusGreen: {
    backgroundColor: '#75EFB2',
    color: '#006837',
  },
  statusYellow: {
    backgroundColor: '#F4CF98',
    color: '#916019',
  },
  statusRed: {
    backgroundColor: '#EDA496',
    color: '#EA3713',
  },

  table: {
    overflow: 'hidden',
    flexGrow: 0,
  },

  tableGrid: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    //flexGrow: '0 !important',
    position: 'relative',
  },
  searchFilterSortcontainer: {
    paddingBottom: '1.5vw !important',
  },
  filterOptionGrid: {
    paddingBottom: '8px !important',
  },
  firstColumnHeader: {
    position: 'sticky',
    left: '0px',
    zIndex: 1,
  },
  firstColumnData: {
    position: 'sticky',
    left: '0px',
    zIndex: 1,
  },
  firstColSeldData: {
    backgroundColor: '#EDEFF1',
  },
  firstColNonSelData: {
    backgroundColor: 'white',
  },
  inner: {
    overflowX: 'scroll',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '94vw',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '94vw',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '80vw',
      maxWidth: '80vw',
    },
  },
  otherColumns: {
    zIndex: '-1',
  },
  outerGrid: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
    },
  },

  tableCellGradient: {
    background: 'linear-gradient(-90deg, #FFFFFF 90%, #003E71 10%)',
  },

  checkboxRoot: {
    '&$checked': {
      color: '#003E71',
    },
  },
  checked: {},
  listClass: {
    padding: 0,
    margin: 0,
    position: 'relative',
    top: '8px',
  },
  listItemClass: {
    padding: 0,
    paddingBottom: '8px',
    margin: 0,
  },
  topListItemClass: {
    padding: 0,
    margin: 0,
  },
}))

const themes = createMuiTheme({
  overrides: {
    MuiPaginationItem: {
      textPrimary: {
        '&$selected': {
          backgroundColor: '#003E71',
          '&:hover': {
            backgroundColor: '#003E71 !important',
          },
        },
      },
    },
  },
})

export function DealerOffloadList(props) {
  const classes = useStyles()
  const defaultCollapse = {}
  props.displayData.forEach(row => (defaultCollapse[row.id] = false))

  const [data, setData] = React.useState({
    collapse: defaultCollapse,
    filterOptionOpen: false,
    selected: [],
    errorMsg: '',
  })

  let startIndex = props.startIndex
  let displayDataCount = props.displayDataCount
  let pageSelected = props.paramState.pageSelected

  let order = props.paramState.order
  let orderBy = props.paramState.orderBy

  let initalLimitPerPage = props.initalLimitPerPage

  let limitPerPageSelected = props.paramState.limitPerPageSelected

  const totalPages = Math.ceil(displayDataCount / limitPerPageSelected)

  let parameterValues = { ...props.paramState }

  //calculate end index
  let endIndex = startIndex + limitPerPageSelected
  if (endIndex > displayDataCount) {
    endIndex = displayDataCount
  }

  //Taking page limit to be always multiple of 10
  const startPerRow = initalLimitPerPage
  const step = initalLimitPerPage

  let pageLimit = []

  if (displayDataCount < startPerRow) {
    pageLimit.push(displayDataCount)
  } else {
    pageLimit = Array.from(
      Array.from(
        Array(Math.ceil((displayDataCount - startPerRow) / step)).keys()
      ),
      x => startPerRow + x * step
    )
    if (
      pageLimit.length === 0 ||
      pageLimit[pageLimit.length - 1] < displayDataCount
    ) {
      pageLimit.push(displayDataCount)
    }
  }

  //Mobile view filter option

  const isSelected = name => data.selected.indexOf(name) !== -1

  const handleClick = (event, name) => {
    let selected = data.selected
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setData({ ...data, selected: newSelected })
  }

  function changePageNumber(event, page) {
    parameterValues.pageSelected = parseInt(page)
    props.loadData(parameterValues, false)
  }

  function changeRowPerPage(event) {
    parameterValues.limitPerPageSelected = parseInt(event.target.value)
    props.loadData(parameterValues, false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    parameterValues.order = isAsc ? 'desc' : 'asc'
    parameterValues.orderBy = property
    props.loadData(parameterValues, false)
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const changeSearchKey = searchKeyTerm => {
    parameterValues.searchKey = searchKeyTerm
    //Everytime there is a change in search key
    //page selected should be set to first page
    parameterValues.pageSelected = 1
    props.loadData(parameterValues, true)
  }

  const changeSortOption = event => {
    parameterValues.order = 'asc'
    parameterValues.orderBy = event.target.value
    props.loadData(parameterValues, false)
  }

  function searchWithDateRange(fromDate, toDate) {
    parameterValues.fromDate = fromDate
    parameterValues.toDate = toDate
    props.loadData(parameterValues, true)
  }

  const handleAddNewItem = () => {
    //if check is temporarly done as add new is not implemented for all components yet
    if (props.isAddNewAllowed) {
      props.onHandleaddNew()
    }
  }

  function handleFilterOnclick(status) {
    setData({ ...data, filterOptionOpen: status })
  }

  function handleFilterSelection(sortBy, fromDate, toDate) {
    parameterValues.fromDate = fromDate
    parameterValues.toDate = toDate
    props.loadData(parameterValues, true)
  }
  return (
    <div className={classes.outerGrid}>
      <Grid container item xs={12} md={12} spacing={1} alignItems="flex-end">
        <Grid container item xs={12} md={3} lg={3}>
          <Grid item xs={8} sm={5} md={12} lg={12}>
            <SearchComponent onChangeSearchKey={changeSearchKey} />
          </Grid>

          <Hidden only={['md', 'lg', 'xl']}>
            <Grid item xs={2} sm={4} />
            <Grid
              container
              item
              xs={2}
              sm={3}
              alignItems="flex-end"
              className={classes.filterOptionGrid}
            >
              <SortIcon onClick={() => handleFilterOnclick(true)} />
              <div key={data.filterOptionOpen}>
                <FilterOptionDrawer
                  open={data.filterOptionOpen}
                  handleOnClose={handleFilterOnclick}
                  sortOptions={props.sortOptions}
                  onFilterSelection={handleFilterSelection}
                  sortBy=""
                  fromDate={parameterValues.fromDate}
                  toDate={parameterValues.toDate}
                  isSortEnabled={props.isSortEnabled}
                />
              </div>
            </Grid>
          </Hidden>
        </Grid>

        <Hidden only={['xs', 'sm']}>
          <Grid item xs={3} className={classes.filterOptionGrid}>
            {props.sortOptions ? (
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignItems="center"
              >
                <Typography
                  display="inline"
                  className={classNames(
                    classes.sortDropdown,
                    classes.textDataLight
                  )}
                >
                  {' '}
                  <Trans i18nKey="sortBy" />{' '}
                </Typography>
                <Select
                  value={orderBy}
                  onChange={changeSortOption}
                  className={classNames(
                    classes.sortOptionSelector,
                    classes.select
                  )}
                  inputProps={{
                    classes: {
                      icon: classes.selectIcon,
                    },
                  }}
                >
                  {props.sortOptions.map(sortOption => (
                    <MenuItem
                      key={sortOption.id}
                      value={sortOption.id}
                      className={classes.textDataRegular}
                    >
                      {sortOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : (
              <div> </div>
            )}
          </Grid>

          <Grid item xs={4} className={classes.filterOptionGrid}>
            <Grid container item xs={12} justify="flex-end">
              <DateRangeComponent
                fromDate={parameterValues.fromDate}
                toDate={parameterValues.toDate}
                onDateRangeChange={searchWithDateRange}
              />
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.filterOptionGrid}>
            <Grid container item xs={12} justify="space-evenly">
              <GetApp fontSize="small" />
              <Print fontSize="small" />
              <img
                src={safisUpload}
                style={{ height: '24px' }}
                alt="safisupload"
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      {props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container className={classes.tableGrid}>
          <div className={classes.inner}>
            <Table size="small" aria-label="simple table">
              {/** Table Header Start **/}
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.tableheading,
                      classes.textDataRegular,
                      classes.firstColumnHeader
                    )}
                  >
                    Sort by
                  </TableCell>

                  {props.headCells.map(headCell => (
                    <TableCell
                      className={classNames(
                        classes.tableheading,
                        classes.textDataRegular,
                        classes.otherColumns
                      )}
                      key={headCell.label}
                      sortDirection={orderBy === headCell.sort ? order : false}
                    >
                      {headCell.sort ? (
                        <TableSortLabel
                          active={orderBy === headCell.sort}
                          direction={orderBy === headCell.sort ? order : 'asc'}
                          onClick={createSortHandler(headCell.sort)}
                        >
                          <Trans i18nKey={headCell.label} />
                          {orderBy === headCell.sort ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <Trans i18nKey={headCell.label} />
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    className={classNames(
                      classes.tableheading,
                      classes.textDataRegular
                    )}
                  ></TableCell>
                </TableRow>
              </TableHead>

              {/** Table Header End **/}

              {/** Table row start **/}
              <TableBody>
                {props.displayDataCount > 0 &&
                  props.displayData.map(row => {
                    const isItemSelected = isSelected(row.id)
                    return (
                      <TableRow
                        hover
                        selected={isItemSelected}
                        classes={{ selected: classes.selected }}
                        className={classes.tableRow}
                        key={row.id}
                      >
                        <TableCell
                          align="center"
                          className={`${
                            isItemSelected
                              ? classes.tabledata
                              : classes.tabledata
                          }
                                      ${
                                        isItemSelected
                                          ? classes.firstColumnData
                                          : classes.firstColumnData
                                      }
                                      ${
                                        isItemSelected
                                          ? classes.firstColSeldData
                                          : classes.firstColNonSelData
                                      }
                                      ${
                                        row.status === 'submitted'
                                          ? classes.tableCellGradient
                                          : ''
                                      }
                                    `}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={event => {
                              handleClick(event, row.id)
                            }}
                            color="primary"
                            className={classes.tablerowselector}
                            classes={{
                              root: classes.checkboxRoot,
                              checked: classes.checked,
                            }}
                          />
                        </TableCell>

                        {/** start of table row iteration */}
                        {props.headCells.map(function(headCell) {
                          let valueToDisplay = row[headCell.id]
                          if (headCell.type && headCell.type === 'Date') {
                            valueToDisplay = valueToDisplay.split(' ')[0]
                          }

                          if (headCell.fetchFromParent) {
                            if (headCell.fetchFromParent == 'weight') {
                              data.collapse[row.id]
                                ? (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[1])
                                : (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[0])
                            } else if (headCell.fetchFromParent == 'species') {
                              data.collapse[row.id]
                                ? (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[1])
                                : (valueToDisplay = props.onFetchFromParent(
                                    headCell.fetchFromParent,
                                    valueToDisplay
                                  )[0])
                            } else {
                              valueToDisplay = props.onFetchFromParent(
                                headCell.fetchFromParent,
                                valueToDisplay
                              )
                            }
                          }

                          if (headCell.toolTipFromParent) {
                            let toolTipValue = row[headCell.id]
                            toolTipValue = props.onFetchFromParent(
                              headCell.toolTipFromParent,
                              toolTipValue
                            )
                            let toolTipBaseValue = valueToDisplay
                            valueToDisplay = (
                              <Tooltip title={toolTipValue} placement="bottom">
                                <div>{toolTipBaseValue}</div>
                              </Tooltip>
                            )
                          }

                          return (
                            <TableCell
                              key={valueToDisplay + '_' + headCell.id}
                              className={classNames(
                                classes.tabledata,
                                classes.textDataLight,
                                classes.otherColumns
                              )}
                              onClick={() => {
                                {
                                  let tempCollapse = { ...data.collapse }
                                  tempCollapse[row.id] = !tempCollapse[row.id]
                                  setData({ ...data, collapse: tempCollapse })
                                }
                              }}
                            >
                              {headCell.id === 'status' ? (
                                <Chip
                                  size="small"
                                  className={`classes.statusChip
                                  ${
                                    valueToDisplay === 'Incoming'
                                      ? classes.statusRed
                                      : ''
                                  }
                                  ${
                                    valueToDisplay === 'Accepted'
                                      ? classes.statusYellow
                                      : ''
                                  }
                                  ${
                                    valueToDisplay === 'Landed'
                                      ? classes.statusGreen
                                      : ''
                                  }
                                  ${
                                    valueToDisplay === 'Incoming'
                                      ? classes.statusChip
                                      : classes.statusChip
                                  }
                                `}
                                  label={valueToDisplay}
                                />
                              ) : (
                                <div
                                  className={
                                    row.status === 'submitted'
                                      ? classes.textBold
                                      : ''
                                  }
                                >
                                  {valueToDisplay}
                                </div>
                              )}
                            </TableCell>
                          )
                        })}
                        {/** end of table row iteration */}

                        <TableCell
                          className={classNames(
                            classes.tabledata,
                            classes.textDataLight,
                            classes.otherColumns
                          )}
                        >
                          {!data.collapse[row.id] ? (
                            <IconButton
                              className={classes.multiIconButton}
                              onClick={() => props.handleEdit(row.order_number)}
                            >
                              {row.status === 'submitted' ? (
                                <DoneAllIcon
                                  className={classes.tableSelectedIcon}
                                  color={'primary'}
                                />
                              ) : (
                                <EditIcon
                                  className={classes.tableSelectedIcon}
                                  color={'primary'}
                                />
                              )}
                            </IconButton>
                          ) : (
                            <>
                              <List className={classes.listClass}>
                                <ListItem className={classes.listItemClass}>
                                  {
                                    <IconButton
                                      className={classes.multiIconButton}
                                      onClick={() =>
                                        props.handleEdit(row.order_number)
                                      }
                                    >
                                      {row.status === 'submitted' ? (
                                        <DoneAllIcon
                                          className={classes.tableSelectedIcon}
                                          color={'primary'}
                                        />
                                      ) : (
                                        <EditIcon
                                          className={classes.tableSelectedIcon}
                                          color={'primary'}
                                        />
                                      )}
                                    </IconButton>
                                  }
                                </ListItem>
                                <ListItem className={classes.listItemClass}>
                                  {
                                    <IconButton
                                      className={classes.multiIconButton}
                                    >
                                      <HighlightOffOutlinedIcon
                                        className={classes.tableSelectedIcon}
                                        color="primary"
                                        fontSize="small"
                                        onClick={() => {
                                          let tempCollapse = {
                                            ...data.collapse,
                                          }
                                          tempCollapse[row.id] = !tempCollapse[
                                            row.id
                                          ]
                                          setData({
                                            ...data,
                                            collapse: tempCollapse,
                                          })
                                        }}
                                      />
                                    </IconButton>
                                  }
                                </ListItem>
                              </List>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
              {/** Table row end **/}
            </Table>
          </div>

          <Grid container>
            {/** Pagination component Start */}
            <Hidden only={['xs', 'sm']}>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                item
                xs={12}
                md={12}
                spacing={2}
              >
                <Grid container item xs={12} md={2} justify="center">
                  {displayDataCount > 0 ? (
                    <Typography
                      display="inline"
                      className={classNames(
                        classes.pageNumber,
                        classes.textDataLight
                      )}
                    >
                      {' '}
                      <Trans i18nKey="showing" /> {startIndex + 1}{' '}
                      <Trans i18nKey="of" /> {endIndex} <Trans i18nKey="of" />{' '}
                      {displayDataCount}{' '}
                    </Typography>
                  ) : (
                    <Typography
                      className={classNames(
                        classes.pageNumber,
                        classes.textDataLight
                      )}
                    >
                      No result returned
                    </Typography>
                  )}
                </Grid>

                <Grid container item xs={10} md={5} justify="center">
                  <MuiThemeProvider theme={themes}>
                    {' '}
                    <Pagination
                      count={totalPages}
                      page={pageSelected}
                      onChange={(event, page) => changePageNumber(event, page)}
                      color="primary"
                      size="small"
                    />
                  </MuiThemeProvider>
                </Grid>

                <Grid container item xs={2} md={3} justify="flex-end">
                  <Typography
                    display="inline"
                    className={classNames(
                      classes.pageNumber,
                      classes.textDataLight
                    )}
                  >
                    {' '}
                    <Trans i18nKey="show" />{' '}
                  </Typography>
                  <select
                    value={limitPerPageSelected}
                    onChange={changeRowPerPage}
                  >
                    {pageLimit.map(perPageVal => (
                      <option key={perPageVal} value={perPageVal}>
                        {perPageVal}
                      </option>
                    ))}
                  </select>
                  <Typography
                    display="inline"
                    className={classNames(
                      classes.pageNumber,
                      classes.textDataLight
                    )}
                  >
                    {' '}
                    <Trans i18nKey="entries" />{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={2} md={2} justify="center">
                  <img
                    src={addNew}
                    className={classes.addNewIcon}
                    alt="notification"
                    onClick={event => handleAddNewItem(event)}
                  />
                </Grid>
              </Grid>
            </Hidden>

            <Hidden only={['md', 'lg', 'xl']}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={1} sm={1} />

                <Grid container item xs={9} sm={8} justify="center">
                  <MuiThemeProvider theme={themes}>
                    {' '}
                    <Pagination
                      count={totalPages}
                      siblingCount={0}
                      page={pageSelected}
                      onChange={(event, page) => changePageNumber(event, page)}
                      color="primary"
                      size="small"
                    />
                  </MuiThemeProvider>
                </Grid>

                <Grid item xs={2} sm={3}>
                  <img
                    src={addNew}
                    className={classes.addNewIcon}
                    alt="notification"
                    onClick={event => handleAddNewItem(event)}
                  />
                </Grid>
              </Grid>
            </Hidden>

            {/** Pagination component end */}
          </Grid>
        </Grid>
      )}
      <Typography display="inline"> {data.errorMsg} </Typography>
    </div>
  )
}
