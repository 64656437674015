import React from 'react'
import { connect } from 'react-redux'
import { Button, TextField, InputAdornment } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { PublicContainer } from '../components'
import Grid from '@material-ui/core/Grid'
import LockIcon from '@material-ui/icons/Lock'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './shared/theme'
import combineStyles from './shared/CombineStyles'
import { stylesBase } from './shared/StylesBase'
import { useHistory } from 'react-router-dom'

const styles = () => ({})

export function Password(props) {
  const history = useHistory()
  const confirmPassword = () => {
    const vesselRegnLocation = {
      pathname: '/vessel_registration',
      state: { from: 'password' },
    }
    history.push(vesselRegnLocation)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <PublicContainer
        top={
          <form>
            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <TextField
                  id="formPassword"
                  label=<Trans i18nKey="password" />
                  type="password"
                  //value={username}
                  // onChange={e => setUsername(e.currentTarget.value)}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end" direction="row">
              <Grid item xs={12} className={props.classes.formControl}>
                <TextField
                  id="formConfirmPassword"
                  label=<Trans i18nKey="confirm_password" />
                  type="password"
                  //value={username}
                  // onChange={e => setUsername(e.currentTarget.value)}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        bottom={
          <Button
            id="formButton"
            className={props.classes.button}
            variant="outlined"
            type="button"
            onClick={() => confirmPassword()}
            margin="normal"
          >
            <Trans i18nKey="next" />
          </Button>
        }
      />
    </MuiThemeProvider>
  )
}

const mapStateToProps = () => {
  return {
    //TODO: related code to be added when functionality is built-ins
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(styles, stylesBase))(Password))
