import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import {
  Container,
  Grid,
  Box,
  CircularProgress,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import LogoTagline from '../../../assets/trace/bigeasy/LogoTagline.svg'
import SearchIcon from '@material-ui/icons/Search'
import Alert from '@material-ui/lab/Alert'
import { ApiHelper } from '../../../lib'
import { theme } from '../../../pages/shared/theme'
import WelcomeBackGroundImage from '../../../assets/WelcomeBackGroundImage.png'
import classNames from 'classnames'
import '../../../assets/Trace.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import ReactPlayer from 'react-player'
import { isWidthUp } from '@material-ui/core/withWidth'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TopLine from '../../../assets/trace/butcherbox/TopLine.svg'
import SplitLine from '../../../assets/trace/butcherbox/SplitLine.svg'
import BBVerifiedTrace from '../../../assets/trace/butcherbox/BBVerifiedTrace.svg'
import BBPendingTrace from '../../../assets/trace/butcherbox/BBPendingTrace.svg'
import BBNotVerifiableTrace from '../../../assets/trace/butcherbox/BBNotVerifiableTrace.svg'
import BBFacebook from '../../../assets/trace/butcherbox/BBFacebook.svg'
import BBTwitter from '../../../assets/trace/butcherbox/BBTwitter.svg'
import BBInsta from '../../../assets/trace/butcherbox/BBInsta.svg'
import BBLink from '../../../assets/trace/butcherbox/BBLink.svg'
import Facebook from '../../../assets/trace/bigeasy/Facebook.svg'
import Twitter from '../../../assets/trace/bigeasy/Twitter.svg'
import Insta from '../../../assets/trace/bigeasy/Insta.svg'
import Link from '../../../assets/trace/bigeasy/Link.svg'
import { SustainabilityContainer } from './Sustainability'
import { RecipesContainer } from './Recipes'
import { AboutContainer } from './About'
import { TraceContainer } from './Trace'
import { BottomAppBarContainer } from './BottomAppBar'
import { TopAppBarContainer } from './TopAppBar'
import { RecipesCardContainer } from './RecipesCard'
import videothumb from '../../../assets/trace/butcherbox/videothumb.png'
import bboverlay from '../../../assets/trace/butcherbox/bboverlay.png'
import { useHistory } from 'react-router-dom'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import InfoIcon from '@material-ui/icons/Info'

const themes = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: '25px !important',
        backgroundColor: 'white',
        '&&&&:hover': {
          backgroundColor: 'white',
        },
      },
      underline: {
        '&&&&:before': {
          borderBottom: 'none',
        },
        '&&&&:after': {
          borderBottom: 'none',
        },
      },
      input: {
        padding: '17px 12px 17px',
      },
    },
  },
})

const styles = () => ({
  carouselImage: {
    height: '51vh',
    [theme.breakpoints.up('md')]: {
      height: '65vh',
    },
  },
  overlayimage: {
    position: 'absolute',
    zIndex: 1,
    height: '22vh',
    width: '100%',
  },
  searchField: {
    position: 'fixed',
    top: '12vh',
    width: '100%',
    zIndex: 2,
    padding: '0 3vh 0',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  legitFishTagLine: {
    width: '50%',
    paddingTop: '10vh',
  },
  recipesCarouselImage: {
    width: '100%',
    height: '51vh',
    [theme.breakpoints.up('md')]: {
      height: '54vh',
    },
  },
  recipeCard: {
    padding: '2vh 25px',
  },
  recipesBox: {
    overflow: 'scroll',
    backgroundImage: `url(${WelcomeBackGroundImage})`,
    height: '88vh',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '25%',
      maxWidth: '50%',
    },
  },
  recipeText: {
    marginBottom: '1vh',
  },
  topContainer: {
    maxWidth: '100%',
    minHeight: '50vh',
    paddingLeft: '0px',
    paddingRight: '0px',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  wrapper: {
    position: 'sticky',
    top: '12vh',
    zIndex: 3,
    width: '100%',
    fontFamily: 'Rubik',
    color: 'black',
    fontWeight: 'regular',
    maxWidth: '100%',
    marginTop: '-3vh',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '35px 35px 0px 0px',
  },
  submitButton: {
    minWidth: '120px',
    backgroundColor: '#003e6d',
    fontSize: '1rem',
    color: '#fbf5f5',
    fontWeight: 500,
    lineHeight: 2.5,
    borderRadius: '3.2rem',
    '&:hover': {
      backgroundColor: '#003e6d',
    },
  },
  reportProblemText: {
    color: '#808080',
    fontSize: '0.8rem',
    marginBlockStart: '0.7em',
    marginBlockEnd: 0,
  },
  reportProblemHeading: {
    fontWeight: 700,
    fontSize: '1.05rem',
    color: '#4F596F',
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoCloseButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
  },
})

const useStyles = makeStyles(() => ({
  root: {
    height: '77vh',
    borderRadius: '35px 35px 0px 0px',
  },
  cardArea: {
    overflow: 'scroll',
    width: '100vw',
    height: '70vh',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  cardContent: {
    height: 'auto',
    padding: 0,
  },
  topLine: {
    width: '20%',
  },
  topLineDiv: {
    textAlign: 'center',
  },
  splitLine: {
    padding: '0 5% 0 5%',
  },
  cardHead: {
    maxHeight: '15vh',
    padding: '0 5% 0 5%',
  },
  headingIconTrace: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    paddingBottom: '1vh',
  },
  networkingIcon: {
    width: '10%',
  },
  headingTrace: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '1.6rem',
    height: 'auto',
    paddingTop: '1vh',
    marginBottom: '-2vh',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
  },
  bigEasyColor: {
    color: '#3675AD',
  },
  butcherBoxColor: {
    color: '#2D4D59',
  },
  headingTextTrace: {
    textAlign: 'center',
    paddingBottom: '2vh',
  },
  headingTextHarvest: {
    textAlign: 'center',
    paddingBottom: '2vh',
    fontSize: '1.3rem',
  },
  headingTextAbout: {
    fontWeight: 600,
    fontSize: '1.2rem',
    paddingBottom: '1vh',
    height: 'auto',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem',
    },
  },
  recipesDiv: {
    textAlign: 'center',
  },
}))

export function ButcherBox(props) {
  const trace = props.trace
  const status = trace.status
  const component = trace.component
  const [loadingState, setLoadingState] = useState('')
  const [trackingId, setTrackingId] = useState('')
  const traceId = () => {
    const verificationResult = {
      pathname: '/trace/' + trackingId,
      state: { from: 'verificationResult' },
    }
    history.push(verificationResult)
  }
  const history = useHistory()
  const back = () => {
    const tracePath = {
      pathname: '/trace',
      state: { from: 'verificationResult' },
    }
    history.push(tracePath)
  }
  const [describeProblem, setDescribeProblem] = useState('')
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')

  const [ticketId, setTicketId] = useState('')

  const [errorMsg, setErrorMsg] = useState('')

  const closeTicketDialog = () => {
    setTicketId('')
    setErrorMsg('')
    setDescribeProblem('')
    setUserEmail('')
    setUserPhone('')
    setUserName('')
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setErrorMsg('')
  }

  const [openInfo, setOpenInfo] = useState(false)
  const hostName = window.location.hostname

  const handleOpenInfo = () => {
    setOpenInfo(true)
  }

  const handleCloseInfo = () => {
    setOpenInfo(false)
  }

  const handleSubmit = () => {
    if (!describeProblem || !userName || !userEmail || !userPhone) {
      setErrorMsg('Please fill all the details')
    } else {
      const comment =
        'Phone: ' + userPhone + '\n\n' + 'Comment: ' + describeProblem
      reportAProblem(userName, userEmail, comment)
    }
  }

  function reportAProblem(name, email, problem) {
    setLoadingState('loading')
    setOpen(false)
    const api = new ApiHelper()
    api
      .post('/tickets', {
        user_name: name,
        user_email: email,
        comment: problem,
      })
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        setTicketId(result.id)
        setLoadingState('success')
      })
      .catch(error => {
        setOpen(true)
        if (error.error) {
          setErrorMsg(error.error)
          console.error('Error:' + error.error)
        } else {
          setErrorMsg('Unable to Connect to Server!')
          console.error('Error:' + error)
        }
        setLoadingState('success')
      })
  }

  const classes = useStyles()
  const [componentToShow, setComponentToShow] = useState('Trace')
  const [selectedRecipe, setSelectedRecipe] = useState('')
  const [selectedRecipeImage, setSelectedRecipeImage] = useState('')

  const [openPlayer, setOpenPlayer] = useState('')

  function showPlayer(value, index) {
    if (value.toLowerCase().includes('mp4')) {
      return (
        <div key={index} onClick={() => setOpenPlayer(value)}>
          <img
            alt="Carousel"
            className={props.classes.recipesCarouselImage}
            src={videothumb}
          ></img>
        </div>
      )
    }
    return (
      <img
        alt="Carousel"
        className={props.classes.recipesCarouselImage}
        key={index}
        src={value}
      ></img>
    )
  }

  function carouselImages() {
    const screenWidth = isWidthUp('lg', props.width)
    if (componentToShow === 'Recipes') {
      return (
        <img
          alt="Recipe Image"
          className={props.classes.recipesCarouselImage}
          src={selectedRecipeImage}
        ></img>
      )
    } else if (componentToShow === 'Sustainability') {
      return (
        <img
          alt="Sustainability Image"
          className={props.classes.recipesCarouselImage}
          src={trace.sustainability_image}
        ></img>
      )
    } else if (componentToShow === 'About') {
      return (
        <img
          alt="About Image"
          className={props.classes.recipesCarouselImage}
          src={trace.organization_image}
        ></img>
      )
    } else if (trace.map_images && trace.map_images.length !== 0) {
      return (
        <Carousel
          showThumbs={false}
          showArrows={screenWidth}
          showStatus={false}
        >
          {trace.map_images.map((value, index) => {
            return showPlayer(value, index)
          })}
        </Carousel>
      )
    }
    return (
      <img
        alt="Carousel"
        className={props.classes.recipesCarouselImage}
        src=""
      ></img>
    )
  }

  function showStatusImage() {
    if (status === 'pending') {
      return BBPendingTrace
    } else if (status === 'not_verifiable') {
      return BBNotVerifiableTrace
    } else if (status === 'verified') {
      return BBVerifiedTrace
    }
  }

  function showStatusText() {
    if (status === 'pending') {
      return <Trans i18nKey="pending_trace" />
    } else if (status === 'not_verifiable') {
      return <Trans i18nKey="not_verifiable_trace" />
    } else if (status === 'verified') {
      return <Trans i18nKey="verified_trace" />
    }
  }

  function showInfoIcon() {
    if (status === 'pending') {
      return <InfoIcon onClick={handleOpenInfo} />
    }
  }

  function selectTraceHeading() {
    if (hostName.startsWith('bb04')) {
      return (
        <Grid item xs={12} className={classes.headingTextHarvest}>
          <Trans i18nKey="industry_harvest_info" />
          <InfoIcon onClick={handleOpenInfo} />
        </Grid>
      )
    } else {
      return (
        <Grid container item alignItems="center">
          <Grid item xs={2}>
            <img
              src={showStatusImage()}
              className={classes.headingIconTrace}
              align="middle"
            />
          </Grid>
          <Grid item xs={8} className={classes.headingTextTrace}>
            {showStatusText()}
            {showInfoIcon()}
          </Grid>
        </Grid>
      )
    }
  }

  function cardHeadSelector() {
    if (
      component === 'BigEasyContainer' &&
      componentToShow === 'Sustainability'
    ) {
      return (
        <div>
          <Typography
            className={classNames(
              classes.headingTextAbout,
              classes.bigEasyColor
            )}
          >
            Always looking for a way to Sustain the U.S Warm Water Shrimp
            Population
          </Typography>
          <Typography
            className={classes.bigEasyColor}
            component="span"
            variant="body2"
          >
            The Gulf&apos;s precautionary approach to resource management is a
            model for the industry.
          </Typography>
        </div>
      )
    } else if (
      componentToShow === 'Sustainability' &&
      trace.sustainability_url
    ) {
      return (
        <div className={classes.recipesDiv}>
          <Typography
            className={classNames(
              classes.headingTextAbout,
              component === 'ButcherBoxContainer'
                ? classes.butcherBoxColor
                : classes.bigEasyColor
            )}
          >
            {
              trace.sustainability_url
                .replace(/(^\w+:|^)\/\//, '')
                .split('/')[0]
            }
          </Typography>
        </div>
      )
    } else if (componentToShow === 'Sustainability') {
      return (
        <div>
          <Typography
            className={classNames(
              classes.headingTextAbout,
              component === 'ButcherBoxContainer'
                ? classes.butcherBoxColor
                : classes.bigEasyColor
            )}
          >
            We are always looking for ways to sustain the U.S. warm water shrimp
            population
          </Typography>
        </div>
      )
    } else if (componentToShow === 'Recipes') {
      return (
        <div className={classes.recipesDiv}>
          <Typography
            className={classNames(
              classes.headingTextAbout,
              component === 'ButcherBoxContainer'
                ? classes.butcherBoxColor
                : classes.bigEasyColor
            )}
          >
            {selectedRecipe.replace(/(^\w+:|^)\/\//, '').split('/')[0]}
          </Typography>
        </div>
      )
    } else if (componentToShow === 'About') {
      return (
        <div>
          <Typography
            className={classNames(
              classes.headingTextAbout,
              component === 'ButcherBoxContainer'
                ? classes.butcherBoxColor
                : classes.bigEasyColor
            )}
          >
            {component === 'ButcherBoxContainer'
              ? 'Delivering high-quality meat is just the beginning'
              : 'An inovative software approach to revolutionizing seafood traceability'}
          </Typography>
          {component === 'BigEasyContainer' ? (
            <div>
              <Typography
                className={classes.bigEasyColor}
                component="span"
                variant="body2"
              >
                Fully traceable from vessel to table.
              </Typography>
            </div>
          ) : (
            <div></div>
          )}
          <img
            src={component === 'ButcherBoxContainer' ? BBLink : Link}
            alt="Link"
            onClick={() =>
              window.open(
                component === 'ButcherBoxContainer'
                  ? 'https://www.butcherbox.com'
                  : '#'
              )
            }
            className={classes.networkingIcon}
          />
          &nbsp;
          <img
            src={component === 'ButcherBoxContainer' ? BBFacebook : Facebook}
            alt="Facebook"
            onClick={() =>
              window.open(
                component === 'ButcherBoxContainer'
                  ? 'https://www.facebook.com/getbutcherbox'
                  : '#'
              )
            }
            className={classes.networkingIcon}
          />
          &nbsp;
          <img
            src={component === 'ButcherBoxContainer' ? BBTwitter : Twitter}
            alt="Twitter"
            onClick={() =>
              window.open(
                component === 'ButcherBoxContainer'
                  ? 'https://twitter.com/butcherbox'
                  : '#'
              )
            }
            className={classes.networkingIcon}
          />
          &nbsp;
          <img
            src={component === 'ButcherBoxContainer' ? BBInsta : Insta}
            alt="Insta"
            onClick={() =>
              window.open(
                component === 'ButcherBoxContainer'
                  ? 'https://www.instagram.com/butcher_box'
                  : '#'
              )
            }
            className={classes.networkingIcon}
          />
        </div>
      )
    } else if (componentToShow === 'Trace' && selectedRecipe) {
      return (
        <div className={classes.recipesDiv}>
          <Typography
            className={classNames(
              classes.headingTextAbout,
              component === 'ButcherBoxContainer'
                ? classes.butcherBoxColor
                : classes.bigEasyColor
            )}
          >
            {selectedRecipe.replace(/(^\w+:|^)\/\//, '').split('/')[0]}
          </Typography>
        </div>
      )
    }
    return (
      <Grid
        container
        alignItems="center"
        className={classNames(
          classes.headingTrace,
          component === 'ButcherBoxContainer'
            ? classes.butcherBoxColor
            : classes.bigEasyColor
        )}
      >
        {selectTraceHeading()}
      </Grid>
    )
  }
  function showSearchField() {
    if (componentToShow === 'Trace') {
      return (
        <Grid item xs={12} className={props.classes.searchField}>
          <form onSubmit={() => traceId()}>
            <TextField
              placeholder={trace.id}
              onChange={e => setTrackingId(e.currentTarget.value)}
              variant="filled"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
      )
    }
    if (component === 'BigEasyContainer' && componentToShow === 'About') {
      return (
        <Grid
          item
          container
          justify="center"
          xs={12}
          className={props.classes.searchField}
        >
          <img
            src={LogoTagline}
            alt="LegitFish Logo Tag line"
            className={props.classes.legitFishTagLine}
          />
        </Grid>
      )
    }
  }
  function showOverlay() {
    if (componentToShow === 'Trace') {
      return (
        <img
          alt="Carousel"
          className={classNames(
            props.classes.carouselImage,
            props.classes.overlayimage
          )}
          src={bboverlay}
        ></img>
      )
    }
  }

  function setSelectedRecipeAndImage(url, image) {
    setSelectedRecipe(url)
    setSelectedRecipeImage(image)
  }

  function selectInfo() {
    if (hostName.startsWith('bb04')) {
      return <Trans i18nKey="industry_harvest_more_info" />
    } else {
      return <Trans i18nKey="pending_trace_more_info" />
    }
  }

  function showResult() {
    if (loadingState === 'loading') {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      const components = {
        Trace: TraceContainer,
        Recipes: RecipesContainer,
        Sustainability: SustainabilityContainer,
        About: AboutContainer,
      }
      const ShowSpecificComponent = components[componentToShow]
      return (
        <div>
          {componentToShow === 'Recipes' && !selectedRecipe ? (
            <Box className={props.classes.recipesBox}>
              <TopAppBarContainer
                componentToShow={componentToShow}
                component={component}
              />
              <Container className={props.classes.recipeCard}>
                <Typography
                  variant="h5"
                  className={classNames(
                    props.classes.recipeText,
                    component === 'ButcherBoxContainer'
                      ? classes.butcherBoxColor
                      : classes.bigEasyColor
                  )}
                >
                  Recipes
                </Typography>
                {trace.recipes.map(recipe => {
                  return (
                    <RecipesCardContainer
                      key={recipe.name}
                      setSelectedRecipeAndImage={setSelectedRecipeAndImage}
                      component={component}
                      species={trace.species}
                      recipe={recipe}
                    />
                  )
                })}
              </Container>
            </Box>
          ) : (
            <div className={props.classes.recipesBox}>
              <Box height="50vh">
                <TopAppBarContainer
                  componentToShow={componentToShow}
                  component={component}
                  selectedRecipe={true}
                  handleClickOpen={handleClickOpen}
                  back={back}
                />
                <Container className={props.classes.topContainer}>
                  {showOverlay()}
                  {carouselImages()}
                  {showSearchField()}
                </Container>
              </Box>
              <Box height="38vh">
                <div>
                  <div className={props.classes.wrapper}>
                    <Card className={classes.root}>
                      <div className={classes.topLineDiv}>
                        <img
                          src={TopLine}
                          alt="Top Line"
                          className={classes.topLine}
                        />
                      </div>
                      <div className={classes.cardHead}>
                        {cardHeadSelector()}
                      </div>
                      <div className={classes.splitLine}>
                        <img src={SplitLine} alt="Split Line" />
                      </div>
                      <div className={classes.cardArea}>
                        <CardContent className={classes.cardContent}>
                          <ShowSpecificComponent
                            setSelectedRecipe={setSelectedRecipe}
                            selectedRecipe={selectedRecipe}
                            trace={trace}
                            iframe={selectedRecipe}
                          />
                        </CardContent>
                      </div>
                    </Card>
                  </div>
                </div>
              </Box>
            </div>
          )}
          <Box height="12vh">
            <BottomAppBarContainer
              componentToShow={componentToShow}
              setComponentToShow={setComponentToShow}
              setSelectedRecipe={setSelectedRecipe}
              component={component}
            />
          </Box>
          <Dialog open={ticketId ? true : false} onClose={closeTicketDialog}>
            <Alert severity="success">
              <Typography color="inherit">
                <Trans i18nKey="ticket_number_is" />: {ticketId}
              </Typography>
            </Alert>
          </Dialog>
          <Dialog open={open} className={props.classes.dialog}>
            <DialogContent>
              <DialogContentText className={props.classes.reportProblemHeading}>
                <Trans i18nKey="report_a_problem" />
              </DialogContentText>
              <DialogContentText className={props.classes.reportProblemText}>
                <Trans i18nKey="issue_with_site" />?
              </DialogContentText>
              <DialogContentText className={props.classes.reportProblemText}>
                <Trans i18nKey="fullName" />
              </DialogContentText>
              <TextField
                placeholder="Your name"
                onChange={e => setUserName(e.currentTarget.value)}
                value={userName}
                variant="outlined"
                fullWidth
              />
              <DialogContentText className={props.classes.reportProblemText}>
                <Trans i18nKey="email_address" />
              </DialogContentText>
              <TextField
                placeholder="Your email address"
                onChange={e => setUserEmail(e.currentTarget.value)}
                value={userEmail}
                variant="outlined"
                fullWidth
              />
              <DialogContentText className={props.classes.reportProblemText}>
                <Trans i18nKey="mobile_number" />
              </DialogContentText>
              <TextField
                placeholder="Your phone number"
                onChange={e => setUserPhone(e.currentTarget.value)}
                value={userPhone}
                variant="outlined"
                fullWidth
              />
              <DialogContentText className={props.classes.reportProblemText}>
                <Trans i18nKey="describe_the_problem" />.
              </DialogContentText>
              <FormControl fullWidth error>
                <TextField
                  placeholder="Describe the problem you experienced here..."
                  multiline
                  rows={5}
                  onChange={e => setDescribeProblem(e.currentTarget.value)}
                  value={describeProblem}
                  variant="outlined"
                  fullWidth
                />
                <FormHelperText>{errorMsg}</FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <Trans i18nKey="cancel" />
              </Button>
              <Button
                id="reportProblemButton"
                variant="outlined"
                type="button"
                onClick={handleSubmit}
                margin="normal"
                className={props.classes.submitButton}
              >
                <Trans i18nKey="submit" />
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullScreen
            open={openPlayer ? true : false}
            onClose={() => setOpenPlayer('')}
          >
            <ReactPlayer
              url={openPlayer}
              muted={false}
              width="100%"
              height="100%"
              playing={true}
              controls
            />
            <Button
              onClick={() => setOpenPlayer('')}
              color="secondary"
              className={props.classes.videoCloseButton}
            >
              <HighlightOffIcon fontSize="large" />
            </Button>
          </Dialog>
          <Dialog open={openInfo} onClose={handleCloseInfo}>
            <Alert icon={false} severity="warning">
              {selectInfo()}
            </Alert>
          </Dialog>
        </div>
      )
    }
  }

  return <MuiThemeProvider theme={themes}> {showResult()} </MuiThemeProvider>
}

export const ButcherBoxContainer = withStyles(styles)(ButcherBox)
