import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ChannelFishLogo from '../../../assets/trace/channelfish/ChannelFishLogo.svg'

const styles = () => ({
  image: {
    width: '80%',
  },
})

export function ChannelFishLogoImage(props) {
  return (
    <Grid container spacing={1} alignItems="flex-end" direction="row">
      <Grid item xs={12}>
        <img
          src={ChannelFishLogo}
          alt="Channel Fish Logo"
          className={props.classes.image}
        />
      </Grid>
    </Grid>
  )
}

export const ChannelFishLogoContainer = withStyles(styles)(ChannelFishLogoImage)
