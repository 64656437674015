import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, AppBar, Toolbar, IconButton } from '@material-ui/core'
import { theme } from '../../../pages/shared/theme'
import BBInfo from '../../../assets/trace/butcherbox/BBInfo.svg'
import Info from '../../../assets/trace/bigeasy/Info.svg'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'
import BBLogo from '../../../assets/trace/butcherbox/BBLogo.png'
import BBWhiteLogo from '../../../assets/trace/butcherbox/BBWhiteLogo.png'
import LfIconBlue from '../../../assets/trace/bigeasy/LfIconBlue.svg'
import LfIconWhite from '../../../assets/trace/bigeasy/LfIconWhite.svg'

const styles = () => ({
  appBarTopRecipes: {
    paddingTop: '2vh',
    boxShadow: 'none',
    color: '#2D4D59',
    backgroundColor: 'transparent',
    maxWidth: '100%',
    position: 'relative',
  },
  appBarTop: {
    paddingTop: '2vh',
    zIndex: 2,
    boxShadow: 'none',
    color: '#2D4D59',
    backgroundColor: 'transparent',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '25%',
      marginRight: '25%',
      maxWidth: '50%',
    },
  },
  iconButton: {
    padding: '0px',
  },
  bbLocationIcon: {
    fontSize: 35,
    color: 'white',
  },
  bigEasyLocationIcon: {
    fontSize: 35,
    color: '#3675AD',
  },
  butcherBoxLogoContainer: {
    width: '55%',
    maxHeight: '7vh',
  },
  bigEasyLogoContainer: {
    width: '40%',
  },
})

export function TopAppBar(props) {
  function selectLogo() {
    if (props.component === 'ButcherBoxContainer') {
      return props.componentToShow === 'Recipes' && !props.selectedRecipe
        ? BBLogo
        : BBWhiteLogo
    } else {
      return props.componentToShow === 'Sustainability' ||
        props.componentToShow === 'About'
        ? LfIconWhite
        : LfIconBlue
    }
  }

  return (
    <AppBar
      className={
        props.componentToShow === 'Recipes' && !props.selectedRecipe
          ? props.classes.appBarTopRecipes
          : props.classes.appBarTop
      }
    >
      <Toolbar>
        <Grid container alignItems="center">
          {props.componentToShow === 'Trace' ? (
            <Grid item container xs={2} lg={4} justify="flex-start">
              <IconButton
                color="inherit"
                className={props.classes.iconButton}
                onClick={props.back}
              >
                <LocationSearchingIcon
                  className={
                    props.component === 'ButcherBoxContainer'
                      ? props.classes.bbLocationIcon
                      : props.classes.bigEasyLocationIcon
                  }
                />
              </IconButton>
            </Grid>
          ) : (
            <Grid item container xs={2} lg={4} justify="flex-start"></Grid>
          )}
          <Grid item container xs={8} lg={4} justify="center">
            <img
              src={selectLogo()}
              alt="ButcherBox Logo"
              className={
                props.component === 'ButcherBoxContainer'
                  ? props.classes.butcherBoxLogoContainer
                  : props.classes.bigEasyLogoContainer
              }
            />
          </Grid>
          <Grid item xs={1} lg={3}></Grid>
          {props.componentToShow === 'Trace' ? (
            <Grid item container xs={1} justify="flex-end">
              <img
                src={props.component === 'ButcherBoxContainer' ? BBInfo : Info}
                alt="ButcherBox Info"
                width="40vw"
                onClick={props.handleClickOpen}
              />
            </Grid>
          ) : (
            <Grid item container xs={1} justify="flex-end"></Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export const TopAppBarContainer = withStyles(styles)(TopAppBar)
