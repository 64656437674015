import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  createMuiTheme,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import { ApiHelper } from '../../lib'
import { Trans } from 'react-i18next'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import withDealerTheme from '../shared/DealerStyles'
import BootstrapInput from './TextfieldForDealer'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import { saveVessel, setPermitTypes } from '../../features/dealerSlice'

const themes = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '900px',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: 'white !important',
        padding: '0px !important',
        color: 'black',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#4D4D4D',
        '&$focused': {
          color: '#4D4D4D',
        },
        '&$disabled': {
          color: '#B3B3B3',
        },
        '&$error': {
          color: '#B80000',
        },
        '&$filled': {
          color: '#4D4D4D',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0px',
      },
    },
    MuiAutocomplete: {
      root: {
        padding: '0px 0px 0px 0px !important',
      },
      input: {
        padding: '0px 0px !important',
      },
      option: {
        fontSize: '0.75rem',
        textTransform: 'none',
      },
      inputRoot: {
        height: '2rem',
        borderRadius: '0px',
        backgroundColor: '#F6F9FA',
        fontSize: '0.75rem',
        '&$focused': {
          borderColor: '#E0E0E0',
        },
      },
    },
  },
})

export function VesselForm(props) {
  const classes = withDealerTheme()

  const [errorMsg, setErrorMsg] = useState('')
  const dispatch = useDispatch()
  const newVessel = useSelector(state => state.dealer.newVessel)
  const permitTypes = useSelector(state => state.dealer.permitTypes)
  const dealerPorts = useSelector(state => state.dealer.dealerPorts)
  const captainContactAttribute = newVessel.contacts.find(
    l => l.role === 'captain'
  )
  const representativeContactAttribute = newVessel.contacts.find(
    l => l.role === 'representative'
  )
  const permitAttribute = useSelector(
    state => state.dealer.newVessel.vessel_permits[0]
  )

  let apiInput = {
    name: newVessel.name,
    vessel_federal_identifier: newVessel.vessel_federal_identifier,
    hull_id_number: newVessel.hull_id_number,
    home_port_id: newVessel.home_port_id,
    vessel_permits_attributes: newVessel.vessel_permits,
    contacts_attributes: newVessel.contacts,
  }

  useEffect(() => {
    const api = new ApiHelper()
    api
      .get(`/permit_types`)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setPermitTypes(result))
        } else {
          dispatch(setPermitTypes([]))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const modifyVessel = Object.assign({}, newVessel)
  const setAttribute = (key, val) => {
    modifyVessel[key] = val
    dispatch(saveVessel(modifyVessel))
  }

  const setPermitAttribute = (key, val) => {
    const modifyVesselPermit = Object.assign({}, permitAttribute)
    modifyVesselPermit[key] = val
    modifyVessel['vessel_permits'] = [modifyVesselPermit]
    dispatch(saveVessel(modifyVessel))
  }

  const setCaptainContactAttribute = (key, val) => {
    const modifyVesselContact = Object.assign({}, captainContactAttribute)
    modifyVesselContact[key] = val
    modifyVessel['contacts'] = [
      modifyVesselContact,
      representativeContactAttribute,
    ]
    dispatch(saveVessel(modifyVessel))
  }

  const setRepresentativeContactAttribute = (key, val) => {
    const modifyVesselContact = Object.assign(
      {},
      representativeContactAttribute
    )
    modifyVesselContact[key] = val
    modifyVessel['contacts'] = [captainContactAttribute, modifyVesselContact]
    dispatch(saveVessel(modifyVessel))
  }

  const handleFinishCancel = (event, actionType) => {
    event.preventDefault()

    if (actionType === 'cancel') {
      props.backToVessels()
      return
    } else if (newVessel.name && newVessel.vessel_federal_identifier) {
      const api = new ApiHelper()
      if (!props.vesselId) {
        api
          .post('/vessels', apiInput)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              return props.backToVessels()
            } else {
              setErrorMsg('Error returning result')
            }
          })
          .catch(error => {
            console.error(error)
            setErrorMsg(error)
          })
      } else {
        api
          .put(`/vessels/${props.vesselId}`, apiInput)
          .then(resp => {
            return resp.json()
          })
          .then(result => {
            if (result) {
              return props.backToVessels()
            } else {
              setErrorMsg('Error returning result')
            }
          })
          .catch(error => {
            console.error(error)
            setErrorMsg(error)
          })
      }
    } else {
      setErrorMsg('Please fill all the details')
    }
  }

  return (
    <div>
      <MuiThemeProvider theme={themes}>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="simple-dialog-title"
          scroll="body"
          fullWidth={true}
          className={classes.dialogPaper}
        >
          <DialogTitle id="form-dialog-title">
            <Grid container className={classes.titleGridContainer}>
              <Grid container item xs={11}>
                <Grid item xs={12} md={4}>
                  <Typography className={classes.titleText}>
                    <Trans i18nKey="add_a_new_vessel" />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={1} justify="flex-end">
                <Grid
                  container
                  item
                  xs={1}
                  alignItems="flex-start"
                  justify="flex-end"
                >
                  <Cancel
                    className={classes.cancelIcon}
                    fontSize="small"
                    onClick={event => handleFinishCancel(event, 'cancel', '')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.lightText}>
                  <Trans i18nKey="vessel_info" />
                </Typography>
              </Grid>
              <Grid item md={3} />
              <Grid container item md={9}>
                <Grid item xs={9} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="vessel_name" />*
                    </InputLabel>
                    <BootstrapInput
                      value={newVessel.name ? newVessel.name : ''}
                      name="name"
                      id="name"
                      onChange={e =>
                        setAttribute('name', e.currentTarget.value)
                      }
                      placeholder="Seafood Vessel"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="federal_id_number" />*
                    </InputLabel>
                    <BootstrapInput
                      value={
                        newVessel.vessel_federal_identifier
                          ? newVessel.vessel_federal_identifier
                          : ''
                      }
                      name="vessel_federal_identifier"
                      id="vessel_federal_identifier"
                      onChange={e =>
                        setAttribute(
                          'vessel_federal_identifier',
                          e.currentTarget.value
                        )
                      }
                      placeholder="124456"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="hull_id_number" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        newVessel.hull_id_number ? newVessel.hull_id_number : ''
                      }
                      name="hull_id_number"
                      id="hull_id_number"
                      onChange={e =>
                        setAttribute('hull_id_number', e.currentTarget.value)
                      }
                      placeholder="1243"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="state_permit_number" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        newVessel.vessel_permits[0]
                          ? newVessel.vessel_permits[0].permit_number
                          : ''
                      }
                      name="permit_number"
                      id="permit_number"
                      onChange={e =>
                        setPermitAttribute(
                          'permit_number',
                          e.currentTarget.value
                        )
                      }
                      placeholder="243456789"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="state_permit_type" />
                    </InputLabel>
                    <Select
                      value={
                        newVessel.vessel_permits[0]
                          ? newVessel.vessel_permits[0].permit_type_id
                          : ''
                      }
                      name="permit_type_id"
                      id="permit_type_id"
                      displayEmpty
                      disableUnderline
                      className={classes.selectEmpty}
                      onChange={e =>
                        setPermitAttribute('permit_type_id', e.target.value)
                      }
                    >
                      {permitTypes &&
                        permitTypes.map(permitType => {
                          return (
                            <MenuItem
                              key={permitType.id}
                              value={permitType.id}
                              className={classes.selectMenuText}
                            >
                              {permitType.name}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} md={5} />
                <Grid item xs={6} />
                <Grid item xs={9} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="home_port" />*
                    </InputLabel>
                    <Select
                      value={
                        newVessel.home_port_id ? newVessel.home_port_id : ''
                      }
                      name="home_port"
                      displayEmpty
                      disableUnderline
                      className={classes.selectEmpty}
                      onChange={e =>
                        setAttribute('home_port_id', e.target.value)
                      }
                    >
                      {dealerPorts &&
                        dealerPorts.map(port => {
                          return (
                            <MenuItem
                              key={port.id}
                              value={port.id}
                              className={classes.selectMenuText}
                            >
                              {port.name}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={9} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="associated_company" />*
                    </InputLabel>
                    <BootstrapInput
                      disabled
                      value={
                        newVessel.order ? newVessel.order.permit_number : ''
                      }
                      name="associated_company"
                      id="associated_company"
                      onChange={e =>
                        setAttribute(
                          'associated_company',
                          e.currentTarget.value
                        )
                      }
                      placeholder="Seafood Company Inc."
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.lightText}>
                  <Trans i18nKey="contact_info" />
                </Typography>
              </Grid>
              <Grid item md={3} />
              <Grid container item md={9}>
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="captain_full_name" />*
                    </InputLabel>
                    <BootstrapInput
                      value={
                        captainContactAttribute
                          ? captainContactAttribute.full_name
                          : ''
                      }
                      name="full_name"
                      id="full_name"
                      onChange={e =>
                        setCaptainContactAttribute(
                          'full_name',
                          e.currentTarget.value
                        )
                      }
                      placeholder="Joe Smith"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="captain_cell_phone_number" />*
                    </InputLabel>
                    <BootstrapInput
                      value={
                        captainContactAttribute
                          ? captainContactAttribute.mobile_number
                          : ''
                      }
                      name="mobile_number"
                      id="mobile_number"
                      onChange={e =>
                        setCaptainContactAttribute(
                          'mobile_number',
                          e.currentTarget.value
                        )
                      }
                      placeholder="012-345-6789"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="captain_email_address" />*
                    </InputLabel>
                    <BootstrapInput
                      value={
                        captainContactAttribute
                          ? captainContactAttribute.email
                          : ''
                      }
                      name="email"
                      id="email"
                      onChange={e =>
                        setCaptainContactAttribute(
                          'email',
                          e.currentTarget.value
                        )
                      }
                      placeholder="Joe@emailprovider.com"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="captain_fax_number" />*
                    </InputLabel>
                    <BootstrapInput
                      value={
                        captainContactAttribute
                          ? captainContactAttribute.fax
                          : ''
                      }
                      name="fax"
                      id="fax"
                      onChange={e =>
                        setCaptainContactAttribute('fax', e.currentTarget.value)
                      }
                      placeholder="012-345-6789"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="representative_full_name" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        representativeContactAttribute
                          ? representativeContactAttribute.full_name
                          : ''
                      }
                      name="representative_full_name"
                      id="representative_full_name"
                      onChange={e =>
                        setRepresentativeContactAttribute(
                          'full_name',
                          e.currentTarget.value
                        )
                      }
                      placeholder="Joe Smith"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="rep_cell_phone_number" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        representativeContactAttribute
                          ? representativeContactAttribute.mobile_number
                          : ''
                      }
                      name="mobile_number"
                      id="mobile_number"
                      onChange={e =>
                        setRepresentativeContactAttribute(
                          'mobile_number',
                          e.currentTarget.value
                        )
                      }
                      placeholder="012-345-6789"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="rep_email_address" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        representativeContactAttribute
                          ? representativeContactAttribute.email
                          : ''
                      }
                      name="email"
                      id="email"
                      onChange={e =>
                        setRepresentativeContactAttribute(
                          'email',
                          e.currentTarget.value
                        )
                      }
                      placeholder="Joe@emailprovider.com"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <FormControl className={classes.margin} fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className={classes.labelText}
                    >
                      <Trans i18nKey="rep_fax_number" />
                    </InputLabel>
                    <BootstrapInput
                      value={
                        representativeContactAttribute
                          ? representativeContactAttribute.fax
                          : ''
                      }
                      name="fax"
                      id="fax"
                      onChange={e =>
                        setRepresentativeContactAttribute(
                          'fax',
                          e.currentTarget.value
                        )
                      }
                      placeholder="012-345-6789"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.lightText}>
                  <Trans i18nKey="notification_settings" />
                </Typography>
              </Grid>
              <Grid item md={3} />
              <Grid container item md={9} alignItems="center">
                <Grid item xs={3} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Trans i18nKey="captain" />:
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="email" />
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="sms_text_message" />
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Trans i18nKey="representative" />:
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="email" />
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.gridFirstRow}>
                  <Typography className={classes.lightText}>
                    <Checkbox
                      disabled
                      size="small"
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Trans i18nKey="sms_text_message" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {errorMsg ? (
              <ErrorMessageComponent errorMessage={errorMsg} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Grid
              container
              item
              xs={12}
              className={classes.gridFirstRow}
              alignItems="flex-start"
            >
              <Grid container item xs={5} md={8} />
              <Grid container item xs={7} md={3} justify="space-around">
                <Button
                  size="small"
                  onClick={event => handleFinishCancel(event, 'cancel', '')}
                  classes={{ label: classes.cancelButtonText }}
                >
                  <Trans i18nKey="cancel" />
                </Button>
                <Button
                  variant="contained"
                  onClick={event => handleFinishCancel(event, 'submit', '')}
                  size="small"
                  className={classes.finishButton}
                  classes={{ label: classes.buttonText }}
                >
                  <Trans i18nKey="save" />
                </Button>
              </Grid>
              <Grid container item xs={1} md={1} />
            </Grid>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </div>
  )
}

export const VesselFormContainer = VesselForm
