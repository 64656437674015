import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ButcherBoxLogo from '../../../assets/trace/butcherbox/ButcherBoxLogo.svg'

const styles = () => ({
  logoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  imageContainer: {
    paddingLeft: '3vw',
  },
})

export function ButcherBoxLogoImage(props) {
  return (
    <Grid
      data-testid="ButcherBoxLogo"
      container
      spacing={1}
      alignItems="flex-end"
      direction="row"
      classes={{ root: props.classes.logoContainer }}
    >
      <Grid item xs={12}>
        <img
          src={ButcherBoxLogo}
          alt="Devi Shrimp Logo"
          className={props.classes.imageContainer}
        />
      </Grid>
    </Grid>
  )
}

export const ButcherBoxLogoContainer = withStyles(styles)(ButcherBoxLogoImage)
