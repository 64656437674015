import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Container } from '@material-ui/core'
import { Trans } from 'react-i18next'
import NoReportsIcon from '../../assets/hail/NoReportsIcon.svg'

const styles = () => ({
  noReports: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '15vh',
    textAlign: 'center',
  },

  noReportsIcon: {
    width: '30%',
  },

  noReportsHeading: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: '#003E6D',
    paddingTop: '2vh',
  },

  noReportsText: {
    fontWeight: 400,
    fontSize: '1.2rem',
    color: '#003E6D',
    paddingTop: '3px',
  },
})

export function NoReports(props) {
  return (
    <Container className={props.classes.noReports}>
      <img
        alt="No Report Icon"
        className={props.classes.noReportsIcon}
        src={NoReportsIcon}
      />
      <Typography className={props.classes.noReportsHeading}>
        <Trans i18nKey="no_past_report" />
      </Typography>
      <Typography className={props.classes.noReportsText}>
        <Trans i18nKey="when_you_add_a_new_trip" />
        <br />
        <Trans i18nKey="history_will_appear" />!
      </Typography>
    </Container>
  )
}

export const NoReportsContainer = withStyles(styles)(NoReports)
