import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EastCoastLogo from '../../../assets/trace/eastcoast/EastCoastLogo.png'

const styles = () => ({
  logoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
  },
  logo: {
    width: '100%',
  },
})

export function EastCoastLogoImage(props) {
  return (
    <Grid
      data-testid="EastCoastLogo"
      container
      spacing={1}
      alignItems="flex-end"
      direction="row"
      classes={{ root: props.classes.logoContainer }}
    >
      <Grid item xs={12}>
        <img
          className={props.classes.logo}
          src={EastCoastLogo}
          alt="East Coast Logo"
        />
      </Grid>
    </Grid>
  )
}

export const EastCoastLogoContainer = withStyles(styles)(EastCoastLogoImage)
