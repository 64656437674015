import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  iframe: {
    width: '100%',
    height: '100vh',
    borderWidth: '0',
  },
})

export function Recipes(props) {
  return (
    <iframe
      title="Fisheries site"
      src={props.iframe}
      width="100%"
      height="100%"
      className={props.classes.iframe}
    ></iframe>
  )
}

export const RecipesContainer = withStyles(styles)(Recipes)
