import { SET_REFERENCE_DATA, CLEAR_REFERENCE_DATA } from '../actions'

const initialState = {}

export default function referenceData(state = initialState, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case SET_REFERENCE_DATA:
      newState = action.reference_data
      return newState

    case CLEAR_REFERENCE_DATA:
      newState = initialState
      return newState

    default:
      return state
  }
}
