import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Radio,
  Button,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withHailTheme } from '../../pages/shared/hailTheme'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { findPortsByDealerId, findDealerById } from '../../selectors'
import { saveHailEventually } from '../../features/hailSlice'

const styles = () => ({
  textFieldHeading: {
    opacity: '60%',
    paddingTop: '2vh',
  },

  textField: {
    opacity: '55%',
    textAlign: 'center',
    fontSize: '0.8rem',
  },

  portListGrid: {
    height: '40vh',
    overflow: 'auto',
  },

  searcIcon: {
    color: '#003E6D',
  },

  loadingGrid: {
    minHeight: '70vh',
  },
})

export function ArrivalPort(props) {
  const dealers = useSelector(state => state.dealers)
  const organization = useSelector(state => state.organization)

  const dispatch = useDispatch()

  let dealerPorts = findPortsByDealerId(dealers, props.hail.dealer_id)

  const [loadingState] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    let homePort = dealerPorts.find(
      port => port.id === organization.home_port_id
    )
    if (!props.hail.port_id && homePort) {
      props.setAttribute('port_id', organization.home_port_id)
    } else {
      return
    }
  }, [props.hail.dealer_id])

  const arrivalDate = () => {
    if (!props.hail.port_id) {
      setErrorMsg('Please select a port')
      setOpenDialog(true)
    } else {
      dispatch(saveHailEventually(props.hail))
      if (props.modify) {
        props.setShowItems('Finalize')
      } else {
        props.setShowItems('Date')
      }
    }
  }

  function buttonSelector() {
    if (props.modify) {
      return <Trans i18nKey="modify" />
    } else {
      return <Trans i18nKey="next" />
    }
  }

  function showPortList() {
    if (!props.hail.dealer_id) {
      return (
        <Grid
          id="enterDealer"
          data-testid="enterDealer"
          item
          container
          xs={12}
          justify="center"
        >
          <Grid item container xs={9} justify="center">
            <Typography color="inherit" className={props.classes.textField}>
              <Trans i18nKey="please_enter_dealer_selection" />
            </Typography>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <List id="portList" data-testid="portList">
          {dealerPorts.map(p => {
            return renderPort(p)
          })}
        </List>
      )
    }
  }

  function renderPort(port) {
    let text = 'primary'
    const labelId = `checkbox-list-label-${port.id}`
    return (
      <div key={`div-${port.id}`}>
        <ListItem
          alignItems="flex-start"
          key={port.id}
          role={undefined}
          dense
          button
        >
          <ListItemIcon>
            <Radio
              edge="start"
              checked={props.hail.port_id === port.id}
              onChange={() => props.setAttribute('port_id', port.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText
            className={classNameForSelectedPort(port, text)}
            id={labelId}
            primary={port.name}
            secondary={
              <React.Fragment>
                <Typography
                  className={classNameForSelectedPort(
                    port,
                    (text = 'secondary')
                  )}
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {port.state}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
      </div>
    )
  }

  function classNameForSelectedPort(port, text) {
    if (!props.hail.port_id) {
      return
    }
    if (props.hail.port_id === port.id && text === 'primary') {
      return props.classes.listPriText
    } else if (props.hail.port_id === port.id && text === 'secondary') {
      return props.classes.listSecText
    }
  }

  function loadArrivalPort() {
    if (loadingState === 'loading') {
      return (
        <Grid
          id="circularProgress"
          container
          justify="center"
          alignItems="center"
          className={props.classes.loadingGrid}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <div>
          <Box height="60vh">
            <Grid
              container
              spacing={0}
              className={props.classes.bottomGridContainer}
            >
              <Grid item xs={12}>
                <Typography
                  color="inherit"
                  className={props.classes.headingText}
                >
                  <Trans i18nKey="select_arrival_port" />:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="inherit"
                  className={props.classes.textFieldHeading}
                >
                  <Trans i18nKey="dealer_name" />:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="dealerSelection"
                  data-testid="dealerSelection"
                  value={findDealerById(dealers, props.hail.dealer_id)}
                  onChange={(e, newValue) => {
                    props.setAttribute(
                      'dealer_id',
                      newValue ? newValue.id : null
                    )
                  }}
                  getOptionLabel={option => {
                    return option.name
                  }}
                  options={dealers}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="&nbsp;Dealer Selection"
                      variant="filled"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment className={props.classes.searcIcon}>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="inherit"
                  className={props.classes.textFieldHeading}
                >
                  <Trans i18nKey="arrival_port" />
                </Typography>
              </Grid>
              <Grid item xs={12} className={props.classes.portListGrid}>
                {showPortList()}
              </Grid>
            </Grid>
          </Box>
          <Box height="10vh">
            <Grid container justify="center" alignItems="flex-end">
              <Button
                id="portButton"
                data-testid="portButton"
                variant="outlined"
                type="button"
                onClick={() => arrivalDate()}
                margin="normal"
                className={props.classes.buttonNext}
              >
                {buttonSelector()}
              </Button>
            </Grid>
          </Box>
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
          >
            <ErrorMessageComponent errorMessage={errorMsg} />
          </Dialog>
        </div>
      )
    }
  }

  return <div>{loadArrivalPort()}</div>
}

export const ArrivalPortContainer = withStyles(
  combineStyles(styles, stylesBase)
)(withHailTheme(ArrivalPort))
