import { SET_FISHING_AREAS } from '../actions'

const initialState = []

export default function fishingAreas(state = initialState, action) {
  switch (action.type) {
    case SET_FISHING_AREAS:
      return action.fishingAreas

    default:
      return state
  }
}
