import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ErrorContainer } from '../components'
import DealerLayout from '../pages/dealer/DealerLayout'
import { useActionCable } from '../actioncable'
import { addNotification } from '../actions'

export function PrivateRoute(props) {
  const channelParams = { channel: 'Noticed::NotificationChannel' }
  const channelHandlers = {
    received(data) {
      props.addNotification(data)
    },
  }

  useActionCable(channelParams, channelHandlers)

  function pathMatchesRole() {
    const role = props.user.role.toLowerCase()
    const path = props.path.split('/')[1]
    return role === path
  }

  const isLoggedIn = props.user && props.user.role

  if (isLoggedIn) {
    if (pathMatchesRole()) {
      const path = props.path.split('/')[1]
      if (path === 'dealer') {
        return (
          <div>
            <DealerLayout {...props} />
          </div>
        )
      } else {
        return <Route {...props} />
      }
    } else {
      return <ErrorContainer status="403" message="Forbidden" />
    }
  } else {
    return <Redirect to="/sign_in" />
  }
}

const mapDispatchToProps = {
  addNotification,
}

const mapStateToProps = state => {
  return { userNotifications: state.userNotifications, user: state.user }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
