import { SET_PRODUCTS } from '../actions'

const initialState = []

export default function products(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.products

    default:
      return state
  }
}
