import React, { useState } from 'react'
import combineStyles from '../../pages/shared/CombineStyles'
import { stylesBase } from '../../pages/shared/StylesBase'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withHailTheme } from '../../pages/shared/hailTheme'
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { saveHailEventually } from '../../features/hailSlice'

const styles = () => ({
  textFieldHeading: {
    paddingTop: '5%',
    opacity: '70%',
  },

  textField: {
    opacity: '50%',
  },

  permitNumberText: {
    paddingRight: '5%',
    opacity: '50%',
  },

  loadingGrid: {
    minHeight: '70vh',
  },
})

export function Evtr(props) {
  const dispatch = useDispatch()

  const [loadingState] = useState('')

  const [errorMsg, setErrorMsg] = useState('')

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  const arrivalPort = () => {
    if (!props.hail.permit_number || !props.hail.number_of_crew) {
      setErrorMsg('Please fill all the details')
      setOpenDialog(true)
    } else {
      const order = props.hail
      dispatch(saveHailEventually(order, false))
      if (props.modify) {
        props.setShowItems('Finalize')
      } else {
        props.setShowItems('Port')
      }
    }
  }

  function buttonSelector() {
    if (props.modify) {
      return <Trans i18nKey="modify" />
    } else {
      return <Trans i18nKey="next" />
    }
  }

  function loadEvtr() {
    if (loadingState === 'loading') {
      return (
        <Grid
          id="circularProgress"
          container
          justify="center"
          alignItems="center"
          className={props.classes.loadingGrid}
        >
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <div>
          <Box height="60vh">
            <Grid
              container
              spacing={0}
              className={props.classes.bottomGridContainer}
            >
              <Grid item xs={12}>
                <Typography
                  color="inherit"
                  className={props.classes.headingText}
                >
                  <Trans i18nKey="enter_evtr_information" />:
                </Typography>
              </Grid>
              <Grid item xs={7} className={props.classes.textFieldHeading}>
                <Typography color="inherit">
                  <Trans i18nKey="permit_number" />:
                </Typography>
              </Grid>
              <Grid item xs={5} className={props.classes.textFieldHeading}>
                <Typography color="inherit">
                  <Trans i18nKey="crew_number" />:
                </Typography>
              </Grid>
              <Grid item xs={7} className={props.classes.permitNumberText}>
                <TextField
                  autoComplete="off"
                  id="permitNumber"
                  data-testid="permitNumber"
                  value={
                    props.hail.permit_number ? props.hail.permit_number : ''
                  }
                  onChange={e =>
                    props.setAttribute('permit_number', e.currentTarget.value)
                  }
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} className={props.classes.textField}>
                <TextField
                  type="number"
                  autoComplete="off"
                  id="crewNumber"
                  data-testid="crewNumber"
                  value={
                    props.hail.number_of_crew ? props.hail.number_of_crew : ''
                  }
                  onChange={e =>
                    props.setAttribute('number_of_crew', e.currentTarget.value)
                  }
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="inherit"
                  className={props.classes.textFieldHeading}
                >
                  <Trans i18nKey="start_time" />:
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    id="startTime"
                    data-testid="startTime"
                    value={props.hail.start_time}
                    onChange={time => props.setAttribute('start_time', time)}
                    fullWidth
                    keyboardIcon={<ScheduleIcon />}
                    InputProps={{ className: `${props.classes.timeField}` }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Box>
          <Box height="10vh">
            <Grid container justify="center" alignItems="flex-end">
              <Button
                id="evtrButton"
                data-testid="evtrButton"
                className={props.classes.buttonNext}
                variant="outlined"
                type="button"
                onClick={() => arrivalPort()}
                margin="normal"
              >
                {buttonSelector()}
              </Button>
            </Grid>
          </Box>
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
          >
            <ErrorMessageComponent errorMessage={errorMsg} />
          </Dialog>
        </div>
      )
    }
  }

  return <div>{loadEvtr()}</div>
}

export const EvtrContainer = withStyles(combineStyles(styles, stylesBase))(
  withHailTheme(Evtr)
)
