import {
  SET_ORGANIZATION,
  CLEAR_ORGANIZATION,
  SAVE_ORGANIZATION,
} from '../actions'

const initialState = {}

export default function organization(state = initialState, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case SET_ORGANIZATION:
      newState = action.current_organization
      return newState

    case CLEAR_ORGANIZATION:
      newState = initialState
      return newState

    case SAVE_ORGANIZATION:
      return action.organization

    default:
      return state
  }
}
