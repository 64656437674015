import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core'

const styles = () => ({
  cardMedia: {
    height: '70vh',
    borderRadius: '25px',
    marginBottom: '3vh',
  },
  cardMediaText: {
    color: 'white',
    fontWeight: 500,
    fontSize: '1.8rem',
    position: 'absolute',
    bottom: '10vh',
  },
})

export function RecipesCard(props) {
  return (
    <Card className={props.classes.cardMedia}>
      <CardActionArea>
        <CardMedia
          className={props.classes.cardMedia}
          component="img"
          alt="Recipes Image"
          image={props.recipe.image}
          title="Recipes Image"
          onClick={() =>
            props.setSelectedRecipeAndImage(
              props.recipe.url,
              props.recipe.image
            )
          }
        />
        <CardContent>
          <Typography variant="h4" className={props.classes.cardMediaText}>
            {props.recipe.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export const RecipesCardContainer = withStyles(styles)(RecipesCard)
