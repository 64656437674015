import React from 'react'
import { Container } from '@material-ui/core'
import { MainNavContainer } from './MainNav'
import '../assets/AppContainer.css'

export function AppContainer(props) {
  return (
    <Container className="AppContainer" maxWidth="lg">
      <MainNavContainer />
      {props.children}
    </Container>
  )
}
