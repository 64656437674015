import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { Trans } from 'react-i18next'

const useStyles = makeStyles(() => ({
  loadCenter: {
    textAlign: 'center',
    display: 'inline-block',
    marginTop: '35vh',
  },
}))

export function LoadingIndicator() {
  const classes = useStyles()

  return (
    <Grid container className={classes.loadCenter}>
      <CircularProgress />
      <Typography>
        <Trans i18nKey="loading" />
        ...
      </Typography>
    </Grid>
  )
}
