import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import combineStyles from '../shared/CombineStyles'
import { stylesBase } from '../shared/StylesBase'
import { useDispatch, useSelector } from 'react-redux'
import { TableView } from '../../components/TableView'
import { ApiHelper } from '../../lib'
import ErrorMessageComponent from '../../components/ErrorMessageComponent'
import { saveGear, setDealerGears } from '../../features/dealerSlice'

export function DealerGears(props) {
  const headCells = [
    {
      id: 'name',
      label: 'frequently_used_ports',
      sort: 'name',
      width: '50px',
    },
  ]

  const dataURL = 'gears'
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const [startIndex, setstartIndex] = useState(1)
  const [displayDataCount, setDisplayDataCount] = useState(0)
  const initalLimitPerPage = 10
  const [gearId, setGearId] = useState('')

  const initialFilterDate = new Date()

  const [isLoading, setIsLoading] = useState(false)

  let tableParameters = {
    pageSelected: 1,
    order: 'asc',
    orderBy: headCells[0].id,
    searchKey: '',
    limitPerPageSelected: initalLimitPerPage,
    fromDate: initialFilterDate,
    toDate: initialFilterDate,
  }

  const [errorMsg, setErrorMsg] = React.useState('')

  const [paramState, setParamState] = React.useState(tableParameters)
  let sortType = props.match.params.type
  const dispatch = useDispatch()
  const dealerGears = useSelector(state => state.dealer.dealerGears)

  function fetchDisplayData() {
    const api = new ApiHelper()

    let pageInt = parseInt(paramState.pageSelected)
    const limitPerPageInt = parseInt(paramState.limitPerPageSelected)

    //For handling corner case, where user is in last page and limit per page is incrased
    //in this case last page should be reset to lesser value
    const totalPagesInt = Math.ceil(displayDataCount / limitPerPageInt)
    pageInt = pageInt > totalPagesInt ? totalPagesInt : pageInt

    //totalpagesint will be zero, if search result has no values. In this case, pageint
    //should be defaulted to one
    if (pageInt === 0) {
      pageInt = 1
    }

    const startIndexVal = (pageInt - 1) * limitPerPageInt

    let ordersUrl = `/${dataURL}?page=${pageInt}&limit=${limitPerPageInt}&_sort=${paramState.orderBy}&_order=${paramState.order}`
    if (paramState.searchKey !== '') {
      ordersUrl = ordersUrl + '&q=' + paramState.searchKey
    }
    setIsLoading(true)
    api
      .get(ordersUrl)
      .then(resp => {
        return resp.json()
      })
      .then(result => {
        if (result) {
          dispatch(setDealerGears(result.gears))
          setstartIndex(startIndexVal)
          setDisplayDataCount(result.pagination.count)
          setErrorMsg('')
        } else {
          setErrorMsg('Error occured while retrieving Dealer Gears')
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setErrorMsg(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchDisplayData()
  }, [paramState, dataURL])

  const handleAddNewGear = () => {
    setGearId('')
    dispatch(saveGear({ name: '' }))
    setOpen(true)
  }

  const handleClose = () => {
    setGearId('')
    dispatch(saveGear({ name: '' }))
    setOpen(false)
    setOpenEdit(false)
  }

  const handleEdit = gearId => {
    let index = dealerGears.findIndex(obj => obj.id == gearId)
    let selectedGear = {}
    selectedGear = { ...dealerGears[index] }
    dispatch(saveGear(selectedGear))
    setGearId(gearId)
    setOpen(false)
    setOpenEdit(true)
  }

  function loadData(paramHash, resetStartEndIndex) {
    if (resetStartEndIndex) {
      setstartIndex(1)
    }
    setParamState(paramHash)
  }

  function backToGear() {
    fetchDisplayData()
    handleClose()
  }

  let isSortEnabled = false
  if (sortType) {
    isSortEnabled = true
  }

  return (
    <div>
      <div>
        <TableView
          headCells={headCells}
          displayData={dealerGears}
          startIndex={startIndex}
          sortType={headCells[0].id}
          paramState={paramState}
          loadData={loadData}
          onHandleaddNew={handleAddNewGear}
          displayDataCount={displayDataCount}
          initalLimitPerPage={initalLimitPerPage}
          handleEdit={handleEdit}
          isAddNewAllowed={true}
          isLoading={isLoading}
          sortOptions={false}
          isSortEnabled={isSortEnabled}
          open={open}
          openEdit={openEdit}
          handleClose={handleClose}
          id={gearId}
          backToGear={backToGear}
          formToShow={'gear'}
        />
        {errorMsg ? <ErrorMessageComponent errorMessage={errorMsg} /> : null}
      </div>
    </div>
  )
}

export const DealerGearsContainer = withStyles(combineStyles(stylesBase))(
  DealerGears
)
